import { api } from 'api';

/**
 * cashbox
 ****************************/

/**
 * get payment source for cashbox
 * @returns
 */
export const getPaymentSource = () => {
  return api.call('dictionaries.payment_source');
};
