import { getTarrificatorType } from 'services/types';

// tarifficators
import { TarrificatorDefault } from './';
import TarrificatorTwoPeriods from '../editors/TwoPeriods';
import TarrificatorTumen from '../editors/Tumen';
import TarrificatorIgora from '../editors/Igora';
import TarrificatorParma from '../editors/Parma';
import TarrificatorMenskings from '../editors/Menskings';

// cmp
export const Tarrificator = (props) => {
  const { info, selectedZone, setZone, tariffication, disabled, zones, newIndividual } = props;
  const { tarifficator, type } = info;
  // console.log('🚀 ~ Tarrificator ~ tarifficator:', info);

  let Cmp = null;

  // type
  const tarifficatorType = getTarrificatorType(tarifficator);
  // console.log('🚀 ~ Tarrificator ~ tarifficatorType:', tarifficatorType);

  // params
  const params = {
    type,
    selectedZone,
    setZone,
    zones,
    initialValue: info,
    tariffication,
    disabled,
    newIndividual,
  };

  // render
  switch (tarifficatorType) {
    case 'menskings':
      Cmp = TarrificatorMenskings;
      break;
    case 'igora':
      Cmp = TarrificatorIgora;
      break;
    case 'tumen':
      Cmp = TarrificatorTumen;
      break;
    case 'parma':
      Cmp = TarrificatorParma;
      break;
    case 'two_periods':
      Cmp = TarrificatorTwoPeriods;
      break;
    default:
      Cmp = TarrificatorDefault;
      break;
  }
  return <Cmp {...params} />;
};
