import { GridContainer } from 'libs/ui';
import { Plate } from 'grids/plate/plate';

const SubscriptionWrapper = (props) => {
  return (
    <GridContainer>
      <Plate {...props}  />
    </GridContainer>
  );
}; 

export default SubscriptionWrapper;
