import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import { Container, ButtonsContainer, Input, DictFilter } from 'libs/form';
import { UseBtn, ResetBtn } from 'libs/ui';

class Filter extends Component {
  render() {
    const { handleSubmit, reset } = this.props;

    return (
      <Container /*height='160px'*/>
        <form onSubmit={handleSubmit}>
          <DictFilter dict="benefit_types.status" />
          <Input name="name" label="Клиент" placeholder="Клиент" type="text" />
          <Input name="fn" label="Номер ФН" placeholder="Номер ФН" type="text" />
          <Input width={'170px'} name="min_amount" label="Минимальная сумма, ₽" placeholder="Минимальная сумма" type="text" naturalNumber />
          <Input width={'170px'} name="amount" label="Сумма скидки, ₽" placeholder="Сумма скидки" type="text" naturalNumber />

          <ButtonsContainer>
            <ResetBtn onClick={reset} />
            <UseBtn />
          </ButtonsContainer>
        </form>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'benefit_typesFilter',
  destroyOnUnmount: false,
  initialValues: {},
})(Filter);
