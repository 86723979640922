import styled, { css } from 'styled-components';

export const Info = styled.div``;

export const Content = styled.div`
  font-size: 13px;
  color: #3e3e3e;
  display: flex;
  align-items: flex-end;
  margin: 0 0 10px;
  img {
    margin-right: 10px;
  }
  ${props => props.theme === 'dark' && css`
    color: #fff;
  `}
`;
