import { api } from 'api';

const CLEAR_ENTRIES = '/pages/options/users/CLEAR_ENTRIES';
const ADD_USERS = '/pages/options/users/ADD_USERS';
const SET_OFFSET = '/page/options/users/SET_OFFSET';
const SET_SORT_PARAMS = '/pages/options/users/SET_SORT_PARAMS';
const SET_USER_INFO = '/pages/options/users/SET_USER_INFO';

export default function reducer(state, action) {
  const defaultState = {
    users: {},
    offset: 0,
    userInfo: null,
    sortParams: [
      /*{ field_name: 'role_id', direction: 'desc' }*/
    ],
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case CLEAR_ENTRIES:
      return {
        ...state,
        users: {},
      };
    case ADD_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          ...action.data,
        },
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.data,
      };
    default:
      return state;
  }
}

export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const addUsers = (users) => {
  return {
    type: ADD_USERS,
    data: users,
  };
};
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};
export const setUserInfo = (value) => {
  return {
    type: SET_USER_INFO,
    data: value,
  };
};

export const getRequestParams = (filter) => () => {
  const decorateParams = {};
  for (let key in filter) {
    if (filter[key]) {
      let val = filter[key];
      if (key === 'is_active') {
        if (val === 'true') {
          val = true;
        } else if (val === 'false') {
          val = false;
        } else if (val === 'null') {
          val = null;
        }
      }
      decorateParams[key] = val;
    }
  }
  //console.log('🚀 ~ getRequestParams ~ decorateParams', filter, decorateParams);
  return decorateParams;
};

export const addUser = (params, callback) => () => {
  //params.hm = '123';
  return api.call('users.add', params).then(() => {
    if (callback) {
      callback();
    }
  });
};

export const updateUser = (id, params, callback) => () => {
  params.id = id;
  return api.call('users.update', params).then((data) => {
    if (callback) {
      callback();
    }
  });
};
