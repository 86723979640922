import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getParkingSessionStatus, parkingSessionClose, parkingSessionCreate } from 'services/payments-service';
import { Toggle, Loader2, Error } from 'libs/ui';
import { notify } from 'libs/common';
import { Content } from './parking-session.styled';

export const ParkingSession = (props) => {
  const { meanId, meanType } = props;
  const [checked, setChecked] = useState(null);
  const [stayId, setStayId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getParkingSessionStatus(meanId, meanType)
      .then((result) => {
        setChecked(true);
        setStayId(result.id);
      })
      .catch((error) => {
        if (error.code === 200) {
          setError(null);
          setChecked(false);
        } else {
          setError(error?.message?.toString());
        }
      });
  }, []);

  const onChange = (event) => {
    const newChecked = event.target.checked;
    //console.log('🚀 ~ onChange ~ val:', val);

    if (newChecked) {
      // open session
      parkingSessionCreate(meanId, meanType)
        .then((result) => {
          setChecked(true);
          setStayId(result.id);
          notify('Создание парковочной сессии', 'Успешно', 'success');
        })
        .catch((error) => {
          notify('Ошибка создания парковочной сессии', error?.message);
        });
    } else {
      // close session
      parkingSessionClose(stayId)
        .then(() => {
          setChecked(false);          
          notify('Закрытие парковочной сессии', 'Успешно', 'success');
        })
        .catch((error) => {
          notify('Ошибка закрытия парковочной сессии', error?.message);
        });
    }
  };

  if (checked === null && error === null) {
    return <Loader2 height={'40px'} />;
  }

  return (
    <Content>
      {error ? <Error>Ошибка получения ПС: {error}</Error> : <Toggle label="Парковочная сессия активна" onChange={onChange} checked={checked} />}
    </Content>
  );
};

ParkingSession.propTypes = {
  meanId: PropTypes.string.isRequired,
  meanType: PropTypes.string.isRequired,
};
