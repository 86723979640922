import { Field } from 'redux-form';
import { createTextMask, createNumberMask } from 'redux-form-input-masks';

import { SourceInput } from './source-input';

export const FieldComponent = (props) => {
  const { mask, tabIndex, autoFocus, ...rest } = props;
  let textMask = null;
  let numberMask = null;

  switch (typeof mask) {
    case 'string':
      textMask = createTextMask({
        pattern: props.mask,
        stripMask: !props.storeWithMask,
      });
      break;
    case 'object':
      numberMask = createNumberMask(mask);
      break;
    default:
      break;
  }

  const normalize = (value) => {
    if (value.length > props.maxValueLength) {
      return value.substring(0, value.length - 1);
    } else {
      const fractional = props.fractional;
      // console.log('🚀 ~ normalize ~ reg:', value, props);
      if (props.naturalNumber) {
        // zero
        if (props.withZero) {
          if (!value) {
            return 0;
          }
          return parseInt(value.replace(/[^\d]/g, ''));
        }
        // minus
        if (props.withMinus) {
          if (!value) {
            return 0;
          }

          if (fractional) {
            // [+-]?([0-9]*[.])?[0-9]+
            // [+-]?\d+(\,\d+)?
            // const numbers = value.match(/(^[+-]?([0-9]*[,])?[0-9]+$)/g);
            // console.log('🚀 ~ normalize ~ numbers:', numbers);
            // if (!numbers?.length) {
            //   return 0;
            // }
            // const out = numbers[0].replace(',', '.');
            // return out[out.length - 1] === '.' ? out : parseFloat(out);
            return value
              .replace(',', '.')
              .replace(/[^0-9.+-]|(?<=\..*)\./g, '')
              .replace(/^0+/, '0')
              .replace(/^0(?=[1-9])/, '');
          }

          const numbers = value.match(/([+-]?\d+)/g);
          return numbers && numbers.length ? parseInt(numbers[0]) : 0;
        }

        if (!value || /^0+$/g.test(value)) {
          return '';
        } else {
          return value.replace(/[^\d]/g, '').replace(/^0+(?=[1-9])/g, '');
        }
      } else {
        return value;
      }
    }
  };

  return (
    <Field
      tabIndex={tabIndex}
      autoFocus={autoFocus}
      component={SourceInput}
      additionalComponent={props.additionalComponent}
      className={props.className}
      validate={props.validate}
      Tip={props.tip}
      mask={props.mask}
      name={props.name}
      label={props.label}
      placeholder={props.placeholder}
      type={props.type}
      disabled={props.disabled}
      value={props.value}
      data={props.data}
      normalize={normalize}
      withoutWrapper={props.withoutWrapper}
      maxLength={props.maxLength}
      // need for autocomplete field
      getList={props.getList}
      setAutocompleteList={props.setAutocompleteList}
      requestParams={props.requestParams}
      // need for date field
      withoutIcon={props.withoutIcon}
      // need for zone field
      countDisplayedIcons={props.countDisplayedIcons}
      //ref={props.ref}
      //withRef={props.withRef}
      {...textMask}
      {...numberMask}
      {...rest}
    >
      {props.children}
    </Field>
  );
};
