import React from 'react';
import styled from 'styled-components';

import { Popup2 } from 'libs/ui';
import { Header } from './styledComponents';

const StyledHeader = styled(Header)`
  margin-bottom: 15px;
`;

const Window = ({
  header,
  type,
  displayHandler,
  addEntity,
  updateEntity,
  initialValues,
  id,
  getEntries,
  updateMessageDisplayHandler,
  Form,
  w,
  top,
  ...rest
}) => {
  return (
    <Popup2 w={w} top={top}>
      <StyledHeader>{header}</StyledHeader>
      <Form
        type={type}
        addEntity={addEntity}
        updateEntity={updateEntity}
        getEntries={getEntries}
        displayHandler={displayHandler}
        id={id || null}
        initialValues={initialValues || {}}
        updateMessageDisplayHandler={updateMessageDisplayHandler}
        {...rest}
      />
    </Popup2>
  );
};

Window.defaultProps = {
  w: 480,
  top: 30,
};

export default Window;
