import React from 'react';

import { Engine } from './audio-player-engine';
import { ActiveBar, ActivePoint, ActiveProgress, Close, PlayerWrapper, Progress, ProgressBar, Time } from './audio-player.styled';

export class AudioPlayer extends React.Component {
  state = {
    duration: '00 : 00 : 00',
    progress: 0,
  };

  progressBar = null;

  componentDidMount() {
    this.engine = new Engine(this.props.file, this.setDuration, this.setProgress, this.progressBar);
  }

  componentWillUnmount = () => {
    this.engine.stop();
  };

  setProgress = (progress, cb) => {
    this.setState({ progress: progress });
    cb && cb();
  };

  setDuration = (duration) => {
    let durationHours = Math.round(duration / 3600) < 10 ? '0' + Math.round(duration / 3600) : Math.round(duration / 3600);
    let durationMinutes = Math.round(duration / 60) < 10 ? '0' + Math.round(duration / 60) : Math.round(duration / 60);
    let durationSeconds = Math.round(duration % 60) < 10 ? '0' + Math.round(duration % 60) : Math.round(duration % 60);
    this.setState({
      duration: `${durationHours} : ${durationMinutes} : ${durationSeconds}`,
    });
  };

  render() {
    return (
      <PlayerWrapper className="player">
        <Progress>
          <ActiveProgress>
            <ActiveBar width={this.state.progress + '%'} />
            <ActivePoint />
          </ActiveProgress>
          <ProgressBar
            onClick={(e) => {
              this.engine.pickNewProgress(e);
            }}
            innerRef={(ref) => {
              this.progressBar = ref;
            }}
          />
        </Progress>
        <Time>{this.state.duration}</Time>
        <Close onClick={this.props.close} src="/images/close-black.png" />
      </PlayerWrapper>
    );
  }
}
