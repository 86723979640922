import styled from 'styled-components';

import { StyledGrid, GeneralInfo } from 'modules/components/card/styledComponents';
import { UpdateMessage } from 'libs/ui';
import TariffsGrid from '../grid/Grid';

export const StatusBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TariffGeneralInfo = styled(GeneralInfo)`
  height: 140px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;
export const HeaderBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${(props) => props.align};
`;
export const Name = styled.p`
  font-size: 26px;
  color: #fff;
`;
export const TransportClass = styled.p`
  font-size: 15px;
  line-height: 1.73;
  color: #fff;
  max-width: 840px;
`;
export const OtherInfo = styled.div`
  span {
    font-size: 15px;
    line-height: 1.73;
    color: #fff;
    &:first-child {
      margin-right: 40px;
      font-weight: bold;
    }
  }
`;
export const StyledTariffsGrid = styled(StyledGrid).attrs({ as: TariffsGrid })``;

export const UpdateMessageBox = styled(UpdateMessage)`
  bottom: -100px;
  left: 180px;
  width: 270px;
`;
export const Status = styled.div`
  color: ${(props) => (props.status === 'active' ? '#00fe8e' : '#fff')};
`;
