import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { user } from 'services';
import { Button, themes, ButtonContainer, ParkomatsSelect } from 'libs/ui';
import { Popup2 } from 'libs/ui';
import { ErrorMsg } from 'pages/styled';
import { PromiseContent, NewTicketHeader } from './app-pay.styled';
import { api } from 'api';
import { notify } from 'libs/common';
import { getPaymentRacks } from 'services/types';

export class AppPay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      working: false,
      validated: false,
      parking_id: props.parkingId ? '' : user.getMaps().parking[0] ? user.getMaps().parking[0].id : '',
      parkomat: '',
      racks: [],
    };
  }

  componentDidMount() {
    getPaymentRacks().then((data) => {
      if (data) {
        //data = data.filter((el) => el.parking_id === parkingId);
        data.forEach((el) => (el.mapped_name = el.name));
        const def = data.length > 0 ? data[0].id : '';
        this.setState({ racks: data, parkomat: def });
      }
    });
  }

  validate = (data) => {
    return !this.state.parkomat;
  };

  save = () => {
    const { parkomat } = this.state;
    //console.log('🚀 ~ parkomat', parkomat);
    const out = {
      payment_rack_id: parkomat,
      stay_id: this.props.id,
    };
    //console.log('🚀 ~ out', out);
    api.call('stays.send_to_payment_rack', out, 'NetworkManager')
      .then((data) => {
        this.props.close();
      })
      .catch((error) => {
        notify('Ошибка инициации оплаты', error);
      });
  };

  selectParkomat = (parkomat) => {
    this.setState({ parkomat });
  };

  render() {
    const { error, racks, working, parkomat } = this.state;
    // console.log('🚀 ~ render ~ racks:', racks);
    const { parkingId } = this.props;

    if (!racks.length) {
      return null;
    }

    return (
      <>
        <Popup2 w={400}>
          <PromiseContent>
            <NewTicketHeader>Оплата</NewTicketHeader>
            {error && (
              <ErrorMsg>
                <img src="/images/error-triangle-icon.png" alt="" />
                Ошибка
              </ErrorMsg>
            )}

            <form>
              <ParkomatsSelect racks={racks} onChange={this.selectParkomat} parkingId={parkingId} value={parkomat} />
              {/*
              <Line2>
                <span className="l sm">Устройство</span>
                <select onChange={this.selectParkomat} width="145px" value={this.state.parkomat}>
                  {parkomats}
                </select>
              </Line2>
            */}
            </form>
            <ButtonContainer space="20px" justifyContent={'flex-start'} style={{ marginTop: '40px' }}>
              <Button disabled={working} fontSize="16px" onClick={this.props.close}>
                Отменить
              </Button>
              <Button disabled={working} theme={themes.blue} fontSize="16px" onClick={this.save}>
                Инициировать оплату
              </Button>
            </ButtonContainer>
          </PromiseContent>
        </Popup2>
      </>
    );
  }
}

AppPay.propTypes = {
  id: PropTypes.any.isRequired,
  parkingId: PropTypes.any,
  close: PropTypes.any.isRequired,
  action: PropTypes.any.isRequired,
};
