import { user } from 'services';
import { StyledField } from 'libs/form';

export const LK = ({ lk_login }) => {
  // console.log('🚀 ~ LK ~ lk_login:', lk_login);
  const useLogin = user.canUserLogin();
  if (!useLogin) {
    return null;
  }

  return (
    <>
      <StyledField type="text" label="Пароль ЛК" name="lkpwd" noaf={true} />
      {lk_login && <StyledField className="checkbox1" type="checkbox" label="Безлимитный" name="lk_unlimited" />}
    </>
  );
};
