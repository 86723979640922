const CLEAR_ENTRIES = '/pages/reports/promises/CLEAR_ENTRIES';
const ADD_PROMISES = '/pages/reports/promises/ADD_BENEFITS';
const SET_PROMISES_COUNT = '/pages/reports/promises/SET_BENEFITS_COUNT';
const SET_OFFSET = '/page/reports/promises/SET_OFFSET';
const SET_SORT_PARAMS = '/pages/reports/promises/SET_SORT_PARAMS';
const RESET_MODEL = '/pages/reports/promises/RESET_MODEL';

export default function reducer(state, action) {
  const defaultState = {
    promises: {},
    offset: 0,
    count: 0,
    sortParams: [{ field_name: 'id', direction: 'desc' }],
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case RESET_MODEL:
      return {
        ...defaultState,
      };
    case CLEAR_ENTRIES:
      return {
        ...state,
        promises: {},
      };
    case ADD_PROMISES:
      return {
        ...state,
        promises: {
          ...state.promises,
          ...action.data,
        },
      };
    case SET_PROMISES_COUNT:
      return {
        ...state,
        count: action.data,
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    default:
      return state;
  }
}

export const reset = () => {
  return {
    type: RESET_MODEL,
  };
};
export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const setCount = (value) => {
  return {
    type: SET_PROMISES_COUNT,
    data: value,
  };
};
export const addBenefits = (promises) => {
  return {
    type: ADD_PROMISES,
    data: promises,
  };
};
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};

export const getRequestParams = (filter) => (dispatch, getState) => {
  const decorateParams = {};
  for (let key in filter) {
    if (filter[key]) {
      switch (key) {
        case 'is_payed':
          if (filter[key] === 'payed') {
            decorateParams[key] = true;
          } else if (filter[key] === 'notpayed') {
            decorateParams[key] = false;
          }
          break;
        default:
          decorateParams[key] = filter[key];
      }
    }
  }
  return decorateParams;
};
