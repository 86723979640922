import { Content, SectionLink } from './menu.styled';

export const Menu = () => {
  return (
    <Content>
      <SectionLink to="/options/soupload" exact>
        Средства оплаты
      </SectionLink>
      <SectionLink to="/options/soupload/clients" exact>
        Клиенты
      </SectionLink>
    </Content>
  );
};
