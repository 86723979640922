import { config } from 'config';
import { user } from 'services';

// access_level for operator = 1
// access_level for admin = 3

export const accessLevelMap = {
  operator: {
    availableSections: [{ id: 'scheme' }, { id: 'operator' }, { id: 'stays' }],
  },

  admin: {
    availableSections: [
      { id: 'test' },
      { id: 'scheme' },
      { id: 'stays' },
      //{ id: 'cashbox' },
      { id: 'clients' },
      { id: 'payments', subsectionIds: ['rfid', 'epm', 'vehiclePlate', 'subscription', 'grey_plate'] },
      {
        id: 'reports',
        subsectionIds: [
          'rides',
          'plateRides',
          'cashboxOperations',
          //'refill',
          'calls',
          'events',
          'actions',
          //'using',
          //'using2',
          //'working',
          'summary',
          'benefits',
          'discounts',
          'promises',
          //'working2'
          'additional',
        ],
      },
      {
        id: 'options',
        subsectionIds: [
          'tariffs',
          /*'tariffs2',*/ 'rules',
          'users',
          'collection',
          'roles',
          'categories',
          'optbenefits',
          'parkings',
          'optadv',
          'soupload',
        ],
      },
      { id: 'stay', type: 'card' },
      { id: 'newClient', type: 'card' },
      { id: 'client', type: 'card' },
      { id: 'newTariff', type: 'card' },
      { id: 'tariff', type: 'card' },
      { id: 'rule', type: 'card' },
    ],
  },

  getSectionsByPermissions(permissions, sections, parent) {
    const self = this;
    const childs = sections !== undefined;
    if (!childs) {
      sections = this.admin.availableSections;
    }
    if (0 === 1) {
      //console.log(sections)
      return sections;
    }

    const reportsItems = [
      'passage.list',
      'payment.list',
      'processing.list',
      /*'calls.list',*/
      'events.list',
      'actions.list',
      'reports.utilization',
      'reports.statistics',
      'promises.list',
      'benefits.list',
      /*'reports.get_xls'*/
    ];
    const setupItems = [
      'tariff.list',
      'rules.list',
      'users.list',
      'roles.list',
      'vehicle_category.list',
      'collection.list',
      'benefit_types.list',
      'advertising',
    ];
    const paymentsItems = ['rfid.list', 'csc.list', 'subscriptions.list', 'plates.list', 'grey_plate.list'];

    const out = sections.filter((item) => {
      let key = item.id;
      let test = false;
      let testResult = false;
      if (!item.type) {
        switch (key) {
          default:
            key += '.list';
            break;
          case 'optadv':
            test = true;
            testResult = user.haveRight('advertising');
            break;
          case 'optbenefits':
            test = true;
            testResult = user.haveRight('benefit_types_check.list');
            break;
          case 'parkings':
            test = true;
            testResult = user.haveRight('stays.auto_close');
            break;
          case 'promises':
            test = true;
            testResult = user.haveRight('promise_pay.edit');
            break;
          case 'test':
          case 'tariffs2':
          case 'soupload':
          case 'benefits':
            test = true;
            testResult = true;
            break;
          case 'greyPlate':
            test = true;
            testResult = user.haveRight('grey_list.list');
            break;
          case 'discounts':
            test = true;
            testResult = user.haveRight('benefit_check.list');
            break;
          // only link - test by config
          case 'additional':
            test = true;
            testResult = !!config.network.additional_reports_url;
            break;
          case 'vehiclePlate':
            key = 'plates.list';
            break;
          case 'subscription':
            key = 'subscriptions.list';
            break;
          case 'epm':
            key = 'csc.list';
            break;
          case 'tariffs':
            key = 'tariff.list';
            break;
          case 'categories':
            key = 'vehicle_category.list';
            break;
          case 'scheme':
            key = 'users_mnemo.info';
            break;
          case 'plateRides':
          case 'rides':
            key = 'passage.list';
            break;
          case 'cashboxOperations':
            key = 'payment.list';
            break;
          case 'refill':
            key = 'processing.list';
            break;
          case 'using':
            key = 'reports.statistics';
            break;
          case 'working':
            key = 'reports.utilization';
            break;
          case 'summary':
            key = 'reports.get_xls';
            break;

          case 'reports':
            test = true;
            reportsItems.forEach((el) => {
              if (permissions[el] !== undefined && permissions[el]) {
                testResult = true;
              }
            });
            break;
          case 'payments':
            test = true;
            paymentsItems.forEach((el) => {
              if (permissions[el] !== undefined && permissions[el]) {
                testResult = true;
              }
            });
            break;
          case 'options':
            test = true;
            setupItems.forEach((el) => {
              //console.log('- ' + el, permissions[el])
              if (permissions[el] !== undefined && permissions[el]) {
                testResult = true;
              }
            });
            break;
        }
      } else {
        switch (key) {
          default:
            key += '.view';
            break;
          case 'stay':
            key = 'stays.view';
            break;
          case 'client':
            key = 'clients.view';
            break;
          case 'rule':
            key = 'rules.view';
            break;
          case 'newClient':
            key = 'clients.add';
            break;
          case 'newTariff':
            key = 'tariff.add';
            break;
        }
      }
      let r = test ? testResult : permissions[key] === true;

      // test for interface setup
      if (r) {
        // console.log('🚀 ~ out ~ item:', item, parent);
        r = self.inInteface(item, parent);
      }

      return r;
    });

    // if nothing found - set default empty page
    if (!out.length || out[0].id === 'scheme') {
      out.splice(0, 0, { id: 'empty', type: 'card' });
    }

    // check for subsections
    out.forEach((item, idx) => {
      if (item.subsectionIds) {
        const subs = item.subsectionIds.map((el) => {
          return { id: el };
        });
        const out1 = self.getSectionsByPermissions(permissions, subs, item);
        out[idx].subsectionIds = out1.map((el) => el.id);
      }
    });

    return out;
  },

  // test menu item for existence in the interface
  inInteface(item, parent) {
    let result = true;
    if (!parent) {
      // console.log('🚀 ~ inInteface ~ item.id:', item.id);
      const testOk = item.id === 'scheme' ? 'mnemo' : item.id;
      if (user.inInterface(testOk)) {
        result = false;
      }
      return result;
    }

    let prefix = parent.id;
    let suffix = item.id;
    switch (parent.id) {
      default:
      case 'options':
        prefix = 'settings';
        break;
      case 'payments':
        prefix = 'payment_means';
        break;
      case 'reports':
        if (suffix === 'refill') {
          suffix = 'refill_accounts';
        }
        break;
    }

    const test1 = prefix + '_' + suffix;
    const test2 = prefix + '_' + suffix + '_menu';

    //console.log(test1, test2)

    if (user.inInterface(test1)) {
      result = false;
    }
    if (result && user.inInterface(test2)) {
      result = false;
    }
    return result;
  },
};
