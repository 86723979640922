import styled from 'styled-components';

export const Content = styled.div``;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    margin: 0 10px;
  }
  margin: 30px 0 0;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #262b31;
  margin: 0 0 30px;
`;

export const Info = styled.div`
  color: #000;
  font-size: 16px;
  text-align: center;
  line-height: 1.3;
  p {
    margin: 0 0 3px;
  }
`;
