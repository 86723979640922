import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import LoadingScreen from './LoadingScreen';
import ClientCard from './ClientCard';

class ClientCardView extends Component {
  componentDidMount() {
    const clientId = this.getClientId();

    this.props.getClientInfo(clientId);
    this.props.getBalance(clientId);
  }

  componentWillUnmount() {
    this.props.removeClientInfo();
    this.props.hideNewContactForm();
  }

  getClientId() {
    let params = {};
    if (window.location.search) {
      let parts = window.location.search.substring(1).split('&');
      for (let i = 0; i < parts.length; i++) {
        let nv = parts[i].split('=');
        if (!nv[0]) continue;
        params[nv[0]] = nv[1] || true;
      }
    }
    return params.id;
  }

  render() {
    if (!this.props.clientInfo || !this.props.clientInfo.type) {
      return <LoadingScreen />;
    }
    return <ClientCard {...this.props} />;
  }
}

export default withRouter(ClientCardView);
