import PropTypes from 'prop-types';

import { Content } from './error.styled';

export const Error = (props) => {
  const { children, ...rest } = props;

  // render
  return <Content {...rest}>{children}</Content>;
};

Error.propTypes = {
  children: PropTypes.any.isRequired,
};
