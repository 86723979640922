import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-right: ${(props) => (props.$lastField ? '0' : '20px')};
  max-width: 354px;
  > div {
    margin-right: 0;
  }
  i.fa {
    right: 10px;
    top: 50%;
    margin-top: -8px;
    color: #000;
  }
  &.dark {
    i.fa {
      color: #fff;
    }
  }
`;

export const CalendarInterval = styled.div`
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
  button {
    /* transition: all 0.15s ease-in; */
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    cursor: pointer;
    color: #3ea5ff;
    font-size: 13px;
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 1px;
      width: 100%;
      background-color: #3ea5ff;
    }
    &:hover {
      color: #0085ef;
      opacity: 0.5;
    }
  }
  &.dark {
    button {
      &:hover {
        opacity: 1;
        color: #fff;
        &:after {
          background-color: #fff;
        }
      }
    }
  }
`;
