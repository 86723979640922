import React from 'react';

export const Toggle = (props) => {
  const { value, input, className, label, disabled } = props;
  // console.log('🚀 ~ props', props);
  const checked = input?.value === true;
  //console.log('🚀 ~ value', value, input);

  return (
    <div className={className}>
      <label>{label}</label>
      <label className="toggle">
        <input disabled={disabled} type="checkbox" value={value} checked={checked} {...input} />
        <span />
      </label>
    </div>
  );
};
