import { data } from './data';

const rackList = {
  getItems() {
    const items = data.getDefaultZones();
    const raks = {};

    Object.keys(items).forEach((key) => {
      const item = items[key].nodes;
      //console.log(items[key])
      item.forEach((node1) => {
        const key1 = 'nodeId-' + node1.node_id;
        const node = { id: node1.node_id, racks: [] };
        node.racks = node1.racks.map((rack) => {
          return rack.rack_name; //id
        });
        node.racks.sort((a, b) => {
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 0;
        });
        raks[key1] = node;
      });
    });

    return raks;
  },
};

export default rackList;
