import { useState } from 'react';

import { isPageWithSetup } from 'services/grid-service';
import { Settings } from './settings';
import { Content, Button } from './grid-setup.styled';

export const GridSetup = ({ page, onChange, ...rest }) => {
  const [showSettings, setShowSettings] = useState(false);
  const active = isPageWithSetup(page);
  if (!active) {
    return null;
  }

  const show = () => {    
    setShowSettings((val) => !val);
  };

  const hide = () => {
    setShowSettings(false);
  };

  const close = (refresh) => {
    hide();
    if (refresh) {
      onChange();
    }
  }

  return (
    <Content>
      <Button onClick={show} title="Настроить вид">
        <img src="/images/settings.svg" alt="*" />
      </Button>
      {showSettings && <Settings page={page} close={close} {...rest} />}
    </Content>
  );
};
