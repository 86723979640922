export const WeekInput = (props) => {
  const { name, value, onChange } = props;

  const change = (event) => {
    const idx = event.target.name;
    let val = event.target.checked;

    const out = value.splice(0);
    out[idx] = val;
    // console.log('🚀 ~ change ~ out:', out);
    onChange(name, out);
  };

  const getItemsEl = () => {
    return value.map((el, idx) => {
      return (
        <td key={idx}>
          <input type="checkbox" name={idx} checked={el} value={1} onChange={change} />
        </td>
      );
    });
  };

  const itemsEl = getItemsEl();

  return <>{itemsEl}</>;
};
