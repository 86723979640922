import { GridContainer } from 'libs/ui';
import { Epm } from 'grids/epm/epm';

const EpmWrapper = (props) => {  
  return (
    <GridContainer>
      <Epm {...props} />
    </GridContainer>
  );
};

export default EpmWrapper;
