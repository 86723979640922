import zones from 'services/zones';
import { Content, Zone, ZoneIcon } from './zones-icons.styled';

export const ZonesIcons = (props) => {
  const { stayZones } = props;
  if (!zones || !stayZones) {
    return null;
  }

  const stayIcons = [];
  const zonesItems = zones.getZones();

  if (stayZones.length) {
    stayZones.forEach((item, idx) => {
      if (item) {
        for (let i = 0; i < zonesItems.length; i++) {
          if (stayIcons.length > 3) break;

          if (item === zonesItems[i].id) {
            stayIcons.push(
              <Zone key={idx}>
                <ZoneIcon color={zonesItems[i].color}>{zonesItems[i].name}</ZoneIcon>
              </Zone>
            );
          }
        }
      }
    });
  }
  return <Content>{stayIcons}</Content>;
};
