import React from 'react';
import { connect } from 'react-redux';

import { user } from 'services';
import { Popup } from 'libs/ui';
import { getRules } from 'store/ducks/models/rules';
import { ZonesRow } from './zones-row';
import { RulesRow } from './rules-row';
import RuleCard from './card/rules-card';
import { HeaderRow, ScrollWrapper, Content, Wrapper } from './rules-form.styled';

class RulesForm extends React.Component {
  state = {
    popup: false,
    item: {},
    rule_id: '',
  };

  componentDidMount() {
    this.props.getRules();
  }

  showPopup = (item, rule_id) => {
    this.setState({
      popup: true,
      item: item,
      rule_id: rule_id,
    });
  };

  closePopup = () => {
    this.setState({ popup: false });
  };

  getRulesList(type) {
    const rules = this.props.rules;
    const rightView = user.haveRight('rules.view');
    return rules
      .filter((item) => item.type === type)
      .map((item) => {
        return <RulesRow key={'rule-' + item.rule_id} {...item} action={rightView ? this.showPopup : () => null} disabled={!rightView} />;
      });
  }

  refresh = () => {
    this.props.getRules();
    this.closePopup();

    //window.location.reload();
    // rules
    //return this.props.history.push(routes.rules);
  };

  render() {
    const { popup, rule_id, item } = this.state;
    const { rules } = this.props;
    if (!rules) {
      return null;
    }

    let rule, title;
    if (popup) {
      rule = rules.find((el) => el.rule_id === rule_id);
      title = rule.name;
    }
    //console.log('🚀 ~ render ~ rules', rules);

    return (
      <Wrapper>
        <Content>
          <ZonesRow rules={rules} />
          <HeaderRow>
            <span>Категории ТС</span>
          </HeaderRow>
          <ScrollWrapper>{this.getRulesList('category')}</ScrollWrapper>
          <ZonesRow rules={rules} />
          <HeaderRow>
            <span>Классы ТС</span>
          </HeaderRow>
          <ScrollWrapper>{this.getRulesList('class')}</ScrollWrapper>
        </Content>
        {popup && (
          <Popup cache={item.zone_id + '_' + rule_id} rule={rule} theme="dark" title={title} close={this.closePopup}>
            <RuleCard item={item} rule={rule} refresh={this.refresh} />
          </Popup>
        )}
      </Wrapper>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    rules: store.rules.rules,
  };
};
export default connect(mapStateToProps, {
  getRules,
})(RulesForm);
