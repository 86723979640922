import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Container } from './container';

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const LayoutContainer = styled.div`
  position: relative;
  width: /* 100vw; */ 100%;
`;
export const HeaderContainer = styled.div`
  height: 60px;
  width: 100%;
  background-color: #262b31;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const HeaderWrapper = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const BrandName = styled.p`
  //font-family: DINPro, sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #7e858c;
  letter-spacing: 1.8px;
  & a {
    color: #7e858c;
    display: block;
    min-width: 105px;
  }
  img {
    max-width: 100%;
    height: auto;
    transition: opacity .2s ease-in;
  }
  &:hover img {
    opacity: .9;
  }
`;
export const Login = styled.div`
  font-size: 14px;
  letter-spacing: 0.7px;
  color: #fff;
  display: flex;
  align-items: center;
  i {
    cursor: pointer;
    margin-left: 10px;
  }
`;
export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  & > a {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
export const MenuLink = styled(NavLink)`
  height: 60px;
  opacity: 0.6;
  padding: 20px 15px;
  font-size: 15px;
  color: #ffffff;
  line-height: normal;
  transition: 0.15s all ease-in;
  &:hover {
    opacity: 0.9;
  }
  &.active {
    color: #3ea5ff;
    text-shadow: 1px 1px rgba(62, 165, 255, 0.5);
    opacity: 1;
  }
  &.active:hover {
    opacity: 1;
  }
  @media (max-width: 1070px) {
    padding: 20px 7px;
    font-size: 14px;
  }
`;
