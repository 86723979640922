import React from 'react';
import ReactDOM from 'react-dom';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'moment/locale/ru';
import { Provider } from 'react-redux';

import store from './store/store';
import { App } from './app';
import './i18n';
import './config';
import './index.css';

ReactDOM.render(
  <Provider store={store}>
    <ReactNotifications />
    <App />
  </Provider>,
  document.getElementById('root')
);
