import React from 'react'
import { connect } from 'react-redux'

import { GridContainer } from 'libs/ui'
import CallsFilter from './Filter'
import CallsGrid from './Grid'
import { Controller } from 'libs/control'

import {
  getRequestParams,
  addCalls,
  clearEntries,
  setOffset,
  setCount,
  setSortParams
} from 'store/ducks/models/calls'

import { setScroll } from 'store/ducks/interface/callsGrid'


const Calls = (props) => {
  return (
    <GridContainer>
      <Controller
        parkingId={props.parkingId}
        type='calls'
        filterName='callsFilter'
        entries={props.calls}
        grid={CallsGrid}
        gridFilter={CallsFilter}
        offset={props.offset}
        count={props.count}
        interface={props.interface}
        //methods
        setScroll={props.setScroll}
        getRequestParams={props.getRequestParams}
        addEntries={props.addCalls}
        clearEntries={props.clearEntries}
        setSortParams={props.setSortParams}
        setOffset={props.setOffset}
        setCount={props.setCount}
      />
    </GridContainer>
  )
}

const mapStateToProps = (store) => {
  return {
    calls: store.calls.calls,
    offset: store.calls.offset,
    count: store.calls.count,
    sortParams: store.calls.sortParams,
    interface: store.callsGrid
  }
}

export default connect(mapStateToProps, {
  getRequestParams,
  addCalls,
  clearEntries,
  setOffset,
  setCount,
  setScroll,
  setSortParams
})(Calls)