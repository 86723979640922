import styled from 'styled-components';

import { ButtonContainer } from 'libs/ui';
import { WhiteStyledField } from 'libs/form';

export const TextareaStyledField = styled(WhiteStyledField)`
  height: 80px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 9px 0;
`;
export const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
  justify-content: flex-end;
`;
