import { api } from 'api';

const SET_RULES = '/pages/options/rules/SET_RULES';
const TOGGLE_ZONE = '/pages/options/rules/TOGGLE_ZONE';
const TOGGLE_NODE = '/pages/options/rules/TOGGLE_NODE';
const TOGGLE_RACK = '/pages/options/rules/TOGGLE_RACK';

export default function reducer(state, action) {
  const defaultState = {
    rules: [],
    rulesForm: [],
  };

  if (!state) {
    state = defaultState;
  }

  let formstr = JSON.parse(JSON.stringify(state.rulesForm));
  //console.log('🚀 ~ reducer ~ formstr', formstr);

  switch (action.type) {
    case SET_RULES:
      return {
        ...state,
        rules: [...action.rules],
        rulesForm: [...action.rules],
      };
    case TOGGLE_ZONE:
      var newForm = formstr.map((rule) => {
        rule = { ...rule };
        if (rule.rule_id === action.ruleId) {
          rule.zones.forEach((zone) => {
            if (zone.zone_id === action.zoneId) {
              zone.nodes.forEach((node) => {
                node.racks.forEach((rack) => {
                  rack.enabled = !action.enabled;
                });
              });
            }
          });
        }
        return rule;
      });
      return {
        ...state,
        rulesForm: newForm,
      };
    case TOGGLE_NODE:
      newForm = formstr.map((rule) => {
        rule = { ...rule };
        if (rule.rule_id === action.ruleId) {
          rule.zones.forEach((zone) => {
            if (zone.zone_id === action.zoneId) {
              zone.nodes.forEach((node) => {
                if (node.node_id === action.nodeId) {
                  node.racks.forEach((rack) => {
                    rack.enabled = !action.enabled;
                  });
                }
              });
            }
          });
        }
        return rule;
      });
      return {
        ...state,
        rulesForm: newForm,
      };
    case TOGGLE_RACK:
      newForm = formstr.map((rule) => {
        rule = { ...rule };
        if (rule.rule_id === action.ruleId) {
          rule.zones.forEach((zone) => {
            if (zone.zone_id === action.zoneId) {
              zone.nodes.forEach((node) => {
                if (node.node_id === action.nodeId) {
                  node.racks.forEach((rack) => {
                    if (rack.rack_id === action.rackId) {
                      rack.enabled = !action.enabled;
                    }
                  });
                }
              });
            }
          });
        }
        return rule;
      });
      //console.log('🚀 ~ reducer ~ newForm', newForm);
      return {
        ...state,
        rulesForm: newForm,
      };

    default:
      return state;
  }
}

const setRules = (rules) => {
  rules = JSON.parse(JSON.stringify(rules));

  return {
    type: SET_RULES,
    rules: rules,
  };
};

export const toggleZone = (enabled, ruleId, zoneId) => {
  return {
    type: TOGGLE_ZONE,
    ruleId: ruleId,
    zoneId: zoneId,
    enabled: enabled,
  };
};

export const toggleNode = (enabled, ruleId, zoneId, nodeId) => {
  return {
    type: TOGGLE_NODE,
    ruleId: ruleId,
    zoneId: zoneId,
    nodeId: nodeId,
    enabled: enabled,
  };
};

export const toggleRack = (enabled, ruleId, zoneId, nodeId, rackId) => {
  return {
    type: TOGGLE_RACK,
    ruleId: ruleId,
    zoneId: zoneId,
    nodeId: nodeId,
    rackId: rackId,
    enabled: enabled,
  };
};

export const updateRule = (ruleId, callback) => (dispatch, getState) => {
  let zones = [];
  getState().rules.rulesForm.forEach((item) => {
    if (item.rule_id === ruleId) {
      zones = item.zones;
    }
  });

  return api
    .call('rules.update', {
      id: ruleId,
      item: {
        zones: zones,
      },
    })
    .then((res) => {
      if (callback) {
        callback();
      }
    });
};

export const getRules = () => (dispatch, getState) => {
  return api.call('rules.list', {}).then((res) => {
    dispatch(setRules(res));
  });
};
