import PropTypes from 'prop-types';

import { data } from 'services';
import { StyledSelect } from './dict-filter.styled';

export const DictFilter = (props) => {
  const { w, dict, exclude, name, ...rest } = props;
  const wOk = w + 'px';

  const dictionary = data.getDict(dict);
  if (!dictionary) {
    return null;
  }
  const getItems = () => {
    return dictionary.items
      .filter((el) => !exclude.includes(el.value))
      .map((el, idx) => {
        let val = el.value;
        return (
          <option key={idx} value={val}>
            {el.name}
          </option>
        );
      });
  };

  const title = dictionary.title;
  const nameOk = dictionary.param ? dictionary.param : name;
  const options = getItems();

  return (
    <StyledSelect width={wOk} name={nameOk} label={title} {...rest}>
      <option value="">Все</option>
      {options}
    </StyledSelect>
  );
};

DictFilter.propTypes = {
  dict: PropTypes.string.isRequired,
  name: PropTypes.string,
  w: PropTypes.number,
  type: PropTypes.string,
  theme: PropTypes.string,
  exclude: PropTypes.array,
};

DictFilter.defaultProps = {
  w: 110,
  type: 'select',
  theme: '',
  exclude: [],
};
