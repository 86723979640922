import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { Button, themes, ButtonContainer } from 'libs/ui';
import { setTariff } from 'store/ducks/models/stays';
import { getTariffList } from 'store/ducks/models/tariffs';
import Warning from 'modules/components/warnings';
import { Popup2 } from 'libs/ui';
import { AttentionIcon, NewTicketContent, NewTicketHeader, NewTicketInfo } from './set-tariff.styled';

class SetTariff extends Component {
  state = {
    tariff: '',
    tariffs: [],
    confirm: false,
  };

  componentDidMount() {
    this.props.getTariffList({ status: 'active', parking_id: this.props.parkingId }, [], (data) => {
      //console.log('tariffs', data);
      let tariff = this.props.tariffId;

      if (data.items.length) {
        let found = false;
        data.items.forEach((el) => {
          if (el.id === tariff) {
            found = true;
          }
        });
        if (!found) {
          tariff = data.items[0].id;
        }
      }
      this.setState({ tariffs: data.items, tariff });
    });
  }

  showConfirmForm = () => {
    this.setState({ confirm: true });
  };

  selectTariff = (event) => {
    this.setState({ tariff: event.target.value });
  };

  getTariffs = () => {
    return this.state.tariffs.map((tariff) => {
      return (
        <option key={tariff.id} value={tariff.id}>
          {tariff.name}
        </option>
      );
    });
  };

  render() {
    const { tariffs, tariff, confirm } = this.state;
    if (!tariffs.length) {
      return null;
    }

    const disabled = !tariff || (tariff && tariff === this.props.tariffId);

    return (
      <>
        <Popup2 w={640}>
          <AttentionIcon src="/images/icon_!.png" alt="" />
          <NewTicketContent>
            <NewTicketHeader>Смена тарифа</NewTicketHeader>
            <NewTicketInfo style={{ marginBottom: '20px' }}>
              Тариф
              <select onChange={this.selectTariff} width="145px" value={tariff}>
                {this.getTariffs()}
              </select>
            </NewTicketInfo>
            <ButtonContainer space="20px" justifyContent={'flex-start'}>
              <Button fontSize="16px" onClick={this.props.close}>
                Отменить
              </Button>
              <Button disabled={disabled} theme={themes.blue} fontSize="16px" onClick={this.showConfirmForm}>
                Заменить
              </Button>
            </ButtonContainer>
          </NewTicketContent>
        </Popup2>
        {confirm && (
          <Warning
            width="480px"
            header={'Тарификация данной парковочной сессии будет изменена. Продожить?'}
            cancelButton={{
              func: () => this.setState({ confirm: false }),
              text: 'Отменить',
            }}
            acceptButton={{
              func: () => {
                this.setState({ confirm: false });
                this.props.action({ stayId: this.props.id, tariffId: tariff });
                this.props.close();
              },
              text: 'Заменить',
              theme: themes.blue,
            }}
          />
        )}
      </>
    );
  }
}

SetTariff.propTypes = {
  id: PropTypes.any.isRequired,
  parkingId: PropTypes.any,
  tariffId: PropTypes.any.isRequired,
  close: PropTypes.any.isRequired,
  action: PropTypes.any.isRequired,
};

const mapStateToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps, { setTariff, getTariffList })(SetTariff));
