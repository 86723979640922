import { api } from 'api';

const SET_BALANCE_INFO = '/processing/SET_BALANCE_INFO';
const SET_IMPORT_ERRORS = '/processing/SET_IMPORT_ERRORS';

export default function reducer(state, action) {
  const defaultState = {
    balanceInfo: null,
    importErrors: null,
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case SET_BALANCE_INFO:
      return {
        ...state,
        balanceInfo: action.data,
      };
    case SET_IMPORT_ERRORS:
      return {
        ...state,
        importErrors: action.data,
      };
    default:
      return state;
  }
}

export const setBalanceInfo = (data) => {
  return {
    type: SET_BALANCE_INFO,
    data: data,
  };
};
export const setImportErrors = (importErrors) => {
  return {
    type: SET_IMPORT_ERRORS,
    data: importErrors,
  };
};
export const clearErrors = () => {
  return {
    type: SET_IMPORT_ERRORS,
    data: null,
  };
};

export const sendFileContent = (params, callback) => (dispatch) => {
  return api
    .call('processing.import_list', params)
    .then((res) => {
      if (callback) {
        callback();
      }
    })
    .catch((res) => {
      dispatch(setImportErrors(res.data));
    });
};

export const getBalance = (id, callback) => (dispatch) => {
  return api
    .call('processing.get_balance', { client_id: id })
    .then((res) => {
      dispatch(setBalanceInfo(res));
    })
    .catch((error) => {
      dispatch(setBalanceInfo(null));
    });
};
