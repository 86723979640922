import { Content } from './toggle.styled';

export const Toggle = (props) => {
  const { label, label2, theme, setOne, setTwo, ...rest } = props;
  const other = { theme };
  const name = rest.name;

  const setTypeOne = () => {
    if (!setOne) {
      return;
    }
    setOne();
  };

  const setTypeTwo = () => {
    if (!setTwo) {
      return;
    }
    setTwo();
  };

  return (
    <Content {...other}>
      <label onClick={setTypeOne}>{label}</label>
      <label className="toggle">
        <input id={name} type="checkbox" value="1" {...rest} />
        <span />
      </label>
      {label2 && (
        <div className="label2" onClick={setTypeTwo}>
          {label2}
        </div>
      )}
    </Content>
  );
};
