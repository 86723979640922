import styled from 'styled-components';

export const TableHead = styled.thead`
  /* position: sticky;
  sticky-anchor: screen; */
  tr {
    th {
      height: 35px;
      font-size: 15px;
      opacity: 1;
      font-weight: 900;

      &:hover {
        opacity: 0.6;
      }
    }
  }
  &.sticky {
    position: sticky;
    border-bottom: 1px solid #aaa;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    background: #fff;
    z-index: 20;
    th{
      height: 50px;
    }
  }
`;

export const TableRow = styled.tr``;
