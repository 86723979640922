import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Zones from './Zones';
import { getRules } from 'store/ducks/models/rules';
import { DataWrapper, TariffManageWrapper } from './rules-card.styled';

class RulesCard extends React.Component {
  render() {
    return (
      <DataWrapper>
        <TariffManageWrapper>
          <Zones
            refresh={this.props.refresh}
            selectedZoneId={this.props.item.zone_id}
            type={this.props.rule.type}
            zones={this.props.rule.zones || []}
            ruleId={this.props.rule.rule_id}
          />
        </TariffManageWrapper>
      </DataWrapper>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    selectedZone: store.tariffCard.selectedZone,
  };
};

export default connect(mapStateToProps, {
  getRules,
})(withRouter(RulesCard));
