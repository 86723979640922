import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
import { WhiteStyledField } from 'libs/form';
import { Button, themes, ButtonContainer } from 'libs/ui';
import { required } from 'libs/form';

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
`;

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      role: '',
    };
  }

  submit = (values) => {
    let decorateValues = { ...values };
    return this.props.addEntity(decorateValues, this.afterSubmit);
  };

  afterSubmit = () => {
    this.props.getEntries({ offset: 0 });
    this.props.updateMessageDisplayHandler(true);
    setTimeout(() => {
      this.props.updateMessageDisplayHandler(false);
    }, 3000);
    this.props.displayHandler(false);
    this.props.destroy(this.props.form);
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.submit)}>
        <WhiteStyledField
          type="text"
          label="Название"
          placeholder="Менеджер"
          name="name"
          validate={required}
          autoFocus
          tabIndex={0}
        />
        <StyledButtonContainer space="20px">
          <Button
            type="button"
            onClick={() => {
              this.props.displayHandler(false);
              this.props.destroy(this.props.form);
            }}
          >
            Отменить
          </Button>
          <Button theme={themes.blue}>
            <i className="fa fa-save" />
            Сохранить
          </Button>
        </StyledButtonContainer>
      </form>
    );
  }
}

Form = reduxForm()(Form);

Form = connect((__store, props) => {
  //const values = (store.form && store.form.addUserForm) ? store.form.addUserForm.values : null;
  //console.log(values);
  return {
    //values: values,
    form: 'addRoleForm',
    destroyOnUnmount: false,
    initialValues: props.initialValues,
  };
})(Form);

export default Form;
