import { Popup2 } from 'libs/ui';
import { getFileLink } from 'services/adv-service';
import { Content } from './media.styled';
import { Video } from './video';
import { Picture } from './picture';

export const Media = (props) => {
  const { close, row } = props;
  const fullFn = getFileLink(row.filelink);
  const title = row.name;
  const isVideo = row.mimetype.indexOf('video') !== -1;
  let w = window.innerWidth - 200;
  if (w > 1024) {
    w = 1024;
  }

  return (
    <Popup2 close={close} title={title} w={w}>
      <Content>{isVideo ? <Video fn={fullFn} name={row.name} /> : <Picture fn={fullFn} />}</Content>
    </Popup2>
  );
};

export default Media;
