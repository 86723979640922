import styled from 'styled-components';

export const Tip = styled.div`
  position: fixed;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
  z-index: 999;
  height: auto; /* 40px; */
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  padding: 10px 12px;
  text-align: center;
  font-size: ${(props) => (props.fs ? props.fs : '18px')};
  &::before {
    content: '';
    top: -10px;
    left: 50%;
    transform: translate(-50%, 0) rotate(45deg);
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-left: solid 2px #a0a0a073;
    border-top: solid 2px #a0a0a073;
    border-bottom-right-radius: 100%;
  }
`;
