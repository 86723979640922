import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: fit-content;
`;
export const OptionsPopup = styled.div`
  position: absolute;
  top: 35px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #f2f2f2), linear-gradient(#ffffff, #ffffff);
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.5);
  z-index: 999;  
  border-radius: 5px;
  left: ${(props) => (props.left ? props.left + '%' : '50%')};
  transform: translate(-50%);
  &::before {
    content: '';
    top: -10px;
    left: ${(props) => (props.left ? 50 + (Math.abs(props.left) - 50) * 2 + '%' : '50%')};
    transform: translate(-50%, 0) rotate(45deg);
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-left: solid 2px #a0a0a073;
    border-top: solid 2px #a0a0a073;
    border-bottom-right-radius: 100%;
  }
`;
export const Option = styled.div`
  white-space: pre;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? '0.6' : '1')};
  display: flex;
  align-items: center;
  color: #000;
  height: 45px;
  font-size: 13px;
  padding: 0 15px;
  min-width: 150px;
  i {
    margin-right: 10px;
    font-size: 15px;
  }
`;
export const OptionsSeparator = styled.div`
  height: 1px;
  opacity: 0.6;
  background-color: rgba(85, 85, 85, 0.2);
`;
