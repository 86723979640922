import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';

import { user } from 'services';
import { ClientData, PersonalDataHeader } from '../styledComponents';
import NaturalPersonForm from './forms/NaturalPerson';
import LegalPersonForm from './forms/LegalPerson';
import SoleProprietorForm from './forms/SoleProprietor';
import { LoginInfo, UpdateMessageBox } from './PersonalData.styled';

class PersonalData extends Component {
  state = { showUpdateMessage: false };

  /**
   * submit form
   * @param {*} values
   * @returns
   */
  submit = (values) => {
    // console.log('🚀 ~ values:', values);

    // return if no right
    const rightUpdate = user.haveRight('clients.update');
    if (!rightUpdate) {
      return Promise.resolve();
    }

    const data = {
      id: this.props.client_id,
      info: { ...values },
    };

    // save
    return this.props
      .updateClientInfo(data)
      .then(() => {
        this.showMessage();
      })
      .catch((error) => {
        const submissionErrors = {};
        if (Array.isArray(error)) {
          error.forEach((element) => {
            submissionErrors[element.field] = element.type;
          });
        } else {
          submissionErrors['_error'] = error;
        }
        // console.log('🚀 ~ submissionErrors:', submissionErrors, error);
        throw new SubmissionError(submissionErrors);
      });
  };

  showMessage = () => {
    this.setState({ showUpdateMessage: true });
    setTimeout(() => {
      this.setState({ showUpdateMessage: false });
    }, 3000);
  };

  getForm = () => {
    if (this.props.clientType) {
      const clientType = this.props.clientType;
      if (clientType === 'natural_person') return <NaturalPersonForm submitText={this.props.submitText} onSubmit={this.submit} />;
      if (clientType === 'legal_person') return <LegalPersonForm submitText={this.props.submitText} onSubmit={this.submit} />;
      if (clientType === 'sole_proprietor') return <SoleProprietorForm submitText={this.props.submitText} onSubmit={this.submit} />;
    }
  };

  render() {
    const { login } = this.props;
    return (
      <ClientData>
        <PersonalDataHeader>Персональные данные</PersonalDataHeader>
        {login && (
          <LoginInfo>
            <b>Логин ЛК</b>
            <i>{login}</i>
          </LoginInfo>
        )}
        {this.getForm()}
        {this.state.showUpdateMessage ? <UpdateMessageBox>Изменения сохранены</UpdateMessageBox> : null}
        {this.props.showMessageAfterAddClient ? <UpdateMessageBox>Клиент успешно создан</UpdateMessageBox> : null}
      </ClientData>
    );
  }
}

export default connect((store) => {
  let { data } = store.client.clientInfo;
  return {
    login: data ? data.lk_login : '',
  };
})(PersonalData);
