import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const SectionLinksWrapper = styled.div`
  margin: 15px 0 0 0;
  min-height: 30px;
  &.double{
    display: flex;
    flex-wrap: wrap;
    > *{
      margin: 0 40px 0px 0;
    }
  }
`
export const SectionLink = styled(NavLink)`
  margin: 0 30px 0 0;
  min-width: 30px;
  opacity: 0.6;
  font-size: 15px;
  color: #000000;
  padding: 0 0 8px 0;
  white-space: nowrap;
  &.active{
    opacity: 1;
    border-bottom: 4px solid #3ea5ff;
    color: #3ea5ff;
    font-weight: 900;
    font-size: 16px;
  }
`