import styled, { css } from 'styled-components';

export const TableRowCmp = styled.tr`
  cursor: pointer;

  &:nth-child(2n) {
    background-color: #fafafa;
  }
  &:hover {
    opacity: 1;
    background-color: #e9eef2;
  }
  th,
  td {
    &:first-child,
    &.pad-left {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
  ${(props) =>
    !props.onClick &&
    css`
      cursor: default;
    `}

  ${(props) =>
    props.header &&
    css`
      &:hover {
        background-color: inherit;
        cursor: default;
      }
    `}

  box-shadow: ${(props) => (props.is_suspicious ? 'inset 5px 0 #d33d34' : '')};
  .td-icon {
    max-width: 18px;
  }
  .td-icon--big {
    margin-bottom: -3px;
  }
  .td-time {
    width: 180px;
  }

  &.archive td {
    color: rgba(0, 0, 0, 0.4);
  }
  &.is_suspicious {
    box-shadow: #d33d34 5px 0px inset;
    td {
      color: #d33d34;
    }
  }

  ${(props) =>
    props.theme === 'dark' &&
    css`
      color: #b7babf;
      opacity: 1;
      border-bottom: 1px solid #6c7277;

      background-color: #393e43;
      td {
        color: #fff;
      }

      &.blocked {
        background-color: #4d5156;
        td {
          color: rgba(248, 248, 248, 0.4);
        }
      }
      &:nth-child(2n) {
        background-color: #3f4449; //#4d5157;
      }

      &:hover {
        background-color: #919aa6;
      }
    `}
`;

export const IconButton = styled.button``;

export const DeleteIcon = styled.span``;
