import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import { DictFilter, Container, Input, ButtonsContainer } from 'libs/form';
import { ResetBtn, UseBtn } from 'libs/ui';

class Filter extends Component {
  getOptionsList = (field) => {
    const data = this.props[field];

    if (Array.isArray(data)) {
      return data.map((item) => {
        return (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        );
      });
    }
  };

  render() {
    const { handleSubmit, reset } = this.props;

    return (
      <Container /*height='160px'*/>
        <form onSubmit={handleSubmit}>
          <DictFilter w={140} dict="users.status" />

          <Input width="220px" name="username" type="text" label="Логин" placeholder="Введите текст для поиска" />

          <Input width="220px" name="name" type="text" label="ФИО" placeholder="Введите текст для поиска" />
          
          <Input width="220px" name="email" type="text" label="E-mail" placeholder="Введите текст для поиска" />

          <ButtonsContainer>
            <ResetBtn onClick={reset} />
            <UseBtn />
          </ButtonsContainer>
        </form>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'usersFilter',
  destroyOnUnmount: false,
  initialValues: { is_active: 'true' },
})(Filter);
