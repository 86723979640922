import React from 'react';
//import styled from 'styled-components'
import moment from 'moment';

import zones from 'services/zones';
import { GridContainer } from 'libs/ui';
import Filter from './Filter';

function Parkings() {
  const items = zones.getZones();
  if (!items || !items.length) {
    return null;
  }
  // console.log('🚀 ~ Parkings ~ items:', items);

  const initialValues = {
    end_at: moment(),
    zones: items.map((el) => el.zone_id),
  };  

  return (
    <GridContainer>
      <Filter initialValues={initialValues} />
    </GridContainer>
  );
}

export default Parkings;
