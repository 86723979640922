import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import Filter from './Filter';

import {
    getXls
} from 'store/ducks/models/summary';

class Summary extends React.Component {

    submit = (data) => {
        //console.log(data);
        this.props.getXls(data);
    }

    setInitial = (values) => {
        //console.log(values)
        this.initial = Object.assign({}, values);
    }

    render() {
        return (
            <Filter setInitial={this.setInitial} onSubmit={this.submit} {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filter: getFormValues('summaryFilter')(state)
    }
}

export default connect(
    mapStateToProps, {
        getXls
    }
)(Summary)