import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GridContainer /*, GridSetup*/ } from 'libs/ui';
import { Headline } from 'modules/components/pageComponents';
import { Client } from 'grids/client/client';

const ClientsWrapper = (props) => {
  const { t } = useTranslation();
  // const page = 'clients';
  const [rerender, setRerender] = useState(1);

  return (
    <>
      <Headline $mb $alignLeft>
        {t('sections.Клиенты')}
        {/* <GridSetup page={page} onChange={() => setRerender((val) => val + 1)} $arrowLeft={'123px'} /> */}
      </Headline>
      <GridContainer>
        <Client {...props} rerender={rerender} />
      </GridContainer>
    </>
  );
};

export default ClientsWrapper;
