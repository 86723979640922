import { formValueSelector, FieldArray } from 'redux-form';
import { useSelector } from 'react-redux';

import { PeriodsLoopInputs } from './periods-loop-inputs';
import { Header, Headers, PeriodsWrapper, Wrapper } from './periods-loop.styled';

export const PeriodsLoop = ({ disabled, zone, zones, newIndividual }) => {
  const selector = formValueSelector('TariffForm');
  const withLoop = useSelector((state) => {
    const zones = selector(state, 'zones');
    return zones && zones[zone] ? zones[zone].loop !== undefined : false;
  });

  const extended = true;//newIndividual || withLoop || !disabled;

  /**
   * get fields
   * @param {*} extended
   * @returns
   */
  const getFields = (extended) => {
    const k = window.innerWidth < 1450 ? 1.2 : 1.7;
    const fields = [
      { w: 100 * k, title: 'Период', right: true },
      { w: 80 * k, title: 'Тариф', right: true },
    ];
    if (extended) {
      fields.push({ w: 80 * k, title: 'Повтор', right: true });
      fields.push({ w: 60 * k, title: 'Цикл', $center: true });
    }
    fields.push({ title: '' });
    return fields;
  };
  const fields = getFields(extended);
  //console.log('🚀 == periods loop');

  return (
    <Wrapper>
      <PeriodsWrapper>
        <Headers>
          {fields.map((el, idx) => {
            const { title, ...rest } = el;
            return (
              <Header {...rest} key={idx}>
                {title}
              </Header>
            );
          })}
        </Headers>
        <FieldArray name="periods" component={PeriodsLoopInputs} disabled={disabled} tableFields={fields} zone={zone} extended={extended} />
      </PeriodsWrapper>
    </Wrapper>
  );
};
