import React from 'react';
import { connect } from 'react-redux';

import { resetParameters } from 'store/ducks/interface/widgetPopup';
import { Tip } from './tooltip.styled';

const TooltipCmp = ({ parameters, resetParameters }) => {
  const mouseLeave = (e) => {
    if (e.relatedTarget !== parameters.parent) {
      resetParameters();
    }
  };

  const mouseEnter = () => {
    if (parameters.clearParentClose) {
      parameters.clearParentClose();
    }
  };

  if (!parameters) {
    return null;
  }

  return (
    <Tip
      id="widget-popup"
      fs={parameters.fontSize}
      width={parameters.width}
      top={parameters.top}
      left={parameters.left}
      onMouseLeave={mouseLeave}
      onMouseEnter={mouseEnter}
    >
      {parameters.content}
    </Tip>
  );
};

const mapStateToProps = (store) => {
  return {
    parameters: store.widgetPopup.parameters,
  };
};

export const Tooltip = connect(mapStateToProps, {
  resetParameters,
})(TooltipCmp);
