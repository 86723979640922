import styled from 'styled-components';

export const NewTicketContent = styled.div`
  margin-left: 120px;
`;
export const NewTicketHeader = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;
export const NewTicketInfo = styled.p`
  font-size: 15px;
  margin-bottom: 10px;
`;
export const AttentionIcon = styled.img`
  width: 50px;
  height: 46px;
  position: absolute;
  left: 30px;
  top: 60px;
`;
export const StyledForm = styled.form`
  border-top: 1px solid ${(props) => (props.$invalid ? '#d33d34' : '#cccccc')};
  border-bottom: 1px solid ${(props) => (props.$invalid ? '#d33d34' : '#cccccc')};
  width: 400px;
  /* height: 60px; */
  margin-left: -120px;
  margin-bottom: 20px;
  padding: 5px 0 10px;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.block {
      display: block;
      padding-left: 118px;
    }
  }
  p {
    width: 131px;
    /* font-size: 13px; */
    color: #3e3e3e;
  }
  input {
    border: none;
    margin-right: 0px;
    width: 78%;
  }
`;

export const Label = styled.div`
  margin: 10px 0;
  color: gray;
  font-size: 12px;
`;
