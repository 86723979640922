import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 380px;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  padding-right: 18px;
`;
export const PeriodsWrapper = styled.div`
  margin-right: 40px;
  width: 225px;
`;
export const Header = styled.div`
  margin-bottom: 15px;
  opacity: 0.5;
  font-size: 20px;
  color: #ffffff;
`;
export const Headers = styled.div`
  margin-left: 14px;
  display: flex;
  div {
    &:first-child {
      margin-right: 32px;
    }
  }
`;
