import { api } from 'api';

const ADD_CLIENTS = '/pages/clients/grid/ADD_CLIENTS';
const CLEAR_ENTRIES = '/pages/clients/grid/CLEAR_ENTRIES';
const SET_CLIENTS_COUNT = '/pages/clients/grid/SET_CLIENTS_COUNT';
const SET_OFFSET = '/page/clients/grid/SET_OFFSET';
const SET_CLIENT_INFO = '/pages/clients/grid/SET_CLIENT_INFO';
const SET_SORT_PARAMS = '/pages/clients/grid/SET_SORT_PARAMS';
const SET_UPLOAD_DATA = '/pages/clients/grid/SET_UPLOAD_DATA';
const SET_IMPORT_ERRORS = '/pages/clients/grid/SET_IMPORT_ERRORS';

export default function reducer(state, action) {
  const defaultState = {
    clients: {},
    offset: 0,
    count: 0,
    sortParams: [{ field_name: 'id', direction: 'desc' }],
    uploadData: null,
    importErrors: null,
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case CLEAR_ENTRIES:
      return {
        ...state,
        clients: {},
      };
    case SET_CLIENTS_COUNT:
      return {
        ...state,
        count: action.data,
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    case ADD_CLIENTS:
      return {
        ...state,
        clients: {
          ...state.clients,
          ...action.data,
        },
      };
    case SET_CLIENT_INFO:
      return {
        ...state,
        client: action.data,
      };
    case SET_UPLOAD_DATA:
      return {
        ...state,
        uploadData: action.data,
      };
    case SET_IMPORT_ERRORS:
      return {
        ...state,
        importErrors: action.data,
      };
    default:
      return state;
  }
}

export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const setCount = (value) => {
  return {
    type: SET_CLIENTS_COUNT,
    data: value,
  };
};
export const addClients = (clients) => {
  return {
    type: ADD_CLIENTS,
    data: clients,
  };
};
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};
export const setClientInfo = (info) => {
  return {
    type: SET_CLIENT_INFO,
    data: info,
  };
};
export const setImportErrors = (importErrors) => {
  return {
    type: SET_IMPORT_ERRORS,
    data: importErrors,
  };
};
export const clearErrors = () => {
  return {
    type: SET_IMPORT_ERRORS,
    data: null,
  };
};
export const setUploadData = (data) => {
  return {
    type: SET_UPLOAD_DATA,
    data: data,
  };
};

export const getRequestParams = (filter) => (dispatch, getState) => {
  let decorateParams = {};
  for (let key in filter) {
    if (filter[key]) {
      switch (key) {
        case 'id':
          decorateParams[key] = Number(filter[key]);
          break;
        default:
          decorateParams[key] = filter[key];
      }
    }
  }
  return decorateParams;
};

export const sendFileContent = (params, callback) => (dispatch) => {
  return api
    .call('processing.import_list', params)
    .then((res) => {
      if (callback) {
        callback();
      }
    })
    .catch((error) => dispatch(setImportErrors(error.data)));
};
