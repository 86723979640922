import React, { Component } from 'react';
import { connect } from 'react-redux';

import { GridContainer } from 'libs/ui';
import { Controller } from 'libs/control';
import Grid from './Grid';
import Filter from './Filter';
import { getRequestParams, addEntries, clearEntries, setSortParams, setOffset, setCount, setEventInfo } from 'store/ducks/models/events';
import { setScroll } from 'store/ducks/interface/eventsGrid';

class Events extends Component {
  render() {
    const props = this.props;

    return (
      <GridContainer>
        <Controller
          parkingId={props.parkingId}
          type="events"
          gridFilter={Filter}
          grid={Grid}
          entries={props.events}
          getRequestParams={props.getRequestParams}
          count={props.count}
          offset={props.offset}
          addEntries={props.addEntries}
          clearEntries={props.clearEntries}
          setSortParams={props.setSortParams}
          setOffset={props.setOffset}
          setScroll={props.setScroll}
          sortParams={props.sortParams}
          setCount={props.setCount}
          setActionInfo={props.setActionInfo}
          actionInfo={props.eventInfo}
          setEventInfo={props.setEventInfo}
        />
      </GridContainer>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    events: store.events.entries,
    offset: store.events.offset,
    count: store.events.count,
    sortParams: store.events.sortParams,
    eventInfo: store.events.eventInfo,
    interface: store.eventsGrid,
  };
};

export default connect(mapStateToProps, {
  getRequestParams,
  addEntries,
  clearEntries,
  setSortParams,
  setOffset,
  setScroll,
  setCount,
  setEventInfo,
})(Events);
