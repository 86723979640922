import styled from 'styled-components';

export const Content = styled.div``;

export const Title = styled.div`
  margin: 0 0 30px;
  font-size: 20px;
  /* font-weight: bold; */
  line-height: normal;
`;

export const Buttons = styled.div`
  margin: 15px 0 0;
  padding: 20px 0 0;
  border-top: 1px solid #999;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > button {
    margin-left: 15px;
  }
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > * {
    margin: 0 20px 15px 0;
  }
`;