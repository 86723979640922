import styled, { css } from 'styled-components';

export const Content = styled.div`
  display: flex;
  cursor: pointer;
  color: #000;
  justify-content: space-between;
  align-items: center;
  height: 41px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.6);
  &:nth-child(2n) {
    background-color: rgba(0, 0, 0, 0.02);
  }
  p {
    padding-left: 20px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
    `}
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 295px;
`;

export const Name = styled.p`
  font-size: 15px;
  color: #000000;
`;
export const Discription = styled.p`
  opacity: 0.46;
  font-size: 12px;
  line-height: 1.4;
`;

export const CheckBox = styled.div`
  width: 14px;
  height: 14px;
  input {
    appearance: none;
    outline: none;
    cursor: pointer;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    border: 1px solid #fff;
    &:checked {
      background-image: url('/images/checkbox-checked.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #fff;
      border: none;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.color};
  width: 40px;
  height: 40px;
`;

export const RowContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
