//import { useField } from 'formik';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { FileField } from '../file-field/file-field';

export const MediaField = (props) => {
  const { onChange, ...other } = props;
  const { setFieldValue } = useFormikContext();

  /**
   * get media info
   * @param {} file
   * @param helpers - formik helpers
   */
  const getMediaInfo = async (file, helpers) => {
    if (!file) {
      const data = { file: '', info: null };
      helpers.setValue(data);
      onChange && onChange(data, helpers);
      return;
    }

    const fileCallbackToPromise = (fileObj) => {
      return Promise.race([
        new Promise((resolve) => {
          if (fileObj instanceof HTMLImageElement) {
            fileObj.onload = resolve;
          } else {
            fileObj.onloadedmetadata = resolve;
          }
        }),
        new Promise((_, reject) => {
          setTimeout(reject, 2000);
        }),
      ]);
    };

    const objectUrl = URL.createObjectURL(file);
    const isVideo = file.type.startsWith('video/');
    let fileInfo;
    if (isVideo) {
      const video = document.createElement('video');
      video.src = objectUrl;
      await fileCallbackToPromise(video);

      fileInfo = {
        duration: video.duration,
        width: video.videoWidth,
        height: video.videoHeight,
      };
    } else {
      const img = document.createElement('img');
      img.src = objectUrl;
      await fileCallbackToPromise(img);
      fileInfo = {
        width: img.naturalWidth,
        height: img.naturalHeight,
      };
    }

    helpers.setValue({ file, info: fileInfo });
    onChange && onChange(fileInfo, { ...helpers, setFieldValue });
    //console.log('🚀 ~ getFileDuration ~ fileInfo:', fileInfo);
  };

  return <FileField {...other} onChange={getMediaInfo} useExternalOnUpdate={true} />;
};

MediaField.defaultProps = {
  type: 'file',
};
MediaField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  accept: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default MediaField;
