import { useMemo, useState } from 'react';

import { user } from 'services';

import { TableExt, TableInfo, Confirm, themes, GridBox, GridUpdateMessage } from 'libs/ui';
import { getTableParams } from './grid.params';
import ReplaceWindow from '../rfid/forms/replace-window';
import { useTranslation } from 'react-i18next';
import { deleteFromGreyPlate, updateGreyPlate } from 'services/payments-service';
import GreyPlateForm from './forms/greyPlate-form';
import moment from 'moment';
import { routes } from 'router';

export const GreyPlateGrid = (props) => {
  const { count, entries, className, theme, getXlsAction, history } = props;

  const [showForm, setShowForm] = useState('');
  const [message, setMessage] = useState('');
  const [row, setRow] = useState({});
  const [confirm, setConfirm] = useState(null);

  const { t } = useTranslation();

  const closeReplaceForm = (success = false) => {
    closeForm();
    if (success) {
      setMessage('Изменение сохранено');
      setTimeout(() => setMessage(''), 3000);
    }
  };

  // actions
  const addItemAction = () => {
    setRow({});
    setShowForm('addForm');
  };

  /**
   * close confirm
   */
  const closeConfirm = () => {
    setConfirm(null);
  };

  const editItemAction = (row) => {
    setRow(row);
    setShowForm('editForm');
  };

  const parkSessionsAction = (row) => {
    setRow(row);
    history.push(routes.stays, {
      filter: {
        vehicle_plate: row.vehicle_plate,
      },
    });
  };

  /*
   * delete item
   */
  const delItem = (id) => {
    // console.log('🚀 ~ delItem', id);
    deleteFromGreyPlate(id).then(() => {
      props.getEntries({ offset: 0 }, null, true);
      closeConfirm();
    });
  };

  const delItemAction = (row) => {
    setRow(row);
    setConfirm({
      title: 'ГРЗ ' + row.vehicle_plate + ' будет удален. Продолжить?',
      width: '440px',
      type: 'danger',
      cancelButton: { text: 'Отмена', func: closeConfirm },
      acceptButton: { text: 'Удалить', func: () => delItem(row.id), theme: themes.red },
    });
  };

  /**
   * close form
   * @returns
   */
  const closeForm = () => setShowForm('');

  // actions
  const actions = [];
  const rightAdd = user.haveRight('grey_plate.add');
  const rightEdit = user.haveRight('grey_plate.update');
  const rightDel = user.haveRight('grey_plate.delete');

  if (rightDel) {
    actions.push({ title: 'Удалить', action: delItemAction });
  }

  if (rightEdit) {
    actions.push({
      isVisible: (row) => row.status !== 'blocked_by_client',
      getTitle: (row) => (!row.is_active ? 'Активировать' : 'Деактивировать'),
      action: (row) =>
        updateGreyPlate({ is_active: !row.is_active, vehicle_plate: row.vehicle_plate, reason: row.reason, comment: row.comment }) && props.refresh(),
    });

    actions.push({
      title: 'Парковочные сессии',
      action: parkSessionsAction,
    });
  }

  // top actions
  const topActions = [];
  if (rightAdd) {
    topActions.push({ title: 'Добавить', icon: 'fa-plus', theme: themes.blue, action: addItemAction });
  }
  if (count) {
    getXlsAction(topActions);
  }

  const d1 = moment().endOf('day').subtract(0, 'days');

  const initial =
    showForm === 'addForm'
      ? { start_at: d1.format(), end_at: d1.format() }
      : { ...row, start_at: moment.unix(row.start_at).format(), end_at: moment.unix(row.end_at).format() };

  const tableParams = useMemo(() => getTableParams(), []);
  // items
  const items = Object.keys(entries).map((key) => entries[key]);

  return (
    <GridBox>
      <TableInfo theme={theme} count={count} actions={topActions} />

      <TableExt
        theme={theme}
        title="Серый список"
        rows={{ count, items }}
        actions={actions}
        className={className}
        refresh={props.refresh}
        clientId={props.clientId}
        onRowClick={rightEdit ? editItemAction : undefined}
        {...tableParams}
      />

      {showForm === 'replaceForm' && <ReplaceWindow close={closeReplaceForm} />}

      {showForm && showForm !== 'extForm' && (
        <GreyPlateForm
          type={showForm}
          close={() => setShowForm('')}
          clientId={props.clientId}
          id={row.id}
          meanId={row.mean_id}
          meanType={row.mean_type}
          setMessage={setMessage}
          refresh={props.refresh}
          initialValues={initial}
        />
      )}

      {confirm && <Confirm {...confirm} />}

      {message && <GridUpdateMessage>{message}</GridUpdateMessage>}
    </GridBox>
  );
};
