import React, { Component } from 'react';
import { connect } from 'react-redux';

import zones from 'services/zones';

import { GridContainer } from 'libs/ui';
import { prepareFilter } from 'libs/common';
import BenefitsFilter from './Filter';
import BenefitsGrid from './Grid';
import { Controller } from 'libs/control';

import { addBenefits, clearEntries, setSortParams, setOffset, setCount, reset } from 'store/ducks/models/benefits';
import { setScroll } from 'store/ducks/interface/benefitsGrid';

class Discounts extends Component {
  /**
   * additional flt
   * @param {*} params
   */
  getRequestParams = (params) => {
    return prepareFilter(params, { type: 'check' }, { priceFields: ['amount_from', 'amount_to'] });
    //return prepareFilter(params, { type: 'handicapped' }, {intFields: ['mean_id']});
  };

  render() {
    return (
      <GridContainer>
        <Controller
          type="benefit"
          filterName="discountFilter"
          gridFilter={BenefitsFilter}
          grid={BenefitsGrid}
          zones={zones}
          entries={this.props.benefits}
          offset={this.props.offset}
          interface={this.props.interface}
          count={this.props.count}
          sortParams={this.props.sortParams}
          //methods
          //getRequestParams={this.props.getRequestParams}
          getRequestParams={this.getRequestParams}
          addEntries={this.props.addBenefits}
          clearEntries={this.props.clearEntries}
          setOffset={this.props.setOffset}
          setSortParams={this.props.setSortParams}
          setCount={this.props.setCount}
          setScroll={this.props.setScroll}
        />
      </GridContainer>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    benefits: store.benefits.benefits,
    offset: store.benefits.offset,
    count: store.benefits.count,
    sortParams: store.benefits.sortParams,
    interface: store.benefitsGrid,
  };
};

export default connect(mapStateToProps, {
  addBenefits,
  clearEntries,
  setScroll,
  setSortParams,
  setOffset,
  setCount,
  reset,
})(Discounts);
