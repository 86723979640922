import styled, { css } from 'styled-components';

export const Reset = styled.div`
  position: relative;
  height: 40px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  font-size: 15px;
  color: #3ea5ff;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 7px;
    height: 1px;
    width: 100%;
    background-color: #3ea5ff;
  }
  &:hover {
    color: #0085ef;
    opacity: 0.6;
  }
  ${(props) =>
    props.theme === 'dark' &&
    css`
      &:hover {
        color: #fff;
        opacity: 1;
        &:after {
          background-color: #fff;
        }
      }
    `}
`;
