import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 4px;
`;

export const Zone = styled.div`
  font-weight: bold;
  font-size: 13px;
  margin: 0 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;
