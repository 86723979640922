import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { user } from 'services';
import { Submit, ButtonContainer } from 'libs/ui';
import { StyledField, ErrorMsg, required, itn, iec, psrn, okpo } from 'libs/form';
import { LK } from './lk';

function LegalPersonForm(props) {
  const { initialValues } = props;  
  const rightUpdate = user.haveRight('clients.update');

  return (
    <form onSubmit={props.handleSubmit}>
      <ErrorMsg theme="dark" message={props.error} />
      <StyledField validate={required} type="text" label="Наименование" name="name" placeholder="ООО Север" />
      <StyledField validate={[itn]} mask="9999 9999 9999" type="text" label="ИНН" name="itn" placeholder="" />
      <StyledField validate={iec} mask="9999 9999 9" type="text" label="КПП" name="iec" placeholder="" />
      <StyledField validate={psrn} mask="9999 9999 9999 9" type="text" label="ОГРН" name="psrn" placeholder="" />
      <StyledField validate={okpo} mask="9999 9999 99" type="text" label="ОКПО" name="okpo" placeholder="" />
      <StyledField type="text" label="Юр.адрес" name="legal_address" placeholder="г. Санкт-Петербург, Загородный проспект 26, кв 43" />
      <StyledField type="text" label="Почтовый адрес" name="postal_address" placeholder="г. Санкт-Петербург, Загородный проспект 26, кв 43" />
      <LK lk_login={initialValues.lk_login} />
      {rightUpdate && (
        <ButtonContainer>
          <Submit disabled={props.submitting} />
        </ButtonContainer>
      )}
    </form>
  );
}

const getDecorateValues = (values) => {
  let decorateValues = {};
  for (let key in values) {
    if (values[key]) {
      decorateValues[key] = values[key];
    }
  }
  return decorateValues;
};

export default connect((store) => {
  let { type, name, itn, iec, psrn, okpo, legal_address, postal_address, data } = store.client.clientInfo;
  // console.log('🚀 ~ connect ~ client:', store.client);

  let { lk_unlimited, lk_login } = data ? data : { lk_unlimited: '', lk_login: '' };

  const initialValues = getDecorateValues({
    type,
    name,
    itn,
    iec,
    psrn,
    okpo,
    legal_address,
    postal_address,
    lk_unlimited,
    lk_login,
  });
  // console.log('🚀 ~ connect ~ init:', initialValues);
  return {
    initialValues: initialValues,
  };
})(
  reduxForm({
    form: 'LegalPersonForm',
  })(LegalPersonForm)
);
