import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { user } from 'services';
import { getDefaultPage } from './router-func';

/**
 * public route
 *
 * @param {*} param0
 */
export const PublicRoute = ({ component: Component, ...rest }) => {
  if (user.isLogined) {
    const defaultPage = getDefaultPage();
    return <Redirect to={defaultPage} />;
  } else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
};
