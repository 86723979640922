import { connect } from 'react-redux';

import { prepareFilter } from 'libs/common';
import { GridContainer } from 'libs/ui';
import { Controller } from 'libs/control';
import Grid from './Grid';
import Filter from './Filter';
import { getRequestParams, addEntries, clearEntries, setSortParams, setOffset, setCount, setActionInfo } from 'store/ducks/models/actions';
import { setScroll } from 'store/ducks/interface/actionsGrid';

const Actions = (props) => {
  /**
   * prepare params
   * @param {*} data
   * @returns
   */
  const prepareParams = (data) => {
    return prepareFilter(props.getRequestParams(data));
  };
  return (
    <GridContainer>
      <Controller
        type="actions"
        gridFilter={Filter}
        grid={Grid}
        entries={props.actions}
        count={props.count}
        offset={props.offset}
        //methods
        getRequestParams={prepareParams}
        addEntries={props.addEntries}
        clearEntries={props.clearEntries}
        setSortParams={props.setSortParams}
        setOffset={props.setOffset}
        setScroll={props.setScroll}
        sortParams={props.sortParams}
        setCount={props.setCount}
        setActionInfo={props.setActionInfo}
        actionInfo={props.actionInfo}
      />
    </GridContainer>
  );
};

const mapStateToProps = (store) => {
  return {
    actions: store.actions.entries,
    offset: store.actions.offset,
    sortParams: store.actions.sortParams,
    actionInfo: store.actions.actionInfo,
    count: store.actions.count,
    interface: store.actionsGrid,
  };
};

export default connect(mapStateToProps, {
  getRequestParams,
  addEntries,
  clearEntries,
  setSortParams,
  setOffset,
  setScroll,
  setCount,
  setActionInfo,
})(Actions);
