import React from 'react';

import { Button } from 'libs/ui';
import { StyledButtonContainer, StyledInput, Form1, Permissions } from './permForm.styled';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: this.props.initialValues.permissions,
      flt: '',
    };
    //console.log('🚀 ~ cre permissions', this.props.initialValues.permissions);
  }

  setPerm = (e, name, idx) => {
    const val = e.target.checked;
    //const name = e.target.name;
    //console.log(name, val);
    this.props.updatePermission(this.props.id, name, val);
    const out = [...this.state.permissions];
    const idxOk = out.findIndex((el) => el.permission_id === name);
    out[idxOk].is_allowed = val;
    //console.log('🚀 ~ out', out);
    this.setState({ permission: out });
  };

  filter = (data) => {
    //console.log('🚀 ~ data', data);
    this.setState({ flt: data.target.value });
  };

  render() {
    const { flt } = this.state;
    const permissions = this.state.permissions
      .filter((el) => {
        return el.permission_description.toLowerCase().indexOf(flt.toLowerCase()) !== -1;
      })
      .map((el, idx) => {
        return (
          <label key={idx}>
            <input
              onChange={(e) => this.setPerm(e, el.permission_id, idx)}
              type="checkbox"
              name={el.permission_id}
              checked={el.is_allowed}
            />
            <span>{el.permission_description}</span>
          </label>
        );
        //return <WhiteStyledField key={el.permission_id} type="checkbox" label={el.permission_description} name={el.permission_id} checked={el.is_allowed} />;
      });

    return (
      <form>
        <Form1>
          <Permissions>{permissions}</Permissions>
        </Form1>
        <StyledButtonContainer space="20px">
          <StyledInput value={flt} onChange={this.filter} type="text" label="Фильтр" placeholder="Фильтр" name="flt" />
          <Button
            type="button"
            onClick={() => {
              this.props.displayHandler(false);
            }}
          >
            Закрыть
          </Button>
        </StyledButtonContainer>
      </form>
    );
  }
}

export default Form;
