import PropTypes from 'prop-types';

import { Content, Button, PrimaryButton } from './footer.styled';

export const Footer = (props) => {
  const { cancel, set } = props;

  const cancelClick = (event) => {
    event.stopPropagation();
    cancel();
  };

  return (
    <Content>
      <Button type="button" onClick={cancelClick}>
        Отмена
      </Button>
      <PrimaryButton type="button" onClick={set}>
        Ok
      </PrimaryButton>
    </Content>
  );
};

Footer.propTypes = {
  cancel: PropTypes.func.isRequired,
  set: PropTypes.func.isRequired,
};
