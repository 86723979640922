import * as yup from 'yup';

const maxSize = 200 * 1024 * 1024;

const toMb = (val) => {
  return (val / (1024 * 1024)).toFixed(1);
};

export const validationSchema = yup.object({
  file1: yup
    .mixed()
    //.required('Заполните поле')
    .test('empty', 'Заполните поле', (data) => {
      return data && data.file;
    })
    .test('size', 'Превышен максимальный размер файла ' + toMb(maxSize) + 'Мб', (data) => {
      if (!data) {
        return false;
      }

      const { file } = data;
      if (!file) {
        return false;
      }

      if (file.size && file.size > maxSize) {
        //const dif = file.size - maxSize;
        //const err = 'Превышен максимальный размер файла ' + toMb(maxSize) + 'Мб на ' + toMb(dif) + 'Мб';
        return false;
      }

      return true;
    })
    .test('wh', 'Максимальный допустимое разрешение 1024x430', (data) => {
      if (!data) {
        return false;
      }
      //console.log('🚀 ~ file1:yup.mixed ~ data:', data);
      const { info } = data; // {duration, width, height}
      if (info) {
        if (info.width > 1024 || info.height > 430) {
          return false;
        }
      }
      return true;
    }),
  duration: yup
    .number()
    //.integer('Только целые числа')
    .positive('Только положительные числа')
    .max(10000, 'Максимум 10000 секунд'),
  //.min(4, 'Минимум 4 символа')
  //.required('Заполните поле')
  datefrom: yup.date(),
  datetill: yup.date(),
  //timefrom: yup.time(),
  //timetill: yup.time(),
});
