import styled from 'styled-components';

export const PlayerWrapper = styled.div`
  position: absolute;
  width: 280px;
  height: 34px;
  border-radius: 20px;
  background-color: #262b31;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 2px;
  right: 128px; //-130px;
`;
export const Progress = styled.div``;
export const ProgressBar = styled.div`
  width: 178px;
  height: 3px;
  background-color: #ffffff;
  cursor: pointer;
`;
export const Time = styled.div`
  margin-left: 10px;
  font-size: 12px;
  color: #ffffff;
`;
export const Close = styled.img`
  position: absolute;
  right: -20px;
  cursor: pointer;
`;
export const ActiveProgress = styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: left;
  position: absolute;
  top: 13px;
  width: 178px;
`;
export const ActiveBar = styled.div`
  height: 3px;
  width: ${(props) => props.width || 0};
  background-color: #47a9fe;
`;
export const ActivePoint = styled.div`
  width: 9px;
  height: 9px;
  background-color: #47a9fe;
  border-radius: 50%;
`;
