export const Time = (props) => {
  const { input, className, label, disabled } = props;

  return (
    <div className={className}>
      <label>{label}</label>
      <input disabled={disabled} type="time" step="30" placeholder="00:00:00" {...input} />
    </div>
  );
};
