import { useState } from 'react';

import { prepareFilter } from 'libs/common';
import { getVehicleClasses, getCategories } from 'services/types';
import { Controller } from 'libs/control';
import { StayGrid } from './grid';
import Filter from './filter';

export const Stay = (props) => {
  const [items, setItems] = useState({});
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [sortParams, setSortParams] = useState([{ field_name: 'entry_at', direction: 'desc' }]);

  // classes
  const vehicleClasses = getVehicleClasses();
  const categories = getCategories();

  /**
   * prepare params
   * @param {*} data
   * @returns
   */
  const prepareParams = (data) => {
    const intFields = [];
    const durationFields = ['duration_from', 'duration_to'];
    const boolFields = ['is_active'];
    const priceFields = ['amount_from', 'amount_to'];
    const addParams = {};
    return prepareFilter(data, addParams, { boolFields, intFields, priceFields, durationFields });
  };

  // grid actions
  const gridActions = {
    addEntries: (data) => setItems({ ...items, ...data }),
    clearEntries: () => setItems({}),
    setSortParams: (data) => setSortParams(data),
    setOffset: (val) => setOffset(val),
    setCount: (val) => setCount(val),
  };

  // grid params
  const gridParams = {
    entries: items,
    offset,
    count,
    sortParams,
  };

  return (
    <Controller
      {...props}
      {...gridParams}
      {...gridActions}
      locationState={props.location.state}
      type="stays"
      filterName="staysFilter"
      grid={StayGrid}
      gridFilter={Filter}
      getRequestParams={prepareParams}
      // additional data
      vehicleClasses={vehicleClasses}
      categories={categories}
    />
  );
};
