import styled from 'styled-components';

export const Content = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid black;
`;

export const CarPlate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 30px;
  border-radius: 2px;
  background-color: ${(props) => (!props.$isSuspicious ? '#ffffff' : 'rgba(211, 61, 52, 0.05)')};
  border: solid 1px ${(props) => (!props.$isSuspicious ? '#000000' : '#d33d34')};
  font-size: 15px;
  color: ${(props) => (!props.$isSuspicious ? 'black' : '#d33d34')};
`;

export const CentralBlock = styled.div`
  width: 800px;
`;

export const CentralBlockContent = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CentralBlockSeparator = styled.div`
  position: relative;
  width: 800px;
  height: 1px;
  opacity: 0.2;
  background-color: #000000;
  &:before,
  &:after {
    display: block;
    border: 1px solid black;
    content: ' ';
    width: 5px;
    height: 5px;
    background-color: #000000;
    border-radius: 50%;
    position: absolute;
    top: -3px;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Length = styled.div`
  font-size: 20px;
  opacity: 0.4;
  color: #000000;
`;

export const MeanNumber = styled.div`
  margin-left: ${(props) => (props.left ? '5px' : '0')};
  margin-right: ${(props) => (props.right ? '5px' : '0')};
  font-size: 15px;
  font-weight: bold;
`;

export const NoNumber = styled.p`
  opacity: 0.4;
`;

export const Time = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  min-width: 150px;
`;
