import styled from 'styled-components';

export const Content = styled.div`
  max-width: 250px;
  width: 100%;
  //height: 100%;
`;

export const CalendarPlace = styled.div`
  position: absolute;
`;

export const InputBox = styled.div`
  position: relative;
  i {
    position: absolute;
    top: 2px;
    right: 0px;
    //color: #fff;
    opacity: 0.6;
  }
`;
