import styled from 'styled-components';

export const Content = styled.div``;

export const Table = styled.table`
  margin: 0 0 10px;
  font-size: 14px;
  th,
  td {
    padding: 0 5px 5px 0;
  }
  th {
    text-align: center;
    font-weight: normal;
    font-size: 13px;
    padding-bottom: 10px;
    vertical-align: middle;
  }
  button {
    margin: 0 0 0 1.6em;
    line-height: 1;
  }
  input,
  select {
    /* max-width: 100%; */
    /* width: auto; */
    max-width: 80px;
    font-size: 15px;
    color: #000;
    padding: 11px 10px 9px;
    border-radius: 4px;
    border: solid 1px #bebebe;
    height: 30px;
  }
  select {
    padding: 3px;
    min-width: 125px;
    max-width: 125px;
  }
  input[type='checkbox'] {
    font-size: 12px;
    line-height: 1;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  td > span {
    display: block;
    position: relative;
    color: #000;
  }
`;
