import styled from 'styled-components';

export const Tr = styled.tr`
  &.row-click {
    cursor: pointer;
  }
`;

export const EmptyCell = styled.td`
  background: rgba(127, 207, 250, 0.3);
`;

export const Cell = styled.td`
  background: white;
  &:first-of-type {
    min-width: 20ch;
  }
`;
