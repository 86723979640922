import styled from 'styled-components';

import { Submit, UpdateMessage } from 'libs/ui';

export const FormHeader = styled.p`
  border-bottom: solid 1px #595d64;
  padding: 0 0 15px 0;
  font-size: 20px;
  color: #fff;
  opacity: 0.6;
`;

export const SaveButton = styled(Submit)`
  position: absolute;
  bottom: 30px;
  right: 30px;
  &[disabled] {
    cursor: not-allowed;
  }
`;

export const UpdateMessageBox = styled(UpdateMessage)`
  bottom: -100px;
  left: 180px;
  width: 270px;
`;

export const TariffData = styled.div`
  width: 590px;
  position: relative;
`