import styled from 'styled-components';

export const Content = styled.div`
  position: fixed;
  left: 35px;
  transform: translateY(10px);
  /* top: 0; */
  /* width: 70%; */
  max-width: 60%;
  z-index: 999;
  min-width: 640px;
  border-radius: 4px;
  background-color: #fff;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #f2f2f2),linear-gradient(#ffffff, #ffffff);
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.5);
  color: #333;
  padding: 20px;
  &:before {
    content: '';
    top: -10px;
    left: ${props => props.$arrowLeft ? props.$arrowLeft : '112px'};
    transform: translate(-50%, 0) rotate(45deg);
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-left: solid 2px #a0a0a073;
    border-top: solid 2px #a0a0a073;
    border-bottom-right-radius: 100%;
  }
`;
