import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { data, user } from 'services';
import { Cell, GridRow } from 'libs/ui';
import { routes } from 'router';

class Client extends Component {
  getContactName = () => {
    return this.props.contact ? this.props.contact.name : '';
  };

  getPhoneNumber = () => {
    return this.props.contact ? this.props.contact.tel_num : '';
  };

  showCard = () => {
    this.props.history.push(`${routes.client}?id=${this.props.id}`);
  };

  render() {
    const rightShowCard = user.haveRight('clients.view');
    return (
      <GridRow onClick={rightShowCard ? this.showCard : null}>
        <Cell>{this.props.name}</Cell>
        <Cell>{data.getDictValue('clients.type', this.props.type)}</Cell>
        <Cell>{data.getDictValue('clients.status', this.props.status)}</Cell>
        <Cell>{this.getContactName()}</Cell>
        <Cell>{this.getPhoneNumber()}</Cell>
      </GridRow>
    );
  }
}

export default withRouter(Client);
