import styled from 'styled-components';

export const Content = styled.div`
  min-height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding: 20px;
`;

export const SumWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Sum = styled.p`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-style: italic;
  margin-left: 10px;
  line-height: 0.92;
  span {
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    margin-left: 15px;
  }
`;
