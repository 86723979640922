import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 16px;
  line-height: normal;  
  cursor: pointer;
`;

export const Title = styled.span`
  display: block;
  padding-left: 5px;
`;
