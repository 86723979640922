import React from 'react'
import { connect } from 'react-redux'

import { GridContainer } from 'libs/ui'
import { Controller } from 'libs/control'
import Grid from './Grid'

import {
  getRequestParams,
  clearEntries,
  addUsers,
  setSortParams,
  setOffset,
  setUserInfo,
  addUser,
  updateUser
} from 'store/ducks/models/users'
import { 
  setScroll,
  addFormDisplayHandler, 
  updateFormDisplayHandler,
  updateMessageDisplayHandler
} from 'store/ducks/interface/usersGrid'
import Filter from './Filter'

const Users = (props) => {
  return (
    <GridContainer>
      <Controller
        type='users'
        grid={Grid}
        gridFilter={Filter}
        offset={props.offset}
        entries={props.users}
        getRequestParams={props.getRequestParams}
        userInfo={props.userInfo}
        clearEntries={props.clearEntries}
        addEntries={props.addUsers}
        setUserInfo={props.setUserInfo}
        addUser={props.addUser}
        updateUser={props.updateUser}
        setSortParams={props.setSortParams}
        setOffset={props.setOffset}
        setScroll={props.setScroll}
        sortParams={props.sortParams}
        addFormDisplayHandler={props.addFormDisplayHandler}
        updateFormDisplayHandler={props.updateFormDisplayHandler}
        updateMessageDisplayHandler={props.updateMessageDisplayHandler}
        interface={props.interface}
      />
    </GridContainer>
  )
}

const mapStateToProps = (store) => {
  return {
    users: store.users.users,
    offset: store.users.offset,
    userInfo: store.users.userInfo,
    sortParams: store.users.sortParams,
    interface: store.usersGrid
  }
}

export default connect(mapStateToProps, {
  clearEntries,
  addUsers,
  getRequestParams,
  setSortParams,
  setOffset,
  setScroll,
  setUserInfo,
  addUser,
  updateUser,
  addFormDisplayHandler,
  updateFormDisplayHandler,
  updateMessageDisplayHandler
})(Users)