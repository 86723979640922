import React from 'react';
import PropTypes from 'prop-types';

import { data } from 'services';
import { WhiteStyledField } from '../fields';
import { required } from '../validation';
//import { Place, StyledLabel } from './dict-input.styled';

export const DictInput = (props) => {
  const { w, dict, exclude, name, withEmpty, disabled, ...rest } = props;

  const dictionary = data.getDict(dict);
  if (!dictionary) {
    return null;
  }

  const getItems = () => {
    return dictionary.items
      .filter((el) => !exclude.includes(el.value))
      .map((el, idx) => {
        let val = el.value;
        return (
          <option key={idx} value={val}>
            {el.name}
          </option>
        );
      });
  };

  const title = dictionary.title;
  const nameOk = dictionary.param ? dictionary.param : name;
  const options = getItems();
  if (props.required) {
    rest.validate = required;
  }

  return (
    <WhiteStyledField type="select" disabled={disabled} label={title} defaultValue={props.defaultValue} name={nameOk} {...rest}>
      {withEmpty && <option value="">Выберите значение</option>}
      {options}
    </WhiteStyledField>
  );
};

DictInput.propTypes = {
  dict: PropTypes.string.isRequired,
  name: PropTypes.string,
  w: PropTypes.number,
  type: PropTypes.string,
  theme: PropTypes.string,
  exclude: PropTypes.array,
  withEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

DictInput.defaultProps = {
  w: 110,
  type: 'select',
  theme: '',
  exclude: [],
  withEmpty: false,
  disabled: false,
};
