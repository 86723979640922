import { useState } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import Calendar from 'rc-calendar';
import ruRU from 'rc-calendar/lib/locale/ru_RU';
//import TimePicker from 'rc-time-picker';
//import TimePicker from 'react-gradient-timepicker';
import OutsideClickHandler from 'react-outside-click-handler';

import { TimePicker } from 'libs/ui';
import { Field } from '../field/field';
import { Content, CalendarPlace, InputBox } from './date-field.styled';

export const DateField = (props) => {
  const { label, placeholder, withoutIcon, ...other } = props;
  const [field, meta, helpers] = useField(other);
  const [show, setShow] = useState(false);

  const placeholderOk = placeholder ?? label;
  const id = props.name;
  const value = field.value;

  /**
   * get display value
   * @returns
   */
  const getValue = () => {
    if (!value) {
      return '';
    }

    if (props.type === 'time') {
      return value;
    }

    return moment(value)
      .format('ll')
      .replace(/[г., ]{4}/, '')
      .replace(/\./, '');
  };

  /**
   * toggle calendar
   */
  const toggleCalendar = () => {
    const showNew = !show;
    setShow(showNew);
  };

  /**
   * close calendar
   * @param {*} event
   */
  const closeCalendar = (event) => {
    if (show) {
      toggleCalendar();
    }
  };

  const getField = () => {
    switch (props.type) {
      case 'time':
        return (
          <TimePicker
            value={value}
            set={(val) => {
              helpers.setValue(val);
            }}
            close={closeCalendar}
          />
        );
      default:
        return (
          <Calendar
            value={moment(value)}
            selectedValue={moment(value)}
            timePicker={null}
            onSelect={(date) => {
              helpers.setValue(date);
            }}
            onChange={(date) => {
              helpers.setValue(date);
            }}
            locale={ruRU}
            className={'calendar'}
            showDateInput={false}
          />
        );
    }
  };

  const togglePopup = (event) => {
    // console.log('🚀 ~ togglePopup ~ event:', event);
    toggleCalendar();
  };

  const displayValue = getValue();
  const error = meta.touched && meta.error ? meta.error : '';
  const params = { label, name: props.name, type: props.type, error };
  const cmp = getField();

  return (
    <OutsideClickHandler onOutsideClick={closeCalendar}>
      <Field {...params}>
        <Content>
          <InputBox onClick={togglePopup}>
            <input {...field} {...other} placeholder={placeholderOk} id={id} disabled value={displayValue} type="text" />
            {!withoutIcon && <i className="fa fa-calendar" />}
          </InputBox>
          {show && <CalendarPlace>{cmp}</CalendarPlace>}
        </Content>
      </Field>
    </OutsideClickHandler>
  );
};

DateField.defaultProps = {
  type: 'date', //time
  withoutIcon: false,
};
DateField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  withoutIcon: PropTypes.bool,
};

export default DateField;
