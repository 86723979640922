import { api } from 'api';

import {
  UPDATE_COMMENT_EPM,
  UPDATE_COMMENT_RFID,
  UPDATE_COMMENT_SUB,
  UPDATE_COMMENT_VP,
  SET_FORM_INFO_EPM,
  SET_FORM_INFO_RFID,
  SET_FORM_INFO_VP,
  SET_FORM_INFO_SUB,
  REMOVE_FORM_INFO_EPM,
  REMOVE_FORM_INFO_RFID,
  REMOVE_FORM_INFO_VP,
  REMOVE_FORM_INFO_SUB,
} from './pay.const';

const types = {
  epms: UPDATE_COMMENT_EPM,
  rfids: UPDATE_COMMENT_RFID,
  vehiclePlates: UPDATE_COMMENT_VP,
  subscriptions: UPDATE_COMMENT_SUB,
};
const typesFormShow = {
  epms: SET_FORM_INFO_EPM,
  rfids: SET_FORM_INFO_RFID,
  vehiclePlates: SET_FORM_INFO_VP,
  subscriptions: SET_FORM_INFO_SUB,
};
const typesFormRemove = {
  epms: REMOVE_FORM_INFO_EPM,
  rfids: REMOVE_FORM_INFO_RFID,
  vehiclePlates: REMOVE_FORM_INFO_VP,
  subscriptions: REMOVE_FORM_INFO_SUB,
};

export const updateComment = (id, comment, type) => {
  return {
    type: types[type],
    id: id,
    comment: comment,
  };
};

export const addComment = (info, type) => (dispatch) => {
  api.call('comment.add', { linked_id: info.id, comment: info.comment }).then((data) => {
    dispatch(updateComment(data.linked_id, data.comment, type));
  });
};

export const setFormInfo1 = (info, type) => {
  return {
    type: typesFormShow[type],
    data: info,
  };
};

export const removeFormInfo1 = (type) => {
  return {
    type: typesFormRemove[type],
  };
};
