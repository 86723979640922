import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
`;
export const Header = styled.h1`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #262b31;
`;
export const MainBlock = styled.div`
  font-size: 15px;
  color: #3e3e3e;
`;
export const FormBox = styled.div`
  position: absolute;
  z-index: 999;
  top: -220px;
  right: 0;
  width: 440px;
  height: 220px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.5);
`;
export const CommentsContent = styled.div`
  position: absolute;
  z-index: 999;
  //top: -420px;
  top: auto;
  bottom: 35px;
  right: 0;
  width: 600px;
  padding: 15px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.5);
`;
export const CommentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 20px 0;
  padding: 13px 0;
  width: 400px;
  height: 80px;
  padding: 10px 0;
  align-items: flex-start;
  border-bottom: solid 1px #8e8e8e;
  border-top: solid 1px #8e8e8e;
  input::placeholder {
    color: #cccccc;
  }
`;
export const CommentFieldName = styled.p`
  color: #8f8f8f;
  font-size: 13px;
`;
export const CommentInput = styled.textarea`
  font-size: 15px;
  width: 270px;
  height: 60px;
  border: none;
  outline: none;
  resize: none;
  ::placeholder {
    color: #cccccc;
  }
`;
export const CommentsBlock = styled.div`
  color: #8f8f8f;
  font-size: 12px;
  margin: 15px 0;
  padding: 10px 0;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;

  th {
    color: #000;
    padding: 5px !important;
    text-align: left;
  }
  td {
    color: #8f8f8f !important;
    padding: 5px !important;
    text-align: left;
    white-space: normal;
    max-width: 100%;
    div {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
`;

export const Comment = styled.tr``;
