import React from 'react';

import { user } from 'services';
import { Table } from 'libs/ui';

export const TableExt = (props) => {
  const { withComment, comments, refresh, getFields, ...rest } = props;

  const changeActions = (actions) => {
    if (!actions) {
      return actions;
    }
    const out = actions.slice();
    if (withComment) {
      if (user.haveRight('comment.add')) {
        out.push({ title: 'Оставить комментарий', action: 'add-comment' }); //addCommentAction
      }
      if (user.haveRight('comment.list')) {
        out.push({ title: 'Посмотреть комментарии', action: 'show-comments', isVisible: (row) => row.comments && row.comments.length }); //showCommentsAction
      }
    }
    return out;
  };

  const actions = changeActions(props.actions);

  // fields
  if (getFields) {
    rest.fields = getFields(props);
  }

  // render
  return <Table {...rest} actions={actions} />;
};
