import React from 'react';
import styled from 'styled-components';

import { SortContext } from 'libs/control';
import { TableHeaders, SortTableHeaders } from './table-header.styled';

export const TableHeader = (props) => {
  const getHeader = () => {
    if (props.sortField) {
      return (
        <SortContext.Consumer>
          {({ setSort, getSortArrow }) => (
            <SortTableHeaders className={props.className} number={props.number} onClick={() => setSort(props.sortField)} width={props.width}>
              <div>
                <p>{props.children}</p>
                {getSortArrow ? getSortArrow(props.sortField) : null}
              </div>
            </SortTableHeaders>
          )}
        </SortContext.Consumer>
      );
    } else {
      return (
        <TableHeaders className={props.className} number={props.number} width={props.width}>
          {props.children}
        </TableHeaders>
      );
    }
  };

  return getHeader();
};

export const StatusTableHeader = styled(TableHeader)`
  padding-left: 15px;
`;
