import { api } from 'api';

const CLEAR_ENTRIES = '/pages/payments/subscriptions/CLEAR_ENTRIES';
const UPDATE_COMMENT = '/pages/payments/subscriptions/UPDATE_COMMENT';
const SET_SUBSCRIPTIONS_COUNT = '/pages/payments/subscriptions/SET_SUBSCRIPTIONS_COUNT';
const SET_OFFSET = '/page/payments/subscriptions/SET_OFFSET';
const SET_SORT_PARAMS = '/pages/payments/subscriptions/SET_SORT_PARAMS';
const SET_FORM_INFO = '/pages/payments/subscriptions/SET_FORM_INFO';
const REMOVE_FORM_INFO = '/pages/payments/subscriptions/REMOVE_FORM_INFO';
const ADD_LIST = '/pages/payments/subscriptions/ADD_LIST';

const SET_ITEM_INFO = '/pages/payments/subscriptions/SET_ITEM_INFO';

export default function reducer(state, action) {
  const defaultState = {
    subscriptions: {},
    item: {},
    offset: 0,
    count: 0,
    sortParams: [{ field_name: 'pan', direction: 'asc' }],
    formInfo: {},
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case CLEAR_ENTRIES:
      return {
        ...state,
        subscriptions: {},
      };
    case ADD_LIST:
      return {
        ...state,
        subscriptions: { ...state.subscriptions, ...action.data },
      };
    case SET_SUBSCRIPTIONS_COUNT:
      return {
        ...state,
        count: action.data,
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    case SET_FORM_INFO:
      return {
        ...state,
        formInfo: action.data,
      };
    case REMOVE_FORM_INFO:
      return {
        ...state,
        formInfo: null,
      };

    case SET_ITEM_INFO:
      return {
        ...state,
        item: action.data,
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.id]: {
            ...state.subscriptions[action.id],
            comment: action.comment,
            comments: [1],
          },
        },
      };

    default:
      return state;
  }
}

export const addList = (items) => {
  return {
    type: ADD_LIST,
    data: items,
  };
};
export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const setCount = (value) => {
  return {
    type: SET_SUBSCRIPTIONS_COUNT,
    data: value,
  };
};
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};
export const setFormInfo = (info) => {
  return {
    type: SET_FORM_INFO,
    data: info,
  };
};
export const removeFormInfo = () => {
  return {
    type: REMOVE_FORM_INFO,
  };
};
export const setCategories = (data) => {
  return {
    type: SET_CATEGORIES,
    data: data,
  };
};
export const setVehicleСlasses = (data) => {
  return {
    type: SET_VEHICLE_СLASSES,
    data: data,
  };
};
export const getRequestParams = (filter) => (__dispatch, __getState) => {
  let decorateParams = {};
  const intItems = ['vehicle_category', 'vehicle_class'];
  for (let key in filter) {
    if (filter[key]) {
      let key1 = key;
      let val = filter[key];
      if (key === 'is_blocked') {
        val = val === 'true';
      }
      if (intItems.indexOf(key) !== -1) {
        val = Number(val);
      }

      decorateParams[key1] = val;
    }
  }
  return decorateParams;
};

export const setItemInfo = (value) => {
  return {
    type: SET_ITEM_INFO,
    data: value,
  };
};

export const addItem = (params, callback) => (__dispatch) => {
  return api.call('subscriptions.add', params).then(
    (__data) => {
      if (callback) {
        callback();
      }
    },
    (error) => {
      return error;
    }
  );
};

export const updateItem = (params, callback) => (__dispatch) => {
  //console.log('🚀 ~ updateItem ~ params', params);

  return api.call('subscriptions.update', params).then(
    (__data) => {
      if (callback) {
        callback();
      }
    },
    (error) => {
      return error;
    }
  );
};
