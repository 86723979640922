import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import moment from 'moment';

import { DateSelect, Container, Input, DoubleField, DateTime, ButtonsContainer, DictFilter, ParkingFilter, SourceFilter } from 'libs/form';
import { ResetBtn, UseBtn } from 'libs/ui';

class Filter extends Component {
  constructor(props) {
    super(props);

    // initial
    this.props.setInitial(initialValues);
  }

  render() {
    const { handleSubmit, reset, change, filter } = this.props;

    return (
      <Container /*height='160px'*/>
        <form onSubmit={handleSubmit}>
          <DateSelect from_name="timestamp_from" to_name="timestamp_to" change={change}>
            <DoubleField>
              <DateTime width="160px" name="timestamp_from" type="dateTime" label="Дата и время" />
              <DateTime width="160px" name="timestamp_to" type="dateTime" />
            </DoubleField>
          </DateSelect>

          <SourceFilter sources="events.source_type" parkingIdLocal={filter ? filter.parking_id : ''} />

          <DictFilter w={140} dict="events.type" />

          <Input width="230px" name="message" type="text" label="Описание" placeholder="Введите название" />
          <ParkingFilter last />

          <ButtonsContainer>
            <ResetBtn onClick={reset} />
            <UseBtn />
          </ButtonsContainer>
        </form>
      </Container>
    );
  }
}

const initialValues = {
  timestamp_from: moment().startOf('day').format(),
  timestamp_to: moment().endOf('day').format(),
};

const mapStateToProps = (state) => {
  const filterName = 'eventsFilter';
  const filterValues = getFormValues(filterName)(state);
  //console.log('🚀 ~ mapStateToProps ~ filterValues', filterValues);
  return {
    filter: filterValues,
  };
};

const FilterForm = reduxForm({
  form: 'eventsFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);

export default connect(mapStateToProps, { getFormValues })(FilterForm);
