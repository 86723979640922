import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import { Container, Input, Select, DoubleField, DateTime, ButtonsContainer, DictFilter, ParkingFilter, DateSelect } from 'libs/form';
import { ResetBtn, UseBtn } from 'libs/ui';

class Filter extends Component {
  getOptionsList = (field) => {
    const data = this.props[field];

    if (Array.isArray(data)) {
      return data.map((item) => {
        return (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        );
      });
    }
  };

  render() {
    const { handleSubmit, reset, change } = this.props;

    return (
      <Container /*height='160px'*/>
        <form onSubmit={handleSubmit}>
          <DictFilter w={120} dict="tariff.status" />

          <DictFilter w={100} dict="tariff.type" />

          <Input width="222px" name="name" type="text" label="Название" placeholder="Предприниматели" />

          <Select width="155px" name="vehicle_category" type="select" label="Категория">
            <option value="">Все</option>
            {this.getOptionsList('categories')}
          </Select>

          <Select width="120px" name="vehicle_class" type="select" label="Класс ТС">
            <option value="">Все</option>
            {this.getOptionsList('vehicleClasses')}
          </Select>

          <DateSelect from_name="time_from" to_name="time_to" change={change}>
            <DoubleField>
              <DateTime width="160px" name="time_from" type="dateTime" label="Начало действия" />
              <DateTime width="160px" name="time_to" type="dateTime" />
            </DoubleField>
          </DateSelect>

          <DateSelect lastField from_name="created_at_from" to_name="created_at_to" change={change}>
            <DoubleField>
              <DateTime width="160px" name="created_at_from" type="dateTime" label="Дата создания" />
              <DateTime width="160px" name="created_at_to" type="dateTime" />
            </DoubleField>
          </DateSelect>

          <ParkingFilter />

          <ButtonsContainer>
            <ResetBtn onClick={reset} />
            <UseBtn />
          </ButtonsContainer>
        </form>
      </Container>
    );
  }
}

export default reduxForm({
  form: 'tariffFilter',
  destroyOnUnmount: false,
  initialValues: { status: 'active' },
})(Filter);
