const CLEAR_ENTRIES = '/pages/reports/plateRides/CLEAR_ENTRIES'
const ADD_RIDES = '/pages/reports/plateRides/ADD_RIDES'
const SET_RIDES_COUNT = '/pages/reports/plateRides/SET_RIDES_COUNT'
const SET_OFFSET = '/page/reports/plateRides/SET_OFFSET'
const SET_SORT_PARAMS = '/pages/reports/plateRides/SET_SORT_PARAMS'
const RESET_MODEL = '/pages/reports/plateRides/RESET_MODEL'

export default function reducer(state, action) {

  const defaultState = {
    rides: {},
    offset: 0,
    count: 0,
    sortParams: [{field_name: 'timestamp', direction: 'desc'}]
  }

  if(!state){
    state = defaultState
  }

  switch(action.type){
    case RESET_MODEL:
      return {
        ...defaultState
      }
    case CLEAR_ENTRIES:
      return {
        ...state,
        rides: {}
      }
    case ADD_RIDES:
      return {
        ...state,
        rides: {
          ...state.rides,
          ...action.data
        }
      }
    case SET_RIDES_COUNT:
      return {
        ...state,
        count: action.data
      }
    case SET_OFFSET:
      return {
        ...state,
        offset : action.data
      }
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data
      }
    default:
      return state
  }
}

export const reset = () => {
  return{
    type: RESET_MODEL
  }
}
export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES
  }
}
export const setCount = (value) => {
  return {
    type: SET_RIDES_COUNT,
    data: value
  }
}
export const addRides = (rides) => {
  return {
    type: ADD_RIDES,
    data: rides
  }
}
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value
  }
}
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value
  }
}

export const getRequestParams = (filter) => (dispatch, getState) => {
  let decorateParams = {}
  for ( let key in filter ) {
    if (filter[key]) {
      switch (key) {
        case 'mean_is_blocked':
        case 'result':
          if (filter[key] === 'true') {
            decorateParams[key] = true
          } else if (filter[key] === 'false') {
            decorateParams[key] = false
          }
          break
        case 'rack_ids':
        if (Array.isArray(filter[key])) {
            if (filter[key].length === 0) {
              decorateParams[key] = null
            } else {
              decorateParams[key] = filter[key]
            }
          }
          break
        case 'zones_from':
        case 'zones_to':
          if (filter[key].length === 0) {
            decorateParams[key] = null
          } else {
            decorateParams[key] = filter[key]
          }
          break
        default:
          decorateParams[key] = filter[key]
      }
    }
  }
  return decorateParams
}