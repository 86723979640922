import { data } from 'services';
import { getAmount } from 'libs/common';
import { getDuration, getDateTime, getShortRFID, getMeanStatus } from 'services/types';
import { ParkingCell, ZonesIcons, MeanStatus } from 'libs/ui';

export const getTableParams = () => {
  // get row class
  const getRowClass = (row) => {
    let out = '';
    row.is_suspicious && (out += ' is_suspicious');
    out += row.is_active ? ' active' : ' archive';
    return out;
  };

  const getFields = () => {
    const fields = [
      {
        name: 'stay_zones',
        title: 'Зона',
        width: 75,
        render: (val) => <ZonesIcons stayZones={val} />,
      },
      { name: 'is_active', title: 'Статус', width: 100, render: (val) => data.getDictValue('stays.status', val) },
      { name: 'client_name', title: 'Клиент' },
      { name: 'vehicle_plate', title: 'ГРЗ', width: 130, sortField: 'vehicle_plate', clip: true },
      { name: 'mean_type', title: 'Тип СО', width: 136, render: (val) => data.getDictValue('stays.mean_type', val), clip: true },

      {
        name: 'mean_number',
        title: 'СО',
        width: 150,
        render: (val, __other, row) => (row.mean_type === 'rfid' ? getShortRFID(val) : val),
        clip: true,
      },
      { name: 'entry_at', title: 'Начало', width: 210, sortField: 'entry_at', render: (val) => getDateTime(val) },
      { name: 'leave_at', title: 'Конец', width: 210, sortField: 'leave_at', render: (val) => getDateTime(val) },
      { name: 'duration', title: 'Длительность', width: 153, sortField: 'duration', render: (val) => getDuration(val), numeric: true, clip: true },
      { name: 'tariff_name', title: 'Тариф', width: 175 },
      { name: 'amount', title: 'Сумма', width: 100, sortField: 'amount', render: (val) => getAmount(val), numeric: true, clip: true },
      {
        name: 'additional_info',
        title: <img src={'/images/infoIcon.svg'} alt={'i'} title="Дополнительная информация" />,
        width: 100,
        align: 'right',
        render: (_val, _other, val) => <MeanStatus row={val} />,
      },
    ];

    if (window.config.central) {
      fields.unshift({ name: 'parking_id', title: 'Парковка', render: (val) => <ParkingCell parking_id={val} cmp={'span'} />, width: 140 });
    }

    return fields;
  };

  return { getFields, getRowClass, withComment: false };
};
