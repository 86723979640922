import { ParkingFilter2, required, MultiselectView, StyledField, DarkMultiselect } from 'libs/form';

import types from 'services/tariffTypes';
import { SectionTitle } from './tariff-form-main.styled';

export const TariffFormMain = (props) => {
  const { type, disabled, vehicleClasses, categories, initialValue } = props;

  //const withSubscriptionPeriod = initialValue.subscription_period !== undefined;
  // const withSecondEntry = initialValue.data.secondentry_amount !== undefined;
  // const withSecondEntry = initialValue.data.secondentry_period !== undefined;

  if (!['individual', 'basic1', 'basic2', 'basic3', 'basic4'].includes(type)) {
    return null;
  }
  const isIndividual = type === 'individual';

  /**
   * get type options
   * @returns
   */
  const getTypeOptions = () => {
    return types.map((type) => {
      return (
        <option key={type.id} value={type.id}>
          {type.name}
        </option>
      );
    });
  };

  return (
    <>
      <StyledField noApperance validate={required} type="select" label="Тип" name="type" disabled={true}>
        {getTypeOptions()}
      </StyledField>
      {isIndividual && (
        <>
          <ParkingFilter2 dark disabled={true} />
          <StyledField validate={required} placeholder="Осень 2017" type="text" label="Название *" name="name" disabled={disabled} />
          <DarkMultiselect
            validate={required}
            type="multiselect"
            label="Класс ТС *"
            name="vehicle_class"
            placeholder="Выберите класс ТС"
            disabled={disabled}
            data={vehicleClasses}
            additionalComponent={MultiselectView}
          />
          <DarkMultiselect
            type="multiselect"
            label="Категория"
            name="vehicle_category"
            placeholder="Выберите категорию ТС"
            disabled={disabled}
            data={categories}
            additionalComponent={MultiselectView}
          />
        </>
      )}
      <StyledField
        className="shortfield"
        validate={required}
        placeholder="08 авг 2017"
        type="dateTime"
        label="Начало *"
        name="time_from"
        disabled={disabled}
      />
      <StyledField type="text" placeholder="15 мин" label="Время на выезд" name="time_to_leave" mask={{ suffix: ' мин' }} disabled={disabled} />
      <SectionTitle>Абонемент</SectionTitle>
      <StyledField
        type="text"
        placeholder="Цена абонемента"
        label="Цена абонемента"
        name="subscription_amount"
        mask={{ suffix: ' руб' }}
        disabled={disabled}
      />
      {/* withSubscriptionPeriod && (*/}
      <StyledField
        type="text"
        placeholder="30 дней"
        label="Срок действия"
        name="subscription_period"
        mask={{ suffix: ' дней' }}
        disabled={disabled}
      />
      {/*)*/}
      {/* {!isIndividual && ( */}
      <>
        <SectionTitle>Повторный въезд</SectionTitle>
        <StyledField
          type="text"
          placeholder="15 мин"
          label="Повторный платный въезд"
          name="secondentry_interval"
          mask={{ suffix: ' мин' }}
          disabled={disabled}
        />
      </>
      {/* )} */}

      {/*withSecondEntry && */!isIndividual && (
        <>
          <StyledField
            type="text"
            placeholder="10 руб"
            label="Цена первого периода"
            name="secondentry_amount"
            mask={{ suffix: ' руб' }}
            disabled={disabled}
          />
          <StyledField
            type="text"
            placeholder="15 сек"
            label="Первый период"
            name="secondentry_period"
            mask={{ suffix: ' мин' }}
            disabled={disabled}
          />
          <StyledField
            type="text"
            placeholder="15 сек"
            label="Отмена платного периода"
            name="secondentry_duration"
            mask={{ suffix: ' мин' }}
            disabled={disabled}
          />
        </>
      )}
    </>
  );
};
