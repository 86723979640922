import PropTypes from 'prop-types';

import { Content, Inner } from './tariffication.styled';
import { PeriodsLoop } from './periods/periods-loop/periods-loop';
import { PeriodsSimple } from './periods/periods-simple/periods-simple';

export const Tariffication = (props) => {
  //console.log('🚀 ~ Tariffication ~ props:', props);
  const { selectedZone, show, ...rest } = props;
  const color = selectedZone?.color;
  const display = show ? 'block' : 'none';
  const isLoop = true;
  const PeriodsCmp = isLoop ? PeriodsLoop : PeriodsSimple;

  return (
    <Content color={color} display={display}>
      <Inner>
        <PeriodsCmp zone={selectedZone.zone_id} {...rest} />
      </Inner>
    </Content>
  );
};

Tariffication.propTypes = {
  selectedZone: PropTypes.any,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  newIndividual: PropTypes.bool,
};
