import styled, { css } from 'styled-components';

export const TotalCmp = styled.p`
  font-size: 13px;
  font-weight: bold;
  color: #1c1c1c;
  span {
    font-size: 15px;
  }
  ${(props) =>
    props.$white &&
    css`
      color: #fff;
    `}
`;
