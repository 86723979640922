import styled from 'styled-components';

import { ButtonContainer } from 'libs/ui';

export const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledInput = styled.input`
  height: 36px;
  padding: 5px;
`;

export const Form1 = styled.div`
  height: 500px;
  overflow-y: auto;
`;
export const Permissions = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 0;
  flex-wrap: wrap;
  > * {
    width: 100%;
    font-size: 16px;
  }
  input {
    cursor: pointer;
    margin: 0 0.5rem 0 0;
  }
  label {
    cursor: pointer;
    display: flex;
    font-weight: normal;
    align-items: center;
    margin: 0 0 5px;
    flex-grow: 0;
    input {
      width: 20px;
      height: 20px;
    }
  }
`;
