import PropTypes from 'prop-types';

import { Content } from './loader2.styled';

export const Loader2 = (props) => {
  return <Content {...props} />;
};

Loader2.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  $absolute: PropTypes.bool,
};

Loader2.defaultProps = {
  $absolute: false,
};
