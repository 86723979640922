import styled from 'styled-components';

import { FieldComponent } from 'libs/form';
import { Button } from 'libs/ui';

export const StyledField = styled(FieldComponent)`
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  label {
    opacity: 0.6;
    font-size: 13px;
    color: #3e3e3e;
    margin-bottom: 2px;
  }
  input {
    padding: 10px 12px;
    width: 320px;
    height: 40px;
    border: solid 1px;
    border-color: ${(props) => (props.error ? '#ff4747' : '#bebebe')};
    background-color: #fff;
    border-radius: 4px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ErrorMsg = styled.div`
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 13px;
  color: #3e3e3e;
  display: flex;
  align-items: flex-end;
  img {
    margin-right: 10px;
  }
`;

export const StyledSubmit = styled(Button)`
  height: 40px;
  background: linear-gradient(#3ea5ff, #009cec);
  box-shadow: 0 1px 1px 0 #1d4a7b;
  font-size: 16px;
  line-height: 0.75;
  width: 100%;
  opacity: ${(props) => (props.disabled ? '0.6' : '1')};
`;
