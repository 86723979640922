import styled from 'styled-components';

export const Content = styled.div`
  padding: 20px;
`;

export const Title = styled.div`
  margin: 0 0 1px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

export const TitleRight = styled.div`
  margin-left: 30px;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
`;

export const Desc = styled.div`
  color: #999;
  font-size: 0.815em;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px;
`;

export const Left = styled.div``;

export const Right = styled.div`
  text-align: right;
`;
