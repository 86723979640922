import { user } from 'services';
import { Cell, GridRow } from 'libs/ui';

const Row = (props) => {
  const editEntity = () => {
    props.setCategoryInfo({
      id: props.id,
      name: props.name,
      comment: props.comment,
    });
    props.updateFormDisplayHandler(true);
  };

  const editRight = user.haveRight('vehicle_category.update');

  return (
    <GridRow onClick={editRight ? editEntity : undefined}>
      <Cell>{props.id}</Cell>
      <Cell>{props.name}</Cell>
      <Cell>{props.comment}</Cell>
    </GridRow>
  );
};

export default Row;
