import React from 'react';
import { connect } from 'react-redux';

import ClientCardView from './ClientCardView';
import {
  getRequestParams,
  addRfids,
  clearEntries,
  setSortParams,
  setOffset,
  setCount,
  changeRfidStatus,
  getClientInfo,
  changeClientStatus,
  updateClientInfo,
  addClientContact,
  updateClientContact,
  removeClientInfo,
  setNewContactFaceData,
} from 'store/ducks/models/client';
import {
  setScroll,
  removeWarning,
  showNewContactForm,
  hideNewContactForm,
  setWarning,
  addClientMessageDisplayHandler,
  addContactMessageDisplayHandler,
} from 'store/ducks/interface/clientsCard';
import { addRfid, updateRfidByCard, setCardFormData, removeCardFormData, setFormData } from 'store/ducks/models/rfids';
import { getBalance } from 'store/ducks/models/processing';

const ClientContainer = (props) => {
  return (
    <ClientCardView
      //data
      clientInfo={props.clientInfo}
      newContactFace={props.newContactFace}
      entries={props.rfids}
      offset={props.offset}
      interface={props.interface}
      count={props.count}
      sortParams={props.sortParams}
      cardForm={props.cardForm}
      epmCategories={props.epmCategories}
      vehicleClasses={props.vehicleClasses}
      balanceInfo={props.balanceInfo}
      //methods
      getRequestParams={props.getRequestParams}
      addClientMessageDisplayHandler={props.addClientMessageDisplayHandler}
      addContactMessageDisplayHandler={props.addContactMessageDisplayHandler}
      addEntries={props.addRfids}
      clearEntries={props.clearEntries}
      setOffset={props.setOffset}
      setSortParams={props.setSortParams}
      setCount={props.setCount}
      setScroll={props.setScroll}
      changeStatus={props.changeRfidStatus}
      setFormInfo={props.setCardFormData}
      removeFormInfo={props.removeCardFormData}
      setWarning={props.setWarning}
      removeWarning={props.removeWarning}
      getClientInfo={props.getClientInfo}
      changeClientStatus={props.changeClientStatus}
      updateClientInfo={props.updateClientInfo}
      addClientContact={props.addClientContact}
      updateClientContact={props.updateClientContact}
      addRfid={props.addRfid}
      updateRfid={props.updateRfidByCard}
      setFormData={props.setFormData}
      removeClientInfo={props.removeClientInfo}
      setNewContactFaceData={props.setNewContactFaceData}
      showNewContactForm={props.showNewContactForm}
      hideNewContactForm={props.hideNewContactForm}
      getBalance={props.getBalance}
    />
  );
};

const mapStateToProps = (store) => {
  return {
    clientInfo: store.client.clientInfo,
    newContactFace: store.client.newContactFace,
    rfids: store.client.rfids,
    offset: store.client.offset,
    interface: store.clientsCard,
    count: store.client.count,
    sortParams: store.client.sortParams,
    cardForm: store.rfids.cardForm,
    epmCategories: store.epms.categories,
    vehicleClasses: store.epms.vehicleClasses,
    balanceInfo: store.processing.balanceInfo,
  };
};

export default connect(mapStateToProps, {
  setScroll,
  setWarning,
  removeWarning,
  getRequestParams,
  addRfids,
  clearEntries,
  setSortParams,
  setOffset,
  setCount,
  changeRfidStatus,
  setCardFormData,
  removeCardFormData,
  getClientInfo,
  changeClientStatus,
  updateClientInfo,
  addClientContact,
  updateClientContact,
  addRfid,
  updateRfidByCard,
  setFormData,
  removeClientInfo,
  setNewContactFaceData,
  showNewContactForm,
  hideNewContactForm,
  getBalance,
  addClientMessageDisplayHandler,
  addContactMessageDisplayHandler,
})(ClientContainer);
