import styled from 'styled-components';

export const NewTicketContent = styled.div`
  margin-left: 120px;
  form {
    margin-bottom: 20px;
  }
`;
export const NewTicketHeader = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;
export const NewTicketInfo = styled.label`
  font-size: 15px;
  display: block;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 10px;
  select {
    margin-left: 0.5rem;
  }
`;
export const AttentionIcon = styled.img`
  width: 50px;
  height: 46px;
  position: absolute;
  left: 30px;
  top: 60px;
`;
