import React from 'react';
import { useTranslation } from 'react-i18next';

import { Headline, PageWrapper } from 'modules/components/pageComponents';

const Operator = () => {
  const { t } = useTranslation();
    return (
      <PageWrapper>
        <Headline style={{ marginTop: '5rem' }}>
          <p>{t('messages.mnemo')}</p>
        </Headline>
      </PageWrapper>
    );
}

export default Operator;
