import styled from 'styled-components';

// import { getVehicleClasses } from 'services/types';
import { Controller } from 'libs/control';
import RfidGrid from './Grid';
import RfidFilter from './Filter';

const RfidBox = styled.div`
  position: relative;
`;

const RfidWrapper = (props) => {
  return (
    <RfidBox>
      <Controller theme="dark" type="rfid" filterName="clientRfidFilter" gridFilter={RfidFilter} grid={RfidGrid} {...props} />
    </RfidBox>
  );
};

export default RfidWrapper;
