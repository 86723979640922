import styled from 'styled-components';

export const TableEl = styled.table`
  color: #fff;
  text-align: left;
  width: 100%;
  font-size: 14px;
  line-height: normal;
  th {
    font-weight: normal;
    color: #3ea5ff;
  }
  th,
  td {
    padding: 5px 15px 5px 0;
    min-width: 70px;
  }
  th:last-child,
  td:last-child {
    text-align: right;
    padding-right: 0;
  }
  tr th,
  tr td {
    border-bottom: 1px solid #666;
  }
  .true{
    color: #00c82e;
  }
`;
