import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import moment from 'moment';

import { DateSelect } from 'libs/form';
import { Container, DoubleField, DateTime, ButtonsContainer } from 'libs/form';
import { Button, Reset, themes } from 'libs/ui';

class Filter extends Component {
  constructor(props) {
    super(props);

    // initial
    this.props.setInitial(initialValues);
  }

  render() {
    const { handleSubmit, reset, change } = this.props;

    return (
      <Container /*height='200px'*/>
        <form onSubmit={handleSubmit}>
          <DateSelect from_name="datetime_from" to_name="datetime_to" change={change}>
            <DoubleField>
              <DateTime width="160px" name="datetime_from" type="dateTime" label="Дата и время" />
              <DateTime width="160px" name="datetime_to" type="dateTime" />
            </DoubleField>
          </DateSelect>

          <ButtonsContainer>
            <ResetBtn onClick={reset} />
            <Button theme={themes.blue}>Скачать</Button>
          </ButtonsContainer>
        </form>
      </Container>
    );
  }
}

const initialValues = {
  datetime_from: moment().startOf('day').format(),
  datetime_to: moment().endOf('day').format(),
};

export default reduxForm({
  form: 'summaryFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
