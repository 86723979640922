import { user } from 'services';
import { Button, themes, TableHeader, GridUpdateMessage, GridBox, Grid, TableHead, TableHeadRow, TopRow } from 'libs/ui';
import Window from 'modules/components/windows/UpdateWindow';
import Row from './Row';
import Form from './Form';

const CategoriesGrid = (props) => {
  const getCategoriesList = () => {
    const categories = props.entries || props.categories;

    let list = [];
    if (categories) {
      for (let key in categories) {
        list.push(
          <Row
            key={categories[key].id}
            formIsShow={props.interface.showUpdateForm}
            setCategoryInfo={props.setCategoryInfo}
            updateFormDisplayHandler={props.updateFormDisplayHandler}
            {...categories[key]}
          />
        );
      }
    }
    return list;
  };

  return (
    <GridBox>
      {user.haveRight('vehicle_category.add') && (
        <TopRow>
          <p />
          <Button theme={themes.blue} onClick={() => props.addFormDisplayHandler(true)}>
            <i className="fa fa-plus" />
            Добавить категорию
          </Button>
        </TopRow>
      )}
      <Grid>
        <TableHead>
          <TableHeadRow>
            <TableHeader width="100px" sortField="id" numeric>
              Номер
            </TableHeader>
            <TableHeader width="280px" sortField="name">
              Название
            </TableHeader>
            <TableHeader>Комментарий</TableHeader>
          </TableHeadRow>
        </TableHead>
        <tbody>{getCategoriesList()}</tbody>
      </Grid>
      {props.interface.showAddForm ? (
        <Window
          type="add"
          header="Новая категория"
          Form={Form}
          displayHandler={props.addFormDisplayHandler}
          updateMessageDisplayHandler={props.updateMessageDisplayHandler}
          addEntity={props.addCategory}
          getEntries={props.getEntries}
        />
      ) : null}
      {props.interface.showUpdateForm ? (
        <Window
          type="update"
          header={props.categoryInfo.name}
          Form={Form}
          displayHandler={props.updateFormDisplayHandler}
          updateMessageDisplayHandler={props.updateMessageDisplayHandler}
          updateEntity={props.updateCategory}
          getEntries={props.getEntries}
          id={props.categoryInfo.id}
          initialValues={{
            name: props.categoryInfo.name,
            comment: props.categoryInfo.comment,
          }}
        />
      ) : null}
      {props.interface.showUpdateMessage ? <GridUpdateMessage>Изменение сохранено</GridUpdateMessage> : null}
    </GridBox>
  );
};

export default CategoriesGrid;
