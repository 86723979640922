import styled from 'styled-components';

export const Line2 = styled.div`
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  .l {
    display: block;
    width: 220px;
    margin-right: 15px;
    flex-shrink: 0;    
    &.sm{
      width: 150px;
    }
  }
`;
export const PromiseContent = styled.div``;

export const NewTicketHeader = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
`;
