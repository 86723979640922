import { Button, ButtonContainer } from 'libs/ui';
import { DisableWarning, WarningHeader, WarningContent, WarningText, Back } from './index.styled';

const Warning = (props) => {
  const { width, type, header, mainText, cancelButton, acceptButton, ...rest } = props;

  const close = () => {
    if (cancelButton) {
      cancelButton.func();
    }
  };

  return (
    <Back onClick={close}>
      <DisableWarning width={width} {...rest}>
        {type && type === 'error' ? (
          <img width={50} height={50} src="/images/error-icon.png" alt="" />
        ) : (
          <img width={50} height={47} src="/images/warning.png" alt="" />
        )}
        <WarningContent>
          <WarningHeader>{header}</WarningHeader>
          {typeof mainText === 'object' && mainText.__html ? (
            <WarningText dangerouslySetInnerHTML={mainText} />
          ) : (
            <WarningText>{mainText}</WarningText>
          )}
          <ButtonContainer space="20px" justifyContent="flex-start">
            {cancelButton ? <Button onClick={cancelButton.func}>{cancelButton.text}</Button> : null}
            {acceptButton ? (
              <Button onClick={acceptButton.func} backgroundColor={acceptButton.backgroundColor} theme={acceptButton.theme}>
                {acceptButton.img ? <img width={10} src={acceptButton.img} alt="" /> : null}
                {acceptButton.text}
              </Button>
            ) : null}
          </ButtonContainer>
        </WarningContent>
      </DisableWarning>
    </Back>
  );
};

export default Warning;
