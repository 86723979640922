import zonesService from 'services/zones';
import { Content, Zone } from './zones-row.styled';

export const ZonesRow = (props) => {
  const colors = zonesService.getZonesColors();
  const zones = zonesService.getZonesFromRule(props.rules);

  const zonesList = zones.map((item) => {
    const id = item.zone_id;
    const title = id;
    const color = colors[id] ? colors[id] : '#4d8b8b';
    return (
      <Zone key={id} color={color}>
        {title}
      </Zone>
    );
  });

  return <Content>{zonesList}</Content>;
};
