import React, { Component } from 'react';

import { TableHeader } from 'libs/ui'
import Row from './Row';
import {
  GridBox,
  Grid,
  TableHead,
  TableHeadRow,
  TopRow,
} from 'libs/ui';
import { Total } from 'libs/ui';

export default class PromisesGrid extends Component {
  getList = () => {
    const items = this.props.entries;
    //console.log('🚀 ~ items', items);
    let itemsList = [];
    if (items) {
      for (let key in items) {
        itemsList.push(<Row key={key} {...items[key]} />);
      }
    }
    return itemsList;
  };

  render() {
    return (
      <GridBox>
        <TopRow>
          <Total cnt={this.props.count} />
          {this.props.getXlsLink()}
        </TopRow>
        <Grid>
          <TableHead>
            <TableHeadRow>
              <TableHeader width="270px" sortField="timestamp">
                Ф.И.О.
              </TableHeader>
              <TableHeader width="220px">№ договора паркирования</TableHeader>
              <TableHeader width="150px">Тип</TableHeader>
              <TableHeader width="170px">№</TableHeader>
              <TableHeader>Долг</TableHeader>
              <TableHeader width="140px">Статус оплаты</TableHeader>
            </TableHeadRow>
          </TableHead>
          <tbody>{this.getList()}</tbody>
        </Grid>
      </GridBox>
    );
  }
}
