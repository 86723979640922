import styled from 'styled-components';

import { Button } from 'libs/ui';

export const Pictures = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const ClosePhotoButton = styled(Button)`
  position: absolute;
  bottom: -15px;
  left: 46%;
  font-size: 16px;
`;
