import React from 'react';

export const Textarea = (props) => {
  const { input, placeholder, className, label, disabled } = props;

  return (
    <div className={className}>
      <label>{label}</label>
      <textarea placeholder={placeholder} disabled={disabled} {...input} />
    </div>
  );
};
