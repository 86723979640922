import styled from 'styled-components';
import { Popup, Checkbox } from '../styled';

export const Wrapper = styled.div``;

export const StyledPopup = styled(Popup)`
  width: fit-content;
  padding: 20px 10px;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 0;
  &.checkbox {
    input {
      margin-right: 8px;
    }
    label {
      margin: 0;
      font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
    }
  }
`;
export const RacksBox = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-right: 17px;
  }
`;
export const NodeBox = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
export const InputBox = styled.div`
  position: relative;
  height: 100%;
  input {
    background-color: #fff;
    text-overflow: ellipsis;
  }
`;
export const Select = styled.div`
  height: 100%;
  position: relative;
`;
