import styled from 'styled-components';

import { GeneralInfo } from 'modules/components/card/styledComponents';

export const HeaderBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${(props) => props.align};
`;
export const Name = styled.p`
  font-size: 26px;
  color: #fff;
`;
export const TariffGeneralInfo = styled(GeneralInfo)`
  height: 140px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;
export const TariffManageWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
