import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
  display: ${(props) => props.display};
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${(props) => props.color};
  min-height: 400px; //500
`;

export const Inner = styled.div`
  padding: 25px 0 0 18px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
