import styled from 'styled-components';

import { Button } from 'libs/ui';

export const AddButton = styled(Button)`
  margin: 20px 12px;
`;

export const InputList = styled.ul`
  li {
    list-style-type: none;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  div {
    &:nth-child(1) {
      border-right: 0;
      border-radius: 4px 0 0 4px;
    }
    &:nth-child(2) {
      border-radius: 0 4px 4px 0;
    }
  }
  img {
    cursor: pointer;
    margin-left: 12px;
  }
`;

export const Sums = styled.div`
  width: 200px;
  margin: 6px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.5;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
`;

export const ZoneInputBox = styled.div`
  width: ${(props) => (props.wide ? '130px' : '100px')};
  height: 40px;
  border-radius: 4px;
  border: solid 1px;
  border-color: #d5d5d5;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  input {
    width: 100%;//${(props) => (props.wide ? '90%' : '100%')};
    height: 100%;
    border: none;
    background-color: inherit;
    outline: none;
    color: ${(props) => (props.error ? '#d33d34' : '#fff')};
    font-size: 15px;
    text-align: end;
    margin-right: 10px;
  }
  span {
    opacity: 0.7;
    color: ${(props) => (props.error ? '#d33d34' : '#fff')};
    font-size: 15px;
  }
`;
