import { Button } from 'libs/ui';
import { Back, Content, Wrapper, Footer, Header } from './popup.styled';

export const Popup = (props) => {
  const { children, title, close, theme } = props;

  return (
    <Back>
      <Wrapper theme={theme}>
        <Header>{title}</Header>
        <Content>{children}</Content>
        <Footer>
          <Button onClick={close} type="button">
            Закрыть
          </Button>
        </Footer>
      </Wrapper>
    </Back>
  );
};
