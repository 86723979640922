import styled from 'styled-components';

export const Content = styled.div``;

export const RackName = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  opacity: 0.5;
  margin-bottom: 5px;
`;

export const CarType = styled.div`
  font-size: 13px;
  font-style: italic;
  p {
    opacity: 0.8;
    color: #000000;
    width: 100px;
  }
  span {
    display: block;
    opacity: 0.36;
    line-height: 1.08;
  }
`;
