import styled from 'styled-components';

import { Container } from 'libs/ui';

export const Wrapper = styled(Container)`
  position: fixed;
  bottom: 0;
  padding: 60px 20px 20px;
  min-height: 317px;
  max-height: 317px;
  overflow-y: auto;
  background-color: #f4f3f4;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(85, 85, 85, 0.2);
  width: auto;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 100;
`;
export const Description = styled.div`
  background-color: #919192;
  border-radius: 4px;
  height: 40px;
  font-size: 15px;
  color: #ffffff;
  padding: 12px;
  position: fixed;
  margin: -48px 0 0;
  width: 97%;
`;
export const Scroller = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 250px;
  max-height: 250px;
`;

export const MainInfo = styled.div`
  width: 620px;
  height: 80px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
export const Label = styled.p`
  color: #000;
  opacity: 0.6;
  font-size: 13px;
`;
export const Property = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  ${Label} {
    width: 90px;
  }
  span {
    font-size: 15px;
  }
`;
export const AdditionalInfo = styled.div`
  margin-top: 15px;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: #333 url('/images/cross_icon_white.png') no-repeat 50% 50%;
  border-radius: 3px;
  position: absolute;
  right: 5px;
  top: 5px;
`;
