import React from 'react';
import { change } from 'redux-form';
import OutsideClickHandler from 'react-outside-click-handler';

import { InputBox, NodeBox, RacksBox, Select, StyledCheckbox, StyledPopup, Wrapper } from './RackSelect.styled';

export class RackSelect extends React.Component {
  constructor() {
    super();

    this.state = { popupIsOpen: false };
    this.wrapper = React.createRef();
  }

  showPopup = () => {
    const { disabled } = this.props;
    if (disabled) return;

    if (!this.state.popupIsOpen) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    this.setState({ popupIsOpen: !this.state.popupIsOpen });
  };

  handleOutsideClick = (e) => {
    if (this.popup) {
      if (this.popup.contains(e.target)) return;
      this.showPopup();
    }
  };

  setValue = (e, fieldValue, type) => {
    const { data } = this.props;
    const { name, value } = this.props.input;
    const { dispatch, form } = this.props.meta;

    let newValue = [...value];

    if (e.target.checked) {
      if (type === 'rack') {
        newValue.push(fieldValue);
      } else if (type === 'node') {
        data[fieldValue].racks.forEach((rack) => {
          if (!newValue.includes(rack)) {
            newValue.push(rack);
          }
        });
      }
    } else {
      if (type === 'rack') {
        newValue.splice(newValue.indexOf(fieldValue), 1);
      } else if (type === 'node') {
        data[fieldValue].racks.forEach((rack) => {
          if (newValue.includes(rack)) {
            newValue.splice(newValue.indexOf(rack), 1);
          }
        });
      }
    }

    // need cuz empty string not storing in form reducer
    // unlike empty array
    if (newValue.length === 0) newValue = '';

    dispatch(change(form, name, newValue));
  };

  getInputs = () => {
    const {
      data,
      input: { value },
    } = this.props;

    let nodes = [];

    const keys = Object.keys(data);
    keys.sort((a, b) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
    //console.log('🚀 ~ data  ', keys);
    keys.forEach((node) => {
      const item = data[node];
      const id = item.id;
      //console.log('🚀 ~ data id ', node, item);
      const racks = item.racks.map((rack) => {
        return (
          <StyledCheckbox key={rack} className="checkbox">
            <input
              onChange={(e) => this.setValue(e, rack, 'rack')}
              type="checkbox"
              name={rack}
              checked={Array.isArray(value) ? value.includes(rack) : false}
            />
            <label>С{rack}</label>
          </StyledCheckbox>
        );
      });
      nodes.push(
        <RacksBox key={`node-${id}`}>
          <StyledCheckbox bold key={id} className="checkbox">
            <input onChange={(e) => this.setValue(e, node, 'node')} type="checkbox" name={id} checked={this.nodeIsChecked(value, node)} />
            <label>У{id}</label>
          </StyledCheckbox>
          {racks}
        </RacksBox>
      );
    });

    return (
      <NodeBox>
        <StyledCheckbox key={'all'} className="checkbox">
          <input onChange={this.selectAll} checked={this.isCheckedAll()} type="checkbox" name="all" />
          <label>Все стойки</label>
        </StyledCheckbox>
        {nodes}
      </NodeBox>
    );
  };

  nodeIsChecked = (values, node) => {
    const { data } = this.props;
    return data[node].racks.every((rack) => {
      return values.includes(rack);
    });
  };

  getDisplayedValue = () => {
    const {
      data,
      input: { value },
    } = this.props;

    if (Array.isArray(value) && value.length > 0) {
      // array for render
      let displayedValue = [];
      // subsidiary array. needed to storage values by node
      let subsidiaryValues = [];

      Object.values(data).forEach((node) => {
        subsidiaryValues = [];
        for (let i = 0; i < node.racks.length; i++) {
          for (let j = 0; j < value.length; j++) {
            if (node.racks[i] === value[j]) {
              subsidiaryValues.push(value[j]);
            }
          }
          if (subsidiaryValues.length === node.racks.length) {
            displayedValue.push(this.getDecorateName('node', node.id));
            subsidiaryValues = [];
          }
        }
        subsidiaryValues.forEach((value) => {
          displayedValue.push(this.getDecorateName('rack', value));
        });
      });
      return displayedValue;
    }
    return '';
  };

  getDecorateName = (type, id) => {
    const prefix = type === 'node' ? 'У' : 'C';

    return `${prefix}${id}`;
  };

  isCheckedAll = () => {
    const {
      input: { value },
    } = this.props;

    let allRacks = this.getAllRacksCount();

    return allRacks.length === value.length;
  };

  selectAll = () => {
    const { dispatch, form } = this.props.meta;
    const {
      input: { value, name },
    } = this.props;

    let newValue = '';

    let allRacks = this.getAllRacksCount();

    if (value.length !== allRacks.length) {
      newValue = allRacks;
    }

    dispatch(change(form, name, newValue));
  };

  getAllRacksCount = () => {
    const { data } = this.props;
    let allRacks = [];

    Object.values(data).forEach((node) => {
      allRacks = allRacks.concat(node.racks);
    });

    return allRacks;
  };

  render() {
    return (
      <Wrapper className={this.props.className} ref={this.wrapper}>
        <label>{this.props.label}</label>
        <Select
          innerRef={(popup) => {
            this.popup = popup;
          }}
        >
          <InputBox onClick={this.showPopup}>
            <input placeholder={this.props.placeholder} value={this.getDisplayedValue()} disabled />
          </InputBox>
          <OutsideClickHandler
            onOutsideClick={(event) => {
              if (this.wrapper && !this.wrapper.current.contains(event.target)) {
                if (this.state.popupIsOpen) {
                  this.showPopup();
                }
              }
            }}
          >
            <StyledPopup className="popup" $popupIsOpen={this.state.popupIsOpen}>
              {this.getInputs()}
            </StyledPopup>
          </OutsideClickHandler>
        </Select>
      </Wrapper>
    );
  }
}
