import styled from 'styled-components';

export const Button = styled.button`
  background: none;
  border: 0;
  padding: 0;
  display: block;
  line-height: 1;
  cursor: pointer;
  margin: 0 0 0 10px;
  transition: opacity 0.2s ease-in;
  &:hover {
    opacity: 0.6;
  }
  img {
    max-height: 28px;
  }
`;

export const Content = styled.div`
  position: relative;
`;
