import React from 'react';

import { user } from 'services';
import { config } from 'config';
import { GridContainer } from 'libs/ui';
import { Link, Item, Content } from './index.styled';

export const AdditionalReports = () => {
  const token = user.getToken();
  const links = config.network.additional_reports_url;

  const getItems = () => {
    const data = typeof links === 'string' ? [{ title: 'Отчеты', link: links + '?token=' + token }] : links;
    return data.map((el, idx) => {
      return (
        <Item key={idx}>
          <Link target="_blank" rel="noopener noreferrer" href={el.link}>
            {el.title}
          </Link>
        </Item>
      );
    });
  };

  const items = getItems();
  return (
    <GridContainer>
      <Content>{items}</Content>
    </GridContainer>
  );
};

export default AdditionalReports;
