import styled from 'styled-components';

export const ZoneTitle = styled.div`
  margin: 0 40px 0 10px;
`;

export const ZoneInput = styled.div`
  display: flex;
  align-items: center;
  label {
    margin-right: 10px;
    font-size: 14px;
    color: #eee;
    white-space: nowrap;
  }
  input {
    max-width: 100%;
    font-size: 15px;
    color: #000;
    width: 150px;
    padding: 11px 10px 9px;
    border-radius: 4px;
    border: solid 1px #bebebe;
    height: 30px;
  }
`;

export const ZoneIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  line-height: 32px;
  border: 2px solid #fff;
  font-size: 13px;
  color: white;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;

export const Zone = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`;
