import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'libs/ui';

export const BtnImages = (props) => {
  // render
  return (
    <Button fontSize="13px" onClick={props.action}>
      <i className="fa fa-image"></i>
      Фото
    </Button>
  );
};

BtnImages.propTypes = {
  action: PropTypes.any.isRequired,
};
