import React, { Component } from 'react';
import { connect } from 'react-redux';

import { GridContainer } from 'libs/ui';
import BenefitsFilter from './Filter';
import BenefitsGrid from './Grid';
import { Controller } from 'libs/control';

import {
  getRequestParams,
  addBenefits,
  clearEntries,
  setSortParams,
  setOffset,
  setCount,
  reset,
} from 'store/ducks/models/promises';
import { setScroll } from 'store/ducks/interface/promisesGrid';

class Promises extends Component {
  render() {

    return (
      <GridContainer>
        <Controller
          type="promise_pay"
          gridFilter={BenefitsFilter}
          grid={BenefitsGrid}
          entries={this.props.promises}
          offset={this.props.offset}
          interface={this.props.interface}
          count={this.props.count}
          sortParams={this.props.sortParams}
          //methods
          getRequestParams={this.props.getRequestParams}
          addEntries={this.props.addBenefits}
          clearEntries={this.props.clearEntries}
          setOffset={this.props.setOffset}
          setSortParams={this.props.setSortParams}
          setCount={this.props.setCount}
          setScroll={this.props.setScroll}
        />
      </GridContainer>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    promises: store.promises.promises,
    offset: store.promises.offset,
    count: store.promises.count,
    sortParams: store.promises.sortParams,
    interface: store.promisesGrid,
  };
};

export default connect(mapStateToProps, {
  getRequestParams,
  addBenefits,
  clearEntries,
  setScroll,
  setSortParams,
  setOffset,
  setCount,
  reset,
})(Promises);
