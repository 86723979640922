import styled from 'styled-components';

import { UpdateMessage } from 'libs/ui';

export const LoginInfo = styled.div`
  position: relative;
  width: 590px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #595d64;
  b {
    font-size: 13px;
    color: #fff;
    opacity: 0.6;
    min-width: 200px;
    display: block;
  }
  i {
    display: inline-block;
    width: 430px;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: #fff;
    opacity: 0.6;
    font-style: normal;
    line-height: 1.15;
  }
`;

export const UpdateMessageBox = styled(UpdateMessage)`
  bottom: -40px;
  left: 100px;
`;
