import styled from 'styled-components';

import { Button } from 'libs/ui';

export const StyledButton = styled(Button)`
  position: absolute;
  right: 20px;
  bottom: 20px;
  &.saving{
    &:after{
      content: '';
      border-radius: 40px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, .9) url('/images/loader.gif') no-repeat 50% 50%;
      background-size: auto 70% ;
    }
  }
`;
export const Zone = styled.div`
  background-color: ${(props) => props.color};
  //height: 650px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;
export const CheckboxWrapper = styled.div`
  margin-bottom: 20px;
`;
export const Checkbox = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 10px;
  &.checkbox {
    input {
      margin-right: 5px;
      appearance: none;
      outline: none;
      cursor: pointer;
      width: 14px;
      height: 14px;
      border-radius: 4px;
      border: 1px solid #fff;
      opacity: 1;
      &:checked {
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #fff;
        border: none;
        &:before {
          content: '';
          display: block;
          width: 5px;
          height: 10px;
          border: 0 solid ${(props) => props.color};
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          margin: -1px 3px;
          pointer-events: none;
        }
      }
    }
    label {
      font-size: 15px;
      color: #fff;
      opacity: 1;
    }
  }
`;
