import styled from 'styled-components';

export const Content = styled.div`
  padding: 15px 15px 5px;
`;


export const Item = styled.div`
  margin: 0 0 30px;
`;

export const Link = styled.a`
  position: relative;
  top: 10px;
  left: 10px;
  color: #3ea5ff;
  font-weight: bold;
  transition: .2s ease-in color;
  &:hover{
    color: #000;
  }
`;
