const SET_SCROLL_POSITION = '/page/payments/grid/SET_SCROLL_POSITION';
const SET_WARNING = '/page/payments/grid/SET_WARNING';
const REMOVE_WARNING = '/page/payments/grid/REMOVE_WARNING';

const UPDATE_MESSAGE_DISPLAY_HANDLER = '/page/epm/grid/UPDATE_MESSAGE_DISPLAY_HANDLER';
const ADD_FORM_DISPLAY_HANDLER = '/page/epm/grid/ADD_FORM_DISPLAY_HANDLER';
const UPDATE_FORM_DISPLAY_HANDLER = '/page/epm/grid/UPDATE_FORM_DISPLAY_HANDLER';

export default function reducer(state, action) {
  const defaultState = {
    scrollPosition: 0,
    warning: null,
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case SET_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.data,
      };
    case SET_WARNING:
      return {
        ...state,
        warning: {
          epm: action.epm,
          reqInfo: action.data,
        },
      };
    case REMOVE_WARNING:
      return {
        ...state,
        warning: null,
      };
    case UPDATE_MESSAGE_DISPLAY_HANDLER:
      return {
        ...state,
        showUpdateMessage: action.data,
      };
    case ADD_FORM_DISPLAY_HANDLER:
      return {
        ...state,
        showAddForm: action.data,
      };
    case UPDATE_FORM_DISPLAY_HANDLER:
      return {
        ...state,
        showUpdateForm: action.data,
      };
    default:
      return state;
  }
}

export const setScroll = (value) => {
  return {
    type: SET_SCROLL_POSITION,
    data: value,
  };
};
export const setWarning = (epm, value) => {
  return {
    type: SET_WARNING,
    epm: epm,
    data: value,
  };
};
export const removeWarning = () => {
  return {
    type: REMOVE_WARNING,
  };
};

export const updateMessageDisplayHandler = (value) => {
  return {
    type: UPDATE_MESSAGE_DISPLAY_HANDLER,
    data: value,
  };
};
export const addFormDisplayHandler = (value) => {
  return {
    type: ADD_FORM_DISPLAY_HANDLER,
    data: value,
  };
};
export const updateFormDisplayHandler = (value) => {
  return {
    type: UPDATE_FORM_DISPLAY_HANDLER,
    data: value,
  };
};
