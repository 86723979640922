import styled from 'styled-components';

export const Back = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  margin: 0 !important;
  background-color: rgba(0, 0, 0, 0.55);
`;

export const Content = styled.div`
  z-index: 1000;
  position: fixed;
  border-radius: 4px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.5);
  top: ${(props) => (props.top ? props.top : '30')}%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => (props.width ? props.width : '540px')};
  padding: 40px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > img {
    margin-right: 30px;
  }
`;
export const WarningHeader = styled.p`
  font-size: 20px;
  color: #262b31;
  font-weight: bold;
  margin-bottom: 18px;
`;
export const WarningContent = styled.div`
  height: 100%;
  //width: 390px;
  width: 100%;
  white-space: normal;
  display: flex;
  flex-direction: column;
`;
export const WarningText = styled.p`
  font-size: 15px;
  color: #000;
  margin-bottom: 25px;
  line-height: 1.2;
`;
export const WarningButtons = styled.div`
  display: flex;
`;
