import styled from 'styled-components';

export const Container = styled.div`
  &:before {
    display: none;
  }
`;
export const InputBox = styled.div`
  height: 40px;
  background-color: #fff;
  ul {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 100%;
    li {
      list-style: none;
      position: relative;
      flex-shrink: 0;
      &:first-child {
        z-index: 8;
        left: 0px;
      }
      &:nth-child(2) {
        z-index: 7;
        left: -20px;
      }
      &:nth-child(3) {
        z-index: 6;
        left: -40px;
      }
      &:nth-child(4) {
        z-index: 5;
        left: -60px;
      }
      &:nth-child(5) {
        z-index: 4;
        left: -80px;
      }
      &:nth-child(6) {
        z-index: 3;
        left: -100px;
      }
      &:nth-child(7) {
        z-index: 2;
        left: -120px;
      }
      &:nth-child(8) {
        z-index: 1;
        left: -140px;
      }
    }
  }
`;
