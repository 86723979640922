import { withRouter } from 'react-router-dom';

import { user, data } from 'services';
import { routes } from 'router';
import { Cell, GridRow } from 'libs/ui';
import { getParking, getDateTime } from 'services/types';

const Tariff = (props) => {
  const { status, type, categories, vehicleClasses, vehicle_category, vehicle_class } = props;
  const rightView = user.haveRight('tariff.view');
  const parking = getParking(props.parking_id);

  const showTariffCard = () => {
    props.history.push(`${routes.tariff}?id=${props.id}`);
  };

  const getList = (fullList, valuesList) => {
    if (Array.isArray(fullList) && Array.isArray(valuesList)) {
      let displayedSting = '';

      valuesList.forEach((value) => {
        const el = fullList.find((el) => {
          return Number(el.id) === Number(value);
        });

        if (displayedSting) {
          displayedSting += ', ';
        }
        displayedSting += el ? el.name : '-';
      });

      return displayedSting;
    }
  };

  return (
    <GridRow status={props.status} onClick={rightView ? showTariffCard : null}>
      {window.config.central && <Cell>{parking}</Cell>}
      <Cell>{props.name}</Cell>
      <Cell>{data.getDictValue('tariff.type', type)}</Cell>
      <Cell>{data.getDictValue('tariff.status', status)}</Cell>
      <Cell>{getDateTime(props.created_at, true)}</Cell>
      <Cell>{getDateTime(props.time_from, true)}</Cell>
      <Cell>{getList(categories, vehicle_category)}</Cell>
      <Cell>{getList(vehicleClasses, vehicle_class)}</Cell>
    </GridRow>
  );
};

export default withRouter(Tariff);
