import React, { Component } from 'react';
import styled from 'styled-components';
import { reduxForm } from 'redux-form';

import { user } from 'services';
import { DictFilter, ParkingFilter, Container, Input, Select, ButtonsContainer } from 'libs/form';
import { ResetBtn, UseBtn } from 'libs/ui';

const StyledContainer = styled(Container)`
  background-color: #4f5761;
  box-shadow: none;
`;
const StyledInput = styled(Input)`
  input {
    background-color: #4f5761;
    color: #fff;
  }
  label {
    color: #b7babf;
  }
`;

class Filter extends Component {
  constructor(props) {
    super(props);

    // initial
    this.props.setInitial(initialValues);
  }

  componentWillUnmount() {
    this.props.reset();
    this.props.setInitial(initialValues);
  }

  getCats = () => {
    const maps = user.getMaps();
    const obj = maps.vehicle_category;
    return obj.map((el, idx) => {
      return (
        <option key={idx} value={el.id}>
          {el.name}
        </option>
      );
    });
  };

  render() {
    const { handleSubmit, reset } = this.props;
    const cats = this.getCats();
    return (
      <StyledContainer /*height='160px'*/>
        <form onSubmit={handleSubmit}>
          <DictFilter w={150} dict="rfid_status" theme="black" exclude={['blocked_by_client']} />

          <StyledInput width="170px" name="rfid" type="text" label="RFID" placeholder="3786 4678 6467 5656" />

          <StyledInput width="180px" name="owner_name" type="text" label="Водитель" placeholder="Иванов И.И." />

          <Select width="160px" name="vehicle_category" type="select" label="Категория СО" black>
            <option value="">Все</option>
            {cats}
          </Select>

          <StyledInput width="135px" name="vehicle_plate" type="text" label="ГРЗ" placeholder="А 460 MP 78 rus" />

          <StyledInput width="160px" name="tariff" type="text" label="Тариф" placeholder="Базовый" />

          <StyledInput width="200px" name="comment" type="text" label="Комментарий" placeholder="Текст комментария" />

          <ParkingFilter black ml0 />

          <ButtonsContainer>
            <ResetBtn onClick={reset} />
            <UseBtn />
          </ButtonsContainer>
        </form>
      </StyledContainer>
    );
  }
}

const initialValues = {};

export default reduxForm({
  form: 'clientRfidFilter',
  destroyOnUnmount: false,
  //forceUnregisterOnUnmount: true,
  //enableReinitialize: true,
  //keepDirtyOnReinitialize: true,
  initialValues: initialValues,
})(Filter);
