import { useLocation } from 'react-router-dom';

import { GridContainer } from 'libs/ui';
import { Content } from './soupload.styled';
import { Menu } from './menu';
import { Uploader } from './uploader';

export const SOUpload = () => {
  const location = useLocation();
  const isClients = location.pathname.indexOf('/clients') !== -1;

  return (
    <GridContainer>
      <Content>
        <Menu />
      </Content>

      <Uploader isClients={isClients} />
    </GridContainer>
  );
};

export default SOUpload;
