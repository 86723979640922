export const gridsConfig = {
  clients: {
    filters: [
      { name: 'id', title: 'ID', active: true },
      { name: 'clients.status', title: 'Статус', active: true },
      { name: 'clients.type', title: 'Тип', active: true },
      { name: 'name', title: 'Наименование', active: true },
      { name: 'contact_name', title: 'Контактное лицо', active: true },
      { name: 'tel_num', title: 'Телефон', active: true },
    ],
  },
  /*
  stays: {
    filters: [
      { name: 'datetime', title: 'Период парковки', active: true },
      { name: 'entry_at', title: 'Начало', active: true },
      { name: 'leave_at', title: 'Конец', active: true },
      { name: 'payment', title: 'Оплата', active: true },
      { name: 'client_name', title: 'Имя клиента', active: true },
      { name: 'zones', title: 'Зона', active: true },
      { name: 'stays.status', title: 'Статус', active: true },
      { name: 'tariff', title: 'Тариф', active: true },
      { name: 'duration', title: 'Длительность', active: true },
      { name: 'vehicle_plate', title: 'ГРЗ', active: true },
      { name: 'stays.mean_type', title: 'СО', active: true },
      { name: 'amount', title: 'Сумма', active: true },
      { name: 'is_suspicious', title: 'Подозрительные парковки', active: true },
      { name: 'dispatcher_pass', title: 'Ручное открытие', active: true },
      { name: 'is_secondentry', title: 'Повторный проезд', active: true },
    ],
  },
  */
};

export default gridsConfig;
