const SET_PARAMETERS = '/widgets/popupWidget/SET_PARAMETERS'
const RESET_PARAMETERS = '/widgets/popupWidget/RESET_PARAMETERS'

export default function reducer(state, action) {

    const defaultState = {
        parameters: null
    }

    if (!state) { state = defaultState }

    switch (action.type) {
        case SET_PARAMETERS:
            return {
                ...state,
                parameters: action.data
            }
        case RESET_PARAMETERS:
            return {
                ...state,
                parameters: null
            }
        default: return state
    }
}

export const setParameters = (parameters) => {
    return {
        type: SET_PARAMETERS,
        data: parameters
    }
}
export const resetParameters = () => {
    return {
        type: RESET_PARAMETERS
    }
}