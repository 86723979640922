import PropTypes from 'prop-types';

import { TableRowCmp } from './table-row.styled';
import { OptionsCell, Cell } from 'libs/ui';

export const TableRow = (props) => {
  const { fields, row, actions, onRowClick, getRowClass, theme, updateEntry } = props;

  /**
   * row click event
   * @param event
   */
  const handleClick = (event) => {
    event.preventDefault();

    // console.log('🚀 ~ handleClick ~ event:', event.target);

    onRowClick && event.target.nodeName.toLowerCase() === 'td' && onRowClick(row);
    // console.log('🚀 ~ handleClick ~ event.target.nodeName:', event.target.nodeName);
  };

  /**
   * render cell value
   * @param val
   * @param field
   * @returns
   */
  const renderCell = (val, field, other, row) => {
    if (field.render) {
      return field.render(val, other, row);
    }
    return val;
  };

  // row optional params
  const params = onRowClick ? { /*hover: true,*/ onClick: (event) => handleClick(event) } : {};
  if (getRowClass) {
    const rowClass = getRowClass(row);
    //console.log('🚀 ~ TableRow ~ color:', row, color);
    if (rowClass) {
      params.className = rowClass;
    }
  }

  return (
    <TableRowCmp
      {...params}
      //role="checkbox"
      //aria-checked={isItemSelected}
      tabIndex={-1}
      theme={theme}
      //selected={isItemSelected}
    >
      {fields.map((field, idx) => {
        if (field.renderCell) {
          return field.render(row[field.name], { key: idx, align: field.align ? field.align : field.numeric ? 'right' : 'left', theme });
        }
        return (
          <Cell
            key={idx}
            number={field.numeric}
            align={field.align ? field.align : field.numeric ? 'right' : 'left'}
            theme={theme}
            clip={field.clip}
            width={field.width}
          >
            {renderCell(row[field.name], field, null, row)}
          </Cell>
        );
      })}

      {actions && (
        <OptionsCell updateEntry={updateEntry} row={row} options={actions} disabled={actions.length === 0} left={-62} theme={theme}></OptionsCell>
      )}
    </TableRowCmp>
  );
};

TableRow.propTypes = {
  fields: PropTypes.array.isRequired,
  actions: PropTypes.array,
  row: PropTypes.any.isRequired,
  onRowClick: PropTypes.func,
  getRowClass: PropTypes.func,
  deleteAction: PropTypes.func,
  align: PropTypes.string,
  theme: PropTypes.string,
};

export default TableRow;
