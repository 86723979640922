import { api } from 'api';

const CLEAR_ENTRIES = '/pages/options/roles/CLEAR_ENTRIES';
const ADD_ROLES = '/pages/options/roles/ADD_ROLES';
const SET_OFFSET = '/page/options/roles/SET_OFFSET';
const SET_SORT_PARAMS = '/pages/options/roles/SET_SORT_PARAMS';
const SET_ROLE_INFO = '/pages/options/roles/SET_ROLE_INFO';

export default function reducer(state, action) {
  const defaultState = {
    roles: {},
    offset: 0,
    roleInfo: null,
    sortParams: [{ field_name: 'title', direction: 'asc' }],
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case CLEAR_ENTRIES:
      return {
        ...state,
        roles: {},
      };
    case ADD_ROLES:
      //console.log('🚀 ~ addRoles ~ roles', roles);
      const newRoles = {
        ...state.roles,
        ...action.data,
      };
      const rolesMap = {};
      Object.keys(newRoles).forEach((key) => {
        const el = newRoles[key];
        rolesMap[el.id] = el.name;
      });
      window.config.role_map = rolesMap;

      return {
        ...state,
        roles: newRoles,
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    case SET_ROLE_INFO:
      return {
        ...state,
        roleInfo: action.data,
      };
    default:
      return state;
  }
}

export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const addRoles = (roles) => {
  return {
    type: ADD_ROLES,
    data: roles,
  };
};
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};
export const setRoleInfo = (value) => {
  return {
    type: SET_ROLE_INFO,
    data: value,
  };
};

export const getRequestParams = () => (dispatch, getState) => {
  return {};
};

export const addRole = (params, callback) => (dispatch) => {
  return api.call('roles.add', params).then((res) => {
    if (callback) {
      callback();
    }
  });
};

export const updateRole = (id, params, callback) => (dispatch) => {
  return api.call('roles.update', { role_id: id, name: params.name }).then((res) => {
    if (callback) {
      callback();
    }
  });
};

export const updatePermission = (role_id, id, value, callback) => (dispatch) => {
  return api.call('roles.change_permission', { role_id: role_id, permission_id: id, is_allowed: value }).then((res) => {
    if (callback) {
      callback();
    }
  });
};

export const delItem = (id, callback) => (dispatch) => {
  return api
    .call('roles.delete', { id })
    .then((res) => {
      if (callback) {
        callback();
      }
    })
    .catch((error) => resolve(error));
};
