import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { data } from 'services';
import { routes } from 'router';
import { ImgViewer, OptionsCell, Cell, GridRow, ZoneIcon } from 'libs/ui';
import { getShortRFID, getParking, getDateTime } from 'services/types';
import { ClosePicturesButton, ImageWrap, PictureRow } from './Row.styled';

class Ride extends Component {
  state = { showPhotos: false };

  togglePhotos = () => {
    this.setState({ showPhotos: !this.state.showPhotos });
  };

  showCard = () => {
    this.props.history.push(`${routes.stay}?id=${this.props.stay_id}`);
  };

  getZonesIcons = (type) => {
    const zones = this.props.zones.getZones();
    let rideZone = '';

    if (type === 'from') {
      rideZone = this.props.zone_from;
    } else {
      rideZone = this.props.zone_to;
    }

    for (let i = 0; i < zones.length; i++) {
      if (zones[i].id === rideZone) {
        return <ZoneIcon color={zones[i].color}>{zones[i].name}</ZoneIcon>;
      }
    }
  };

  getPhoto = (photo) => {
    if (this.props.data && this.props.data.photos) {
      return this.props.data.photos[photo];
    }
  };

  getRack = (nodeId, rackId) => {
    const node = this.getDecorateName('node', nodeId);
    const rack = this.getDecorateName('rack', rackId);
    return `${node} ${rack}`;
  };

  getDecorateName = (type, id) => {
    if (id) {
      const prefix = type === 'node' ? 'У' : 'C';

      if (type === 'node' && id < 10) {
        return `${prefix}0${id}`;
      }
      return `${prefix}${id}`;
    }
    return '-';
  };

  getOptionsCell = () => {
    // const options = [{ text: this.state.showPhotos ? 'Скрыть фото' : 'Показать фото', func: this.togglePhotos }];
    const options = [];
    if (this.props.stay_id && this.props.stay_id !== 'system_30_07_2018_rfids') {
      options.push({ text: 'Перейти в карточку парковки', func: this.showCard });
    }

    return <OptionsCell left={-61} options={options} />;
  };

  render() {
    const {
      type,
      mean_type,
      mean_number,
      parking_id,
      is_suspicious,
      timestamp,
      node_id,
      rack_id,
      vehicle_class,
      vehicle_plate,
      csc_owner,
      result,
      client_name,
    } = this.props;
    const parking = getParking(parking_id);
    const mean = mean_type === 'rfid' ? getShortRFID(mean_number) : mean_number;

    return (
      <>
        <GridRow $is_suspicious={is_suspicious} onClick={this.togglePhotos}>
          {/*<Cell>{this.props.id}</Cell>*/}
          {window.config.central && <Cell pr10>{parking}</Cell>}
          <Cell pr10>{getDateTime(timestamp)}</Cell>
          <Cell pr10>{vehicle_plate}</Cell>
          <Cell pr10>{data.getDictValue('passage.type', type)}</Cell>
          <Cell pr10>{data.getDictValue('passage.result', result)}</Cell>
          <Cell pr10>{this.getZonesIcons('from')}</Cell>
          <Cell pr10>{this.getRack(node_id, rack_id)}</Cell>
          <Cell pr10>{this.getZonesIcons('to')}</Cell>
          <Cell pr10>{vehicle_class}</Cell>
          <Cell pr10>{data.getDictValue('payment_mean_type', mean_type)}</Cell>
          <Cell pr10>{mean}</Cell>
          <Cell>{csc_owner}</Cell>
          <Cell>{client_name}</Cell>
          {this.getOptionsCell()}
        </GridRow>
        {this.state.showPhotos && (
          <PictureRow>
            <ImageWrap>
              <ImgViewer images={[this.getPhoto('photo_1')]} width={'432px'} height={'300px'} />
              <ImgViewer images={[this.getPhoto('photo_2')]} width={'432px'} height={'300px'} />
              <ImgViewer images={[this.getPhoto('photo_3')]} width={'432px'} height={'300px'} />
              <ClosePicturesButton
                onClick={() => {
                  this.setState({ showPhotos: !this.state.showPhotos });
                }}
              >
                <i className="fa fa-arrow-up" />
                Скрыть
              </ClosePicturesButton>
            </ImageWrap>
          </PictureRow>
        )}
      </>
    );
  }
}

export default withRouter(Ride);
