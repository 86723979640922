import PropTypes from 'prop-types';

import { Back, Content, WarningHeader, WarningContent, WarningText } from './confirm.styled';
import { Button, ButtonContainer } from 'libs/ui';

export const Confirm = (props) => {
  const { width, type, title, info, cancelButton, acceptButton, ...rest } = props;

  const close = () => {
    if (cancelButton) {
      cancelButton.func();
    }
  };

  const disableClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Back onClick={close}>
      <Content width={width} {...rest} onClick={disableClick}>
        {type && type === 'error' ? (
          <img width={50} height={50} src="/images/error-icon.png" alt="" />
        ) : (
          <img width={50} height={47} src="/images/warning.png" alt="" />
        )}
        <WarningContent>
          <WarningHeader>{title}</WarningHeader>
          {typeof info === 'object' && info.__html ? <WarningText dangerouslySetInnerHTML={info} /> : <WarningText>{info}</WarningText>}
          <ButtonContainer space="20px" justifyContent="flex-start">
            {cancelButton ? <Button onClick={cancelButton.func}>{cancelButton.text}</Button> : null}
            {acceptButton ? (
              <Button onClick={acceptButton.func} backgroundColor={acceptButton.backgroundColor} theme={acceptButton.theme}>
                {acceptButton.img ? <img width={10} src={acceptButton.img} alt="" /> : null}
                {acceptButton.text}
              </Button>
            ) : null}
          </ButtonContainer>
        </WarningContent>
      </Content>
    </Back>
  );
};

Confirm.propTypes = {
  width: PropTypes.any,
  type: PropTypes.string,
  title: PropTypes.any.isRequired,
  info: PropTypes.string,
  cancelButton: PropTypes.object,
  acceptButton: PropTypes.object,
};

Confirm.defaultProps = {
  width: '440px',
};
