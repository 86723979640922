import React, { Component } from 'react';
import styled from 'styled-components';
import { SubmissionError } from 'redux-form';

import { user } from 'services';
import { UpdateMessage } from 'libs/ui';
import { ClientData, PersonalDataHeader } from '../styledComponents';
import ContactFaceForm from './forms/ContactFace';

const ContactFacesDataWrapper = styled(ClientData)`
  position: relative;
`;
const UpdateMessageBox = styled(UpdateMessage)`
  bottom: -40px;
  left: 100px;
  z-index: 1;
`;

export default class ContactFace extends Component {
  state = { showUpdateMessage: false };

  componentDidUpdate() {
    if (this.props.info && this.props.showMessageAfterAddContact === this.props.info.id) {
      this.removeMessageAfterAdd();
    }
  }

  removeMessageAfterAdd = () => {
    setTimeout(() => {
      this.props.addContactMessageDisplayHandler(false);
    }, 3000);
  };

  submit = (values) => {
    const rightUpdate = user.haveRight('clients.update');
    if (!rightUpdate) {
      return Promise.resolve();
    }

    if (this.props.info && this.props.info.id) {
      return this.props
        .updateClientContact(
          {
            id: this.props.info.id,
            info: {
              client_id: this.props.info.client_id,
              ...values,
            },
          },
          this.showMessage
        )
        .then((errors) => {
          let submissionErrors = {};
          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              submissionErrors[element.field] = element.type;
              submissionErrors._error = errors.type;
            });
          }

          throw new SubmissionError({ ...submissionErrors });
        });
    } else {
      return this.props
        .addClientContact(
          {
            client_id: this.props.client_id,
            info: { ...values },
          },
          this.afterAddContact
        )
        .then((errors) => {
          let submissionErrors = {};
          if (Array.isArray(errors)) {
            errors.forEach((element) => {
              submissionErrors[element.field] = element.type;
              submissionErrors._error = errors.type;
            });
          }

          throw new SubmissionError({ ...submissionErrors });
        });
    }
  };

  showMessage = () => {
    this.setState({ showUpdateMessage: true });
    setTimeout(() => {
      this.setState({ showUpdateMessage: false });
    }, 3000);
  };

  afterAddContact = (contactId) => {
    this.props.addContactMessageDisplayHandler(contactId);
    this.props.hideNewContactForm();
  };

  render() {
    return (
      <ContactFacesDataWrapper>
        <PersonalDataHeader>Контактное лицо</PersonalDataHeader>
        <ContactFaceForm onSubmit={this.submit} info={this.props.info} />
        {this.state.showUpdateMessage ? <UpdateMessageBox>Изменения сохранены</UpdateMessageBox> : null}
        {this.props.info && this.props.showMessageAfterAddContact === this.props.info.id ? (
          <UpdateMessageBox>Контактное лицо сохранено</UpdateMessageBox>
        ) : null}
      </ContactFacesDataWrapper>
    );
  }
}
