import React from 'react';
import { Wrapper } from './source-input-wrapper';

import { Textarea, Date, Time, Autocomplete, Autocomplete2, RackSelect } from './inputs';
import { Input } from './inputs/Input';
import { Select } from './inputs/Select';
import { Toggle } from './inputs/Toggle';
import { Multiselect } from './inputs/Multiselect';

export const SourceInput = (props) => {
  const { type, withoutWrapper, ...rest } = props;
  const out = { ...rest };
  out.type = type;

  const getInputByType = () => {
    let Component = null;

    switch (type) {
      case 'text':
      case 'password':
        Component = Input;
        break;
      case 'checkbox':
      case 'radio':
        out.checked = !!out.value;
        Component = Input;
        break;
      case 'toggle':
        Component = Toggle;
        break;
      case 'dateTime':
      case 'date':
        Component = Date;
        break;
      case 'time':
        Component = Time;
        break;
      case 'select':
        Component = Select;
        break;
      case 'multiselect':
        Component = Multiselect;
        break;
      case 'textarea':
        Component = Textarea;
        break;
      case 'autocomplete':
        Component = Autocomplete;
        break;
      case 'autocomplete2':
        Component = Autocomplete2;
        break;
      case 'rackSelect':
        Component = RackSelect;
        break;
      default:
        throw new Error('There are missing type of Input');
    }
    return Component;
  };

  const Component = getInputByType();
  return withoutWrapper ? <Component {...out} /> : <Wrapper component={Component} {...props} />;
};
