import styled from 'styled-components';

export const StyledAuth = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  & > img {
    max-height: 100px;
    margin-bottom: 50px;
  }
  form {
    position: relative;
    border-radius: 15px;
    width: 440px;
    background-color: #fff;
    padding: 60px;
  }
`;
