import React from 'react';

import { user, data } from 'services';
import { Cell, GridRow } from 'libs/ui';

const Row = (props) => {
  const editEntity = () => {
    props.setUserInfo({
      id: props.id,
      is_active: props.is_active,
      role: props.role,
      username: props.username,
      password: '',
      name: props.name,
      email: props.email,
      phone: props.phone,
      role_id: props.role_id,
    });
    props.updateFormDisplayHandler(true);
  };

  const updateRight = user.haveRight('users.update');

  return (
    <GridRow status={props.is_active ? 'active' : 'blocked'} onClick={updateRight ? editEntity : undefined}>
      <Cell>{props.username}</Cell>
      <Cell>{props.role}</Cell>
      <Cell>{data.getDictValue('users.status', props.is_active)}</Cell>
      <Cell>{props.name}</Cell>
      <Cell>{props.email}</Cell>
      <Cell>{props.phone}</Cell>
    </GridRow>
  );
};

export default Row;
