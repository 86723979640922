import styled from 'styled-components';

import { GridRow, Button } from 'libs/ui';

export const PictureRow = styled(GridRow)`
  height: 300px;
  width: 1300px;
  display: flex;
  justify-content: space-between;
`;
export const ImageWrap = styled.td`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 !important;
`;
export const ClosePicturesButton = styled(Button)`
  position: absolute;
  bottom: 15px;
  left: 46%;
`;
