import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { Checkbox, Popup } from '../styled';
import { Container, InputBox } from './zone-input.styled';
import { ZoneIcon, Ellipsis } from 'libs/ui';

export class ZoneInput extends React.Component {
  constructor(props) {
    super(props);

    this.wrapper = React.createRef();
  }

  getDisplayedValue = () => {
    const { values, countDisplayedIcons } = this.props;

    let list = [];

    for (let i = 0; i < values.length; i++) {
      if (i >= countDisplayedIcons) {
        list.push(<Ellipsis key="ellipsis">...</Ellipsis>);
        break;
      }
      list.push(
        <ZoneIcon key={values[i].id} color={values[i].color}>
          {values[i].name}
        </ZoneIcon>
      );
    }

    return <ul>{list}</ul>;
  };

  getInputs = () => {
    const { values, setValue, data } = this.props;

    if (Array.isArray(data)) {
      return data.map((elem) => {
        if (!elem.id) {
          return null;
        }
        const checked = values?.includes(elem);
        return (
          <Checkbox key={elem.id} className="checkbox">
            <input onChange={(e) => setValue(e, elem.id)} type="checkbox" name={elem.name} checked={checked} />
            <ZoneIcon color={elem.color}>{elem.name}</ZoneIcon>
          </Checkbox>
        );
      });
    }
  };

  render() {
    const { showPopup, values, data, $popupIsOpen, popupIsOpen, selectAll, className } = this.props;
    const open = $popupIsOpen || popupIsOpen;
    // console.log('🚀 ~ render ~ popupIsOpen:', open);

    return (
      <Container className={className} ref={this.wrapper}>
        <InputBox onClick={showPopup} className="input">
          {this.getDisplayedValue()}
        </InputBox>
        <OutsideClickHandler
          onOutsideClick={(event) => {
            if (this.wrapper && !this.wrapper.current.contains(event.target)) {
              if (open) {
                showPopup();
              }
            }
          }}
        >
          <Popup className="popup" $popupIsOpen={open}>
            <Checkbox className="checkbox">
              <input onChange={selectAll} checked={values.length === data.length} type="checkbox" name="all" />
              <label>Все</label>
            </Checkbox>
            {this.getInputs()}
          </Popup>
        </OutsideClickHandler>
      </Container>
    );
  }
}
