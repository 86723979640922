import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { data } from 'services';
import { routes } from 'router';
import { GridRow, ZoneIcon, Cell, Button, ImgViewer, OptionsCell } from 'libs/ui';
import { getDateTime, getParking } from 'services/types';

const PictureRow = styled(GridRow)`
  height: 300px;
  width: 1300px;
  display: flex;
  justify-content: space-between;
`;
const ImageWrap = styled.td`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 !important;
`;
const ClosePicturesButton = styled(Button)`
  position: absolute;
  bottom: 15px;
  left: 46%;
`;
const EmptyCell = styled(Cell)`
  text-align: center;
`;

class Ride extends Component {
  constructor(props) {
    super(props);

    this.state = { showPhotos: false };
  }

  togglePhotos = () => {
    this.setState({ showPhotos: !this.state.showPhotos });
  };

  showCard = () => {
    this.props.history.push(routes.stay + '?id=' + this.props.stay_id);
  };

  getZonesIcons = (type) => {
    const zones = this.props.zones.getZones();
    let rideZone = '';

    if (type === 'from') {
      rideZone = this.props.zone_from;
    } else {
      rideZone = this.props.zone_to;
    }

    for (let i = 0; i < zones.length; i++) {
      if (zones[i].id === rideZone) {
        return <ZoneIcon color={zones[i].color}>{zones[i].name}</ZoneIcon>;
      }
    }
  };

  getPhoto = (photo) => {
    if (this.props.data && this.props.data.photos) {
      return this.props.data.photos[photo];
    }
  };

  getRack = (nodeId, rackId) => {
    const node = this.getDecorateName('node', nodeId);
    const rack = this.getDecorateName('rack', rackId);
    return `${node} ${rack}`;
  };

  getDecorateName = (type, id) => {
    if (id) {
      const prefix = type === 'node' ? 'У' : 'C';

      if (type === 'node' && id < 10) {
        return `${prefix}0${id}`;
      }
      return `${prefix}${id}`;
    }
    return '-';
  };

  getOptionsCell = () => {
    if (this.props.list === 'black' && !this.props.result) {
      return <EmptyCell center>—</EmptyCell>;
    } else {
      // const options = [{ text: this.state.showPhotos ? 'Скрыть фото' : 'Показать фото', func: this.togglePhotos }];
      const options = [];
      if (this.props.stay_id && this.props.stay_id !== 'system_30_07_2018_rfids') {
        options.push({ text: 'Перейти в карточку парковки', func: this.showCard });
      }
      return <OptionsCell options={options} left={-61} />;
    }
  };

  render() {
    const { type, timestamp, mean_is_blocked, result } = this.props;
    const parking = getParking(this.props.parking_id);

    return (
      <>
        <GridRow $is_suspicious={(this.props.list === 'black' && this.props.result) || this.props.is_suspicious} onClick={this.togglePhotos}>
          {window.config.central && <Cell>{parking}</Cell>}
          <Cell>{getDateTime(timestamp)}</Cell>
          <Cell>{this.props.vehicle_plate}</Cell>
          <Cell>{data.getDictValue('passage.list', mean_is_blocked)}</Cell>
          <Cell>{data.getDictValue('passage.type', type)}</Cell>
          <Cell>{data.getDictValue('passage.result', result)}</Cell>
          <Cell>{this.getZonesIcons('from')}</Cell>
          <Cell>{this.getRack(this.props.node_id, this.props.rack_id)}</Cell>
          <Cell>{this.getZonesIcons('to')}</Cell>
          <Cell>{this.props.csc_owner}</Cell>
          <Cell>{this.props.vehicle_class}</Cell>
          {this.getOptionsCell()}
        </GridRow>
        {this.state.showPhotos ? (
          <PictureRow>
            <ImageWrap>
              <ImgViewer images={[this.getPhoto('photo_1')]} width={'432px'} height={'300px'} />
              <ImgViewer images={[this.getPhoto('photo_2')]} width={'432px'} height={'300px'} />
              <ImgViewer images={[this.getPhoto('photo_3')]} width={'432px'} height={'300px'} />
              <ClosePicturesButton
                onClick={() => {
                  this.setState({ showPhotos: !this.state.showPhotos });
                }}
              >
                <i className="fa fa-arrow-up"></i> Скрыть
              </ClosePicturesButton>
            </ImageWrap>
          </PictureRow>
        ) : null}
      </>
    );
  }
}

export default withRouter(Ride);
