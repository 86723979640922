import { useState } from 'react';
import PropTypes from 'prop-types';

// import { useStateStorage } from 'libs/common';
import TableHeader from './table-header';
import TableRow from './table-row';
import { Loader, Confirm } from 'libs/ui';
import { TablePlace, TableCmp, TableBody } from './table.styled';
import { useStickyHeader } from 'libs/hooks';

/**
 * table component
 */
export const Table = (props) => {
  const { theme, minWidth, title, fields, rows, actions, onRowClick, getRowClass, getRowPopup, updateEntry } = props;
  const [showConfirm, setShowConfirm] = useState(null);
  const [error, setError] = useState(null);
  const { tableRef, isSticky } = useStickyHeader();

  if (!rows) {
    return <Loader />;
  }

  if (!rows.count) {
    return null;
  }

  /**
   * delete action with confirm
   * @param row
   * @returns
   */
  const deleteAction = (row) => {
    const action = props.deleteAction;
    if (!action) {
      return;
    }

    let title = 'Удалить запись';
    if (row.title) {
      title += ' ' + row.title;
    }

    setShowConfirm({
      active: true,
      confirmText: title,
      confirmAction: () => {
        action(
          row,
          () => {
            closeConfirm();
          },
          (error) => {
            const out = typeof error === 'object' ? JSON.stringify(error) : error;
            //console.log('error', error);
            setError(out);
          }
        );
      },
    });
  };

  const closeConfirm = () => {
    setShowConfirm(null);
  };

  const withDelete = !!props.deleteAction;
  const rowParams = { onRowClick, actions, fields, getRowClass, getRowPopup, updateEntry };
  if (withDelete) {
    rowParams.deleteAction = deleteAction;
  }

  return (
    <>
      <TablePlace>
        {/* {isSticky && (
          <TableCmp className="sticky" style={{ minWidth }} theme={theme}>
            <TableHeader fields={fields} actions={actions} withDelete={withDelete} theme={theme} />
            <TableBody>
              <TableRow row={rows.items[0]} {...rowParams} theme={theme} />
            </TableBody>
          </TableCmp>
        )} */}
        <TableCmp ref={tableRef} aria-labelledby={title} style={{ minWidth }} theme={theme} $sticky={isSticky ?? undefined}>
          <TableHeader fields={fields} actions={actions} withDelete={withDelete} theme={theme} $sticky={isSticky ?? undefined} />
          <TableBody>
            {rows.items.map((row, idx) => {
              return <TableRow key={idx} row={row} {...rowParams} theme={theme} />;
            })}
          </TableBody>
        </TableCmp>
      </TablePlace>

      {showConfirm && showConfirm.active && (
        <Confirm
          error={error}
          open={true}
          close={closeConfirm}
          title="Подтвердите действие"
          info={showConfirm.confirmText}
          action={showConfirm.confirmAction}
          actionTitle="Подтвердить"
        />
      )}
    </>
  );
};

Table.propTypes = {
  fields: PropTypes.array.isRequired,
  rows: PropTypes.object,
  actions: PropTypes.array,
  minWidth: PropTypes.number,
  title: PropTypes.string,
  onRowClick: PropTypes.func,
  getRowClass: PropTypes.func,
  control: PropTypes.any,
  theme: PropTypes.string,
};

Table.defaultProps = {
  theme: '',
  minWidth: 750,
  title: '',
  defaultOrder: 'asc',
  defaultOrderBy: '',
};

export default Table;
