import { useState } from 'react';

import { Button, themes, Confirm } from 'libs/ui';
import { Content, ConfirmTitle } from './row-actions.styled';
import { deleteAdv } from 'services/adv-service';
import { notify } from 'libs/common';

export const RowActions = (props) => {
  const { row, refreshData } = props;
  const [form, setForm] = useState('');

  /**
   * del confirm
   */
  const showDelConfirm = () => {
    setForm('delConfirm');
  };

  /**
   * hide del confirm
   */
  const hideDelConfirm = () => {
    setForm('');
  };

  /**
   * delete adv
   */
  const delAdv = async () => {
    let err = false;
    await deleteAdv(row.original?.id).catch((error) => {
      err = true;
      notify('Ошибка удаления файла', error?.message);
    });

    if (!err) {
      refreshData();
    }
  };

  let fn = row.original?.name;
  if (fn.length > 15) {
    fn = fn.substring(0, 15) + '...';
  }

  const confirm = {
    title: <ConfirmTitle>Файл <b title={row.original?.name}>{fn}</b> будет удален. Продолжить?</ConfirmTitle>,
    width: '440px',
    type: 'danger',
    cancelButton: { text: 'Отмена', func: hideDelConfirm },
    acceptButton: { text: 'Удалить', func: delAdv, theme: themes.red },
  };

  return (
    <Content>
      <Button
        theme={themes.red}
        fontSize={'13px'}
        onClick={(event) => {
          event.stopPropagation();
          showDelConfirm();
        }}
      >
        Удалить
      </Button>
      {form === 'delConfirm' && <Confirm {...confirm} />}
    </Content>
  );
};

export default RowActions;
