import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { thunk } from 'redux-thunk';

//models
import staysReducer from './ducks/models/stays';
import ridesReducer from './ducks/models/rides';
import plateRidesReducer from './ducks/models/plateRides';
import clientsReducer from './ducks/models/clients';
import clientReducer from './ducks/models/client';
import rfidsReducer from './ducks/models/rfids';
import epmReducer from './ducks/models/epm';
import subscriptionsReducer from './ducks/models/subscriptions';
import vehiclePlatesReducer from './ducks/models/vehiclePlates';
import callsReducer from './ducks/models/calls';
import cashboxOperationsReducer from './ducks/models/cashboxOperations';
import tariffsReducer from './ducks/models/tariffs';
import tariffReducer from './ducks/models/tariff';
import reportsReducer from './ducks/models/reports';
import rules from './ducks/models/rules';
import users from './ducks/models/users';
import collection from './ducks/models/collection';
import roles from './ducks/models/roles';
import categories from './ducks/models/categories';
import refill from './ducks/models/refill';
import events from './ducks/models/events';
import processing from './ducks/models/processing';
import actions from './ducks/models/actions';
import using from './ducks/models/using';
import working from './ducks/models/working';
import benefits from './ducks/models/benefits';
import promises from './ducks/models/promises';
import optbenefits from './ducks/models/optbenefits';

//interface
import staysGridReducer from './ducks/interface/staysGrid';
import clientsGridReducer from './ducks/interface/clientsGrid';
import clientsCardReducer from './ducks/interface/clientsCard';
import ridesGridReducer from './ducks/interface/ridesGrid';
import plateRidesGridReducer from './ducks/interface/plateRides';
import rfidsGridReducer from './ducks/interface/rfidsGrid';
import vehiclePlatesGridReducer from './ducks/interface/vehiclePlatesGrid';
import epmsGridReducer from './ducks/interface/epmsGrid';
import subscriptionsGridReducer from './ducks/interface/subscriptionsGrid';
import cashboxOperationsGridReducer from './ducks/interface/cashboxOperationsGrid';
import callsGridReducer from './ducks/interface/callsGrid';
import tariffsGridReducer from './ducks/interface/tariffsGrid';
import tariffCardReducer from './ducks/interface/tariffCard';
import usersReducer from './ducks/interface/usersGrid';
import collectionReducer from './ducks/interface/collectionGrid';
import rolesReducer from './ducks/interface/rolesGrid';
import categoriesReducer from './ducks/interface/categoriesGrid';
import refillGrid from './ducks/interface/refillGrid';
import eventsReducer from './ducks/interface/eventsGrid';
import actionsReducer from './ducks/interface/actionsGrid';
import usingReducer from './ducks/interface/usingGrid';
import workingReducer from './ducks/interface/workingGrid';
import benefitsReducer from './ducks/interface/benefitsGrid';
import promisesReducer from './ducks/interface/promisesGrid';
import optbenefitsReducer from './ducks/interface/optbenefitsGrid';

//widget
import widgetPopupReducer from './ducks/interface/widgetPopup';

const reducers = combineReducers({
  //model
  stays: staysReducer,
  rides: ridesReducer,
  plateRides: plateRidesReducer,
  clients: clientsReducer,
  client: clientReducer,
  rfids: rfidsReducer,
  epms: epmReducer,
  subscriptions: subscriptionsReducer,
  vehiclePlates: vehiclePlatesReducer,
  cashboxOperations: cashboxOperationsReducer,
  calls: callsReducer,
  tariffs: tariffsReducer,
  tariff: tariffReducer,
  reports: reportsReducer,
  rules: rules,
  users: users,
  collection: collection,
  roles: roles,
  categories: categories,
  refill: refill,
  events: events,
  processing: processing,
  actions: actions,
  using: using,
  working: working,
  benefits: benefits,
  promises: promises,
  optbenefits: optbenefits,

  //interface
  staysGrid: staysGridReducer,
  clientsGrid: clientsGridReducer,
  clientsCard: clientsCardReducer,
  ridesGrid: ridesGridReducer,
  plateRidesGrid: plateRidesGridReducer,
  rfidsGrid: rfidsGridReducer,
  callsGrid: callsGridReducer,
  vehiclePlatesGrid: vehiclePlatesGridReducer,
  epmsGrid: epmsGridReducer,
  subscriptionsGrid: subscriptionsGridReducer,
  cashboxOperationsGrid: cashboxOperationsGridReducer,
  tariffsGrid: tariffsGridReducer,
  tariffCard: tariffCardReducer,
  usersGrid: usersReducer,
  collectionGrid: collectionReducer,
  rolesGrid: rolesReducer,
  categoriesGrid: categoriesReducer,
  refillGrid: refillGrid,
  eventsGrid: eventsReducer,
  actionsGrid: actionsReducer,
  usingGrid: usingReducer,
  workingGrid: workingReducer,
  benefitsGrid: benefitsReducer,
  promisesGrid: promisesReducer,
  optbenefitsGrid: optbenefitsReducer,

  //other
  widgetPopup: widgetPopupReducer,
  form: formReducer,
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
