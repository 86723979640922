import zones from 'services/zones';

export const ZoneSelect = (props) => {
  // const { name, value, onChange } = props;

  const items = zones.getZones();
  // console.log('🚀 ~ ZoneSelect ~ items:', items);

  const getItemsEl = () => {
    return items.map((el, idx) => {
      return <option key={idx}>{el.name}</option>;
    });
  };

  const itemsEl = getItemsEl();

  return <select {...props}>{itemsEl}</select>;
};
