import { routes } from 'router';
import zones from 'services/zones';
import ZoneInfo from './ZoneInfo';
import { Content, CheckBox, CheckboxWrapper, Discription, Name, RowContentWrapper, TextWrapper } from './rules-row.styled';

export const RulesRow = (props) => {
  function getValue(zone) {
    let result = {
      nodes: {},
    };
    zone.nodes.forEach((node) => {
      let enabledRacks = [];
      let complete = true;

      node.racks.forEach((rack) => {
        if (rack.enabled) {
          enabledRacks.push('С' + rack.rack_id);
        } else {
          complete = false;
        }
      });
      if (enabledRacks.length) {
        if (complete) {
          result.nodes[node.node_id] = true;
        } else {
          result.nodes[node.node_id] = enabledRacks;
        }
      }
    });

    if (Object.keys(result.nodes).length) {
      return <ZoneInfo nodes={result.nodes} />;
    }
  }

  const getCheckboxes = () => {
    const zonesArray = props.zones;
    const checkboxList = zonesArray.map((item) => {
      return (
        <div onClick={() => props.action(item, props.rule_id)} key={item.zone_id} to={`${routes.rule}?id=${props.rule_id}&zone=${item.zone_id}`}>
          <CheckboxWrapper color={zones.getColor(item.zone_id)}>
            <CheckBox>{getValue(item)}</CheckBox>
          </CheckboxWrapper>
        </div>
      );
    });

    return checkboxList;
  };

  const { disabled } = props;

  return (
    <Content disabled={disabled}>
      <TextWrapper>
        <Name>{props.name}</Name>
        <Discription>{props.discription}</Discription>
      </TextWrapper>
      <RowContentWrapper>{getCheckboxes()}</RowContentWrapper>
    </Content>
  );
};
