import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageWrapper, Headline } from 'modules/components/pageComponents';
import { SectionLinksWrapper, SectionLink } from 'modules/components/sectionLinks';

export const Section = ({ match, sectionName, subsectionList, subRoutes }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentLocation = location.pathname;

  const withSubsections = () => {
    return Array.isArray(subsectionList) && subsectionList.length > 0;
  };

  const getLinks = () => {
    if (!withSubsections()) {
      return null;
    }

    const list = subsectionList.filter((el) => !el['hidden'] || (el['hidden'] && currentLocation.indexOf(el.path) !== -1));
    // console.log('🚀 ~ getLinks ~ list:', list);
    const cl = list.length > 7 ? 'double' : '';
    const links = list.map((subsection, index) => {
      return (
        <SectionLink key={index} to={subsection.path}>
          {t('sections.' + subsection.name)}
        </SectionLink>
      );
    });
    return <SectionLinksWrapper className={cl}>{links}</SectionLinksWrapper>;
  };

  const getRoutes = () => {
    const routes = getRouteArray(subRoutes);
    const subsectionRoutes = getRouteArray(subsectionList);
    return routes.concat(subsectionRoutes);
  };

  const getRouteArray = (routes) => {
    let out = [];
    if (Array.isArray(routes) && routes.length > 0) {
      out = routes.map((route, index) => {
        //console.log(route.path, typeof route.component, route.component)
        return <Route key={index} path={route.path} component={route.component} exact={route.exact} />;
      });
    }
    return out;
  };

  const getRedirectPath = () => {
    if (subsectionList.length > 0) {
      return subsectionList[0].path;
    }

    return match.path;
  };

  const getHeadline = () => {
    if (!withSubsections()) {
      return null;
    }
    return <Headline>{t('sections.' + sectionName)}</Headline>;
  };

  return (
    <PageWrapper>
      {getHeadline()}
      {getLinks()}
      <Switch>
        {getRoutes()}
        <Redirect to={getRedirectPath()} />
      </Switch>
    </PageWrapper>
  );
};
