const CLEAR_ENTRIES = '/pages/reports/calls/CLEAR_ENTRIES'
const ADD_CALLS = '/pages/reports/calls/ADD_CALLS'
const SET_CALLS_COUNT = '/pages/reports/calls/SET_CALLS_COUNT'
const SET_OFFSET = '/pages/reports/calls/SET_OFFSET'
const SET_SORT_PARAMS = '/pages/reports/calls/SET_SORT_PARAMS'

export default function reducer(state, action) {

  const defaultState = {
    calls: {},
    call: null,
    offset: 0,
    count: 0,
    sortParams: [{field_name: 'timestamp', direction: 'desc'}]
  }

  if (!state) {
    state = defaultState
  }

  switch (action.type) {
    case CLEAR_ENTRIES:
      return {
        ...state,
        calls: {}
      }
    case SET_CALLS_COUNT:
      return {
        ...state,
        count: action.data
      }
    case SET_OFFSET: 
      return {
        ...state,
        offset: action.data
      }
    case ADD_CALLS:
      return {
        ...state,
        calls: {
          ...state.calls,
          ...action.data
        }
      }
    default:
      return state
  }
}

export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value
  }
}
export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES
  }
}
export const setCount = (value) => {
  return {
    type: SET_CALLS_COUNT,
    data: value
  }
}
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value
  }
}
export const addCalls = (calls) => {
  return {
    type: ADD_CALLS,
    data: calls
  }
}

const getFormatDurationValue = (value) => {
  const hours = value.match(/..(?=ч)/)[0].replace(/_/g, 0)
  const minutes = value.match(/..(?=м)/)[0].replace(/_/g, 0)
  const seconds = value.match(/..(?=с)/)[0].replace(/_/g, 0)

  return hours * 3600 + minutes * 60 + Number(seconds)
}

export const getRequestParams = (filter) => (dispatch, getState) => {
  let decorateParams = {}
  for ( let key in filter ) {
    if (filter[key] || filter[key] === 0) {
      switch (key) {
        case 'dur_from':
          decorateParams[key] = getFormatDurationValue(filter[key])
          break
        case 'dur_to':
          decorateParams[key] = getFormatDurationValue(filter[key])
          break
        default:
          decorateParams[key] = filter[key]
      }
    }
  }
  return decorateParams
}