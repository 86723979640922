import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { config } from 'config';
import { data } from 'services';
import { getSource2, getDateTime, getDuration } from 'services/types';
import { Cell, GridRow, AudioPlayer } from 'libs/ui';

const SoundCell = styled(Cell)`
  overflow: visible;
  img {
    cursor: pointer;
    &:last-child {
      margin-left: 30px;
    }
  }
`;

class Call extends Component {
  state = {
    showPlayer: false,
  };

  getDuration = (duration) => {
    return `${Math.round(duration / 3600)} ч. ${Math.round(duration / 60)} мин. ${Math.round(duration % 60)} сек`;
  };

  togglePlayer = () => {
    this.setState({ showPlayer: !this.state.showPlayer });
  };

  closePlayer = () => {
    this.setState({ showPlayer: false });
  };

  render() {
    const { source, source_type, calldate, duration, dcontext, disposition } = this.props;
    const ended = disposition === 'ANSWERED';
    return (
      <GridRow>
        <Cell>{data.getDictValue('calls.type', dcontext)}</Cell>
        <Cell>{data.getDictValue('calls.status', disposition)}</Cell>
        <Cell>{getDateTime(calldate)}</Cell>
        <Cell>{getDuration(duration, true)}</Cell>
        <Cell>{data.getDictValue('rack_type', source_type)}</Cell>
        <Cell>{getSource2(source)}</Cell>
        <SoundCell>
          {ended && (
            <>
              <img onClick={this.togglePlayer} src={this.state.showPlayer ? '/images/active-speaker.png' : '/images/speaker.png'} alt="" />
              <a target="_blank" rel="noopener noreferrer" href={config.network.calls_files_url + this.props.id + '.mp3'}>
                <img src="/images/download.png" alt="" />
              </a>
              {this.state.showPlayer && <AudioPlayer close={this.closePlayer} file={config.network.calls_files_url + this.props.id + '.mp3'} />}
            </>
          )}
        </SoundCell>
      </GridRow>
    );
  }
}
export default withRouter(Call);
