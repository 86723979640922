const CLEAR_ENTRIES = '/pages/reports/cashbox/CLEAR_ENTRIES';
const ADD_OPERATIONS = '/pages/reports/cashbox/ADD_OPERATIONS';
const SET_OPERATIONS_COUNT = '/pages/reports/cashbox/SET_OPERATIONS_COUNT';
const SET_OFFSET = '/page/reports/cashbox/SET_OFFSET';
const SET_SORT_PARAMS = '/pages/reports/cashbox/SET_SORT_PARAMS';
const SET_SUMM = '/pages/reports/cashbox/SET_SUMM';
const SET_ALL_OPERATIONS = '/pages/reports/cashbox/SET_ALL_OPERATIONS';
const SET_TERMINAL_LIST = '/pages/reports/cashbox/SET_TERMINAL_LIST';

export default function reducer(state, action) {
  const defaultState = {
    operations: {},
    allOperations: {},
    offset: 0,
    count: 0,
    sortParams: [{ field_name: 'timestamp', direction: 'desc' }],
    summ: 0,
    terminalList: [],
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case CLEAR_ENTRIES:
      return {
        ...state,
        operations: {},
      };
    case ADD_OPERATIONS:
      return {
        ...state,
        operations: {
          ...state.operations,
          ...action.data,
        },
      };
    case SET_OPERATIONS_COUNT:
      return {
        ...state,
        count: action.data,
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    case SET_SUMM:
      return {
        ...state,
        summ: action.data,
      };
    case SET_ALL_OPERATIONS:
      return {
        ...state,
        allOperations: action.data,
      };
    case SET_TERMINAL_LIST:
      return {
        ...state,
        terminalList: action.data,
      };
    default:
      return state;
  }
}

export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const setAllOperations = (operations) => {
  return {
    type: SET_ALL_OPERATIONS,
    data: operations,
  };
};
export const addOperations = (operations) => {
  return {
    type: ADD_OPERATIONS,
    data: operations,
  };
};
export const setCount = (value) => {
  return {
    type: SET_OPERATIONS_COUNT,
    data: value,
  };
};
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};
export const setSumm = (summ) => {
  return {
    type: SET_SUMM,
    data: summ,
  };
};
export const setTerminalList = (data) => {
  return {
    type: SET_TERMINAL_LIST,
    data: data,
  };
};

export const getRequestParams = (filter) => (dispatch, getState) => {
  let decorateParams = {};
  for (let key in filter) {
    if (filter[key]) {
      switch (key) {
        case 'result':
          if (filter[key]) {
            decorateParams[key] = filter[key] === 'true';
          }
          break;
        case 'amount_from':
          decorateParams[key] = filter[key] * 100;
          break;
        case 'amount_to':
          decorateParams[key] = filter[key] * 100;
          break;
        case 'shift':
          decorateParams[key] = Number(filter[key]);
          break;
        default:
          decorateParams[key] = filter[key];
      }
    }
  }
  // bad bad bad, but don't know where to set this callback
  //dispatch(getSumm(decorateParams))
  return decorateParams;
};
