import { Fragment } from 'react';

import { Button, ButtonContainer, Total } from 'libs/ui';
import { Content } from './table-info.styled';

export const TableInfo = (props) => {
  const { count, actions, theme } = props;
  const actionsEl = actions.map((el, idx) => {
    const { action, icon, title, cmp, ...rest } = el;
    if (cmp) {
      return <Fragment key={idx}>{cmp}</Fragment>;
    }
    return (
      <Button key={idx} onClick={action} {...rest}>
        <i className={'fa ' + icon} />
        {title}
      </Button>
    );
  });

  const other = { cnt: count };
  if (theme === 'dark') {
    other.white = true;
  }

  return (
    <Content>
      <Total {...other} />
      <ButtonContainer>{actionsEl}</ButtonContainer>
    </Content>
  );
};
