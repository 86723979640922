import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import moment from 'moment';

import { DictFilter, SourceFilter, DateSelect, Container, Input, DoubleField, DateTime, ButtonsContainer } from 'libs/form';
import { ResetBtn, UseBtn } from 'libs/ui';

class Filter extends Component {
  constructor(props) {
    super(props);

    // initial
    this.props.setInitial(initialValues);
  }

  render() {
    const { handleSubmit, reset, change } = this.props;

    return (
      <Container>
        <form onSubmit={handleSubmit}>
          <DictFilter w={130} dict="calls.type" />

          <DictFilter w={110} dict="calls.status" />

          <DateSelect from_name="time_from" to_name="time_to" change={change}>
            <DoubleField>
              <DateTime width="160px" name="time_from" type="dateTime" label="Начало события" />
              <DateTime width="160px" name="time_to" type="dateTime" />
            </DoubleField>
          </DateSelect>

          <DoubleField>
            <Input width="110px" name="dur_from" type="text" label="Длительность" mask="99ч 99м 99с" storeWithMask placeholder="От" />
            <Input width="110px" name="dur_to" type="text" mask="99ч 99м 99с" storeWithMask placeholder="До" />
          </DoubleField>

          <SourceFilter exclude={['rack.server', 'rack.cashbox']} parkingId={this.props.parkingId} />

          <ButtonsContainer>
            <ResetBtn onClick={reset} />
            <UseBtn />
          </ButtonsContainer>
        </form>
      </Container>
    );
  }
}

const initialValues = {
  time_from: moment().startOf('day').format(),
  time_to: moment().endOf('day').format(),
};

export default reduxForm({
  form: 'callsFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
