import React, { Component, Fragment } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { ChangeStatus } from 'libs/ui';
import CommentForm from 'modules/components/windows/AddComment';
import Comments from 'modules/components/windows/Comments';
import { addPaymentComment } from 'services/payments-service';
import { Container, Option, OptionsPopup, OptionsSeparator } from './with-popup.styled';

const types = {
  rfid: 'rfids',
  csc: 'epms',
  plates: 'vehiclePlates',
  //subscriptions: '',
};

const getRealType = (type) => {
  return types[type] ? types[type] : type;
};

export const withPopup = (WrappedComponent) => {
  return class WithPopup extends Component {
    constructor(props) {
      super(props);
      this.state = { showOptions: false, showForm: '', actionType: '' };

      this.popup = null;
    }

    hideOptions = () => {
      this.setState({ showOptions: false });
    };

    toggleOptions = () => {
      const show = this.state.showOptions;
      this.setState({ showOptions: !show });
    };

    getOptions = (optionsArray) => {
      if (Array.isArray(optionsArray)) {
        const cnt = optionsArray.length;
        const options = [];

        optionsArray.forEach((item, index) => {
          const last = index === cnt - 1;
          const show = item.isVisible ? item.isVisible(this.props.row) : true;
          if (show) {
            const onClick = (event) => {
              event.stopPropagation();
              event.preventDefault();
              this.optionOnClick(item.func || item.action, item.actionType);
            };
            options.push(
              <Fragment key={index}>
                <Option disabled={this.props.disabled} onClick={onClick}>
                  {item.icon && <i className={item.icon} />}
                  {item.getTitle ? item.getTitle(this.props.row) : item.text || item.title}
                </Option>
                {!last && <OptionsSeparator />}
              </Fragment>
            );
          }
        });
        return options;
      }
      return null;
    };

    optionOnClick = (func, type) => {
      if (this.props.disabled) {
        return;
      } else {
        if (func === 'status') {
          // status change form
          this.setState({ showForm: 'status', actionType: type });
        } else if (func === 'add-comment' || func === 'show-comments') {
          // comments
          this.setState({ showForm: func, actionType: type });
        } else {
          // common
          if (func) {
            func(this.props.row);
          }
        }
        this.toggleOptions();
      }
    };

    closeForm = () => {
      this.setState({ showForm: '' });
    };

    /**
     * prepare row
     * @param {*} row
     * @returns
     */
    prepareRow = (row) => {
      return {
        id: row.id,
        parking_id: row.parking_id,
        value: row.mean_id,
        status: row.status,
        type: getRealType(row.type),
      };
    };

    /**
     * add comment
     */
    addComment = (data) => {
      // console.log('🚀 ~ withPopup ~ data:', data);
      const update = this.props.updateEntry;
      addPaymentComment(data).then((comment) => {
        //closeForm();
        const out = { ...this.props.row };
        out.comment = data.comment;
        if (!out.comments) {
          out.comments = [];
        }
        out.comments.push(comment);
        update(data.id, out);
        //refresh();
      });
    };

    render() {
      const { left, options, row } = this.props;
      const { showForm, actionType, showOptions } = this.state;
      return (
        <Container>
          <OutsideClickHandler
            onOutsideClick={(event) => {
              event.stopPropagation();
              if (showOptions) {
                this.hideOptions();
              }
            }}
          >
            <WrappedComponent popupDisplayHandler={this.toggleOptions} {...this.props} />
            {showOptions && (
              <div
                ref={(popup) => {
                  this.popup = popup;
                }}
              >
                <OptionsPopup left={left}>{this.getOptions(options)}</OptionsPopup>
              </div>
            )}
          </OutsideClickHandler>
          {this.props.children}
          {showForm === 'status' && <ChangeStatus updateEntry={this.props.updateEntry} type={actionType} close={this.closeForm} info={row} />}
          {showForm === 'add-comment' && (
            <CommentForm addComment={this.addComment} type="addComment" removeFormInfo={this.closeForm} info={this.prepareRow(row)} />
          )}
          {showForm === 'show-comments' && <Comments type="comments" removeFormInfo={this.closeForm} id={row.id} />}
        </Container>
      );
    }
  };
};
