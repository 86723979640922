import styled from 'styled-components';

export const Content = styled.div`
  padding: 20px;

  &.loading {
    position: relative;
    min-height: 38px;
  }
  &.loading:after {
    content: '';
    position: absolute;
    left: -5px;
    top: -5px;
    right: -5px;
    bottom: -5px;
    z-index: 999;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.75) url(/images/loader.svg) no-repeat 50%;
  }
`;

export const DropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  &:focus {
    border-color: #2196f3;
  }

  &.disabled {
    opacity: 0.6;
  }
  margin: 0 0 30px;
`;

export const FileInfo = styled.div`
  font-size: 14px;
  span {
    color: #aaa;
  }
`;

export const Buttons = styled.div`
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  button {
    margin: 0 10px 10px 0;
  }
`;

export const Error = styled.div`
  margin: 20px 0 0;
  background: #fee;
  color: #f00;
  padding: 10px 15px;
`;

export const Success = styled.div`
  margin: 20px 0 0;
  background: #f0fff0;
  color: #060;
  padding: 10px 15px;
`;
