import React, { Component } from 'react';
import { reduxForm, SubmissionError, submit, Form } from 'redux-form';

import { getTariffs, replaceRfidTariffs } from 'services/payments-service';
import { Button, themes, ButtonContainer } from 'libs/ui';
import { required } from 'libs/form';
import Warning from 'modules/components/warnings';
import { Popup2 } from 'libs/ui';
import { Inputs, ReplaceBlock, ReplaceHeader, StyledField } from './replace-window.styled';

class ReplaceWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWarning: false,
      oldTariff: null,
      newTariff: null,
    };
  }

  submit = (__values) => {
    const { oldTariff, newTariff } = this.state;

    return replaceRfidTariffs(oldTariff.id, newTariff.id).then((errors) => {
      if (errors && Array.isArray(errors)) {
        let submissionErrors = {};
        errors.forEach((element) => {
          submissionErrors[element.field] = element.type;
        });
        throw new SubmissionError(submissionErrors);
      }

      this.props.reset();
      this.props.close(true);
    });
  };

  getTariffsList = () => {
    return getTariffs({
      filter_params: {
        type: 'individual',
        status: 'active',
      },
      sort_params: [{ field_name: 'id', direction: 'asc' }],
    });
  };

  render() {
    const { oldTariff, newTariff } = this.state;
    const { close, handleSubmit, reset, dispatch, valid } = this.props;

    return (
      <Popup2 w={770}>
        <ReplaceHeader>Заменить тариф у RFID</ReplaceHeader>
        <Form onSubmit={handleSubmit(this.submit)}>
          <Inputs>
            <StyledField
              placeholder="Старый тариф"
              type="autocomplete2"
              name="old_id"
              getList={this.getTariffsList}
              validate={required}
              onSelectItem={(val) => this.setState({ oldTariff: val })}
            />
            <img src="/images/long-arrow-right.png" alt="" />
            <StyledField
              placeholder="Старый тариф"
              type="autocomplete2"
              name="new_id"
              getList={this.getTariffsList}
              validate={required}
              onSelectItem={(val) => this.setState({ newTariff: val })}
            />
          </Inputs>
          <ReplaceBlock>
            <img src="/images/warning.png" alt="" />
            <p>При массовой замене тарифа у RFID, новый тариф автоматически прекратит действие активного</p>
          </ReplaceBlock>
          <ButtonContainer space="20px">
            <Button
              type="button"
              onClick={() => {
                reset();
                close();
              }}
            >
              Отменить
            </Button>
            <Button type="button" theme={themes.blue} onClick={() => this.setState({ showWarning: true })} disabled={!valid}>
              Заменить
            </Button>
          </ButtonContainer>
        </Form>

        {this.state.showWarning && (
          <Warning
            width="480px"
            header={`
                Заменить тариф «${oldTariff.name}»
                на «${newTariff.name}»?
              `}
            cancelButton={{
              func: () => this.setState({ showWarning: false }),
              text: 'Отменить',
            }}
            acceptButton={{
              func: () => {
                this.setState({ showWarning: false });
                dispatch(submit('replaceTariffForm'));
              },
              text: 'Заменить',
              theme: themes.blue,
            }}
          />
        )}
      </Popup2>
    );
  }
}

export default reduxForm({
  form: 'replaceTariffForm',
  destroyOnUnmount: false,
})(ReplaceWindow);
