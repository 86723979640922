import React from 'react';
import moment from 'moment';

import { Wrapper, Description, MainInfo, Label, Property, AdditionalInfo, CloseButton, Scroller } from 'libs/ui';

const InfoBlock = (props) => {
  const getDateTime = (date, format) => {
    if (date) {
      return moment(date).format(format);
    } else return '';
  };

  return (
    <Wrapper>
      <Description>
        {props.info ? props.info.action : ''}
        <CloseButton onClick={props.close} />
      </Description>
      <Scroller>
        <MainInfo>
          <Property>
            <Label>Дата и время</Label>
            <span>{props.info ? getDateTime(props.info.timestamp, 'DD MMM YYYY, HH:mm:ss') : ''}</span>
          </Property>
          <Property>
            <Label>Пользователь</Label>
            <span>{props.info ? props.info.user.name : ''}</span>
          </Property>
          <Property>
            <Label>Роль</Label>
            <span>{props.info ? props.info.user.role : ''}</span>
          </Property>
          <Property>
            <Label>Описание</Label>
            <span>{props.info ? props.info.action : ''}</span>
          </Property>
        </MainInfo>
        <AdditionalInfo>
          <Label>Дополнительно</Label>
          <div>
            {props.info ? (
              <pre>
                <br />
                {JSON.stringify(props.info, null, 2)}
              </pre>
            ) : (
              ''
            )}
          </div>
        </AdditionalInfo>
      </Scroller>
    </Wrapper>
  );
};

export default InfoBlock;
