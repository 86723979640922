import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { prepareFilter } from 'libs/common';
import { GridContainer } from 'libs/ui';
import { Controller } from 'libs/control';
import OperationsFilter from './Filter';
import OperationsGrid from './Grid';
import { getRequestParams, addOperations, clearEntries, setSortParams, setOffset, setCount, setSumm } from 'store/ducks/models/cashboxOperations';
import { setScroll } from 'store/ducks/interface/cashboxOperationsGrid';
import { getPaymentSource } from 'services/reports-service';

export const Operations = (props) => {
  const [apps, setApps] = useState(null);

  useEffect(() => {
    getPaymentSource().then((data) => {
      setApps(data);
    });
  }, []);

  /**
   * prepare params
   * @param {*} data
   * @returns
   */
  const prepareParams = (data) => {
    return prepareFilter(props.getRequestParams(data));
  };

  if (!apps) {
    return null;
  }

  return (
    <GridContainer>
      <Controller
        type="payment"
        filterName="cashboxOperationsFilter"
        apps={apps}
        gridFilter={OperationsFilter}
        grid={OperationsGrid}
        entries={props.operations}
        offset={props.offset}
        interface={props.interface}
        count={props.count}
        summ={props.summ}
        sortParams={props.sortParams}
        terminalList={props.terminalList}
        //methods
        getRequestParams={prepareParams}
        addEntries={props.addOperations}
        clearEntries={props.clearEntries}
        setOffset={props.setOffset}
        setSumm={props.setSumm}
        setSortParams={props.setSortParams}
        setCount={props.setCount}
        setScroll={props.setScroll}
      />
    </GridContainer>
  );
};

const mapStateToProps = (store) => {
  //console.log(store.cashboxOperations);
  return {
    operations: store.cashboxOperations.operations,
    offset: store.cashboxOperations.offset,
    count: store.cashboxOperations.count,
    summ: store.cashboxOperations.summ,
    sortParams: store.cashboxOperations.sortParams,
    terminalList: store.cashboxOperations.terminalList,
    interface: store.cashboxOperationsGrid,
  };
};

export default connect(mapStateToProps, {
  setScroll,
  getRequestParams,
  addOperations,
  clearEntries,
  setSortParams,
  setOffset,
  setCount,
  setSumm,
})(Operations);
