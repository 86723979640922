import React from 'react';
import PropTypes from 'prop-types';

import { zones } from 'services/zones';
import { RideMainInfo } from './blocks/ride-main';
import { RideAdditionalInfo } from './blocks/ride-additional';
import { Content } from './ride.styled';

export const StayCardRide = (props) => {
  const { from, to, last, amount, duration, is_suspicious } = props;

  let color = '#fff';
  if ((to.data && to.data.dispatcher_pass) || (from.data && from.data.dispatcher_pass)) {
    color = '#d35048';
  } else {
    color = zones.getColor(from.zone_to);
  }

  const mainProps = { amount, from, to, duration, is_suspicious, last };

  return (
    <Content color={color}>
      <RideMainInfo {...mainProps} />
      <RideAdditionalInfo amount={amount} from={from} />
    </Content>
  );
};

StayCardRide.propTypes = {
  from: PropTypes.any.isRequired,
  to: PropTypes.any.isRequired,
  last: PropTypes.any.isRequired,
  amount: PropTypes.any.isRequired,
  duration: PropTypes.any.isRequired,
  is_suspicious: PropTypes.any.isRequired,
};
