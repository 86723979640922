import React, { Component } from 'react';
import moment from 'moment';
import { reduxForm } from 'redux-form';

import zones from 'services/zones';
import { Container, ButtonsContainer, Multiselect, ZoneInput, DateTime, required } from 'libs/form';
import { Button, themes } from 'libs/ui';
import { api } from 'api';
import { Buttons, Confirm, Title, UpdateMessageBox } from './Filter.styled';

class Filter extends Component {
  state = {
    data: undefined,
    confirm: false,
    result: false,
  };

  showMessage = () => {
    this.setState({ result: true });
    setTimeout(() => {
      this.setState({ result: false });
    }, 3000);
  };

  action = () => {
    const { data } = this.state;

    const d = data.end_at.toISOString();
    //console.log('🚀 ~ date', d)

    api.call('stays.auto_close', { max_entry_at: d, zones: data.zones }).then((__data) => {
      //console.log('🚀 ~ api ~ data', data);
      this.setState({ confirm: false }, () => {
        this.showMessage();
      });
    });
  };

  closeConfirm = () => {
    this.setState({ confirm: false });
  };

  showConfirm = (data) => {
    // console.log('🚀 ~ data:', data);
    // e.preventDefault();
    this.setState({ confirm: true, data });
  };

  render() {
    const { data, confirm, result } = this.state;
    // console.log('🚀 ~ render ~ this.props.initialValues:', this.props.initialValues);

    const zonesItems = zones.getZones();
    const d = data ? moment(data.end_at).format('ll') : '';
    const zonesList = data
      ? data.zones.reduce((acc, current) => {
          let out = acc;
          if (out) {
            out += ', ';
          }
          return out + current;
        }, '')
      : '';
    const valid = true;

    return (
      <Container>
        {result && <UpdateMessageBox>Парковки завершены</UpdateMessageBox>}
        {confirm ? (
          <Confirm>
            <Title>
              Все активные парковки с датой начала (въездом) до 0:00 {d} для выбранных парковочных зон ({zonesList}) будут завершены. Подтвердить?
            </Title>
            <Buttons>
              <Button theme={themes.blue} onClick={this.closeConfirm}>
                Отменить
              </Button>
              <Button theme={themes.red} onClick={this.action}>
                Завершить
              </Button>
            </Buttons>
          </Confirm>
        ) : (
          <form onSubmit={this.props.handleSubmit(this.showConfirm)}>
            <DateTime width="175px" name="end_at" type="date" label="Завершить все парковки до" validate={required} />

            <Multiselect
              width="160px"
              name="zones"
              type="multiselect"
              label="Зоны"
              additionalComponent={ZoneInput}
              data={zonesItems}
              countDisplayedIcons={3}
              simpleErrorWrapper
              validate={required}
            />

            <ButtonsContainer>
              <Button disabled={!valid} type="submit" theme={themes.blue}>
                Завершить
              </Button>
            </ButtonsContainer>
          </form>
        )}
      </Container>
    );
  }
}

export default reduxForm({
  form: 'parkingsFilter',
  destroyOnUnmount: false,
  // initialValues: initialValues,
})(Filter);
