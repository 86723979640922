import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { Back, Content, Wrapper, Title } from './popup2.styled';

function lockScroll(e) {
  e.preventDefault();
}

export const Popup2 = (props) => {
  const { close, children, theme, w, top, title } = props;

  useEffect(() => {
    document.body.addEventListener('mousewheel', lockScroll, { passive: false });
    // mousewheel touchmove

    return () => {
      document.body.removeEventListener('mousewheel', lockScroll, { passive: false });
    };
  }, []);

  /**
   * close popup
   */
  const closePopup = () => {
    if (close) {
      close();
    }
  };

  /**
   * cancel click event propogation
   * @param {*} event
   */
  const cancelClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Back onClick={closePopup}>
      <Wrapper theme={theme} w={w} top={top} onClick={cancelClick}>
        <Content>
          {title && <Title>{title}</Title>}
          {children}
        </Content>
      </Wrapper>
    </Back>
  );
};

Popup2.propTypes = {
  w: PropTypes.number,
  top: PropTypes.number,
  close: PropTypes.func,
  children: PropTypes.any,
  theme: PropTypes.string,
  title: PropTypes.string,
};

Popup2.defaultProps = {
  w: 600,
};
