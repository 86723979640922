import styled, { css } from 'styled-components';

export const Content = styled.div`
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  user-select: none;
  background: #3ea5ff;
  background: linear-gradient(to left, #3ea5ff, #1e95ef);
`;

export const Container = styled.div`
  font-size: 42px;
  color: inherit;
  letter-spacing: 2px;
  cursor: pointer;
  font-weight: bold;
  .text-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.13);
  }
`;

export const Item = styled.span`
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.13);
  color: rgba(255, 255, 255, 0.7);
  ${(props) =>
    props.$active &&
    css`
      color: white;
    `}
`;
