import styled, { css } from 'styled-components';

import { Container } from 'libs/ui';

export const PageWrapper = styled(Container)`
  margin: auto;
  position: relative;
  margin-top: 25px;
`;

export const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  font-size: 26px;
  ${(props) =>
    props.$mb &&
    css`
      margin: 0 0 45px;
    `}
  ${(props) =>
    props.$alignLeft &&
    css`
      justify-content: flex-start;
    `}
`;
