import React from 'react';
import styled from 'styled-components';

import { FieldComponent } from 'libs/form';
import { ZoneInputBox } from '../../styledComponents';

const Wrapper = styled.div`
  padding: 25px 0 0 18px;
  width: 100%;
  /*
  display: flex;
  justify-content: space-between;
  */
`;
const Interface = styled.div`
  position: relative;
  display: ${(props) => props.display};
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${(props) => props.color};
  height: 500px;
`;
const FormWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  padding-right: 18px;
  margin: 0 0 0.3rem;
`;
const PeriodsWrapper = styled.div``;
const Headers = styled.div`
  display: flex;
  align-items: center;
`;
const Header = styled.div`
  margin-bottom: 15px;
  opacity: 0.5;
  font-size: 18px;
  font-weight: normal;
  color: #ffffff;
  width: 130px;
  text-align: center;
`;

const Inputs = styled.div`
  display: flex;
`;
const ZoneLabel = styled.div`
  color: #fff;
  white-space: nowrap;
  line-height: 40px;
  margin: 0;
  width: 130px;
  text-align: center;
`;

class TwoPeriodsTariffication extends React.Component {
  getPeriods() {
    const periods = this.props.selectedZone.periods;
    if (!periods) {
      return null;
    }
    const labels = ['День', 'Ночь'];
    return periods.map((period, idx) => {
      const label = labels[idx]; //period.start_at + ':00 - ' + period.end_at + ':00';
      const name = 'periods[' + idx + '].amount';
      return (
        <FormWrapper key={idx}>
          <PeriodsWrapper>
            {!idx && (
              <Headers>
                <Header>Период</Header>
                <Header>Тариф</Header>
              </Headers>
            )}
            <Inputs>
              <ZoneLabel>{label}</ZoneLabel>
              <ZoneInputBox wide>
                <FieldComponent type="text" name={name} naturalNumber maxValueLength={5} disabled={this.props.disabled} />
                <span>₽</span>
              </ZoneInputBox>
            </Inputs>
          </PeriodsWrapper>
        </FormWrapper>
      );
    });
  }

  render() {
    const { selectedZone, display } = this.props;
    return (
      <Interface color={selectedZone.color} display={display ? 'block' : 'none'}>
        <Wrapper>{this.getPeriods()}</Wrapper>
      </Interface>
    );
  }
}

export default TwoPeriodsTariffication;
