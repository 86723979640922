import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Header } from './header';
import { Footer } from './footer';
import { Clock } from './clock';
import { getPadValue } from './helper';
import { Content, Back, Modal } from './time-picker.styled';

export const TimePicker = (props) => {
  const { close, set, value } = props;
  const [view, setView] = useState('h'); // m s
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);
  const [sec, setSecund] = useState(null);

  /**
   * initial values
   */
  useEffect(() => {
    let tmpval = value;
    if (!tmpval || tmpval.indexOf(':') === -1) {
      tmpval = '00:00:00';
    }
    const temp = tmpval.split(':');
    const h = Number(temp[0]);
    const m = Number(temp[1]);
    const s = Number(temp[2]);

    // h m s
    setHour(h);
    setMinute(m);
    setSecund(s);
  }, [value]);

  /**
   * close widget
   */
  const closeWidget = () => {
    close();
  };

  /**
   * set value and close
   */
  const setValue = () => {
    let out = getPadValue(hour) + ':' + getPadValue(minute) + ':' + getPadValue(sec);
    set(out);
    close();
  };

  /**
   * inc value
   */
  const incValue = () => {
    let newVal;
    switch (view) {
      default:
      case 'h':
        newVal = hour === 23 ? 0 : hour + 1;
        setHour(newVal);
        break;
      case 'm':
        newVal = minute === 59 ? 0 : minute + 1;
        setMinute(newVal);
        break;
      case 's':
        newVal = sec === 59 ? 0 : sec + 1;
        setSecund(newVal);
        break;
    }
  };

  /**
   * dec value
   */
  const decValue = () => {
    switch (view) {
      default:
      case 'h':
        newVal = hour === 0 ? 23 : hour - 1;
        setHour(newVal);
        break;
      case 'm':
        newVal = minute === 0 ? 59 : minute - 1;
        setMinute(newVal);
        break;
      case 's':
        newVal = sec === 0 ? 59 : sec - 1;
        setSecund(newVal);
        break;
    }
  };

  /**
   * events
   */
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  /**
   * keyboard events
   * @param {*} e
   */
  const handleKeyPress = (e) => {
    switch (e.which) {
      // enter
      case 13:
        {
          setValue();
        }
        break;

      // escape
      case 27:
        {
          closeWidget();
        }
        break;

      // up
      case 38:
        {
          incValue();
        }
        break;

      // down
      case 40:
        {
          decValue();
        }
        break;
    }
  };

  /**
   * get current val
   * @returns
   */
  const getCurrent = () => {
    switch (view) {
      default:
      case 'h':
        return hour;
      case 'm':
        return minute;
      case 's':
        return sec;
    }
  };

  /**
   * set current val
   * @param {*} val
   */
  const setCurrent = (val) => {
    switch (view) {
      default:
      case 'h': {
        setHour(val);
        break;
      }
      case 'm': {
        setMinute(val);
        break;
      }
      case 's': {
        setSecund(val);
        break;
      }
    }
  };

  const current = getCurrent();

  if (hour === null) {
    return null;
  }

  return (
    <Content>
      <div>
        <Back onClick={closeWidget} />

        <Modal>
          <Header view={view} setView={setView} hour={hour} minute={minute} sec={sec} />

          <Clock current={current} setCurrent={setCurrent} view={view} />

          <Footer cancel={closeWidget} set={setValue} />
        </Modal>
      </div>
    </Content>
  );
};

TimePicker.propTypes = {
  set: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  value: PropTypes.string,
};

TimePicker.defaultProps = {};
