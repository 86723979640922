import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  //height: 90%;//380px;
  //overflow-y: auto;
  display: flex;
  align-items: flex-start;
  padding-right: 18px;
`;

export const PeriodsWrapper = styled.div`
  //margin-right: 40px;
  //width: 225px;
`;

export const Header = styled.div`
  opacity: 0.5;
  font-size: 18px;
  white-space: nowrap;
  color: #ffffff;
  box-sizing: border-box;
  padding: 10px;
  width: ${(props) => props.w}px;
  text-align: ${(props) => (props.right ? 'right' : 'left')};
`;

export const Headers = styled.div`
  display: flex;
  align-items: center;
`;
