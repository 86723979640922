export class Data {
  dictionaries = null;
  sections = null;
  zones = null;
  defaultZones = null;
  racks = null;
  racksMap = null;

  setZones(data) {
    const out = {};
    data.forEach((zone) => {
      if (!zone.periods) {
        //zone.periods = [{ period: 60, start_at: 6, end_at: 24, amount: 2500 }, { period: 660, start_at: 20, end_at: 7, amount: 15000 }];
        zone.periods = [{ /*period: 60, start_at: 6, end_at: 24,*/ amount: 2500 }, { /*period: 660, start_at: 20, end_at: 7,*/ amount: 15000 }];
      }
      out[zone.zone_id] = zone;
    });
    this.defaultZones = out;
    this.zones = data;
  }

  getZones() {
    return this.zones;
  }

  setRacks(data) {
    this.racks = data;
    this.racksMap = {};
    data.forEach((el) => {
      this.racksMap[el.id] = el.mapped_name;
    });
  }

  getRacks() {
    return this.racks;
  }

  getRacksMap() {
    return this.racksMap;
  }

  getDefaultZones() {
    return this.defaultZones;
  }

  setSections(data) {
    this.sections = data;
  }

  getSections() {
    return this.sections;
  }

  setDictionaries(data) {
    const out = {};
    data.forEach((el) => {
      if (el.value) {
        out[el.value] = { title: el.name, param: el.param, items: el.details };
      }
    });

    this.dictionaries = out;
  }

  getDict(name) {
    return this.dictionaries[name];
  }

  /**
   * get value from dictionary by id
   * @param {*} dict
   * @param {*} id
   * @param {*} def
   * @returns
   */
  getDictValue = (dict, id) => {
    const dictionary = this.getDict(dict);
    if (!dictionary) {
      return id;
    }
    const checkFunction = (dictVal, val) => {
      const test = String(val); //(typeof val === 'boolean') ? val.toString() : val;
      return dictVal === test;
    };
    const el = dictionary.items.find((el) => checkFunction(el.value, id));
    if (!el) {
      return id;
    }
    return el.name;
  };
}
