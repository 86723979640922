import { data } from 'services';

export const getTableParams = () => {
  // get row class
  const getRowClass = (row) => {
    let out = '';
    out += ' ' + row.status;
    return out;
  };

  const getContactName = (val) => {
    return val ? val.name : '';
  };

  const getPhoneNumber = (val) => {
    return val ? val.tel_num : '';
  };

  const getFields = () => {
    const fields = [
      { name: 'name', title: 'Клиент', sortField: 'name' },
      { name: 'type', title: 'Тип', width: 100, render: (val) => data.getDictValue('clients.type', val) },
      { name: 'status', title: 'Статус', width: 115, render: (val) => data.getDictValue('clients.status', val) },
      { name: 'contact', title: 'Контактное лицо', width: 215, render: (val) => getContactName(val) },
      { name: 'name', title: 'Телефон', width: 140, render: (val) => getPhoneNumber(val) },
    ];

    return fields;
  };

  return { getFields, getRowClass, withComment: true };
};
