import { useMemo, useState } from 'react';

import { user } from 'services';
// import { deleteSubscription } from 'services/payments-service';
import { TableExt, TableInfo, /*Confirm,*/ themes, GridBox, GridUpdateMessage } from 'libs/ui';
import { getTableParams } from './grid.params';
import ReplaceWindow from './forms/replace-window';
import RfidForm from './forms/rfid-form';

export const RfidGrid = (props) => {
  const { count, entries, className, theme, getXlsAction, clientId, updateEntry } = props;
  //console.log('🚀 ~ RfidGrid ~ updateEntry:', updateEntry);
  //const interface1 = props.interface;
  const [showForm, setShowForm] = useState('');
  const [message, setMessage] = useState('');
  const [row, setRow] = useState({});

  // const [confirm, setConfirm] = useState(null);

  /**
   * close confirm
   */
  // const closeConfirm = () => {
  //   setConfirm(null);
  // };

  /**
   * delete item
   */
  // const delItem = (id) => {
  //   deleteSubscription(id).then(() => {
  //     props.getEntries({ offset: 0 }, null, true);
  //     closeConfirm();
  //   });
  // };

  // actions
  /**
   * show replace form
   */
  const replaceAction = () => {
    setShowForm('replaceForm');
  };

  /**
   * hide replace form with message
   */
  const closeReplaceForm = (success = false) => {
    closeForm();
    if (success) {
      setMessage('Изменение сохранено');
      setTimeout(() => setMessage(''), 3000);
    }
  };

  // const addItemAction = () => {
  //   setRow({});
  //   setShowForm('addForm');
  // };

  const editItemAction = (row) => {
    setRow(row);
    setShowForm('editForm');
  };

  // const activateItemAction = (row) => {
  //   setRow(row);
  //   setShowForm('extForm');
  // };

  // const delItemAction = (row) => {
  //   setRow(row);
  //   setConfirm({
  //     title: 'СО ' + row.mean_id + ' будет удалено. Продолжить?',
  //     width: '440px',
  //     type: 'danger',
  //     cancelButton: { text: 'Отмена', func: closeConfirm },
  //     acceptButton: { text: 'Удалить', func: () => delItem(row.id), theme: themes.red },
  //   });
  // };

  /**
   * close form
   * @returns
   */
  const closeForm = () => setShowForm('');

  // /**
  //  * close ext form
  //  * @param {*} success
  //  */
  // const closeExtensionForm = (success = false) => {
  //   closeForm();
  //   if (success) {
  //     setMessage('Абонемент активирован');
  //     setTimeout(() => setMessage(''), 3000);
  //   }
  // };

  // items
  const items = Object.keys(entries).map((key) => entries[key]);

  // actions
  const actions = [];
  if (user.haveRight('rfid.update')) {
    actions.push({
      isVisible: (row) => row.status !== 'blocked_by_client',
      getTitle: (row) => (row.is_blocked ? 'Разблокировать' : 'Заблокировать'),
      action: 'status', //() => setShowForm('statusForm'),
      actionType: 'rfid',
    });

    actions.push({
      title: 'Редактировать',
      action: editItemAction,
    });
  }
  if (clientId) {
    if (user.haveRight('rfid.update') && user.haveRight('clients.view')) {
      actions.push({ title: 'Перейти в карточку клиента', action: () => (window.location = '/card/client?id=' + clientId) });
    }
  }

  // top actions
  const rightAdd = user.haveRight('subscriptions.add');
  const topActions = [];
  if (rightAdd && count) {
    topActions.push({ title: 'Заменить тариф у RFID', icon: 'fa-exchange', theme: themes.blue, action: replaceAction });
  }
  if (count) {
    getXlsAction(topActions);
  }

  const tableParams = useMemo(() => getTableParams(), []);
  const initial = showForm === 'addForm' ? {} : { ...row };

  return (
    <GridBox>
      <TableInfo theme={theme} count={count} actions={topActions} />

      <TableExt
        updateEntry={updateEntry}
        theme={theme}
        title="RFID"
        rows={{ count, items }}
        actions={actions}
        className={className}
        refresh={props.refresh}
        {...tableParams}
      />

      {/* {confirm && <Confirm {...confirm} />} */}

      {showForm === 'replaceForm' && <ReplaceWindow close={closeReplaceForm} />}
      {showForm === 'editForm' && (
        <RfidForm
          type={showForm}
          close={() => setShowForm('')}
          clientId={props.clientId}
          id={row.id}
          meanId={row.mean_id}
          meanType={row.mean_type}
          setMessage={setMessage}
          refresh={props.refresh}
          initialValues={initial}
          tariffs={props.tariffs}
        />
      )}

      {message && <GridUpdateMessage>{message}</GridUpdateMessage>}
    </GridBox>
  );
};
