import { FieldComponent } from 'libs/form';
import { ZoneInputBox } from '../../styledComponents';
import { FormWrapper, Header, Headers, Inputs, Interface, ParamsWrapper, Wrapper, ZoneLabel } from './TumenTariffication.styled';

export const TumenTariffication = (props) => {
  const { selectedZone, display, disabled } = props;

  /**
   * get params for zones
   * @returns
   */
  const getParams = () => {
    // "free_interval": 15,             # минуты
    // "minute_cost": 75,               # копейки
    // "start_paid_time": "09:00:00",
    // "end_paid_time": "09:00:00",

    const fields = [
      { name: 'free_interval', title: 'Бесплатный период, мин', type: 'number' },
      { name: 'minute_cost', title: 'Стоимость минуты, коп', type: 'number' },
      { name: 'start_paid_time', title: 'Начало платного времени', type: 'time' },
      { name: 'end_paid_time', title: 'Конец платного времени', type: 'time' },
    ];

    const fieldsEl = fields.map((el, idx) => {
      const other = { type: 'text', name: el.name, maxValueLength: 8 };
      if (el.type === 'number') {
        other.naturalNumber = true;
        other.withZero = true;
      }
      if (el.type === 'time') {
        other.type = el.type;
      }

      return (
        <Inputs key={idx}>
          <ZoneLabel>{el.title}</ZoneLabel>
          <ZoneInputBox wide>
            <FieldComponent disabled={disabled} {...other} />
            {/* <span>₽</span> */}
          </ZoneInputBox>
        </Inputs>
      );
    });

    return (
      <FormWrapper>
        <ParamsWrapper>{fieldsEl}</ParamsWrapper>
      </FormWrapper>
    );
  };

  return (
    <Interface color={selectedZone.color} display={display ? 'block' : 'none'}>
      <Wrapper>{getParams()}</Wrapper>
    </Interface>
  );
};

export default TumenTariffication;
