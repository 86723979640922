import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { flexRender } from '@tanstack/react-table';

import { DragHandle } from './drag-handle';
import { EmptyCell, Cell, Tr } from './table-row.styled';

export const TableRow = ({ row, ...rest }) => {
  //console.log('🚀 ~ TableRow ~ row:', row);
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
    transition: {
      duration: 50, // milliseconds
      easing: 'normal',
    },
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };
  const cells = row.getVisibleCells();

  // row params
  const other = { ...rest };
  if (other.rowClick) {
    other.onClick = (event) => {
      event.stopPropagation();
      rest.rowClick(row.original);
    };
    other.className = 'row-click';
    delete other.rowClick;
  }

  return (
    <Tr ref={setNodeRef} style={style} {...other}>
      {isDragging ? (
        <EmptyCell colSpan={cells.length}>&nbsp;</EmptyCell>
      ) : (
        cells.map((cell, i) => {
          const cellProps = {
            key: cell.id,
            style: {
              width: cell.column.getSize(),
            },
          };
          return (
            <Cell {...cellProps}>
              {i === 0 && <DragHandle {...attributes} {...listeners} />}
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Cell>
          );
        })
      )}
    </Tr>
  );
};
