import { Button, themes } from 'libs/ui';
import { ZoneSelect } from './zone-select';
import { TimeSelect } from './time-select';
import { WeekInput } from './week-input';

export const LimitByTimeRow = (props) => {
  const { id, row, onChange, onDel } = props;

  const change = (event) => {
    const name = event.target.name;
    let val = event.target.value;
    if (name === 'limit') {
      val = parseInt(val);
      if (val > 9999) {
        val = 9999;
      }
    }
    change2(name, val);
  };

  const change2 = (name, val) => {
    // console.log('🚀 ~ change2', name, val);
    onChange(id, name, val);
  };

  /**
   * focus
   * @param {*} event
   */
  const handleFocus = (event) => {
    event.target.select();
  };

  const del = () => {
    onDel(id);
  };

  return (
    <tr>
      <td>
        <ZoneSelect name="zone_id" value={row.zone_id} onChange={change} />
      </td>
      <td>
        <TimeSelect name="start_time" value={row.start_time} onChange={change2} />
      </td>
      <td>
        <TimeSelect name="end_time" value={row.end_time} onChange={change2} />
      </td>
      <td>
        <input min={0} max={9999} onFocus={handleFocus} type="number" name="limit" value={row.limit} onChange={change} />
      </td>
      <td></td>
      <WeekInput name="weekdays" value={row.weekdays} onChange={change2} />
      <td>
        {id > 0 && (
          <Button theme={themes.red} onClick={del}>
            Удалить
          </Button>
        )}
      </td>
    </tr>
  );
};
