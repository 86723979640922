import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Content = styled.div`
  display: flex;
  margin: 0 0 0px;
  align-items: center;
`;

export const SectionLink = styled(NavLink)`
  margin: 0 10px 0 0;

  cursor: pointer;
  height: 30px;
  padding: 0 15px;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  line-height: 0.8;
  border: none;
  outline: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5), inset 0 0px 30px 0 rgba(0, 0, 0, 0);
  transition: 0.3s all ease-out;
  font-size: 15px;
  display: flex;
  opacity: 1;
  color: #fff;
  background-color: #a2d4ff;
  opacity: 0.8;
  white-space: nowrap;
  &.active {
    opacity: 1;
    background-color: #3ea5ff;
  }
`;
