import React from 'react';

import { data } from 'services';
import { Cell, GridRow } from 'libs/ui';
import { getParking, getDateTime, getSource2 } from 'services/types';

const Row = (props) => {
  const { source, source_type, type, timestamp, message, parking_id } = props;
  const parking = getParking(parking_id);

  return (
    <GridRow onClick={() => props.setEventInfo({ ...props })}>
      {window.config.central && <Cell>{parking}</Cell>}
      <Cell>{getDateTime(timestamp)}</Cell>
      <Cell fullh>{message}</Cell>
      <Cell>{data.getDictValue('events.type', type)}</Cell>
      <Cell>{data.getDictValue('rack_type', source_type)}</Cell>
      <Cell>{getSource2(source)}</Cell>
    </GridRow>
  );
};

export default Row;
