import { Zone, ZoneIcon, ZoneInput, ZoneTitle } from './limit-by-zone.styled';

export const LimitByZone = (props) => {
  const { data, onChange, colors } = props;

  /**
   * change limit
   * @param {*} idx
   * @param {*} event
   */
  const change = (idx, event) => {
    const out = data.splice(0);
    let val = parseInt(event.target.value);
    if (val > 9999) {
      val = 9999;
    }
    out[idx].limit = val;
    onChange(out);
  };

  /**
   * focus
   * @param {*} event
   */
  const handleFocus = (event) => {
    event.target.select();
  };

  const getZonesEl = () => {
    return data.map((el, idx) => {
      const color = colors[el.zone_id];
      return (
        <Zone key={idx}>
          <ZoneIcon color={color} />
          <ZoneTitle>{el.zone_id}</ZoneTitle>
          <ZoneInput>
            <label htmlFor={idx}>Лимит мест</label>
            <input
              min={0}
              max={9999}
              onFocus={handleFocus}
              id={idx}
              type="number"
              name={idx}
              value={el.limit}
              onChange={(event) => change(idx, event)}
            />
          </ZoneInput>
        </Zone>
      );
    });
  };

  const zonesEl = getZonesEl();

  return <>{zonesEl}</>;
};
