import styled, { css } from 'styled-components';

export const Content = styled.div`
  text-align: right;
  padding: 20px;
`;

export const Button = styled.button`
  border: 0;
  background: white;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  margin-left: 12px;
  font-weight: bold;
  color: #ffffff;
  padding: 10px 21px;
  border-radius: 21px;
  transition: 0.2s all ease-in;
  color: #3ea5ff;
  &:hover {
    background: #eee;
  }
`;

export const PrimaryButton = styled(Button)`
  background: #3ea5ff;
  color: #fff;
  &:hover {
    background: #2e95ef;
  }
`;
