import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GridContainer, GridSetup } from 'libs/ui';
import { Headline } from 'modules/components/pageComponents';
import { Stay } from 'grids/stay/stay';

const StayWrapper = (props) => {
  const { t } = useTranslation();
  const page = 'stays';
  const [rerender, setRerender] = useState(1);

  return (
    <>
      <Headline $mb $alignLeft>
        {t('sections.Парковки')}
        <GridSetup page={page} onChange={() => setRerender((val) => val + 1)} $arrowLeft={'123px'} />
      </Headline>
      <GridContainer>
        <Stay {...props} rerender={rerender} />
      </GridContainer>
    </>
  );
};

export default StayWrapper;
