import { useField } from 'formik';
import PropTypes from 'prop-types';

import { Field } from '../field/field';

// https://react-dropzone.js.org/?ref=blog.horizon-ui.com

export const FileField = (props) => {
  const { useExternalOnUpdate, label, placeholder, onChange, ...other } = props;
  const [field, meta, helpers] = useField(other);
  const { value, ...fieldProps } = field;

  const placeholderOk = placeholder ?? label;
  const id = props.name;
  const cmp = (
    <input
      {...fieldProps}
      {...other}
      id={id}
      placeholder={placeholderOk}
      onChange={(event) => {
        if (!useExternalOnUpdate) {
          helpers.setValue(event.currentTarget.files[0]);
        }
        onChange && onChange(event.currentTarget.files[0], helpers);
      }}
      //value={''}
    />
  );

  const error = meta.touched && meta.error ? meta.error : '';
  const params = { label, name: props.name, type: props.type, cmp, error };
  return <Field {...params} />;
};

FileField.defaultProps = {
  type: 'file',
  useExternalOnUpdate: false,
};
FileField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  accept: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChnage: PropTypes.func,
  useExternalOnUpdate: PropTypes.bool,
};

export default FileField;
