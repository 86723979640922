// other themes 
export const themes = {
  blue: {
    color: '#fff',
    bgColor: '#3ea5ff'
  },
  gray: {
    color: '#fff',
    bgColor: '#6c7882'
  },
  red: {
    color: '#fff',
    bgColor: '#d14d45'
  },
  black: {
    color: '#fff',
    bgColor: '#262b31'
  },
  green: {
    color: '#fff',
    bgColor: '#36d60e'
  },
  darkGradient: {
    color: '#fff',
    bgColor: 'unset',
    bg: 'linear-gradient(to top,#262b31, #49525db3)',
    border: '#3ea5ff'
  }
}
