import styled from 'styled-components';

export const TariffManageWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
export const DataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #393e44;
  padding: 0;
`;
