import { useEffect } from 'react';
import { reduxForm } from 'redux-form';

import { ResetBtn, UseBtn } from 'libs/ui';
import { Container, Input, ParkingFilter, ButtonsContainer, DoubleField, DateSelect, DateTime, Select } from 'libs/form';

const initial = {};

const GreyPlateFilter = (props) => {
  const { setInitial, reset, handleSubmit, change, theme } = props;

  // did mount
  useEffect(() => {
    setInitial(initial);
    return () => {
      reset();
      setInitial(initial);
    };
  }, []);

  return (
    <Container theme={theme}>
      <form onSubmit={handleSubmit}>
        <Input width="130px" name="vehicle_plate" type="text" label="ГРЗ" placeholder="А000AA 000 rus" />

        <Input width="270px" name="reason" type="text" label="Причина внесения" placeholder="Введите текст для поиска" />

        <DateSelect from_name="start_at_from" to_name="start_at_to" change={change} theme={theme}>
          <DoubleField>
            <DateTime width="160px" name="start_at_from" type="dateTime" label="Дата действия с" theme={theme} />
            <DateTime width="160px" name="start_at_to" type="dateTime" theme={theme} />
          </DoubleField>
        </DateSelect>

        <DateSelect from_name="end_at_from" to_name="end_at_to" change={change} theme={theme}>
          <DoubleField>
            <DateTime width="160px" name="end_at_from" type="dateTime" label="Дата действия по" theme={theme} />
            <DateTime width="160px" name="end_at_to" type="dateTime" theme={theme} />
          </DoubleField>
        </DateSelect>

        <ParkingFilter />
        <ButtonsContainer>
          <ResetBtn onClick={reset} theme={theme} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </Container>
  );
};

export default reduxForm({
  form: 'GreyPlateFilter',
  destroyOnUnmount: false,
  initialValues: initial,
})(GreyPlateFilter);
