import styled from 'styled-components';

export const Content = styled.div`
  padding: 20px 20px 10px;

  &.loading {
    position: relative;
    min-height: 38px;
  }
  &.loading:after {
    content: '';
    position: absolute;
    left: -5px;
    top: -5px;
    right: -5px;
    bottom: -5px;
    z-index: 999;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.75) url(/images/loader.svg) no-repeat 50%;
  }
`;
