import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;
export const Popup = styled.div`
  max-height: 230px;
  overflow-y: auto;
  position: absolute;
  top: 38px;
  width: 100%;
  background-color: #fff;
  border: solid 1px #bebebe;
  border-radius: 4px;
  z-index: 11;
  ul {
    li {
      padding: 5px;
      list-style: none;
      cursor: pointer;
      :hover {
        background-color: #3ea5ff;
        color: #fff;
      }
    }
  }
`;
