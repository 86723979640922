import { api } from 'api';

const ADD_ITEMS = '/pages/options/collection/ADD_ITEMS';
const CLEAR_ENTRIES = '/pages/options/collection/CLEAR_ENTRIES';
const SET_OFFSET = '/page/options/collection/SET_OFFSET';
const SET_SORT_PARAMS = '/pages/options/collection/SET_SORT_PARAMS';
const SET_ITEM_INFO = '/pages/options/collection/SET_ITEM_INFO';

export default function reducer(state, action) {

    const defaultState = {
        items: {},
        offset: 0,
        itemInfo: null,
        sortParams: [{ field_name: 'role_id', direction: 'desc' }]
    }

    if (!state) {
        state = defaultState
    }

    switch (action.type) {
        case CLEAR_ENTRIES:
            return {
                ...state,
                items: {}
            }
        case ADD_ITEMS:
            return {
                ...state,
                items: {
                    ...state.items,
                    ...action.data
                }
            }            
        case SET_OFFSET:
            return {
                ...state,
                offset: action.data
            }
        case SET_SORT_PARAMS:
            return {
                ...state,
                sortParams: action.data
            }
        case SET_ITEM_INFO:
            return {
                ...state,
                itemInfo: action.data
            }
        default:
            return state
    }
}

export const clearEntries = () => {
    return {
        type: CLEAR_ENTRIES
    }
}

export const setOffset = (value) => {
    return {
        type: SET_OFFSET,
        data: value
    }
}
export const setSortParams = (value) => {
    return {
        type: SET_SORT_PARAMS,
        data: value
    }
}
export const setItemInfo = (value) => {
    return {
        type: SET_ITEM_INFO,
        data: value
    }
}


export const getRequestParams = () => (dispatch, getState) => {
    return {}
}

export const addItems = (items) => {
    return {
        type: ADD_ITEMS,
        data: items
    }
}

export const addItem = (params, callback) => (dispatch) => {
    return api.call('collection.add', params).then(data => {
        if (callback) {
            callback();
        }
    });
}

export const updateItem = (id, params, callback) => (dispatch) => {
    return api.call('collection.update', {id: id, name: params.name, is_active: params.is_active}).then(data => {
        if (callback) {
            callback();
        }
    });
}

export const deleteItem = (id, callback) => (dispatch) => {
    return api.call('collection.delete', {id: id}).then(data => {
        if (callback) {
            callback();
        }
    });
}