import styled, { css } from 'styled-components';

export const TableRow = styled.tr`
  cursor: pointer;

  &:nth-child(2n) {
    background-color: #fafafa;
  }
  &:hover {
    opacity: 1;
    background-color: #e9eef2;
  }
  th,
  td {
    &:first-child,
    &.pad-left {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
    a {
      color: #333;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  ${(props) =>
    !props.onClick &&
    css`
      cursor: default;
    `}
`;
export const TableHeadRow = styled(TableRow)`
  color: #ffffff;
  &:hover {
    background-color: inherit;
    cursor: default;
  }
`;

export const GridRow = styled(TableRow)`
  box-shadow: ${(props) => (props.$is_suspicious ? 'inset 5px 0 #d33d34' : '')};
  td {
    color: ${(props) => {
      if (props.$is_suspicious) return '#d33d34';
      if (props.status === 'active') return '#000';
      if (props.status === 'blocked' || props.status === 'archive' || props.status === 'blocked_by_client') return 'rgba(0, 0, 0, 0.4)';
    }};
  }
  .td-icon {
    max-width: 18px;
  }
  .td-icon--big {
    margin-bottom: -3px;
  }
  .td-time {
    width: 180px;
  }
`;
