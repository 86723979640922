import React, { Component } from 'react';
import moment from 'moment';

import { Button, ButtonContainer } from 'libs/ui';
import { CommentsContent, Header, CommentsBlock, Comment } from './styledComponents';
import { api } from 'api';

export default class Comments extends Component {
  state = {
    items: null,
  };

  componentDidMount() {
    api.call('comment.list', { filter_params: { payment_id: this.props.id } }).then((data) => {
      //console.log('🚀 ~ api ~ data', data);
      this.setState({ items: data.items });
    });
  }

  getItems = () => {
    const { items } = this.state;
    return items.map((el, idx) => {
      //дата время автор текст
      const d = moment(el.created_at).format('lll');
      return (
        <Comment key={idx}>
          <td>{d}</td>
          <td>{el.user_name}</td>
          <td title={el.comment}><div>{el.comment}</div></td>
        </Comment>
      );
    });
  };

  close = () => {
    this.props.removeFormInfo(this.props.type);
  };

  render() {
    const { items } = this.state;
    if (!items) {
      return null;
    }

    const cancelClick = (event) => {
      event.stopPropagation();
    };

    const itemsEl = items.length ? this.getItems() : null;
    return (
      <CommentsContent onClick={cancelClick}>
        <Header>Комментарии</Header>
        <CommentsBlock>
          {items.length > 0 ? (
            <table width="100%">
              <thead>
                <tr>
                  <th width="155">Дата</th>
                  <th width="120">Автор</th>
                  <th>Текст</th>
                </tr>
              </thead>
              <tbody>{itemsEl}</tbody>
            </table>
          ) : (
            <p>Комментарии отсутствуют</p>
          )}
        </CommentsBlock>
        <ButtonContainer space="20px" justifyContent="flex-end">
          <Button onClick={this.close}>Ок</Button>
        </ButtonContainer>
      </CommentsContent>
    );
  }
}
