import React from 'react';
import { useTranslation } from 'react-i18next';

import { Headline, PageWrapper } from 'modules/components/pageComponents';

const Empty = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <Headline>
        <p>{t('errors.noComponents')}</p>
      </Headline>
    </PageWrapper>
  );
};

export default Empty;
