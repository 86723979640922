import styled, { css } from 'styled-components';

export const Content = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  label {
    margin: 0 10px 0 0;
    font-size: 16px;
    line-height: 1;
    color: #3e3e3e;
    cursor: pointer;
  }
  .label2 {
    cursor: pointer;
    margin: 0 0 0 10px;
    font-size: 16px;
    line-height: 1;
    color: #3e3e3e;
  }

  .toggle {
    position: relative;
    height: 20px;
    width: 38px;
    margin: 0;
    span {
      cursor: pointer;
      top: 1px;
      left: 1px;
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      background-color: #fff;
      width: 18px;
      height: 18px;
      transition: 0.2s;
    }
    input {
      height: inherit;
      position: relative;
      cursor: pointer;
      appearance: none;
      border: 0;
      border-radius: 20px;
      outline: none;
      background-color: #d0d2d6;

      font-size: 15px;
      color: rgb(0, 0, 0);
      width: 100%;
      padding: 11px 10px 9px;

      &:checked {
        background-color: #3ea5ff;
      }
      &:checked + span {
        left: 18px;
      }
    }
  }

  ${(props) =>
    props.theme === 'two' &&
    css`
      label,
      .label2 {
        font-size: 13px;
        color: #999;
      }
      .toggle {
        input {
          background-color: #3ea5ff;
          &:checked {
            background-color: #3ea5ff;
          }
        }
      }
    `}
`;
