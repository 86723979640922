import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { data } from 'services';
import { routes } from 'router';
import { getAmount } from 'libs/common';
import { GridRow, ImgViewer, Cell, OptionsCell } from 'libs/ui';
import { getParking, getDateTime } from 'services/types';
import { ClosePicturesButton, ImageWrap, PictureRow } from './Row.styled';

class Operation extends Component {
  state = { showPhotos: false };

  showCard = () => {
    this.props.history.push(`${routes.stay}?id=${this.props.stay_id}`);
  };

  togglePhotos = () => {
    this.setState({ showPhotos: !this.state.showPhotos });
  };

  getAppNumber = () => {
    return this.props.apps[this.props.payment_terminal] || data.getRacksMap()[this.props.payment_terminal] || this.props.payment_terminal;
  };

  getPhoto = (photo) => {
    if (this.props.data && this.props.data.photos) {
      return this.props.data.photos[photo];
    }
  };

  render() {
    const actions = [];
    if (this.props.stay_id) {
      actions.push({ text: 'Перейти в карточку парковки', func: this.showCard });
    }
    // actions.push({ text: this.state.showPhotos ? 'Скрыть фото' : 'Показать фото', func: this.togglePhotos });
    const parking = getParking(this.props.parking_id);

    return (
      <>
        <GridRow onClick={this.togglePhotos}>
          {window.config.central && <Cell>{parking}</Cell>}
          <Cell>{getDateTime(this.props.timestamp)}</Cell>
          <Cell>{data.getDictValue('payment.payment_type', this.props.payment_type)}</Cell>
          <Cell>{data.getDictValue('payment.success', this.props.success)}</Cell>
          <Cell number>{getAmount(this.props.amount)}</Cell>
          <Cell number>{getAmount(this.props.amount_in)}</Cell>
          <Cell number>{getAmount(this.props.amount_out)}</Cell>
          <Cell number>{getAmount(this.props.shortage)}</Cell>
          <Cell>{data.getDictValue('payment.mean_type', this.props.mean_type)}</Cell>
          <Cell>{this.props.mean_number}</Cell>
          <Cell>{this.getAppNumber()}</Cell>
          <Cell number>{this.props.check_number}</Cell>
          <Cell number>{this.props.shift}</Cell>
          <OptionsCell options={actions} left={-61} />
        </GridRow>
        {this.state.showPhotos ? (
          <PictureRow>
            <ImageWrap>
              <ImgViewer images={[this.getPhoto('photo_1')]} width={'432px'} height={'300px'} />
              <ImgViewer images={[this.props.photo]} width={'432px'} height={'300px'} />
              <ImgViewer images={[this.getPhoto('photo_3')]} width={'432px'} height={'300px'} />
              <ClosePicturesButton
                onClick={() => {
                  this.setState({ showPhotos: !this.state.showPhotos });
                }}
              >
                <i className="fa fa-arrow-up"></i> Скрыть
              </ClosePicturesButton>
            </ImageWrap>
          </PictureRow>
        ) : null}
      </>
    );
  }
}

export default withRouter(Operation);
