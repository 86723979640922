import moment from 'moment';

const CLEAR_ENTRIES = '/pages/reports/working/CLEAR_ENTRIES'
const ADD_ENTRIES = '/pages/reports/working/ADD_RIDES'
const SET_ENTRIES_COUNT = '/pages/reports/working/SET_RIDES_COUNT'
const SET_OFFSET = '/page/reports/working/SET_OFFSET'
const SET_SORT_PARAMS = '/pages/reports/working/SET_SORT_PARAMS'
const RESET_MODEL = '/pages/reports/working/RESET_MODEL'


export default function reducer(state, action) {

    const defaultState = {
        items: {},
        offset: 0,
        count: 0,
        sortParams: [{ field_name: 'timestamp', direction: 'desc' }]
    }

    if (!state) {
        state = defaultState
    }

    switch (action.type) {
        case RESET_MODEL:
            return {
                ...defaultState
            }
        case CLEAR_ENTRIES:
            return {
                ...state,
                items: {}
            }
        case ADD_ENTRIES:
            return {
                ...state,
                items: {
                    ...state.items,
                    ...action.data
                }
            }
        case SET_ENTRIES_COUNT:
            return {
                ...state,
                count: action.data
            }
        case SET_OFFSET:
            return {
                ...state,
                offset: action.data
            }
        case SET_SORT_PARAMS:
            return {
                ...state,
                sortParams: action.data
            }
        default:
            return state
    }
}

export const reset = () => {
    return {
        type: RESET_MODEL
    }
}
export const clearEntries = () => {
    return {
        type: CLEAR_ENTRIES
    }
}
export const setCount = (value) => {
    return {
        type: SET_ENTRIES_COUNT,
        data: value
    }
}
export const addEntries = (items) => {
    return {
        type: ADD_ENTRIES,
        data: items
    }
}
export const setOffset = (value) => {
    return {
        type: SET_OFFSET,
        data: value
    }
}
export const setSortParams = (value) => {
    return {
        type: SET_SORT_PARAMS,
        data: value
    }
}

export const getRequestParams = (flt) => (dispatch, getState) => {
    
    const out = {};
    if (flt) {
        Object.keys(flt).forEach(key => {
            let val = flt[key];
            if (key === 'type') {
                //console.log('11', val);
                if (typeof val === 'boolean') {
                    val = val ? 'months' : 'days';
                } else {
                    val = val === 'days' ? 'days' : 'months';
                }
            }
            if (key === 'date_from') {
                if (!val) {
                    val = '2019-01-01T00:00:00.716Z'
                }
            }
            if (key === 'date_to') {
                if (!val) {
                    val = moment().endOf('day').format()
                }
            }
            out[key] = val;
        });
    }
    return out;
}