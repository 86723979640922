import styled, { css } from 'styled-components';

export const Content = styled.div`
  color: white;
  font-size: 18px;
  margin-bottom: 6px;
  line-height: 1.11;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.final &&
    css`
      color: #3ea5ff;
      margin-top: 35px;
      margin-bottom: 0;
      font-weight: bold;
    `}
`;

export const TitleVal = styled.div`
  display: inline-block;
  &:after {
    position: absolute;
    transition: .1s all ease-out;
    content: ':';
  }
  ${(props) =>
    props.open &&
    css`
      &:after {
        transform: rotate(0deg) !important;
      }
    `}
`;

export const Title = styled.div`
  width: 230px;
  font-weight: normal;
  position: relative;
  &.link ${TitleVal} {
    cursor: pointer;
    border-bottom: 1px dashed #999;
    &:after {
      top: 4px;
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url('/images/up.svg') no-repeat 0 0;
      background-size: contain;
      margin-left: 5px;
      transform: rotate(180deg);
    }
  }
`;

export const Value = styled.div`
  display: inline-block;
  text-align: right;
  width: 140px;
`;

export const Collapse = styled.div`
  margin: 10px 0 20px;
`;
