import { api } from 'api';

const UPDATE_STAY = '/pages/parkings/stays/UPDATE_STAY';
const CLEAR_ENTRIES = '/pages/parkings/stays/CLEAR_ENTRIES';
const ADD_STAYS = '/pages/parkings/stays/ADD_STAYS';
const SET_STAYS_COUNT = '/pages/parkings/stays/SET_STAYS_COUNT';
const SET_OFFSET = '/page/parkings/stays/SET_OFFSET';
const SET_STAY_INFO = '/pages/parkings/stays/SET_STAY_INFO';
const REMOVE_STAY_INFO = '/pages/parkings/stays/REMOVE_STAY_INFO';
const SET_SORT_PARAMS = '/pages/parkings/stays/SET_SORT_PARAMS';
const SET_SUSPICIOUS = '/pages/parkings/stays/SET_SUSPICIOUS';
const SET_HANDICAPED = '/pages/parkings/stays/SET_HANDICAPED';
const RESET = '/pages/parkings/stays/RESET';

export default function reducer(state, action) {
  const defaultState = {
    stays: {},
    stay: null,
    offset: 0,
    count: 0,
    sortParams: [{ field_name: 'entry_at', direction: 'desc' }],
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case UPDATE_STAY:
      return {
        ...state,
        stays: {
          ...state.stays,
          [action.id]: {
            ...state.stays[action.id],
            [action.fieldName]: action.value,
          },
        },
      };
    case CLEAR_ENTRIES:
      return {
        ...state,
        stays: {},
      };
    case SET_STAYS_COUNT:
      return {
        ...state,
        count: action.data,
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    case ADD_STAYS:
      return {
        ...state,
        stays: {
          ...state.stays,
          ...action.data,
        },
      };
    case SET_STAY_INFO:
      return {
        ...state,
        stay: action.data,
      };
    case REMOVE_STAY_INFO:
      return {
        ...state,
        stay: null,
      };
    case SET_SUSPICIOUS:
      return {
        ...state,
        stay: {
          ...state.stay,
          is_suspicious: action.data,
        },
      };
    case SET_HANDICAPED:
      return {
        ...state,
        stay: {
          ...state.stay,
          is_handicapped: action.data,
          amount: action.amount,
        },
      };
    case RESET:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
}
export const reset = () => {
  return {
    type: RESET,
  };
};
export const updateEntries = (fieldName, id, value) => {
  return {
    type: UPDATE_STAY,
    fieldName: fieldName,
    id: id,
    value: value,
  };
};
export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const setCount = (value) => {
  return {
    type: SET_STAYS_COUNT,
    data: value,
  };
};
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};
export const addStays = (stays) => {
  return {
    type: ADD_STAYS,
    data: stays,
  };
};
export const setStayInfo = (info) => {
  return {
    type: SET_STAY_INFO,
    data: info,
  };
};
export const removeStayInfo = () => {
  return {
    type: REMOVE_STAY_INFO,
  };
};
export const setSuspicious = (data) => {
  return {
    type: SET_SUSPICIOUS,
    data: data,
  };
};
export const setHandicaped = (data, amount) => {
  return {
    type: SET_HANDICAPED,
    data: data,
    amount: amount,
  };
};

/**
 * get duration
 * @param {*} value
 * @returns
 */
const getFormatDurationValue = (value) => {
  const days = value.match(/..(?=д)/)[0].replace(/_/g, 0);
  const hours = value.match(/..(?=ч)/)[0].replace(/_/g, 0);
  const minutes = value.match(/..(?=м)/)[0].replace(/_/g, 0);

  return days * 86400 + hours * 3600 + minutes * 60;
};

/**
 * prepare req params
 * @param {*} filter
 * @returns
 */
export const getRequestParams = (filter) => () => {
  const decorateParams = {};
  for (let key in filter) {
    if (filter[key] || filter[key] === 0) {
      switch (key) {
        case 'duration_from':
          decorateParams[key] = getFormatDurationValue(filter[key]);
          break;
        case 'duration_to':
          decorateParams[key] = getFormatDurationValue(filter[key]);
          break;
        case 'amount_from':
          decorateParams[key] = filter[key] * 100;
          break;
        case 'amount_to':
          decorateParams[key] = filter[key] * 100;
          break;
        case 'is_active':
          if (filter[key] === 'true') decorateParams[key] = true;
          if (filter[key] === 'false') decorateParams[key] = false;
          break;
        default:
          decorateParams[key] = filter[key];
      }
    }
  }
  return decorateParams;
};

/**
 * get stay info
 * @param {*} id
 * @returns
 */
export const getStayInfo = (id) => (dispatch) => {
  return api.call('stays.view', {
    id: id,
  }).then((data) => {
    dispatch(setStayInfo(data));
  });
};

/**
 * change mean status
 * @param {*} id
 * @param {*} type
 * @param {*} newStatus
 * @param {*} stayId
 * @returns
 */
export const changeMeanStatus = (id, type, newStatus, stayId) => (dispatch) => {
  return api.call('stays.change_mean_status', {
    mean_type: type,
    mean_id: id,
    status: newStatus,
  }).then(() => {
    dispatch(getStayInfo(stayId));
  });
};

/**
 * change suspicious
 * @param {*} id
 * @param {*} isSuspicious
 * @returns
 */
export const changeSuspicious = (id, isSuspicious) => (dispatch) => {
  return api.call('stays.change_suspicious', {
    id: id,
    suspicious: isSuspicious,
  }).then((data) => {
    dispatch(getStayInfo(id));
    dispatch(updateEntries('is_suspicious', data.id, data.is_suspicious));
  });
};

/**
 * change handicapped
 * @param {*} id
 * @param {*} number
 * @returns
 */
export const changeHandicapped = (id, number) => (dispatch) => {
  return api.call('stays.set_exemption', {
    stay_id: id,
    exemption_document: number,
    // return api.call('stays.set_handicapped', {
    //   id: id,
    //   handicapped_permit_number: number,
  }).then(() => {
    dispatch(getStayInfo(id));
  });
};

/**
 * print new ticket
 * @param {*} stay_id
 * @param {*} payment_rack_id
 * @param {*} penalty
 * @param {*} comment
 * @param {*} callback
 * @param {*} parking_id
 * @returns
 */
export const printNewTicket =
  (stay_id, payment_rack_id, penalty, comment = '', callback = null, parking_id = '') =>
  (__dispatch) => {
    const params = {
      stay_id: typeof stay_id === 'string' ? stay_id : null,
      payment_rack_id: payment_rack_id,
      penalty: penalty,
      comment: penalty ? comment : '',
    };

    return api.call('stays.print_receipt', params, 'NetworkManager', parking_id ? '/' + parking_id : '').then((data) => {
      window.location = '/card/stay?id=' + data.id;
      if (callback) {
        callback();
      }
    });
  };

/**
 * set tariff
 * @param {*} stay_id
 * @param {*} tariff_id
 * @param {*} onSuccess
 * @returns
 */
export const setTariff = (stay_id, tariff_id, onSuccess) => (dispatch) => {
  return api.call('stays.set_tariff', {
    stay_id: stay_id,
    tariff_id: tariff_id,
  }).then(() => {
    dispatch(getStayInfo(stay_id));
    onSuccess && onSuccess();
  });
};

/**
 * close parking
 * @param {*} stay_id
 * @param {*} onSuccess
 * @returns
 */
export const closeParking = (stay_id, onSuccess) => (__dispatch) => {
  return api.call('stays.manual_close', {
    stay_id: stay_id,
  }).then(() => {
    onSuccess && onSuccess();
  });
};
