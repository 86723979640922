import styled, { css } from 'styled-components';

export const UploadInfo = styled.p`
  opacity: 0.4;
  font-size: 15px;
  line-height: 1.33;
  color: #000;
`;

export const GridContainer = styled.div`
  width: 100%;
  min-height: 100px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(85, 85, 85, 0.2);
`;
export const GridBox = styled.div`
  padding-top: 20px;
  padding-bottom: 1px;
  position: relative;
  bottom: -2px;
`;
export const TotalBox = styled.div`
  display: flex;
  align-items: center;
  & > p {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
export const TopRow = styled.div`
  margin-bottom: 20px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
`;

export const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  & > * {
    margin-left: 15px;
  }
`;

export const Total = styled.p`
  font-size: 13px;
  font-weight: bold;
  color: #1c1c1c;
  span {
    font-size: 15px;
  }
  ${(props) =>
    props.white &&
    css`
      color: #fff;
    `}
`;
export const Grid = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  text-align: left;
`;
export const TableHead = styled.thead``;
export const TableRow = styled.tr`
  cursor: pointer;

  &:nth-child(2n) {
    background-color: #fafafa;
  }
  &:hover {
    opacity: 1;
    background-color: #e9eef2;
  }
  th,
  td {
    &:first-child,
    &.pad-left {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
  ${(props) =>
    !props.onClick &&
    css`
      cursor: default;
    `}
`;
export const TableHeadRow = styled(TableRow)`
  &:hover {
    background-color: inherit;
    cursor: default;
  }
`;

export const GridRow = styled(TableRow)`
  box-shadow: ${(props) => (props.is_suspicious ? 'inset 5px 0 #d33d34' : '')};
  td {
    color: ${(props) => {
      if (props.is_suspicious) return '#d33d34';
      if (props.status === 'active') return '#000';
      if (props.status === 'blocked' || props.status === 'archive' || props.status === 'blocked_by_client') return 'rgba(0, 0, 0, 0.4)';
    }};
  }
  .td-icon {
    max-width: 18px;
  }
  .td-icon--big {
    margin-bottom: -3px;
  }
  .td-time {
    width: 180px;
  }
`;

export const CommentBox = styled.div`
  height: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 0;
`;
export const ZoneIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  line-height: 32px;
  border: 2px solid #fff;
  font-size: 13px;
  color: white;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;
export const LongZoneIcon = styled.div`
  width: 30px;
  height: 70px;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  p {
    font-size: 13px !important;
    color: white !important;
  }
`;

export const OptionsPopup = styled.div`
  position: absolute;
  top: 35px;
  width: 220px;
  height: 125px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #f2f2f2), linear-gradient(#ffffff, #ffffff);
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.5);
  z-index: 999;
  border-radius: 5px;
  right: -62px;
  p {
    top: -10px;
    right: 90px;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid white;
  }
`;
export const Option = styled.div`
  height: 40px;
  font-size: 13px;
  padding: 15px 0 0 15px;
  color: #000;
`;
export const OptionsSeparator = styled.div`
  height: 1px;
  opacity: 0.6;
  background-color: rgba(85, 85, 85, 0.2);
`;
