import { Button, themes, withPopup } from 'libs/ui';

export const AddClientAction = withPopup((props) => {
  return (
    <Button onClick={props.popupDisplayHandler} theme={themes.blue}>
      <img src="/images/shape.png" alt="" />
      Создать клиента
    </Button>
  );
});
