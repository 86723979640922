import { user, data } from 'services';
import { routeList } from './section-list';
import { accessLevelMap } from './access-level-map';

/**
 * get sections with right and interface config support
 */
export const getAvailableSections = () => {
  // filled ?
  const sections = data.getSections();
  if (sections) {
    return sections;
  }

  // fill
  const permissions = user.getPermissions();
  if (!permissions) {
    return;
  }
  const tmp = accessLevelMap.getSectionsByPermissions(permissions);
  const out = tmp.map((section) => {
    return routeList.find((route) => {
      return section.id === route.id;
    });
  });

  data.setSections(out);
  return out;
};

/**
 * find default page for user
 */
export const getDefaultPage = () => {
  const sections = getAvailableSections();
  let result = sections.find((el) => {
    return el.id === 'stays';
  });

  // if no stays - find first
  if (!result) {
    result = sections.length ? sections[0] : { path: '/empty' };
  }

  return result.path;
};

export const getSections = () => {
  const sections = getAvailableSections();
  return sections.filter((section) => {
    return section.type === 'section';
  });
};

export const getSubsectionList = (section) => {
  const availableSections = accessLevelMap.getSectionsByPermissions(user.getPermissions());
  const availableSection = availableSections.find((availableSection) => {
    return availableSection.id === section.id;
  });
  const availableSubsections = availableSection.subsectionIds;

  return availableSubsections.map((availableSubsection) => {
    return section.subsections.find((subsection) => {
      return subsection.id === availableSubsection;
    });
  });
};
