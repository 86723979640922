import { config } from 'config';
import { notify, LocalStorage } from 'libs/common';

import { Transport } from './transport';
import { PacketJSONRPC } from './packet-jsonrpc';
import { Logic } from './logic';
import { getRpcError } from './func';

const packet = new PacketJSONRPC({ getErrorMessage: getRpcError });
const logic = new Logic({
  notify,
  storage: new LocalStorage('api'),
  packet,
  loginMethod: 'user.login',
  debug: false,
  getError: getRpcError,
  unsetAuth: () => {
    window.location = '/';
  },
});
export const api = new Transport({ apiUrl: config.network.api_url, timeout: config.network.timeout, logic });
