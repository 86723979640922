import styled, { css } from 'styled-components';

export const Indicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.height &&
    css`
      position: static;
      height: ${(props) => props.height};
      background: #fff;
      padding: 20px 0;
      ${Indicator} {
        margin-top: 20px;
      }
    `}
  ${(props) =>
    props.$absolute &&
    css`
      position: absolute;
      z-index: 2;
      box-sizing: border-box;
      height: 110px;
      padding: 20px 0;
      top: 46%;
      left: 42%;
      ${Indicator} {
        margin-top: 20px;
      }
    `}
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 2px;
  border-radius: 50%;
  background-color: #979797;
  transition: all 5s ease;
  animation: imganim 1s infinite linear both;
  @keyframes imganim {
    0% {
      width: 5px;
      height: 5px;
    }
    50% {
      width: 12px;
      height: 12px;
    }
    100% {
      width: 5px;
      height: 5px;
    }
  }
  ${(props) =>
    props.size === 'medium' &&
    css`
      width: 30px;
      height: 30px;
      margin: 0 10px;

      @keyframes imganim {
        0% {
          width: 10px;
          height: 10px;
        }
        50% {
          width: 20px;
          height: 20px;
        }
        100% {
          width: 10px;
          height: 10px;
        }
      }
    `}
  animation-delay: ${(props) => props.$delay};
`;
