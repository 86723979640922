import React from 'react';
import { useTranslation } from 'react-i18next';

import { themes } from './theme';
import { Button } from './button';

export const Submit = ({ children, disabled, className, invisible }) => {
  const { t } = useTranslation();
  if (invisible) {
    return null;
  }
  return (
    <Button fontSize="16px" disabled={disabled} className={className}>
      <i className="fa fa-save" />
      {children ? children : t('grid.save')}
    </Button>
  );
};

export const CloseCardButton = ({ children, close }) => {
  const { t } = useTranslation();
  return (
    <Button theme={themes.black} fontSize="16px" onClick={close}>
      <img src="/images/cross_icon_white.png" alt="" />
      {children ? children : t('grid.closecard')}
    </Button>
  );
};

export const GetXlsButton = ({ children, getXls }) => {
  const { t } = useTranslation();
  return (
    <Button theme={themes.blue} onClick={getXls}>
      <img src="/images/export-excel-icon.svg" alt="" style={{ width: '22px', height: '22px' }} />
      {children ? children : t('grid.getxls')}
    </Button>
  );
};

export const OpenFileMaganerButton = ({ children, openFileManager }) => {
  //const { t } = useTranslation();
  return (
    <Button theme={themes.blue} onClick={openFileManager}>
      <img src="/images/document_icon.png" alt="" />
      {children}
    </Button>
  );
};
