import { useState, useMemo } from 'react';
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { TableRow } from './table-row';
import { TableRowStatic } from './table-row-static';

export const TableReact = (props) => {
  const columnResizeMode = 'onChange';
  const { columns, data, setData, rowClick } = props;
  const [activeId, setActiveId] = useState();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableColumnResizing: true,
    columnResizeMode,
  });
  const rows = table.getRowModel().rows;
  const items = useMemo(() => data?.map(({ id }) => id), [data]);
  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  /**
   * drag start
   * @param {*} event
   */
  const handleDragStart = (event) => {
    // console.log('🚀 ~ handleDragStart ~ event:', event);
    setActiveId(event.active.id);
  };

  /**
   * drag end
   * @param {*} event
   * @returns
   */
  const handleDragEnd = (event) => {
    // console.log('🚀 ~ drag end', activeId, event);
    const { active, over } = event;
    if (active.id !== over.id) {
      setActiveId(null);
      setData((data) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const out = arrayMove(data, oldIndex, newIndex);
        //console.log('🚀 ~ setData ~ out:', data, oldIndex, newIndex, out);
        return out;
      });

      return;
    }
    setActiveId(null);
  };

  /**
   * cancel drag
   */
  const handleDragCancel = () => {
    //console.log('🚀 ~ cancel drag', activeId);
    setActiveId(null);
  };

  /**
   * selected row
   */
  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rows.find((row) => row.original.id === activeId);
    return row;
  }, [activeId, rows]);


  // row params
  const rowParams = { rowClick};
  
  // render
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    width: header.getSize(),
                  }}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  <div
                    {...{
                      onMouseDown: header.getResizeHandler(),
                      onTouchStart: header.getResizeHandler(),
                      className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                      style: {
                        transform:
                          columnResizeMode === 'onEnd' && header.column.getIsResizing()
                            ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)`
                            : '',
                      },
                    }}
                  />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {rows.map((row) => {
              return <TableRow key={row.id} row={row} {...rowParams} />;
            })}
          </SortableContext>
        </tbody>
      </table>
      <DragOverlay>
        {activeId && (
          <table style={{ width: '100%' }}>
            <tbody>
              <TableRowStatic row={selectedRow} />
            </tbody>
          </table>
        )}
      </DragOverlay>
    </DndContext>
  );
};
