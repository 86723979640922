import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCategories, getVehicleClasses } from 'services/types';
import { prepareFilter } from 'libs/common';
import { getRequestParams, addTariffs, clearEntries, setSortParams, setOffset, setCount } from 'store/ducks/models/tariffs';
import { setScroll } from 'store/ducks/interface/tariffsGrid';
import { GridContainer } from 'libs/ui';
import TariffsFilter from './Filter';
import TariffsGrid from './Grid';
import { Controller } from 'libs/control';

class Tariffs extends Component {
  /**
   * prepare params
   * @param {*} data
   * @returns
   */
  prepareParams = (data) => {
    return prepareFilter(this.props.getRequestParams(data));
  };

  render() {
    const categories = getCategories();
    const vehicleClasses = getVehicleClasses();

    return (
      <GridContainer>
        <Controller
          type="tariff"
          gridFilter={TariffsFilter}
          grid={TariffsGrid}
          vehicleClasses={vehicleClasses}
          categories={categories}
          entries={this.props.tariffs}
          offset={this.props.offset}
          interface={this.props.interface}
          count={this.props.count}
          sortParams={this.props.sortParams}
          //methods
          getRequestParams={this.prepareParams}
          addEntries={this.props.addTariffs}
          clearEntries={this.props.clearEntries}
          setOffset={this.props.setOffset}
          setSortParams={this.props.setSortParams}
          setCount={this.props.setCount}
          setScroll={this.props.setScroll}
        />
      </GridContainer>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    tariffs: store.tariffs.tariffs,
    offset: store.tariffs.offset,
    count: store.tariffs.count,
    sortParams: store.tariffs.sortParams,
    interface: store.tariffsGrid,
  };
};

export default connect(mapStateToProps, {
  getRequestParams,
  addTariffs,
  clearEntries,
  setScroll,
  setSortParams,
  setOffset,
  setCount,
})(Tariffs);
