import PropTypes from 'prop-types';

import { Content, Info } from './error-msg.styled';

export const ErrorMsg = (props) => {
  const { error, message, ...rest } = props;
  if (!error && !message) {
    return null;
  }

  if (message === 'Отсутсвует пароль') {
    return null;
  }

  const getErrorMessage = () => {
    switch (error) {
      case 'Item not found':
        return 'Элемент не найден';
      case 'duplicate':
        return 'СО уже существует';
      default:
        return 'Ошибка сохранения';
    }
  };

  const msg = message ? message : getErrorMessage();

  return (
    <Content {...rest}>
      <img src="/images/error-triangle-icon.png" width={22} height={20} alt="" />
      <Info>{msg}</Info>
    </Content>
  );
};

ErrorMsg.propTypes = {
  theme: PropTypes.string,
  error: PropTypes.string,
  message: PropTypes.string,
};
