import React from 'react';

import { Select } from './fields';

export const Select2 = (props) => {
  const { items, ...rest } = props;

  const itemsEl = items.map((el, idx) => {
    return (
      <option key={idx} value={el.value}>
        {el.name}
      </option>
    );
  });

  return (
    <Select type="select" {...rest}>
      <option value="">Все</option>
      {itemsEl}
    </Select>
  );
};

Select2.defaultProps = {
  width: '140px',
  label: 'Выберите',
  black: false,
};
