import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 25px 0 0 18px;
  width: 100%;
  height: 100%;
`;
export const Interface = styled.div`
  position: relative;
  display: ${(props) => props.display};
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${(props) => props.color};
  height: 500px;
`;
export const FormWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  padding-right: 18px;
  margin: 0 0 0.3rem;
  height: 100%;
`;
export const ParamsWrapper = styled.div`
`;
export const Headers = styled.div`
  display: flex;
  align-items: center;
`;
export const Header = styled.div`
  margin-bottom: 15px;
  opacity: 0.5;
  font-size: 18px;
  font-weight: normal;
  color: #ffffff;
  width: 130px;
  text-align: center;
`;

export const Inputs = styled.div`
  display: flex;
  margin: 0 0 5px;
`;
export const ZoneLabel = styled.div`
  color: #fff;
  white-space: nowrap;
  line-height: 40px;
  margin: 0 0 0 5px;
  width: 230px;
  text-align: left;
`;
