import { useEffect } from 'react';

import { api } from 'api';

const timeoutBetweenKeys = 1200;
const minLength = 6;
const endChar = '!';
let timer = null;
let working = false;
let keys = '';

export const KeyInterceptor = () => {
  /**
   * set events
   */
  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  /**
   * on keydown handler
   * @param {*} event
   * @returns
   */
  const keyDownHandler = (event) => {
    if (event.target.name !== undefined) {
      return;
    }
    //console.log(event.keyCode, event.target.name);
    const val = event.keyCode;
    //console.log('🚀 ~ keyDownHandler ~ val', val);

    // end
    if (val === 13) {
      if (keys.length < 6) {
        keys = '';
        //setKeys('');
        //return;
      } else {
        //console.log('2');
        keys += endChar;
        //const out = keys + endChar;
        //setKeys(out);
      }
    } else if ((val >= 48 && val <= 57) || (val >= 65 && val <= 70)) {
      keys += String.fromCharCode(val);
      //setKeys('');
    } else {
      //const out = keys + String.fromCharCode(val);
      keys = '';
    }

    //console.log('🚀 ~ keyDownHandler ~ keys', keys);
    //setKeys(out);

    // tests
    if (!working) {
      if (timer) {
        clearTimeout(timer);
      }
      if (keys) {
        const isEnd = keys.length >= minLength && keys[keys.length - 1] === endChar;
        if (isEnd) {
          findParking();
        } else {
          // clear keys if timer completed
          timer = setTimeout(() => {
            //setKeys('');
            keys = '';
          }, timeoutBetweenKeys);
        }
      }
    }
  };

  /**
   * check keys
   */
  /*
  useEffect(() => {
    // if keys changed and keys !== '' clear and starttimer
    console.log('🚀 ~ useEffect ~ keys', keys);
    if (!working) {
      if (timer) {
        clearTimeout(timer);
      }
      if (keys) {
        const isEnd = keys.length > 5 && keys[keys.length - 1] === endChar;
        if (isEnd) {
          findParking();
        } else {
          // clear keys if timer completed
          timer = setTimeout(() => {
            //setKeys('');
          }, timeoutBetweenKeys);
        }
      }
    }
    // on end
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [keys]);
  */

  /**
   * find parking by meanId
   */
  const findParking = () => {
    const meanId = keys.substring(0, keys.length - 1);
    console.log('🚀 ~ findParking ~ meanId', meanId);

    if (working) {
      return;
    }

    working = true;

    api.call('stays.list', {
      filter_params: { mean_number: meanId },
      sort_params: [{ field_name: 'entry_at', direction: 'desc' }],
      paging_params: { limit: 1, offset: 0 },
    })
      .then((data) => {
        //console.log('🚀 ~ .then ~ data', data);
        if (data && data.items && data.items.length) {
          const item = data.items[0];
          window.location.assign('/card/stay?id=' + item.id);
          return;
          //} else {
          //notify('Парковка не найдена', meanId);
        }
        working = false;
        keys = '';
        //setKeys('');
      })
      .finally(() => {
        working = false;
        keys = '';
      });
  };

  return null;
};
