import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TableHeader } from 'libs/ui';
import Row from './Row';
import { GridBox, TotalBox, Grid, TableHead, TableHeadRow, TopRow } from 'libs/ui';
import { Total } from 'libs/ui';

class OperationsGrid extends Component {
  getOperationsList = () => {
    const appsArray = this.props.apps;
    const apps = {};
    appsArray.forEach((el) => (apps[el.id] = el.name));
    const operations = this.props.entries || this.props.operations;
    let operationsList = [];
    if (operations) {
      for (let key in operations) {
        //console.log('123', operations[key])
        operationsList.push(<Row apps={apps} key={operations[key].id} {...operations[key]} />);
      }
    }
    return operationsList;
  };

  getTotalAmount = () => {
    let operations = {};
    if (this.props.entries) {
      operations = this.props.entries;
      let totalAmount = 0;
      for (let key in operations) {
        if (operations[key].amount && typeof operations[key].amount === 'number') {
          totalAmount += operations[key].amount / 100;
        }
      }
      return totalAmount;
    }
  };

  render() {
    let summ = this.props.summ;
    if (Number.isNaN(summ) || !summ) {
      summ = 0;
    } else {
      summ /= 100;
    }

    return (
      <GridBox>
        <TopRow>
          <TotalBox>
            <Total cnt={this.props.count} />
            <Total cnt={summ} title="sum" after=" ₽" />
          </TotalBox>
          {this.props.getXlsLink()}
        </TopRow>
        <Grid>
          <TableHead>
            <TableHeadRow>
              {window.config.central && <TableHeader width="140px">Парковка</TableHeader>}
              <TableHeader width="220px" sortField="timestamp">
                Дата и время
              </TableHeader>
              <TableHeader width="115px">Способ оплаты</TableHeader>
              <TableHeader width="80px">Результат</TableHeader>
              <TableHeader width="100px" sortField="amount">
                Сумма
              </TableHeader>
              <TableHeader width="100px" sortField="amount_in">
                Внесено
              </TableHeader>
              <TableHeader width="100px" sortField="amount_out">
                Выдано
              </TableHeader>
              <TableHeader width="100px" sortField="shortage">
                Недостача
              </TableHeader>
              <TableHeader width="150px">Тип СО</TableHeader>
              <TableHeader>СО</TableHeader>
              <TableHeader width="130px">№ устройства</TableHeader>
              <TableHeader width="90px" number>
                Счет N
              </TableHeader>
              <TableHeader width="100px" number>
                Смена
              </TableHeader>
              <TableHeader width="80px">Действие</TableHeader>
            </TableHeadRow>
          </TableHead>
          <tbody>{this.getOperationsList()}</tbody>
        </Grid>
      </GridBox>
    );
  }
}

function mapStateToProps() {
  return {
    //summ: store.cashboxOperations.summ
  };
}

export default connect(mapStateToProps)(OperationsGrid);
