import { useState, useMemo } from 'react';

import { user } from 'services';
import { deletePlate } from 'services/payments-service';
import { TableExt, TableInfo, Confirm, themes, GridBox, GridUpdateMessage } from 'libs/ui';
import PlateForm from './forms/plate-form';
import { getTableParams } from './grid.params';

export const PlateGrid = (props) => {
  const { count, entries, className, theme, getXlsAction, clientId } = props;
  //const interface1 = props.interface;
  const [showForm, setShowForm] = useState('');
  const [message, setMessage] = useState('');
  const [row, setRow] = useState({});
  const [confirm, setConfirm] = useState(null);

  /**
   * close confirm
   */
  const closeConfirm = () => {
    setConfirm(null);
  };

  /**
   * delete item
   */
  const delItem = (id) => {
    deletePlate(id).then(() => {
      props.getEntries({ offset: 0 }, null, true);
      closeConfirm();
    });
  };

  // actions
  const addItemAction = () => {
    setRow({});
    setShowForm('addForm');
  };

  const editItemAction = (row) => {
    setRow(row);
    setShowForm('editForm');
  };

  const delItemAction = (row) => {
    setRow(row);
    setConfirm({
      title: 'СО ' + row.mean_id + ' будет удалено. Продолжить?',
      width: '440px',
      type: 'danger',
      cancelButton: { text: 'Отмена', func: closeConfirm },
      acceptButton: { text: 'Удалить', func: () => delItem(row.id), theme: themes.red },
    });
  };

  // items
  const items = Object.keys(entries).map((key) => entries[key]);

  // actions
  const rightEdit = user.haveRight('plates.update');
  const rightDel = user.haveRight('plates.delete');
  const rightAdd = user.haveRight('plates.add');
  const actions = [];
  // if (rightEdit) {
  //   actions.push({ title: 'Редактировать', action: editItemAction });
  // }
  if (rightDel) {
    actions.push({ title: 'Удалить', action: delItemAction });
  }

  // top actions
  const topActions = [];
  if (rightAdd) {
    topActions.push({ title: 'Добавить', icon: 'fa-plus', theme: themes.blue, action: addItemAction });
  }
  if (count) {
    getXlsAction(topActions);
  }

  const initial = showForm === 'addForm' ? {} : { ...row };
  const tableParams = useMemo(() => getTableParams(), []);

  return (
    <GridBox>
      <TableInfo theme={theme} count={count} actions={topActions} />

      <TableExt
        theme={theme}
        title="ГРЗ"
        rows={{ count, items }}
        actions={actions}
        className={className}
        refresh={props.refresh}
        clientId={clientId}
        onRowClick={rightEdit ? editItemAction : undefined}
        {...tableParams}
      />

      {confirm && <Confirm {...confirm} />}

      {showForm && (
        <PlateForm
          type={showForm}
          close={() => setShowForm('')}
          clientId={props.clientId}
          id={row.id}
          meanId={row.mean_id}
          meanType={row.mean_type}
          setMessage={setMessage}
          refresh={props.refresh}
          initialValues={initial}
          tariffs={props.tariffs}
        />
      )}

      {message && <GridUpdateMessage>{message}</GridUpdateMessage>}
    </GridBox>
  );
};
