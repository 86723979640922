import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { user } from 'services';
import CommentForm from 'modules/components/windows/AddComment';
import Comments from 'modules/components/windows/Comments';
import { OptionsCell } from 'libs/ui';
//import { Cell } from 'libs/ui';
import { addComment, setFormInfo1, removeFormInfo1 } from 'store/ducks/models/pay';

const types = {
  rfid: 'rfids',
  csc: 'epms',
  plates: 'vehiclePlates',
  //subscriptions: '',
};

const getRealType = (type) => {
  return types[type] ? types[type] : type;
};

/**
 * hoc for table row comments support
 * @param {*} WrappedComponent
 * @returns
 */
const withComment = (WrappedComponent) => {
  return class WithComment extends Component {
    showForm = (type) => {
      //console.log('🚀 ~ withComment ~ type', type, this.props.type);
      this.props.setFormInfo1(
        {
          parking_id: this.props.parking_id,
          value: this.props.mean_id,
          id: this.props.id,
          status: this.props.status,
          type: type,
        },
        getRealType(this.props.type)
      );
    };

    getMenu = ({ parentGetMenu, parentGetForm, brdColor = '' }) => {
      const out = parentGetMenu ? parentGetMenu() : [];

      if (user.haveRight('comment.add')) {
        out.push({ text: 'Оставить комментарий', func: () => this.showForm('comment') });
      }
      if (user.haveRight('comment.list') && this.props.comments.length) {
        out.push({ text: 'Посмотреть комментарии', func: () => this.showForm('commentlist') });
      }

      const disabled = !out.length;
      /*
      if (!out.length) {
        return <Cell> - </Cell>;
      }
      */

      return (
        <OptionsCell disabled={disabled} left={-62} options={out} brdColor={brdColor}>
          {this.getForm(parentGetForm)}
        </OptionsCell>
      );
    };

    getForm = (parentGetForm) => {
      const out = parentGetForm ? parentGetForm() : null;
      if (out /*&& (out || out.length)*/) {
        return out;
      }

      const type = getRealType(this.props.type);
      const form = this.props.formInfo;
      if (form && form.id === this.props.id) {
        if (form.type === 'comment') {
          return <CommentForm type={type} info={form} addComment={this.props.addComment} removeFormInfo={this.props.removeFormInfo1} />;
        } else if (form.type === 'commentlist') {
          return <Comments id={this.props.id} removeFormInfo={this.props.removeFormInfo1} type={type} />;
        }
      }
    };

    render() {
      const { children, ...other } = this.props;
      return (
        <>
          <WrappedComponent {...other} getCommentsCell={this.getMenu} />
          {children}
        </>
      );
    }
  };
};

const mapStateToProps = (store, props) => {
  const type = getRealType(props.type);
  //console.log('🚀 ~ mapStateToProps ~ type', type);
  return {
    formInfo: store[type]['formInfo'],
  };
};

export default compose(
  connect(mapStateToProps, {
    addComment,
    setFormInfo1,
    removeFormInfo1,
  }),
  withComment
);
