import styled from 'styled-components';

export const InputBoxContainer = styled.div`
  &.in-filter .fa {
    right: 10px;
    top: 50%;
    margin-top: -8px;
    color: #000;
  }
`;

export const InputBox = styled.div`
  position: relative;
  i {
    position: absolute;
    top: 2px;
    right: 0px;
    color: #fff;
    opacity: 0.6;
  }
`;
