import { GridContainer } from 'libs/ui';
import { Rfid } from 'grids/rfid/rfid';

const RfidWrapper = (props) => {
  return (
    <GridContainer>
      <Rfid {...props} />
    </GridContainer>
  );
};

export default RfidWrapper;
