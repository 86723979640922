import styled from 'styled-components';

export const Content = styled.div`
  .loading {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: -1px;
      background: rgba(255, 255, 255, 0.9) url('/images/loader.gif') no-repeat 50% 50%;
      //background-size: auto 70%;
    }
  }
`;

export const Alert = styled.div`
  padding: 10px 15px;
  border-radius: 3px;
  font-size: 16px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  margin: 30px 0 20px;
`;
