import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';

import { WhiteStyledField } from 'libs/form';
import { Button, ButtonContainer, themes } from 'libs/ui';
import { required } from 'libs/form';

const checkFn = (value) => {
  if (value) {
    return value.length === 16 ? undefined : `В номере должно быть 16 символов`;
  } else {
    return 'Обязательное поле';
  }
};

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
`;

let Form = (props) => {
  const submit = (values) => {
    if (props.type === 'add') {
      props.addEntity(values, afterSubmit);
    } else if (props.type === 'update') {
      props.updateEntity(props.id, values, afterSubmit);
    }
  };

  const afterSubmit = () => {
    props.getEntries({ offset: 0 });
    props.displayHandler(false);
    props.updateMessageDisplayHandler(true);
    setTimeout(() => {
      props.updateMessageDisplayHandler(false);
    }, 3000);
    props.destroy(props.form);
  };

  return (
    <form onSubmit={props.handleSubmit(submit)}>
      <WhiteStyledField validate={required} placeholder="Клиент" type="text" label="Клиент" name="name" autoFocus />
      <WhiteStyledField validate={checkFn} placeholder="Номер ФН" type="text" label="Номер ФН" name="fn" maxLength={16} />
      <WhiteStyledField validate={required} placeholder="Минимальная сумма" type="text" label="Минимальная сумма" name="min_amount" naturalNumber />
      <WhiteStyledField validate={required} placeholder="Сумма скидки" type="text" label="Сумма скидки" name="amount" naturalNumber />
      <StyledButtonContainer space="20px">
        <Button
          type="button"
          onClick={() => {
            props.displayHandler(false);
            props.destroy(props.form);
          }}
        >
          Отменить
        </Button>
        <Button theme={themes.blue}>
          <i className="fa fa-save" />
          Сохранить
        </Button>
      </StyledButtonContainer>
    </form>
  );
};

Form = reduxForm()(Form);

Form = connect((store, props) => {
  let formName = '';
  if (props.type === 'add') {
    formName = 'addBenefitForm';
  } else if (props.type === 'update') {
    formName = `addBenefitForm_${props.id}`;
  }

  return {
    form: formName,
    destroyOnUnmount: false,
    initialValues: props.initialValues,
  };
})(Form);

export default Form;
