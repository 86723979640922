import styled from 'styled-components';

export const Content = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  video {
    object-fit: contain;
    /* max-width: 100%;
    max-height: 100%; */
    width: 100%;
    height: 100%;
    //height: auto;
  }
`;
