import { data } from './data';

export const zones = {
  getZonesColors() {
    const out = {};
    data.getZones()?.forEach((el) => {
      out[el.zone_id] = el.background_color;
    });
    return out;
  },

  getZones() {
    if (!this.zones) {
      const activeZones = data.getDefaultZones();
      if (!activeZones) {
        return [];
      }
      const out = Object.keys(activeZones).map((zoneId) => {
        const zone = Object.assign({}, activeZones[zoneId]);
        zone.color = zone.background_color;
        zone.id = zone.zone_id;
        return zone;
      });
      this.zones = out;
    }
    return this.zones;
  },

  getColor(id) {
    const colors = this.getZonesColors();
    return colors[id] ? colors[id] : '#4d8b8b';
  },

  getZonesFromRule(rules) {
    return rules.length ? rules[0].zones : [];
  },

  findNode(zone_id, node_id) {
    const zone = data.getZones().find((el) => el.zone_id === zone_id);
    if (!zone) {
      return null;
    }
    const node = zone.nodes.find((el) => el.node_id === node_id);
    if (!node) {
      return null;
    }
    return node;
  },

  getNodeTitle(zone_id, node_id) {
    const node = this.findNode(zone_id, node_id);
    if (!node) {
      return node_id;
    }
    return node.name;
  },

  getRackTitle(zone_id, node_id, rack_id) {
    const node = this.findNode(zone_id, node_id);
    if (!node) {
      return node_id;
    }
    const rack = node.racks.find((el) => el.id === rack_id);
    if (!rack) {
      return rack_id;
    }

    return rack.name;
  },
};

export default zones;
