export const routes = {
  test: '/test',
  // mnemonic diagram
  scheme: '/scheme',
  // stays
  stays: '/stays',
  // cashbox
  // cashbox: '/cashbox',
  // clients
  clients: '/clients',
  // payments
  payments: '/payments',
  rfid: '/payments/rfid',
  epm: '/payments/epm',
  vehiclePlate: '/payments/vehiclePlate',
  subscription: '/payments/subscription',
  grey_plate: '/payments/grey_plate',
  // reports
  reports: '/reports',
  rides: '/reports/rides',
  plateRides: '/reports/plateRides',
  cashboxOperations: '/reports/cashboxOperations',
  //refill: '/reports/refill',
  calls: '/reports/calls',
  events: '/reports/events',
  actions: '/reports/actions',
  additional: '/reports/additional',
  benefits: '/reports/benefits',
  discounts: '/reports/discounts',
  promises: '/reports/promises',

  //using: '/reports/using',
  //using2: '/reports/using2',
  //working: '/reports/working',
  //working2: '/reports/working2',
  summary: '/reports/summary',

  // options
  options: '/options',
  tariffs: '/options/tariffs',
  // tariffs2: '/options/tariffs2',
  rules: '/options/rules',
  users: '/options/users',
  roles: '/options/roles',
  categories: '/options/categories',
  collection: '/options/collection',
  optparkings: '/options/parkings',
  optbenefits: '/options/benefits',
  optadv: '/options/adv',
  soupload: '/options/soupload',

  // cards
  stay: '/card/stay',
  newClient: '/card/newClient',
  client: '/card/client',
  newTariff: '/card/newTariff',
  tariff: '/card/tariff',
  rule: '/card/rule',

  empty: '/empty',
  operator: '/operator',
};
