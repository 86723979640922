import styled from 'styled-components';

import { Submit } from 'libs/ui';

export const TariffData = styled.div`
  width: 590px;
  position: relative;
`;
export const HeaderText = styled.p`
  font-size: 26px;
  color: #fff;
`;
export const FormHeader = styled.p`
  border-bottom: solid 1px #595d64;
  padding: 0 0 15px 0;
  font-size: 20px;
  color: #fff;
  opacity: 0.6;
`;
export const ZoneInputBox = styled.div`
  width: ${(props) => (props.wide ? '130px' : '100px')};
  height: 40px;
  border-radius: 4px;
  border: solid 1px;
  border-color: #d5d5d5;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  input {
    width: ${(props) => (props.wide ? '90%' : '42px')};
    height: 100%;
    border: none;
    background-color: inherit;
    outline: none;
    color: ${(props) => (props.error ? '#d33d34' : '#fff')};
    font-size: 15px;
    text-align: end;
    margin-right: 10px;
  }
  span {
    opacity: 0.7;
    color: ${(props) => (props.error ? '#d33d34' : '#fff')};
    font-size: 15px;
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(100%);
  }
`;
export const SaveButton = styled(Submit)`
  position: absolute;
  bottom: 30px;
  right: 30px;
  &[disabled] {
    cursor: not-allowed;
  }
`;
export const TariffManageWrapper = styled.div`
  width: 100%;
  form {
    position: relative;
    display: flex;
    //justify-content: space-between;
    align-items: flex-start;
  }
`;
