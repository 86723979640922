import React, { Component } from 'react';
import { SubmissionError } from 'redux-form';

import { Popup2 } from 'libs/ui';
import ClientsRfidForm from './Form';
import { FormHeader } from './Window.styled';

class ClientRfidWindow extends Component {
  state = { tariffList: [], errors: {} };

  componentDidMount() {
    this.tariff_id = this.props.data.tariff_id;
  }

  getHeader = () => {
    if (this.props.type === 'editForm') {
      return `RFID ${this.props.data.rfid}`;
    } else {
      return `Новая RFID метка`;
    }
  };

  updateGrid = () => {
    this.props.getEntries({ offset: 0 });
    this.props.getEntriesCount();
    this.props.removeFormInfo();
  };

  saveRfid = (values) => {
    let decorateValues = { ...values };
    // console.log('🚀 ~ decorateValues:', decorateValues);
    const change = decorateValues.mode === 'change';
    let client_id = decorateValues.client_id1;
    if (!client_id) {
      client_id = this.props.clientId;
    }
    // console.log('🚀 ~ client_id:', client_id);
    const id1 = decorateValues.id1;
    delete decorateValues.mode;
    delete decorateValues.client_id1;
    delete decorateValues.id1;

    decorateValues.vehicle_class = Number(decorateValues.vehicle_class);
    decorateValues.vehicle_category = Number(decorateValues.vehicle_category);

    const isEdit = change || this.props.type === 'editForm';
    const isAdd = !change && this.props.type === 'addForm';

    if (isAdd) {
      return this.props.addRfid(decorateValues, this.updateGrid).catch((data) => {
        let submissionErrors = {};
        submissionErrors[data.data.field] = data.data.type;
        throw new SubmissionError(submissionErrors);
      });
    } else if (isEdit) {
      delete decorateValues.rfid;
      let id = this.props.id;
      if (!decorateValues.client_id && client_id) {
        decorateValues.client_id = client_id;
      }
      if (change) {
        decorateValues.client_id = client_id;
        id = id1;
        delete decorateValues.comment;
      }
      // console.log('🚀 ~ decorateValues:', decorateValues);
      // return Promise.reject('123');

      return this.props.updateRfid(decorateValues, id, this.updateGrid).catch((errors) => {
        let submissionErrors = {};

        if (errors && Array.isArray(errors)) {
          errors.forEach((element) => {
            submissionErrors[element.field] = element.type;
          });
        }

        throw new SubmissionError(submissionErrors);
      });
    }
  };

  setTariffList = (list) => {
    this.setState({ tariffList: list });
  };

  setTariff = (item) => {
    this.tariff_id = item.id;
  };

  render() {
    return (
      <Popup2 w={470}>
        <FormHeader>{this.getHeader()}</FormHeader>
        <ClientsRfidForm
          clientId={this.props.clientId}
          errors1={this.state.errors}
          type={this.props.type}
          removeFormInfo={this.props.removeFormInfo}
          data={this.props.data}
          onSubmit={this.saveRfid}
          setTariff={this.setTariff}
          categories={this.props.epmCategories}
          vehicleClasses={this.props.vehicleClasses}
          tariffList={this.state.tariffList}
          setTariffList={this.setTariffList}
          getList={this.props.getTariffList}
        />
      </Popup2>
    );
  }
}

export default ClientRfidWindow;
