import RulesForm from './rules-form';
import { Content } from './index.styled';

export const Rules = () => {
  return (
    <Content>
      <RulesForm />
    </Content>
  );
};

export default Rules;
