import styled from 'styled-components';

export const Content = styled.div`
  margin-bottom: 20px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
`;

export const Total = styled.p`
  font-size: 13px;
  font-weight: bold;
  color: #1c1c1c;
  span {
    font-size: 15px;
  }
  ${(props) =>
    props.white &&
    css`
      color: #fff;
    `}
`;
