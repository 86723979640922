import React from 'react';
import styled, { css } from 'styled-components';

import { withPopup } from '../buttons';
import { GridCell } from './cell.styled';

const Option = withPopup((props) => {
  // console.log('🚀 ~ Option ~ props:', props);
  const onClick = (event) => {
    event.stopPropagation();
    props.popupDisplayHandler(event);
  };

  return <img onClick={props.disabled ? null : onClick} src="/images/grid-options.png" alt="" style={{ cursor: 'pointer' }} />;
});

const StyledCell = styled(GridCell)`
  overflow: visible;
  padding: 4px 10px;
  img {
    display: block;
  }
  ${(props) =>
    props.brdColor &&
    css`
      border-color: ${props.brdColor};
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      img {
        opacity: 0.3;
      }
    `}
`;

export const OptionsCell = (props) => {
  const { brdColor, disabled, theme, ...other } = props;
  return (
    <StyledCell disabled={disabled} className={props.className} brdColor={brdColor} theme={theme}>
      {other.options?.length > 0 && <Option disabled={disabled} {...other} />}
    </StyledCell>
  );
};
