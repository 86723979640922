import React from 'react';
import { flexRender } from '@tanstack/react-table';

import { DragHandle } from './drag-handle';
import { Row, Cell } from './table-row-static.styled';

export const TableRowStatic = ({ row }) => {
  const cells = row.getVisibleCells();
  return (
    <Row>
      {cells.map((cell, i) => {
        const cellProps = {
          key: cell.id,
          style: {
            width: cell.column.getSize(),
          },
        };
        return (
          <Cell {...cellProps}>
            {i === 0 && <DragHandle isDragging />}
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Cell>
        );
      })}
    </Row>
  );
};
