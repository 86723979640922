import { useState } from 'react';
import { TimePicker } from 'libs/ui';

export const TimeSelect = (props) => {
  const { name, value, onChange } = props;
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  };

  const openInput = () => {
    setOpen(true);
  };

  return (
    <span>
      <input type="text" value={value} readOnly onFocus={openInput} />
      {open && (
        <TimePicker
          value={value}
          set={(val) => {
            onChange(name, val);
          }}
          close={close}
        />
      )}
    </span>
  );
};
