import { useEffect, useRef } from 'react';
import { reduxForm } from 'redux-form';
import moment from 'moment';

import { Filters } from 'libs/form';

const d = moment();
const initialFlt = {
  // status: 'active',
  datetime_from: d.startOf('day').format(),
  datetime_to: d.endOf('day').format(),
};

const Filter = (props) => {
  const { locationState, reset, handleSubmit, change, type } = props;
  const formRef = useRef(null);

  /**
   * set filter
   * @param {*} field
   * @param {*} value
   */
  const setFilter = (field, value) => {
    change(field, value);
  };

  /**
   * update filter
   * @param {*} filter
   */
  const updateFilter = (filter) => {
    reset();
    for (let field in filter) {
      setFilter(field, filter[field]);
    }

    setTimeout(() => {
      formRef.current.dispatchEvent(new Event('submit'));
    }, 500);
  };

  // did mount
  useEffect(() => {
    // console.log('🚀 ~ useEffect ~ locationState.filter:', locationState.filter);
    if (locationState && locationState.filter) {
      updateFilter(locationState.filter);
    }
  }, []);

  const filters = [
    { type: 'doubledate', name: 'datetime', title: 'Период парковки', change },
    { type: 'doubledate', name: 'entry_at', title: 'Начало', change },
    { type: 'doubledate', name: 'leave_at', title: 'Конец', change },
    { type: 'doubledate', name: 'payment', title: 'Оплата', change },
    { type: 'input', name: 'client_name', title: 'Клиент', placeholder: 'Иванов И.И.', className: 'input-big' },
    { type: 'zones', w: 140, name: 'zones', title: 'Зона', change },
    { type: 'dict', dict: 'stays.status' },
    { type: 'input', name: 'tariff', title: 'Тариф', placeholder: 'Название тарифа', className: 'input-big' },
    { type: 'duration', name: 'duration', title: 'Длительность' },
    { type: 'input', name: 'vehicle_plate', title: 'ГРЗ', placeholder: 'А000AA 000 rus' },
    { type: 'meandouble', dict: 'stays.mean_type', name: 'mean_number', placeholder: '55030', label: 'СО', w: 120 },
    { type: 'doublenumber', name: 'amount', title: 'Сумма', w: 80 },
    { type: 'toggle', name: 'is_suspicious', title: 'Подозрительные парковки' },
    { type: 'toggle', name: 'dispatcher_pass', title: 'Ручное открытие' },
    { type: 'toggle', name: 'is_secondentry', title: 'Повторный проезд' },
    { type: 'parking' },

    // { type: 'dict', w: 120, dict: 'clients.type' },
    // { type: 'input', w: 290, name: 'contact_name', title: 'Контактное лицо', placeholder: 'Иванов И.И.' },
    // { type: 'input', w: 160, name: 'tel_num', title: 'Телефон', placeholder: '+7 956 456-56-78' },
  ];

  return <Filters ref={formRef} items={filters} page={type} handleSubmit={handleSubmit} reset={reset} />;
};

export default reduxForm({
  form: 'staysFilter',
  destroyOnUnmount: false,
  initialValues: initialFlt,
})(Filter);
