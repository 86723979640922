import React, { Component } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { user, data } from 'services';
import { routes } from 'router';
import { ParkingCell, Cell, GridRow } from 'libs/ui';
import StatusForm from 'modules/components/windows/ChangeStatus';
import withComment from 'modules/components/windows/withComment';

const RfidGridCell = styled(Cell)`
  border-bottom: 1px solid #6c7277;
  color: #fff;
  height: 40px;
`;
const RfidLockCell = styled(RfidGridCell)`
  &::before {
    position: absolute;
    left: 5px;
    content: ${(props) => {
      if (props.status === 'active') {
        return '';
      } else {
        return 'url(/images/icon_lock_transparent.png)';
      }
    }};
  }
`;
const ClientsRfidRow = styled(GridRow)`
  background-color: ${(props) => {
    if (props.status === 'active') {
      return '#393e43';
    } else {
      return '#4d5156';
    }
  }};
  td {
    color: ${(props) => {
      if (props.status === 'active') {
        return '#fff';
      } else {
        return 'rgba(248, 248, 248, 0.4)';
      }
    }};
  }
  &:nth-child(2n) {
    background-color: #4d5157;
  }
  &:hover {
    background-color: #919aa6;
  }
`;
class Rfid extends Component {
  isBlocked = () => {
    return this.props.is_blocked;
  };

  getLockIcon = () => {
    if (this.isBlocked()) {
      return <img src="/images/icon_unlock_black.png" alt="" style={{ marginRight: '10px' }} />;
    } else {
      return <img src="/images/icon_lock_black.png" alt="" style={{ marginRight: '10px' }} />;
    }
  };

  showEditForm = () => {
    //console.log('ac showEditForm', this.props.tariff);
    this.props.setFormInfo(
      {
        type: 'editForm',
        id: this.props.id,
        data: {
          rfid: this.props.rfid || '',
          owner: this.props.owner || '',
          vehicle_class: this.props.vehicle_class || '',
          vehicle_category: this.props.vehicle_category || '',
          vehicle_plate: this.props.vehicle_plate || '',
          tariff: this.props.tariff || '',
          tariff_id: this.props.tariff_id || '',
          comment: this.props.comment || '',
          parking_id: this.props.parking_id || '',
        },
      },
      this.props.type
    );
  };

  showStatusForm = () => {
    this.props.setFormInfo(
      {
        type: 'status',
        value: this.props.rfid,
        id: this.props.id,
        status: this.props.status,
      },
      this.props.type
    );
  };

  getForm = () => {
    const cardForm = this.props.cardForm;
    if (cardForm && cardForm.type === 'status' && cardForm.id === this.props.id) {
      return (
        <StatusForm
          type="rfid"
          info={cardForm}
          getEntries={this.props.getEntries}
          changeStatus={this.props.changeStatus}
          removeFormInfo={this.props.removeFormInfo}
          setWarning={this.props.setWarning}
          removeWarning={this.props.removeWarning}
        />
      );
    }
  };

  toClientStays = () => {
    this.props.history.push(routes.stays, {
      filter: {
        mean_type: 'rfid',
        mean_number: this.props.rfid,
      },
    });
  };

  getDisplayedValue = (field, id) => {
    const data = this.props[field];
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        if (Number(data[i].id) === id) {
          return data[i].name;
        }
      }
    }
  };

  getMenu = () => {
    const rightUpdate = user.haveRight('rfid.update');
    const rightStaysList = user.haveRight('stays.list');
    const out = [];

    if (rightStaysList) {
      out.push({ text: 'Перейти в парковки', func: this.toClientStays });
    }
    if (rightUpdate) {
      out.push({ text: 'Редактировать', func: this.showEditForm });
      out.push({ text: this.isBlocked() ? 'Разблокировать' : 'Заблокировать', func: this.showStatusForm });
    }

    return out;
  };

  render() {
    const maps = user.getMaps();
    const cat = maps.vehicle_categoryMap[this.props.vehicle_category]
      ? maps.vehicle_categoryMap[this.props.vehicle_category]
      : this.props.vehicle_category;
    return (
      <ClientsRfidRow status={this.props.status}>
        <ParkingCell parking_id={this.props.parking_id} cmp={RfidGridCell} />
        <RfidLockCell status={this.props.status}>{this.props.rfid}</RfidLockCell>
        <RfidGridCell>{data.getDictValue('payment_mean.status', this.props.is_blocked)}</RfidGridCell>
        <RfidGridCell>{this.props.owner}</RfidGridCell>
        <RfidGridCell>{cat}</RfidGridCell>
        <RfidGridCell>{this.getDisplayedValue('vehicleClasses', this.props.vehicle_class)}</RfidGridCell>
        <RfidGridCell>{this.props.vehicle_plate}</RfidGridCell>
        <RfidGridCell>{this.props.tariff}</RfidGridCell>
        <RfidGridCell>{this.props.comment}</RfidGridCell>
        {this.props.getCommentsCell({
          parentGetMenu: this.getMenu,
          parentGetForm: this.getForm,
          brdColor: '#6c7277',
        })}
      </ClientsRfidRow>
    );
  }
}

export default withRouter(withComment(Rfid));
