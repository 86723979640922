import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-end'};
  & > * {
    margin-right: ${props => props.space ? props.space : '10px'};
    &:last-child { margin-right: 0; }
  }
`

export const ScrollUpButton = styled.img`
  position: fixed;
  bottom: 40px;
  left: 230px;
  cursor: pointer;
`;