import styled from 'styled-components';

export const TableContent = styled.div`
  table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    text-align: left;
  }
  th {
    width: 160px;
    text-align: start;
    padding-right: 5px;
    padding-left: 5px;

    position: relative;
    border-bottom: 1px solid rgb(146, 146, 146);
    height: 35px;
    font-size: 15px;
    color: #000000;
    opacity: 1;

    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
  td {
    position: relative;
    text-align: start;
    padding-right: 5px;
    padding-left: 5px;
    border-bottom: 1px solid rgb(215, 215, 215);
    font-size: 15px;
    color: rgb(0, 0, 0);
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background: rgba(0, 0, 0, 0.2);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }

  .resizer.isResizing {
    background: blue;
    opacity: 1;
  }

  @media (hover: hover) {
    .resizer {
      opacity: 0;
    }

    *:hover > .resizer {
      opacity: 1;
    }
  }
`;
