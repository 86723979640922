import React from 'react';
import { connect } from 'react-redux';
import { SubmissionError, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { required, WhiteStyledField, DictInput, ErrorMsg } from 'libs/form';
import { Button, themes, ButtonContainer } from 'libs/ui';

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
`;

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      role: props.initialValues.role_id.toString(),
    };
    this.noEdit = props.initialValues.is_active === 'null' || props.initialValues.is_active === null;
  }

  /**
   * submit form
   * @param {*} values
   * @returns
   */
  submit = (values) => {
    // console.log('🚀 ~ values:', values);
    // return false;
    let decorateValues = {};

    for (let key in values) {
      switch (key) {
        case 'role_id':
          decorateValues[key] = Number(values[key]);
          break;
        case 'is_active':
          if (values[key].toString() === 'true') {
            decorateValues[key] = true;
          } else if (values[key].toString() === 'false') {
            decorateValues[key] = false;
          } else if (values[key].toString() === 'null') {
            decorateValues[key] = null;
          }
          break;
        case 'password':
          if (values[key]) {
            decorateValues[key] = values[key];
          }
          break;
        default:
          decorateValues[key] = values[key];
          break;
      }
    }

    return this.props
      .updateEntity(this.props.id, decorateValues, this.afterSubmit)
      .then((errors) => {
        if (errors && errors.data && errors.data.type === 'duplicate') {
          throw new SubmissionError({
            username: 'duplicate',
          });
        }
      })
      .catch((errors) => {
        if (errors && errors.data) {
          throw new SubmissionError({
            _error: errors.data.type,
          });
        }
        if (errors && errors.message) {
          throw new SubmissionError({
            _error: errors.message,
          });
        }
      });
  };

  afterSubmit = () => {
    this.props.refresh();
    this.props.updateMessageDisplayHandler(true);
    setTimeout(() => {
      this.props.updateMessageDisplayHandler(false);
    }, 3000);
    this.props.displayHandler(false);
    this.props.destroy(this.props.form);
  };

  changeRole = (data) => {
    const el = data.target;
    if (el.name === 'role_id') {
      this.setState({ role: el.value });
    }

    //console.log(data.target);
  };

  render() {
    const { role } = this.state;
    const { error } = this.props;
    const incassator = role === '2';
    const roles = Object.keys(window.config.role_map).map((idx) => {
      return (
        <option key={idx} value={idx}>
          {window.config.role_map[idx]}
        </option>
      );
    });

    return (
      <form onSubmit={this.props.handleSubmit(this.submit)} onChange={this.changeRole}>
        <ErrorMsg error={error} />
        <DictInput dict="users.status" disabled={this.noEdit} />

        <WhiteStyledField type="select" label="Роль" name="role_id" disabled={this.noEdit} className="shortfield">
          {roles}
        </WhiteStyledField>
        <WhiteStyledField
          disabled={this.noEdit}
          type="text"
          label={incassator ? 'Номер БСК' : 'Логин'}
          placeholder={incassator ? 'Номер БСК' : 'Логин'}
          name="username"
          validate={required}
        />
        <WhiteStyledField type="text" label="ФИО" placeholder="Иванов Иван Иванович" name="name" validate={required} disabled={this.noEdit} />
        <WhiteStyledField
          type="text"
          label="Новый пароль"
          placeholder="password"
          name="password"
          className={incassator ? 'hidden' : ''}
          disabled={this.noEdit}
        />
        <WhiteStyledField type="text" label="E-mail" placeholder="someone@gmail.com" name="email" disabled={this.noEdit} />
        <WhiteStyledField type="text" label="Телефон" placeholder="+7 956 456-56-78" name="phone" disabled={this.noEdit} />
        <StyledButtonContainer space="20px">
          <Button
            type="button"
            onClick={() => {
              this.props.displayHandler(false);
              this.props.destroy(this.props.form);
            }}
          >
            Отменить
          </Button>
          <Button theme={themes.blue} disabled={this.noEdit}>
            <i className="fa fa-save" />
            Сохранить
          </Button>
        </StyledButtonContainer>
      </form>
    );
  }
}

Form = reduxForm()(Form);

Form = connect((store, props) => {
  return {
    form: `updateUserForm_${props.id}`,
    destroyOnUnmount: false,
    initialValues: props.initialValues,
  };
})(Form);

export default Form;
