import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Content, Label, Error } from './field.styled';

/**
 * Visual template for field
 * @param {*} props
 * @returns
 */
export const Field = forwardRef((props, ref) => {
  const { cmp, children, name, label, type, error, className, ...rest } = props;
  const [tip, setTip] = useState(false);
  const isCheckbox = type === 'checkbox';
  const out = cmp ? cmp : children;
  let cl = className ? className : '';
  if (error) {
    cl += ' error';
  }

  return (
    <Content {...rest} ref={ref} className={cl} onMouseEnter={() => setTip(true)} onMouseLeave={() => setTip(false)}>
      <Label htmlFor={name}>
        {isCheckbox && out}
        <span>{label}</span>
      </Label>
      {!isCheckbox && out}
      {error && tip && <Error>{error}</Error>}
    </Content>
  );
});

Field.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  cmp: PropTypes.any,
  error: PropTypes.string,
};

export default Field;
