import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { user } from 'services';
import Tariff from './Row';
import { GridBox, Grid, TableHead, TableHeadRow, TopRow, Total } from 'libs/ui';
import { Button, themes } from 'libs/ui';
import { TableHeader } from 'libs/ui';
import { routes } from 'router';

class TariffsGrid extends Component {
  state = { showWarning: false };

  showNewTariffCard = () => {
    this.props.history.push(routes.newTariff);
  };

  getTariffList = () => {
    const { categories, vehicleClasses, entries } = this.props;

    let tariffList = [];
    if (entries) {
      for (let key in entries) {
        tariffList.push(<Tariff key={entries[key].id} vehicleClasses={vehicleClasses} categories={categories} {...entries[key]} />);
      }
    }
    return tariffList;
  };
  render() {
    return (
      <GridBox>
        {this.props.hideTotal ? null : (
          <TopRow>
            <Total cnt={this.props.count} />
            {user.haveRight('tariff.add') && (
              <Button theme={themes.blue} onClick={this.showNewTariffCard}>
                <img src="/images/add-icon.png" alt="" />
                Добавить индивидуальный тариф
              </Button>
            )}
          </TopRow>
        )}
        <Grid className={this.props.className}>
          <TableHead>
            <TableHeadRow>
              {window.config.central && <TableHeader width="140px">Парковка</TableHeader>}              
              <TableHeader width="280px">Название</TableHeader>
              <TableHeader width="240px">Тип</TableHeader>
              <TableHeader width="115px">Статус</TableHeader>
              <TableHeader width="210px">Дата создания</TableHeader>
              <TableHeader width="210px">Начало действия</TableHeader>
              <TableHeader width="165px">Категория</TableHeader>
              <TableHeader>Класс ТС</TableHeader>
            </TableHeadRow>
          </TableHead>
          <tbody>{this.getTariffList()}</tbody>
        </Grid>
      </GridBox>
    );
  }
}

export default withRouter(TariffsGrid);
