import React, { Component } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'

import { Button, themes, ButtonContainer } from 'libs/ui'
import { Popup2 } from 'libs/ui';

const NewTicketContent = styled.div`
  margin-left:120px;
`
const NewTicketHeader = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`
const NewTicketInfo = styled.p`
  font-size: 15px;
  margin-bottom: 10px;
`
const AttentionIcon = styled.img`
  width:50px;
  height: 46px;
  position: absolute;
  left: 30px;
  top: 60px;
`
const StyledForm = styled.form`
  border-top: 1px solid ${(props) => props.$invalid ? '#d33d34' : '#cccccc'};
  border-bottom: 1px solid ${(props) => props.$invalid ? '#d33d34' : '#cccccc'};
  width: 400px;
  height: 60px;
  margin-left: -100px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    width: 101px;
    font-size: 13px;
    color: #3e3e3e;
  }
  input {
    border: none;
    margin-right: 70px;
  }
`

class Invalid extends Component{

  state = {
    number: '',
    invalid: false
  }

  setHandicapped = () => {
    if(this.state.number){
      this.props.setHandicapped(this.state.number)
      this.props.close()
    }else{
      this.setState({invalid: true})
    }
  }

  render(){
    return(
      <Popup2 w={440}>
        <AttentionIcon src='/images/icon_!.png' alt='' />
        <NewTicketContent>
          <NewTicketHeader>
            Признак инвалид
          </NewTicketHeader>
          <NewTicketInfo style={{marginBottom: '20px'}}>
            Внесите номер удостоверения
            об инвалидности и нажмите «Сохранить»
          </NewTicketInfo>
          <StyledForm $invalid={this.state.invalid}>
            <p>Номер удостоверения</p>
            <input onChange={(e) => this.setState({number: e.target.value})} value={this.state.number} placeholder='Введите номер'/>
          </StyledForm>
          <ButtonContainer space='20px' justifyContent={'flex-start'}>
            <Button fontSize='16px' onClick={this.props.close} >Отменить</Button>
            <Button theme={themes.blue} fontSize='16px' onClick={this.setHandicapped}>Сохранить</Button>
          </ButtonContainer>
        </NewTicketContent>
      </Popup2>
    )
  }
}

export default withRouter(Invalid)