import styled, { css } from 'styled-components';

export const Back = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  z-index: 100000;
  background: rgba(0, 0, 0, 0.49);
`;

export const Modal = styled.div`
  font-family: 'Helvetica';
  width: 50%;
  max-width: 400px;
  min-width: 300px;
  background: #fff;
  position: fixed;
  top: 50%;
  z-index: 100001;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 45px, rgba(0, 0, 0, 0.22) 0px 10px 18px;
  border-radius: 6px;
  overflow: hidden;
`;

export const Content = styled.div`

  .timepicker-modal .am-pm-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .text-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.13);
  }


  


  .timepicker-modal .mask {
    width: 100%;
    height: 100%;
  }

`;
