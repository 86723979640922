import { Content } from './video.styled';

export const Video = ({ fn }) => {
  return (
    <Content>
      <video autoPlay muted loop>
        <source src={fn} type="video/mp4" />
      </video>
    </Content>
  );
};
