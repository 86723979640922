import React, { Component } from 'react';
import { change } from 'redux-form';
import styled from 'styled-components';

const Wrapper = styled.div``;
const Select = styled.div`
  height: 100%;
  position: relative;
`;

export class Multiselect extends Component {
  constructor() {
    super();
    this.state = { popupIsOpen: false };
  }

  showPopup = () => {
    const { disabled } = this.props;
    // console.log('🚀 ~ this.props:', this.props);
    if (disabled) return;

    if (!this.state.popupIsOpen) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    this.setState({ popupIsOpen: !this.state.popupIsOpen });
  };

  handleOutsideClick = (e) => {
    if (this.popup) {
      if (this.popup.contains(e.target)) return;
      this.showPopup();
    }
  };

  setValue = (e, fieldValue) => {
    const { name, value } = this.props.input;
    const { dispatch, form } = this.props.meta;

    let newValue = [...value];

    if (e.target.checked) {
      newValue.push(fieldValue);
    } else {
      newValue.splice(newValue.indexOf(fieldValue), 1);
    }

    // need cuz empty string not storing in form reducer
    // unlike empty array
    if (newValue.length === 0) newValue = '';

    dispatch(change(form, name, newValue));
  };

  getValues = () => {
    const { data, input } = this.props;
    const value = input ? input.value : [];

    if (Array.isArray(value)) {
      let list = [];

      value.forEach((id) => {
        data.forEach((elem) => {
          if (id === elem.id) {
            list.push(elem);
          }
        });
      });

      return list;
    } else return [];
  };

  selectAll = () => {
    const { dispatch, form } = this.props.meta;
    const {
      data,
      input: { value, name },
    } = this.props;

    if (Array.isArray(data)) {
      let newValue = '';

      if (!value || value.length !== data.length) {
        newValue = data.map((elem) => {
          return elem.id;
        });
      }

      dispatch(change(form, name, newValue));
    }
  };

  render() {
    const Input = this.props.additionalComponent;

    const values = this.getValues();
    return (
      <Wrapper className={this.props.className}>
        <label>{this.props.label}</label>
        <Select
          ref={(popup) => {
            this.popup = popup;
          }}
        >
          <Input
            showPopup={this.showPopup}
            values={values}
            setValue={this.setValue}
            $popupIsOpen={this.state.popupIsOpen}
            selectAll={this.selectAll}
            data={this.props.data}
            countDisplayedIcons={this.props.countDisplayedIcons}
            {...this.props}
          />
        </Select>
      </Wrapper>
    );
  }
}
