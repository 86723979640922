import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { HeadLineWithMargin, Card, GeneralInfo } from 'modules/components/card/styledComponents';
import PersonalData from './PersonalData';
import { PageWrapper } from 'modules/components/pageComponents';
import { CloseCardButton } from 'libs/ui';
import { ProfileInfo, ProfileInfoData, ClientData, Tabs, Tab, StyledDataWrapper } from '../styledComponents';
import { addClient, removeClientInfo } from 'store/ducks/models/client';
import { addClientMessageDisplayHandler } from 'store/ducks/interface/clientsCard';
import { routes } from 'router';

const DataTab = styled(Tab)`
  border-bottom: solid 4px #3ea5ff;
  font-weight: bold;
`;

class CreateClientCard extends Component {
  componentDidMount() {
    if (!this.props.clientType) {
      this.closeCard();
    }
  }

  closeCard = () => {
    this.props.history.push(routes.clients);
  };

  openClientCard = (clientId) => {
    this.props.history.push(`${routes.client}?id=${clientId}`);
  };

  getClientType = () => {
    if (this.props.clientType) {
      const clientType = this.props.clientType;
      if (clientType === 'natural_person') return 'Физическое лицо';
      if (clientType === 'legal_person') return 'Юридическое лицо';
      if (clientType === 'sole_proprietor') return 'Индивидуальный предприниматель';
    }
  };

  getPersonalData = () => {
    if (this.props.clientType) {
      const clientType = this.props.clientType;
      if (clientType === 'natural_person')
        return (
          <PersonalData
            clientType={clientType}
            submitText="Создать карточку"
            updateClientInfo={(params) =>
              this.props.addClient({ info: { ...params.info, type: clientType } }, this.afterSubmit)
            }
          />
        );
      if (clientType === 'legal_person')
        return (
          <PersonalData
            clientType={clientType}
            submitText="Создать карточку"
            updateClientInfo={(params) =>
              this.props.addClient({ info: { ...params.info, type: clientType } }, this.afterSubmit)
            }
          />
        );
      if (clientType === 'sole_proprietor')
        return (
          <PersonalData
            clientType={clientType}
            submitText="Создать карточку"
            updateClientInfo={(params) =>
              this.props.addClient({ info: { ...params.info, type: clientType } }, this.afterSubmit)
            }
          />
        );
    }
  };

  afterSubmit = (id) => {
    this.props.addClientMessageDisplayHandler(true);
    this.openClientCard(id);
  };

  render() {
    return (
      <PageWrapper>
        <HeadLineWithMargin>
          <p>{this.props.t('cards.Карточка клиента')}</p>
          <CloseCardButton close={this.closeCard} />
        </HeadLineWithMargin>
        <Card>
          <GeneralInfo>
            <ProfileInfo>
              <img src={`/images/${this.props.clientType}.png`} alt="" />
              <ProfileInfoData>
                <p>{this.getClientType()}</p>
              </ProfileInfoData>
            </ProfileInfo>
            <Tabs>
              <DataTab>Данные</DataTab>
            </Tabs>
          </GeneralInfo>
          <StyledDataWrapper>
            <ClientData>{this.getPersonalData()}</ClientData>
          </StyledDataWrapper>
        </Card>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientType: state.client.typeOfNewClient,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    addClient,
    removeClientInfo,
    addClientMessageDisplayHandler,
  })(withTranslation()(CreateClientCard))
);
