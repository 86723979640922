import { useField } from 'formik';
import PropTypes from 'prop-types';

import { Field } from '../field/field';

export const TextField = (props) => {
  const { label, placeholder, ...other } = props;
  const [field, meta] = useField(other);

  const placeholderOk = placeholder ?? label;
  const id = props.name;
  const cmp = <input {...field} {...other} placeholder={placeholderOk} id={id} />;

  const error = meta.touched && meta.error ? meta.error : '';
  const params = { label, name: props.name, type: props.type, cmp, error };
  return <Field {...params} />;
};

TextField.defaultProps = {
  type: 'text',
};
TextField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
};
