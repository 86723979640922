const CLEAR_ENTRIES = '/pages/reports/refill/CLEAR_ENTRIES'
const ADD_ENTRIES = '/pages/reports/refill/ADD_ENTRIES'
const SET_COUNT = '/pages/reports/refill/SET_COUNT'
const SET_OFFSET = '/page/reports/refill/SET_OFFSET'
const SET_SORT_PARAMS = '/pages/reports/refill/SET_SORT_PARAMS'
const RESET_MODEL = '/pages/reports/refill/RESET_MODEL'

export default function reducer(state, action){

  const defaultState = {
    entries: {},
    offset: 0,
    count: 0,
    // sortParams: [{field_name: 'name', direction: 'desc'}]
  }

  if (!state) {
    state = defaultState
  }

  switch(action.type){
    case CLEAR_ENTRIES:
      return {
        ...state,
        entries: {}
      }
    case ADD_ENTRIES:
      return {
        ...state,
        entries: {
          ...state.entries,
          ...action.data
        }
      }
    case SET_COUNT:
      return {
        ...state,
        count: action.data
      }
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data
      }
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data
      }
    case RESET_MODEL:
      return {
        ...defaultState
      }
    default:
      return state
  }
}

export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES
  }
}
export const addEntries = (entries) => {
  return {
    type: ADD_ENTRIES,
    data: entries
  }
}
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value
  }
}
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value
  }
}
export const setCount = (value) => {
  return {
    type: SET_COUNT,
    data: value
  }
}
export const reset = () => {
  return {
    type: RESET_MODEL
  }
}
export const getRequestParams = (filter) => (dispatch, getState) => {
  let newOperations = {}
  for(let key in filter){
    if(key === 'amount_from' || key === 'amount_to'){
      if(filter[key] * 100){
        newOperations[key] = filter[key] * 100
      }
    }else if(filter[key]){
      newOperations[key] = filter[key]
    }
  }

  return newOperations
}
