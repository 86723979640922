import { useTranslation } from 'react-i18next';

import { CalendarInterval } from './date-select.styled';

export const CalInterval = (props) => {
  const { setPeriod, ...rest } = props;
  const { t } = useTranslation();
  return (
    <CalendarInterval className="calendar-intervals" {...rest}>
      <button type="button" onClick={() => setPeriod('today')}>
        {t('grid.Сегодня')}
      </button>
      <button type="button" onClick={() => setPeriod('currentWeek')}>
        {t('grid.7 дней')}
      </button>
      <button type="button" onClick={() => setPeriod('currentMonth')}>
        {t('grid.30 дней')}
      </button>
      <button type="button" onClick={() => setPeriod('lastThreeMonths')}>
        {t('grid.3 месяца')}
      </button>
      <button type="button" onClick={() => setPeriod('')}>
        {t('grid.Весь период')}
      </button>
    </CalendarInterval>
  );
};
