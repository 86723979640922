import React from 'react';
import { connect } from 'react-redux';

import { GridContainer } from 'libs/ui';
import { Controller } from 'libs/control';
import Grid from './Grid';
import Filter from './Filter';

import {
  getRequestParams,
  addBenefits,
  clearEntries,
  setSortParams,
  setItemInfo,
  addBenefit,
  updateBenefit,
  setOffset,
} from 'store/ducks/models/optbenefits';
import { setScroll, addFormDisplayHandler, updateFormDisplayHandler, updateMessageDisplayHandler } from 'store/ducks/interface/optbenefitsGrid';

/**
 * benefits options
 * @param {*} props
 * @returns
 */
const OptBenefits = (props) => {
  /**
   * additional flt
   * @param {*} params
   */
  const getRequestParams = (params) => {
    const out = {...params, type: 'check'};
    if (out.min_amount) {
      out.min_amount = parseInt(out.min_amount) * 100;
    }
    if (out.amount) {
      out.amount = parseInt(out.amount) * 100;
    }
    return out;
  };

  return (
    <GridContainer>
      <Controller
        type="benefit_types"
        grid={Grid}
        gridFilter={Filter}
        offset={props.offset}
        entries={props.categories}
        getRequestParams={getRequestParams}
        addEntries={props.addBenefits}
        clearEntries={props.clearEntries}
        addBenefit={props.addBenefit}
        updateBenefit={props.updateBenefit}
        setItemInfo={props.setItemInfo}
        itemInfo={props.itemInfo}
        addFormDisplayHandler={props.addFormDisplayHandler}
        updateFormDisplayHandler={props.updateFormDisplayHandler}
        updateMessageDisplayHandler={props.updateMessageDisplayHandler}
        setSortParams={props.setSortParams}
        setOffset={props.setOffset}
        setScroll={props.setScroll}
        sortParams={props.sortParams}
        interface={props.interface}
      />
    </GridContainer>
  );
};

const mapStateToProps = (store) => {
  return {
    categories: store.optbenefits.benefits,
    offset: store.optbenefits.offset,
    sortParams: store.optbenefits.sortParams,
    itemInfo: store.optbenefits.itemInfo,
    interface: store.optbenefitsGrid,
  };
};

export default connect(mapStateToProps, {
  getRequestParams,
  addBenefits,
  clearEntries,
  setSortParams,
  setItemInfo,
  addBenefit,
  updateBenefit,
  setOffset,
  setScroll,
  addFormDisplayHandler,
  updateFormDisplayHandler,
  updateMessageDisplayHandler,
})(OptBenefits);
