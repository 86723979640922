import styled from 'styled-components';

export const TableHeaders = styled.th`
  width: ${(props) => props.width};
  text-align: ${(props) => (props.number ? 'end' : 'start')};
  padding-right: ${(props) => (props.number ? '35px' : '0')};
  position: relative;
  border-bottom: 1px solid #929292;
  height: 35px;
  font-size: 15px;
  color: #000000;
  opacity: 1;
  font-weight: 900;
`;

export const SortTableHeaders = styled(TableHeaders)`
  pointer-events: auto;
  &:hover {
    font-weight: bold;
    opacity: 0.6;
  }

  div {
    cursor: pointer;
    p {
      display: inline-block;
      margin-right: 10px;
    }
  }
`;

export const PaymentsTableHeaders = styled(TableHeaders)`
  color: #b7babf;
  opacity: 1;
  border-bottom: 1px solid #4d5156;
`;
