import { reduxForm } from 'redux-form';
import moment from 'moment';

import { Headline, PageWrapper } from 'modules/components/pageComponents';
import { Container, DateTime, DoubleField, required, WhiteStyledField, DateSelect } from 'libs/form';
import { Button } from 'libs/ui';

const Test = (props) => {
  const { handleSubmit, change } = props;

  const submit = (values) => {
    console.log('🚀 ~ submit ~ out:', values);
    return Promise.resolve();
  };

  const name = 'date';
  const fn1 = name + '_from';
  const fn2 = name + '_to';

  const name2 = 'date2';
  const fn21 = name2 + '_from';
  const fn22 = name2 + '_to';

  return (
    <PageWrapper>
      <Headline>Test</Headline>
      <br />

      <form onSubmit={handleSubmit(submit)}>
        <WhiteStyledField
          className="shortfield"
          type="dateTime"
          label="Дата действия с"
          placeholder="Дата действия с"
          name="start_at"
          validate={required}
        />
        <br />
        <DateSelect from_name={fn1} to_name={fn2} change={change}>
          <DoubleField>
            <DateTime width="160px" name={fn1} type="dateTime" label={'Дата'} />
            <DateTime width="160px" name={fn2} type="dateTime" />
          </DoubleField>
        </DateSelect>

        <br />
        <Container theme="dark">
          <DateSelect from_name={fn21} to_name={fn22} change={change} theme="dark">
            <DoubleField>
              <DateTime width="160px" name={fn21} type="dateTime" label={'Дата'} theme="dark" />
              <DateTime width="160px" name={fn22} type="dateTime" theme="dark" />
            </DoubleField>
          </DateSelect>
        </Container>

        <br />
        <br />
        <Button type="submit">Save</Button>
      </form>
    </PageWrapper>
  );
};

const d = moment();
const initial = {
  // status: 'active',
  start_at: d.startOf('day').format(),
  start_to: d.endOf('day').format(),
};

export default reduxForm({
  form: 'testForm',
  destroyOnUnmount: false,
  initialValues: initial,
})(Test);

// export default Test;
