import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { user } from 'services';
import { Button, GridRow, Cell, OptionsCell, ImgViewer } from 'libs/ui';
import { getDateTime, getParking } from 'services/types';
import { routes } from 'router';

const PictureRow = styled(GridRow)`
  height: 300px;
  width: 1300px;
  display: flex;
  justify-content: space-between;
`;
const ImageWrap = styled.td`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 !important;
`;

const ClosePicturesButton = styled(Button)`
  position: absolute;
  bottom: 15px;
  left: 46%;
`;

class Benefit extends Component {
  state = { showPhotos: false };

  showCard = () => {
    if (this.props.disableClick === true) {
      return;
    }
    this.props.history.push(`${routes.stay}?id=${this.props.stay_id}`);
  };

  togglePhotos = () => {
    this.setState({ showPhotos: !this.state.showPhotos });
  };

  getOptionsCell = () => {
    // const options = [{ text: this.state.showPhotos ? 'Скрыть фото' : 'Показать фото', func: this.togglePhotos }];
    const options = [];
    if (this.props.stay_id && this.props.stay_id !== 'system_30_07_2018_rfids' && user.haveRight('stays.view')) {
      options.push({ text: 'Перейти в карточку парковки', func: this.showCard });
    }
    return <OptionsCell options={options} left={-61} />;
  };

  render() {
    const parking = this.props.parking_id ? getParking(this.props.parking_id) : null;
    return (
      <>
        <GridRow onClick={this.togglePhotos}>
          {window.config.central && <Cell pr10>{parking}</Cell>}
          <Cell pr10>{getDateTime(this.props.created_at)}</Cell>
          <Cell pr10>{this.props.vehicle_plate}</Cell>
          <Cell>{this.props.mean_id}</Cell>
          {this.getOptionsCell()}
        </GridRow>
        {this.state.showPhotos ? (
          <PictureRow>
            <ImageWrap>
              <ImgViewer images={[this.props.photo]} width={'432px'} height={'300px'} />
              <ClosePicturesButton
                onClick={() => {
                  this.setState({ showPhotos: !this.state.showPhotos });
                }}
              >
                <i className="fa fa-arrow-up" />
                Скрыть
              </ClosePicturesButton>
            </ImageWrap>
          </PictureRow>
        ) : null}
      </>
    );
  }
}

export default withRouter(Benefit);
