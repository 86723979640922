const SET_SCROLL_POSITION = '/page/client/card/SET_SCROLL_POSITION'
const SET_WARNING = '/page/client/card/SET_WARNING'
const REMOVE_WARNING = '/page/client/card/REMOVE_WARNING'
const SHOW_NEW_CONTACT_FORM = '/page/client/card/SHOW_NEW_CONTACT_FORM'
const HIDE_NEW_CONTACT_FORM = '/page/client/card/HIDE_NEW_CONTACT_FORM'
const SHOW_MESSAGE_AFTER_ADD_CLIENT = '/page/client/card/SHOW_MESSAGE_AFTER_ADD_CLIENT'
const SHOW_MESSAGE_AFTER_ADD_CONTACT = '/page/client/card/SHOW_MESSAGE_AFTER_ADD_CONTACT'

export default function reducer(state, action) {

  const defaultState = {
    scrollPosition: 0,
    warning: null,
    showNewContactForm: false,
    showMessageAfterAddClient: false,
    showMessageAfterAddContact: false,
  }

  if (!state) { state = defaultState }

  switch (action.type) {
    case SET_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.data
      }
    case SET_WARNING:
      return {
        ...state,
        warning: {
          rfid: action.rfid,
          reqInfo: action.data
        }
      }
    case REMOVE_WARNING: 
      return {
        ...state,
        warning: null
      }
    case SHOW_NEW_CONTACT_FORM: 
      return {
        ...state,
        showNewContactForm: true
      }
    case HIDE_NEW_CONTACT_FORM: 
      return {
        ...state,
        showNewContactForm: false
      }
    case SHOW_MESSAGE_AFTER_ADD_CLIENT: 
      return {
        ...state,
        showMessageAfterAddClient: action.data
      }
    case SHOW_MESSAGE_AFTER_ADD_CONTACT: 
      return {
        ...state,
        showMessageAfterAddContact: action.data
      }
    default: return state
  }
}

export const setScroll = (value) => {
  return {
    type: SET_SCROLL_POSITION,
    data: value
  }
}
export const setWarning = (rfid, value) => {
  return {
    type: SET_WARNING,
    rfid: rfid,
    data: value
  }
}
export const removeWarning = () => {
  return {
    type: REMOVE_WARNING
  }
}
export const showNewContactForm = () => {
  return {
    type: SHOW_NEW_CONTACT_FORM
  }
}
export const hideNewContactForm = () => {
  return {
    type: HIDE_NEW_CONTACT_FORM
  }
}
export const addClientMessageDisplayHandler = (value) => {
  return {
    type: SHOW_MESSAGE_AFTER_ADD_CLIENT,
    data: value
  }
}
export const addContactMessageDisplayHandler = (value) => {
  return {
    type: SHOW_MESSAGE_AFTER_ADD_CONTACT,
    data: value
  }
}