import styled, { css } from 'styled-components';

import { Select } from './fields';

export const StyledSelect = styled(Select)`
  ${(props) =>
    props.theme == 'black' &&
    css`
      select {
        color: #fff;
        background-color: #4f5761;
      }
      label {
        color: #b7babf;
      }
    `}
`;
