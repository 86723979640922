import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
  color: #fff;
`;

export const Buttons = styled.div`
  margin: 30px 0 0;
`;
