import styled, { css } from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
`;

export const Dial = styled.div`
  width: 260px;
  height: 260px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #efefef;
  border-radius: 50%;
  user-select: none;
`;

export const Hand = styled.div`
  user-select: none;
  width: 45%;
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform-origin: center left 0px;
  pointer-events: none;
  transform: rotateZ(0deg);
  height: 2px;
  background: #3ea5ff;
  &:after {
    position: absolute;
    content: '';
    top: -5px;
    border-radius: 50%;
    right: 0;
    width: 12px;
    height: 12px;
    background: inherit;
  }
`;

export const ClockLabel = styled.span`
  user-select: none;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;

  ${(props) =>
    props.$active &&
    css`
      color: #fff;
      background: #3ea5ff;
    `}

  &:nth-of-type(1) {
    transform: translate3d(0px, -110px, 0) translateX(-50%) translateY(-50%);
  }

  &:nth-of-type(2) {
    transform: translate3d(55px, -95px, 0) translateX(-50%) translateY(-50%);
  }

  &:nth-of-type(3) {
    transform: translate3d(95px, -55px, 0) translateX(-50%) translateY(-50%);
  }

  &:nth-of-type(4) {
    transform: translate3d(110px, 0px, 0) translateX(-50%) translateY(-50%);
  }

  &:nth-of-type(5) {
    transform: translate3d(95px, 55px, 0) translateX(-50%) translateY(-50%);
  }

  &:nth-of-type(6) {
    transform: translate3d(55px, 95px, 0) translateX(-50%) translateY(-50%);
  }

  &:nth-of-type(7) {
    transform: translate3d(0px, 110px, 0) translateX(-50%) translateY(-50%);
  }

  &:nth-of-type(8) {
    transform: translate3d(-55px, 95px, 0) translateX(-50%) translateY(-50%);
  }

  &:nth-of-type(9) {
    transform: translate3d(-95px, 55px, 0) translateX(-50%) translateY(-50%);
  }

  &:nth-of-type(10) {
    transform: translate3d(-110px, 0px, 0) translateX(-50%) translateY(-50%);
  }

  &:nth-of-type(11) {
    transform: translate3d(-95px, -55px, 0) translateX(-50%) translateY(-50%);
  }

  &:nth-of-type(12) {
    transform: translate3d(-55px, -95px, 0) translateX(-50%) translateY(-50%);
  }
`;
