import { api } from 'api';

import { UPDATE_COMMENT_VP } from './pay.const'

const CLEAR_ENTRIES = '/pages/payments/vehiclePlates/CLEAR_ENTRIES';
const ADD_LIST = '/pages/payments/vehiclePlates/ADD_LIST';
const SET_LIST_COUNT = '/pages/payments/vehiclePlates/SET_STAYS_COUNT';
const SET_OFFSET = '/page/payments/vehiclePlates/SET_OFFSET';
const SET_SORT_PARAMS = '/pages/payments/vehiclePlates/SET_SORT_PARAMS';
const SET_IMPORT_ERRORS = '/pages/payments/vehiclePlates/SET_IMPORT_ERRORS';
const SET_UPLOAD_DATA = '/pages/payments/vehiclePlates/SET_UPLOAD_DATA';
const SET_ITEM_INFO = '/pages/payments/vehiclePlates/SET_ITEM_INFO';

const SET_FORM_INFO = '/pages/payments/vehiclePlates/SET_FORM_INFO';
const REMOVE_FORM_INFO = '/pages/payments/vehiclePlates/REMOVE_FORM_INFO';


export default function reducer(state, action) {
  const defaultState = {
    vehiclePlates: {},
    item: {},
    importErrors: null,
    offset: 0,
    count: 0,
    sortParams: [],
    uploadData: null,
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case CLEAR_ENTRIES:
      return {
        ...state,
        vehiclePlates: {},
      };
    case SET_IMPORT_ERRORS:
      return {
        ...state,
        importErrors: action.data,
      };
    case ADD_LIST:
      return {
        ...state,
        vehiclePlates: {
          ...state.vehiclePlates,
          ...action.data,
        },
      };
    case SET_LIST_COUNT:
      return {
        ...state,
        count: action.data,
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    case SET_UPLOAD_DATA:
      return {
        ...state,
        uploadData: action.data,
      };
    case SET_ITEM_INFO:
      return {
        ...state,
        item: action.data,
      };
    case SET_FORM_INFO:
      return {
        ...state,
        formInfo: action.data,
      };
    case REMOVE_FORM_INFO:
      return {
        ...state,
        formInfo: null,
      };
    case UPDATE_COMMENT_VP:
      return {
        ...state,
        vehiclePlates: {
          ...state.vehiclePlates,
          [action.id]: {
            ...state.vehiclePlates[action.id],
            comment: action.comment,
            comments: [1],
          },
        },
      };

    default:
      return state;
  }
}

export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};
export const setCount = (value) => {
  return {
    type: SET_LIST_COUNT,
    data: value,
  };
};
export const addList = (stays) => {
  return {
    type: ADD_LIST,
    data: stays,
  };
};
export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const setImportErrors = (importErrors) => {
  return {
    type: SET_IMPORT_ERRORS,
    data: importErrors,
  };
};
export const clearErrors = () => {
  return {
    type: SET_IMPORT_ERRORS,
    data: null,
  };
};
export const setUploadData = (data) => {
  return {
    type: SET_UPLOAD_DATA,
    data: data,
  };
};
export const setItemInfo = (value) => {
  return {
    type: SET_ITEM_INFO,
    data: value,
  };
};

export const getRequestParams = (filter) => (dispatch, getState) => {
  const decorateParams = {};

  if (filter) {
    for (let key in filter) {
      if (filter[key]) {
        let key1 = key;
        let val = filter[key];
        if (key === 'list') {
          key1 = 'is_blocked';
          val = val !== 'white';
        }
        decorateParams[key1] = val;
      }
    }
  }
  //console.log(decorateParams);
  return decorateParams;
};

export const sendFileContent = (params, callback) => (dispatch) => {
  return api.call('plates.import_list', params).then(
    (res) => {
      if (callback) {
        callback();
      }
    },
    (error) => {
      dispatch(setImportErrors(error.data));
    }
  );
};

export const addItem = (params, callback) => (dispatch) => {
  return api.call('plates.add', params).then(
    (res) => {
      if (callback) {
        callback();
      }
    },
    (error) => {
      return error;
    }
  );
};

export const updateItem = (params, callback) => (dispatch) => {
  //console.log('🚀 ~ updateItem ~ params', params);

  return api.call('plates.update', params).then(
    (res) => {
      if (callback) {
        callback();
      }
    },
    (error) => {
      return error;
    }
  );
};

export const delItem = (id, callback) => (dispatch) => {
  return api.call('plates.delete', { id: id }).then(
    (res) => {
      if (callback) {
        callback();
      }
    },
    (error) => {
      return error;
    }
  );
};
