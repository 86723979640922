const CLEAR_ENTRIES = '/pages/reports/actions/CLEAR_ENTRIES'
const ADD_ENTRIES = '/pages/reports/actions/ADD_ENTRIES'
const SET_COUNT = '/pages/reports/actions/SET_COUNT'
const SET_OFFSET = '/pages/reports/actions/SET_OFFSET'
const SET_SORT_PARAMS = '/pages/reports/actions/SET_SORT_PARAMS'
const SET_ACTION_INFO = '/pages/reports/actions/SET_ACTION_INFO'

export default function reducer(state, action) {

  const defaultState = {
    entries: {},
    offset: 0,
    count: 0,
    actionInfo: null,
    sortParams: [{field_name: 'timestamp', direction: 'desc'}]
  }

  if (!state) {
    state = defaultState
  }

  switch (action.type) {
    case CLEAR_ENTRIES:
      return {
        ...state,
        entries: {}
      }
    case ADD_ENTRIES:
      return {
        ...state,
        entries: {
          ...state.entries,
          ...action.data
        }
      }
    case SET_COUNT:
      return {
        ...state,
        count: action.data
      }
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data
      }
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data
      }
    case SET_ACTION_INFO:
      return {
        ...state,
        actionInfo: action.data
      }
    default: return state
  }
}

export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES
  }
}
export const addEntries = (entries) => {
  return {
    type: ADD_ENTRIES,
    data: entries
  }
}
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value
  }
}
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value
  }
}
export const setCount = (value) => {
  return {
    type: SET_COUNT,
    data: value
  }
}
export const setActionInfo = (info) => {
  return {
    type: SET_ACTION_INFO,
    data: info
  }
}

export const getRequestParams = (filter) => (dispatch, getState) => {
  let decorateParams = {}

  for ( let key in filter ) {
    if (filter[key] || filter[key] === 0) {
      switch (key) {
        case 'role_id':
          decorateParams[key] = Number(filter[key])
          break
        default:
          decorateParams[key] = filter[key]
      }
    }
  }
  return decorateParams
}