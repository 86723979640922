import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Card, StyledGrid, GeneralInfo, DataWrapper } from 'modules/components/card/styledComponents';
import { PageWrapper, Headline } from 'modules/components/pageComponents';
import { CloseCardButton, Loader2 } from 'libs/ui';
import ClientsGrid from '../grid/Grid';
const StyledClientsGrid = styled(StyledGrid).attrs({ as: ClientsGrid })``;

const LoadingScreen = (props) => {
  const { t } = useTranslation();
  const closeCard = () => {
    props.history.goBack();
  };

  return (
    <PageWrapper>
      <Headline>
        <p>{t('cards.Карточка клиента')}</p>
        <CloseCardButton close={closeCard} />
      </Headline>
      <StyledClientsGrid hideTotal clients={[]} />
      <Card>
        <Loader2 />
        <GeneralInfo />
        <DataWrapper />
      </Card>
    </PageWrapper>
  );
};

export default withRouter(LoadingScreen);
