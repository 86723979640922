import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';

import zonesClass from 'services/zones';
import { Tariffication } from './tariffication';
import { Content, Tabs, Tab } from './tarifficator-default.styled';

export const TarrificatorDefault = (props) => {
  const { type, newIndividual, selectedZone, setZone, initialValue, tariffication, disabled } = props;
  //console.log('🚀 ~ TarrificatorDefault ~ initialValue:', initialValue);
  const zones = zonesClass.getZones();

  // default zone
  useEffect(() => {
    if (!selectedZone) {
      setZone(zones[0]);
    }
  }, []);

  /**
   * get zone tab name
   */
  const getTabName = (name) => {
    if (name !== 'P6/P11') {
      return <div>{name}</div>;
    }
    const splitName = name.split('/');
    return (
      <>
        <div style={{ marginRight: '10px' }}>{splitName[0]}</div>
        <div>{splitName[1]}</div>
      </>
    );
  };

  /**
   * get tabs
   * @returns
   */
  const getTabs = () => {
    const zonesArray = zones;
    let zoneType = null;
    let isSelected = null;
    const tabs = zonesArray.map((zone, idx) => {
      zone.name === 'P6/P11' ? (zoneType = 'double') : (zoneType = 'single');
      if (selectedZone && selectedZone.id) {
        isSelected = zone.id === selectedZone.id;
      }
      return (
        <Tab isSelected={isSelected} key={idx} color={zone.color} type={zoneType} onClick={() => setZone(zone)}>
          {getTabName(zone.name)}
        </Tab>
      );
    });
    return <Tabs>{tabs}</Tabs>;
  };

  /**
   * get zones elements
   * @returns
   */
  const getZones = () => {
    if (!selectedZone || !Array.isArray(zones)) {
      return null;
    }

    return zones.map((zone, idx) => {
      const show = selectedZone.id === zone.id;
      return (
        <FormSection key={idx} name={zone.id}>
          <Tariffication
            //zones={props.zones}
            selectedZone={selectedZone}
            show={show}
            name={zone.id}
            disabled={disabled}
            newIndividual={newIndividual}
          />
        </FormSection>
      );
    });
  };

  return (
    <Content>
      {getTabs()}
      {getZones()}
    </Content>
  );
};

TarrificatorDefault.propTypes = {
  type: PropTypes.any,
  selectedZone: PropTypes.any,
  setZone: PropTypes.func.isRequired,
  initialValue: PropTypes.any,
  tariffication: PropTypes.any,
  zones: PropTypes.any,
  disabled: PropTypes.bool,
  newIndividual: PropTypes.bool,
};
