import React from 'react';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { required } from 'libs/form';
import { ErrorMsg, StyledField, StyledSubmit } from './form.styled';

const Form = ({ error, handleSubmit, submitting, valid }) => {
  const { t } = useTranslation();

  const getErrorMessage = () => {
    switch (error) {
      case 'Invalid params: user not found':
      case 'User not found':
      case 'Invalid password':
        return t('errors.invalidPassword');
      case 'User is blocked':
        return t('errors.userIsBlocked');
      default:
        return t('errors.loginError');
    }
  };

  const tlogin = t('form.login');
  const tpassword = t('form.password');
  const tloginbtn = t('form.loginBtn');

  return (
    <form onSubmit={handleSubmit}>
      {error ? (
        <ErrorMsg>
          <img src="/images/error-triangle-icon.png" alt="" />
          {getErrorMessage()}
        </ErrorMsg>
      ) : null}
      <StyledField type="text" label={tlogin} name="username" placeholder={tlogin} error={error} withoutWrapper validate={required} />
      <StyledField type="password" label={tpassword} name="password" placeholder={tpassword} error={error} withoutWrapper validate={required} />
      <StyledSubmit disabled={!valid || submitting}>{tloginbtn}</StyledSubmit>
    </form>
  );
};

export const LoginForm = reduxForm({ form: 'auth' })(Form);
