import moment from 'moment';

export const getTableParams = () => {
  const fields = [
    { name: 'vehicle_plate', title: 'ГРЗ', width: 130, sortField: 'vehicle_plate' },
    {
      name: 'is_active',
      title: 'Статус',
      width: 145,
      sortField: 'is_active',
      render: (val) => (val ? 'Активен' : 'Деактивирован'),
    },
    { name: 'start_at', title: 'Дата действия с', width: 210, sortField: 'created_at', render: (val) => moment.unix(val).format('DD.MM.YYYY HH:mm') },

    { name: 'end_at', title: 'Дата действия по', width: 210, sortField: 'end_at', render: (val) => moment.unix(val).format('DD.MM.YYYY HH:mm') },

    { name: 'reason', title: 'Причина внесения', width: 112, sortField: 'reason' },
    { name: 'comment', title: 'Комментарий', width: 150 },
  ];

  // get row class
  const getRowClass = (row) => {
    let out = '';
    out += ' ' + row.status;
    return out;
  };

  return { fields, getRowClass, withComment: false };
};
