import React from 'react';
import PropTypes from 'prop-types';

import { zones } from 'services/zones';
import { Content, ZoneIcon } from './zones-icons.styled';

export const ZonesIcons = (props) => {
  const { activeZoneId } = props;

  const zonesItems = zones.getZones();
  const icons = [];

  zonesItems.forEach((el, idx) => {
    if ((activeZoneId && el.id === activeZoneId) || !activeZoneId) {
      icons.push(
        <ZoneIcon key={idx} color={el.color}>
          {el.name}
        </ZoneIcon>
      );
    }
  });

  return <Content>{icons}</Content>;
};

ZonesIcons.propTypes = {
  activeZoneId: PropTypes.any,
};
