const SET_SCROLL_POSITION = '/page/working/grid/SET_SCROLL_POSITION'

export default function reducer(state, action) {

    const defaultState = {
        scrollPosition: 0
    }

    if (!state) { state = defaultState }

    switch (action.type) {
        case SET_SCROLL_POSITION:
            return {
                ...state,
                scrollPosition: action.data
            }
        default: return state
    }
}

export const setScroll = (value) => {
    return {
        type: SET_SCROLL_POSITION,
        data: value
    }
}