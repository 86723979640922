import { useState } from 'react';
import PropTypes from 'prop-types';

import { changeRfidStatus } from 'services/payments-service';
import Warning from 'modules/components/warnings';
import { Button, themes, ButtonContainer } from 'libs/ui';
import { FormBox, Header, CommentBox, CommentFieldName, CommentInput } from './change-status.styled';

export const ChangeStatus = (props) => {
  const { close, type, info, updateEntry } = props;
  const [comment, setComment] = useState('');
  const [warning, setWarning] = useState(false);
  const typeTitle = type === 'rfid' ? 'RFID' : 'БСК';
  const isBlocked = info.status === 'blocked' || info.status === 'blocked_by_client';

  /**
   * change status
   */
  const changeStatus = () => {
    if (isBlocked) {
      change();
      close();
    } else {
      setWarning(true);
    }
  };

  const change = () => {
    const params = {
      id: info.id,
      status: isBlocked ? 'active' : 'blocked',
      comment: comment,
    };
    changeRfidStatus(params).then((data) => {
      if (!data.error) {
        updateEntry(data.id, data);
      }
    });
  };

  return (
    <>
      <FormBox type={type}>
        <Header>{typeTitle + ' ' + (info.rfid || info.mean_id)}</Header>
        <CommentBox>
          <CommentFieldName>Комментарий</CommentFieldName>
          <CommentInput placeholder="Напишите свой комментарий" value={comment} onChange={(e) => setComment(e.target.value)}></CommentInput>
        </CommentBox>
        <ButtonContainer space="20px" justifyContent="flex-end">
          <Button onClick={close}>Отменить</Button>
          <Button theme={isBlocked ? themes.green : themes.red} onClick={changeStatus} status={info.status}>
            <img width="10" src="/images/icon_lock.png" alt="" />
            {isBlocked ? 'Разблокировать ' + typeTitle : 'Заблокировать ' + typeTitle}
          </Button>
        </ButtonContainer>
      </FormBox>
      
      {warning && (
        <Warning
          width="540px"
          header="Блокировка RFID метки"
          mainText={`Блокированные RFID метки не дают право проезда на парковку. Вы уверены что хотите заблокировать RFID №${info.rfid || info.mean_id}?`}
          cancelButton={{
            func: () => setWarning(false),
            text: 'Отменить',
          }}
          acceptButton={{
            func: () => {
              change();
              setWarning(false);
              close();
            },
            text: 'Заблокировать RFID',
            img: '/images/icon_lock.png',
            theme: themes.red,
          }}
        />
      )}
    </>
  );
};

ChangeStatus.propTypes = {
  width: PropTypes.any,
  type: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

ChangeStatus.defaultProps = {
  width: '440px',
};
