import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { user, data } from 'services';
import { getDefaultPage } from './router-func';

/**
 * private route
 *
 * @param {*} param0
 */
export const PrivateRoute = ({ component: Component, params, ...rest }) => {
  if (!user.isLogined) {
    return <Redirect to="/auth" />;
  } else {
    //console.log(params, rest)
    if (rest.path === '/scheme') {
      const defaultPage = getDefaultPage();
      return <Redirect to={defaultPage} />;
    }

    return <Route {...rest} render={(props) => <Component {...props} {...params} />} />;
  }
};
