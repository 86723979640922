import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';

import { WhiteStyledField } from 'libs/form';
import { Button, ButtonContainer, themes } from 'libs/ui';
import { required } from 'libs/form';

const TextareaStyledField = styled(WhiteStyledField)`
  height: 80px;
  align-items: start;
  padding: 9px 0;
`;
const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
`;

let Form = (props) => {
  const submit = (values) => {
    if (props.type === 'add') {
      props.addEntity(values, afterSubmit);
    } else if (props.type === 'update') {
      props.updateEntity(props.id, values, afterSubmit);
    }
  };

  const afterSubmit = () => {
    props.getEntries({ offset: 0 });
    props.displayHandler(false);
    props.updateMessageDisplayHandler(true);
    setTimeout(() => {
      props.updateMessageDisplayHandler(false);
    }, 3000);
    props.destroy(props.form);
  };

  return (
    <form onSubmit={props.handleSubmit(submit)}>
      <WhiteStyledField validate={required} placeholder="Категория" type="text" label="Название" name="name" autoFocus />
      <TextareaStyledField placeholder="Напишите комментарий..." type="textarea" label="Комментарий" name="comment" />
      <StyledButtonContainer space="20px">
        <Button
          type="button"
          onClick={() => {
            props.displayHandler(false);
            props.destroy(props.form);
          }}
        >
          Отменить
        </Button>
        <Button theme={themes.blue}>
          <i className="fa fa-save" />
          Сохранить
        </Button>
      </StyledButtonContainer>
    </form>
  );
};

Form = reduxForm()(Form);

Form = connect((store, props) => {
  let formName = '';
  if (props.type === 'add') {
    formName = 'addCategotyForm';
  } else if (props.type === 'update') {
    formName = `updateCategotyForm_${props.id}`;
  }

  return {
    form: formName,
    destroyOnUnmount: false,
    initialValues: props.initialValues,
  };
})(Form);

export default Form;
