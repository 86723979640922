import { data } from 'services';
import { getCategoryById, getVehicleClassById } from 'services/types';
import { ParkingCell } from 'libs/ui';

export const getTableParams = () => {
  const fields = [
    { name: 'rfid', title: 'RFID', width: 120, sortField: 'rfid' },
    { name: 'vehicle_plate', title: 'ГРЗ', width: 130, sortField: 'vehicle_plate' },
    { name: 'status', title: 'Статус', width: 145, sortField: 'status', render: (val) => data.getDictValue('rfid.status', val) },
    { name: 'owner', title: 'Держатель', sortField: 'owner' },
    { name: 'client_name', title: 'Клиент', width: 147, sortField: 'client_name' },
    { name: 'vehicle_class', title: 'Класс ТС', width: 112, sortField: 'vehicle_class', render: (val) => getVehicleClassById(val) },
    { name: 'vehicle_category', title: 'Категория', width: 112, sortField: 'vehicle_category', render: (val) => getCategoryById(val) },
    { name: 'tariff', title: 'Тариф', width: 180 },
    { name: 'comment', title: 'Комментарий', width: 150 },
  ];

  if (window.config.central) {
    fields.unshift({ name: 'parking_id', title: 'Парковка', render: (val) => <ParkingCell parking_id={val} cmp={'span'} />, width: 140 });
  }

  // get row class
  const getRowClass = (row) => {
    let out = '';
    out += ' ' + row.status;
    return out;
  };

  return { fields, getRowClass, withComment: true };
};
