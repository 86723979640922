import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { user } from 'services';
import { Submit, ButtonContainer } from 'libs/ui';
import { StyledField, required } from 'libs/form';

function ContactFaceForm(props) {
  const rightUpdate = user.haveRight('clients.update');

  return (
    <form onSubmit={props.handleSubmit}>
      <StyledField validate={required} type="text" label="ФИО" name="name" placeholder="Иванов Иван Иванович" />
      <StyledField validate={required} type="text" label="Телефон" name="tel_num" placeholder="+7 956 456-56-78" />
      <StyledField type="text" label="Доп.телефон" name="tel_num_2" placeholder="+7 926 442-65-34" />
      <StyledField type="text" label="E-mail" name="email" placeholder="first@email.com" />
      <StyledField type="text" label="Доп. email" name="email_2" placeholder="second@email.com" />
      <StyledField type="text" label="Веб-сайт" name="website" placeholder="website.com" />
      <StyledField type="text" label="Адрес" name="address" placeholder="г. Санкт-Петербург, Загородный проспект 26, кв 43" />
      {rightUpdate && (
        <ButtonContainer>
          <Submit disabled={props.submitting} />
        </ButtonContainer>
      )}
    </form>
  );
}

export default connect((store, props) => ({
  form: 'ContactFaceForm_' + (props.info && props.info.id ? props.info.id : 'new'),
  initialValues: {
    ...props.info,
  },
}))(reduxForm()(ContactFaceForm));
