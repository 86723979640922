import styled from 'styled-components';

export const Content = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmTitle = styled.span`
  display: block;
`;
