const errors = {
  'Forbidden: invalid password': 'Неверный пароль',
  'Invalid params: user not found': 'Пользователь не найден',
  ERR_NETWORK: 'Сетевая ошибка',
  ECONNABORTED: 'Соединение отменено',
  404: 'Страница не найдена',
  'duplicate': 'Уже существует',
};

export const getRpcError = (msg) => {
  if (typeof msg === 'object') {
    if (msg.type) {
      msg.message = errors[msg.type] ? errors[msg.type] : msg.type;
    }
    return msg;
  }
  const out = errors[msg] ? errors[msg] : msg;
  return out;
};
