import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { SubmissionError } from 'redux-form';
import styled from 'styled-components';

import { required, WhiteStyledField, DictInput } from 'libs/form';
import { Button, themes, ButtonContainer } from 'libs/ui';

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 20px;
`;

class Form extends React.Component {
  submit = (values) => {
    //values.password = values.username;
    let decorateValues = {};

    for (let key in values) {
      switch (key) {
        case 'is_active':
          if (values[key].toString() === 'true') {
            decorateValues[key] = true;
          } else if (values[key].toString() === 'false') {
            decorateValues[key] = false;
          }
          break;
        default:
          decorateValues[key] = values[key];
          break;
      }
    }

    return this.props.updateEntity(this.props.id, decorateValues, this.afterSubmit).then((errors) => {
      if (errors && errors.data && errors.data.type === 'duplicate') {
        throw new SubmissionError({
          card_id: 'duplicate',
        });
      }
    });
  };

  afterSubmit = () => {
    this.props.getEntries({ offset: 0 });
    this.props.updateMessageDisplayHandler(true);
    setTimeout(() => {
      this.props.updateMessageDisplayHandler(false);
    }, 3000);
    this.props.displayHandler(false);
    this.props.destroy(this.props.form);
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.submit)}>
        <DictInput dict="users.status" />
        <WhiteStyledField type="text" label="Номер БСК" placeholder="Номер БСК" name="card_id" validate={required} readOnly disabled />
        <WhiteStyledField type="text" label="ФИО" placeholder="Иванов Иван Иванович" name="name" validate={required} />
        <StyledButtonContainer space="20px">
          <Button
            type="button"
            onClick={() => {
              this.props.displayHandler(false);
              this.props.destroy(this.props.form);
            }}
          >
            Отменить
          </Button>
          <Button theme={themes.blue}>
            <i className="fa fa-save" />
            Сохранить
          </Button>
        </StyledButtonContainer>
      </form>
    );
  }
}

Form = reduxForm()(Form);

Form = connect((store, props) => {
  return {
    form: `updateUserForm_${props.id}`,
    destroyOnUnmount: false,
    initialValues: props.initialValues,
  };
})(Form);

export default Form;
