import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { user } from 'services';
import { api } from 'api';
import { Button, themes } from 'libs/ui';
//import { getMeanType3 } from 'services/types';
//import { printNewTicket } from 'store/ducks/models/stays';
//import { getStayInfo } from 'store/ducks/models/stays';
import { AppPay } from './forms/app-pay';

export const AppPayAction = () => {
  const info = useSelector((state) => state.stays.stay);
  //const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  // check
  if (!info.is_active || !user.haveRight('stays.view')) {
    return null;
  }

  /**
   * show form
   */
  const showForm = () => {
    setShow(true);
  };

  /**
   * hide form
   */
  const hideForm = () => {
    setShow(false);
  };

  /**
   * change mean status
   */
  const action = ({ parkomat, value, comment }) => {
    const { parking_id, id } = info;
    const params = {
      stay_id: typeof id === 'string' ? id : null,
      payment_rack_id: parkomat,
      penalty: value ? value * 100 : 0,
      comment: penalty ? comment : '',
    };

    const withParkingId = window.config.central ? parking_id : '';

    return api.call('stays.print_receipt', params, 'NetworkManager', withParkingId ? '/' + parking_id : '');
  };

  return (
    <>
      <Button title="Оплата" theme={themes.gray} onClick={showForm}>
        Оплата
      </Button>
      {show && <AppPay id={info.id} parkingId={info.parking_id} close={hideForm} action={action} />}
    </>
  );
};
