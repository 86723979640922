import { api } from 'api';

const SET_REPORT = 'pages/reports/reports/SET_REPORT';
const SET_REPORT_DATA = 'pages/reports/reports/SET_REPORT_DATA';
const SET_WEEKS_FILTER = 'pages/reports/reports/SET_WEEKS_FILTER';

export default function reportsReducer(state, action) {
  const defaultState = {
    selectedReport: 'parkCost',
    data: null, //must be obj in future
    filter: {},
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case SET_REPORT:
      return {
        ...state,
        selectedReport: action.data,
      };
    case SET_REPORT_DATA:
      return {
        ...state,
        data: action.data,
      };
    // temporary
    case SET_WEEKS_FILTER:
      return {
        ...state,
        filter: action.data,
      };
    default:
      return state;
  }
}

export const setReport = (reportId) => {
  return {
    type: SET_REPORT,
    data: reportId,
  };
};

export const setReportData = (data) => {
  return {
    type: SET_REPORT_DATA,
    data: data,
  };
};
// temporary
export const setWeeksFilter = (data) => {
  return {
    type: SET_WEEKS_FILTER,
    data: data,
  };
};

export const getReportData = () => (dispatch, getState) => {
  return api.call('report.week', getState().reports.filter).then((res) => {
    dispatch(setReportData(res));
  });
};
