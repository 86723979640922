import React from 'react'
import styled from 'styled-components'

const MsgBox = styled.div`
  position: absolute;
  z-index: 1000;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  border-radius: 5px;
  background-color: #15181c;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.22);
  animation: bubbling 1s linear;
  @keyframes bubbling {
    0% {opacity: 0;}
    100% {opacity: 0.9;}
  }
  img {
    margin-right: 16px;
  }
  p {
    font-size: 15px;
    line-height: 2.27;
    color: #e5e5e5;
  }
`

export function UpdateMessage(props) {
  return (
    <MsgBox className={props.className}>
      <img src='/images/success_transparent.png' alt=''/>
      <p>{props.children}</p>
    </MsgBox>
  )
}
