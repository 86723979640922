import PropTypes from 'prop-types';

import { getPadValue } from './helper'
import { Content, Container, Item } from './header.styled';

export const Header = (props) => {
  const { view, setView, hour, minute, sec } = props;

  return (
    <Content>
      <Container>
        <Item $active={view === 'h'} onClick={() => setView('h')}>
          {getPadValue(hour)}
        </Item>
        <Item>:</Item>
        <Item $active={view === 'm'} onClick={() => setView('m')}>
          {getPadValue(minute)}
        </Item>
        <Item>:</Item>
        <Item $active={view === 's'} onClick={() => setView('s')}>
          {getPadValue(sec)}
        </Item>
      </Container>
    </Content>
  );
};

Header.propTypes = {
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  hour: PropTypes.number,
  minute: PropTypes.number,
  sec: PropTypes.number,
};
