import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { HeadLineWithMargin, Card, DataWrapper } from 'modules/components/card/styledComponents';
import Zones from './Zones';
import { PageWrapper } from 'modules/components/pageComponents';
import { CloseCardButton } from 'libs/ui';
import { getRules } from 'store/ducks/models/rules';
import { routes } from 'router';
import { HeaderBlock, Name, TariffGeneralInfo, TariffManageWrapper } from './index.styled';

class RulesCard extends React.Component {
  componentDidMount() {
    if (!Object.keys(this.props.rule).length) {
      this.props.getRules();
    }
  }

  closeCard = () => {
    if (document.referrer) {
      this.props.history.goBack();
    } else {
      this.props.history.push(routes.rules);
    }
  };

  render() {
    return (
      <PageWrapper>
        <HeadLineWithMargin>
          <p>Карточка правила</p>
          <CloseCardButton close={this.closeCard} />
        </HeadLineWithMargin>
        <Card>
          <TariffGeneralInfo>
            <HeaderBlock align={'flex-start'}>
              <Name>{this.props.rule.name}</Name>
            </HeaderBlock>
          </TariffGeneralInfo>
          <DataWrapper>
            <TariffManageWrapper>
              <Zones
                selectedZoneId={this.props.zoneId}
                type={this.props.rule.type}
                zones={this.props.rule.zones || []}
                ruleId={this.props.rule.rule_id}
              />
            </TariffManageWrapper>
          </DataWrapper>
        </Card>
      </PageWrapper>
    );
  }
}

const getUrlParam = (param) => {
  let params = {};
  if (window.location.search) {
    let parts = window.location.search.substring(1).split('&');
    for (let i = 0; i < parts.length; i++) {
      let nv = parts[i].split('=');
      if (!nv[0]) continue;
      params[nv[0]] = nv[1] || true;
    }
  }
  return params[param];
};

const mapStateToProps = (store) => {
  let zoneId = getUrlParam('zone');
  let ruleId = getUrlParam('id');
  let rule = {};
  store.rules.rulesForm.forEach((item) => {
    if (item.rule_id === ruleId) {
      rule = item;
    }
  });

  return {
    zoneId: zoneId,
    rule: rule || {},
    selectedZone: store.tariffCard.selectedZone,
  };
};

export default connect(mapStateToProps, {
  getRules,
})(withRouter(RulesCard));
