import moment from 'moment';

import { Container } from './date-select.styled';
import { CalInterval } from './date-select-intervals';

export const DateSelect = (props) => {
  const { theme } = props;  

  /**
   * set date period
   * @param {*} period 
   */
  const setPeriod = (period) => {
    let date = moment(); //new Date();
    let firstDay = null;
    let lastDay = null;
    const today = moment(date).set({ hour: 23, minute: 59, second: 0, millisecond: 0 }); //new Date(date.setHours(23, 59, 59, 0));
    const today2 = moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }); //new Date(date.setHours(0, 0, 0, 0));
    switch (period) {
      case 'currentMonth':
        firstDay = moment(today2).subtract(29, 'days');
        lastDay = today;
        break;
      case 'currentWeek':
        firstDay = moment(today2).subtract(6, 'days');
        lastDay = today;
        break;
      case 'lastThreeMonths':
        firstDay = moment(today2).subtract(89, 'days'); //new Date(today2.setDate(today2.getDate() - 89));
        lastDay = today;
        break;
      case 'today':
        firstDay = today2;
        lastDay = today;
        break;
      default:
        lastDay = '';
        firstDay = '';
    }
    props.change(props.from_name, firstDay);
    props.change(props.to_name, lastDay);
  };

  return (
    <Container $lastField={props.lastField} className={theme}>
      {props.children}
      <CalInterval setPeriod={setPeriod} className={theme} />
    </Container>
  );
};
