import { GridContainer } from 'libs/ui';
import { GreyPlate } from 'grids/greyPlate/greyPlate';

const GreyPlateWrapper = (props) => {
  return (
    <GridContainer>
      <GreyPlate {...props} />
    </GridContainer>
  );
};

export default GreyPlateWrapper;
