import styled from 'styled-components'


export const PanelWrapper = styled.div`
  position: relative;
  width: 600px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
`
export const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
`
export const Tab = styled.div`
  cursor: pointer;
  width: ${(props) => props.type === 'double' ? '105px' : '68px'};
  height: 74px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: ${(props) => props.color};
  box-shadow: ${(props) => props.isSelected ? '0px -1px 0px 0px rgba(0,0,0,0.5)' : '0 0 4px 0 rgba(0, 0, 0, 0.5)'};
  border-bottom: ${(props => props.isSelected ? 'none' : 'solid 1px rgba(0, 0, 0, 0.5)')};
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    font-size: 13px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: solid 1px #fff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`