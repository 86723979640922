import React, { Component } from 'react';

import { Button, themes, ButtonContainer } from 'libs/ui';
import { Popup2 } from 'libs/ui';
import { ErrorMsg } from 'pages/styled';
import { PromiseContent, NewTicketHeader, Line2 } from './Promise.styled';
import { api } from 'api';
import { notify } from 'libs/common';

class Promise extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      working: false,
      validated: false,
      contract: undefined,
      data: { fio: '', n: '', status: 'notpayed' },
    };
  }

  componentDidMount() {
    this.getContract();
  }

  setValue = (e) => {
    const out = { ...this.state.data };
    out[e.target.name] = e.target.value;
    const validated = this.validate(out);
    this.setState({ data: out, validated: validated });
  };

  validate = (data) => {
    let out = true;

    Object.keys(data).forEach((key) => (out = data[key] !== ''));
    return out;
  };

  getContract = () => {
    api.call('promise_pay.view', { stay_id: this.props.id }, 'NetworkManager')
      .then((data) => {
        this.setState({
          contract: data,
          data: {
            fio: data ? data.client_name : '',
            n: data ? data.contract_number : '',
            status: data ? (data.is_payed ? 'payed' : 'notpayed') : 'notpayed',
          },
        });
      })
      .catch((error) => {
        //console.log('🚀 ~ api ~ error', error);
        notify('Ошибка получения договора', error);
      });
  };

  save = () => {
    const { data } = this.state;
    const out = {
      stay_id: this.props.id, //id
      client_name: data.fio,
      contract_number: data.n,
      is_payed: data.status === 'payed',
    };
    api.call('promise_pay.edit', out, 'NetworkManager')
      .then((data) => {
        this.props.close();
      })
      .catch((error) => {
        notify('Ошибка изменения договора', error);
      });
  };

  render() {
    const { contract, error, data, working, validated } = this.state;

    if (contract === undefined) {
      return null;
    }

    return (
      <>
        <Popup2 w={560}>
          <PromiseContent>
            <NewTicketHeader>Договор паркирования</NewTicketHeader>
            {error && (
              <ErrorMsg>
                <img src="/images/error-triangle-icon.png" alt="" />
                Ошибка
              </ErrorMsg>
            )}

            <form>
              <Line2>
                <span className="l">Ф.И.О.</span>
                <input type="text" name="fio" value={data.fio} onChange={this.setValue} />
              </Line2>
              <Line2>
                <span className="l">№ договора паркирования</span>
                <input type="text" name="n" value={data.n} onChange={this.setValue} />
              </Line2>
              <Line2>
                <span className="l">Статус оплаты</span>
                <select name="status" value={data.status} onChange={this.setValue}>
                  <option value={'payed'}>Оплачено</option>
                  <option value={'notpayed'}>Не оплачено</option>
                </select>
              </Line2>
            </form>
            <ButtonContainer space="20px" justifyContent={'flex-start'} style={{ marginTop: '40px' }}>
              <Button disabled={working} fontSize="16px" onClick={this.props.close}>
                Отменить
              </Button>
              <Button disabled={working || !validated} theme={themes.blue} fontSize="16px" onClick={this.save}>
                Сохранить
              </Button>
            </ButtonContainer>
          </PromiseContent>
        </Popup2>
      </>
    );
  }
}
export default Promise;
