import styled from 'styled-components';

import { FieldComponent } from 'libs/form';
import { Header, MainBlock } from 'modules/components/windows/styledComponents';

export const ReplaceHeader = styled(Header)`
  margin-bottom: 15px;
`;
export const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledField = styled(FieldComponent)`
  height: 40px;
  width: 300px;
  input {
    width: 100%;
    font-size: 15px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    border-top: solid 1px #bebebe;
    border-bottom: solid 1px #bebebe;
    color: #000;
    outline: none;
  }
`;
export const ReplaceBlock = styled(MainBlock)`
  display: flex;
  align-items: center;
  margin: 100px 0;
  padding: 0 68px;
  img {
    margin-right: 24px;
  }
  p {
    line-height: 1.6;
  }
`;
