// import {  } from 'libs/ui';
import { Content, Items, Item } from './result.styled';

export const Result = ({ result, check }) => {
  if (!result) {
    return null;
  }
  
  const getItems = () => {
    return result.details
      .filter((el) => el.result === 'failed')
      .map((el, idx) => {
        return (
          <Item key={idx}>
            <i>#{el.line_num}</i> {el.errors.join(', ')}
          </Item>
        );
      });
  };

  const itemsEl = getItems();
  return (
    <Content>
      <p>
        Всего: <b>{result.total}</b> &nbsp;&nbsp; {!check && <span>Сохранено: <b>{result.processed}</b></span>}
      </p>
      <Items>{itemsEl}</Items>
    </Content>
  );
};
