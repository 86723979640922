import { Store } from 'react-notifications-component';
import moment from 'moment';

/**
 * notify
 * @param {*} title
 * @param {*} message
 * @param {*} type
 * @param {*} timeout
 * @returns
 */
export const notify = (title, message, type = 'danger', timeout = 2000) => {
  //console.log('error ~ 🚀:', message);
  if (message === undefined) {
    return;
  }

  if (message.code && message.code === 403) {
    return;
  }
  let msg = message;
  if (msg.message) {
    msg = msg.message;
  }
  Store.addNotification({
    title: title,
    message: msg,
    type: type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: timeout,
      onScreen: true,
    },
  });
};

/**
 * get duration string
 * @param {*} duration number sec
 * @returns
 */
export const getDuration = (durationInSec) => {
  if (!durationInSec) {
    return '';
  }

  if (durationInSec >= 86400) {
    let days = Math.floor(durationInSec / 86400);
    let remainderHours = durationInSec % 86400;

    let hours = Math.floor(remainderHours / 3600);
    let remainderMinutes = remainderHours % 3600;

    let minutes = Math.floor(remainderMinutes / 60);
    let seconds = remainderMinutes % 60;

    return `${days}д ${hours}ч ${minutes}м ${seconds}c`;
  } else if (durationInSec >= 3600) {
    let hours = Math.floor(durationInSec / 3600);
    let remainderMinutes = durationInSec % 3600;

    let minutes = Math.floor(remainderMinutes / 60);
    let seconds = remainderMinutes % 60;

    return `${hours}ч ${minutes}м ${seconds}c`;
  } else {
    let minutes = Math.floor(durationInSec / 60);
    let seconds = durationInSec % 60;

    return `${minutes}м ${seconds}c`;
  }
};

/**
 * get datetime string
 * @param {*} dateTime
 * @returns
 */
export const getFormattedDateTime = (dateTime) => {
  if (!dateTime) {
    return '';
  }
  return moment(dateTime).format('D MMM YYYY HH:mm');
};

/**
 * get formatted price
 * @param {*} amount decimals
 * @param {*} suffix
 * @returns
 */
export const getAmount = (amount, suffix = ' ₽') => {
  let out = '';
  if (amount) {
    out = Math.round(amount) / 100;
  } else {
    out = '0';
  }
  return out + suffix;
};

/**
 * find element in array
 * @param {*} items
 * @param {*} id
 * @param {*} field
 * @returns
 */
export const findElement = (items, id, field = 'id') => {
  return items.find((el) => el[field] === id);
};

/**
 * get duration
 * @param {*} value
 * @returns
 */
export const durationToAPI = (value) => {
  const days = value.match(/..(?=д)/)[0].replace(/_/g, 0);
  const hours = value.match(/..(?=ч)/)[0].replace(/_/g, 0);
  const minutes = value.match(/..(?=м)/)[0].replace(/_/g, 0);

  return days * 86400 + hours * 3600 + minutes * 60;
};

/**
 * prepare filter for grid
 * @param {*} data
 * @param {*} addParams
 * @returns
 */
export const prepareFilter = (data, addParams = {}, params = {}) => {
  const def = {
    dateFields: [
      'start_at_from',
      'start_at_to',
      'end_at_from',
      'end_at_to',
      'datetime_from',
      'datetime_to',
      'entry_at_from',
      'entry_at_to',
      'leave_at_from',
      'leave_at_to',
      'payment_from',
      'payment_to',
      'ts_from',
      'ts_to',
      'timestamp_from',
      'timestamp_to',
      'time_from',
      'time_to',
    ],
  };
  const paramsOk = { ...def, ...params };

  const out = { ...data, ...addParams };
  // console.log('🚀 ~ prepareFilter ~ out:', out);
  Object.keys(out).forEach((key) => {
    if (typeof out[key] !== 'boolean' && !out[key]) {
      delete out[key];
    }
  });

  if (paramsOk.boolFields) {
    paramsOk.boolFields.forEach((fn) => {
      if (out[fn] !== undefined) {
        out[fn] = out[fn] === 'true';
      }
    });
  }
  if (paramsOk.intFields) {
    paramsOk.intFields.forEach((fn) => {
      if (out[fn]) {
        out[fn] = parseInt(out[fn]);
      }
    });
  }
  if (paramsOk.priceFields) {
    paramsOk.priceFields.forEach((fn) => {
      if (out[fn]) {
        out[fn] = parseInt(out[fn]) * 100;
      }
    });
  }

  if (paramsOk.durationFields) {
    paramsOk.durationFields.forEach((fn) => {
      if (out[fn]) {
        out[fn] = durationToAPI(out[fn]);
      }
    });
  }

  if (paramsOk.dateFields) {
    paramsOk.dateFields.forEach((fn) => {
      if (out[fn] && moment.isMoment(out[fn])) {
        out[fn] = out[fn].format();
      }
    });
  }

  // Object.keys(out).forEach((key) => {
  //   if (typeof out[key] === 'string' && out[key].indexOf('\\\\') !== -1) {
  //     out[key] = out[key].replace('\\\\', '\\');
  //     console.log('🚀 ~ Object.keys ~ out[key]:', out[key]);
  //   }
  // });

  return out;
};

/**
 * get data field
 * @param {*} fn
 * @param {*} toValute
 * @returns
 */
export const getDataField = (data, fn, toValute = false) => {
  if (!data || !data[fn]) {
    return '-';
  }
  let val = data[fn];
  if (toValute) {
    val = Math.round(val / 100);
    val += ' ₽';
  }
  return val;
};

/**
 * is object empty
 * @param {*} obj
 * @returns
 */
export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 * format number
 * @param {*} n
 * @returns
 */
export const formatNumber = (n) => {
  return new Intl.NumberFormat('ru-Ru').format(n);
};
