import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit, SubmissionError } from 'redux-form';
import { withRouter } from 'react-router';
import moment from 'moment';

import { getCategories, getVehicleClasses } from 'services/types';
import { user } from 'services';
import types from 'services/tariffTypes';
import { getTarrificatorType } from 'services/types';
import Warning from 'modules/components/warnings';
import { routes } from 'router';
import { CloseCardButton, Button, themes } from 'libs/ui';
import { PageWrapper, Headline } from 'modules/components/pageComponents';
import { Card, DataWrapper } from 'modules/components/card/styledComponents';
import { TariffManageWrapper } from '../styledComponents';
import { getTariffInfo, removeTariffInfo, toArchive, updateTariff } from 'store/ducks/models/tariff';
import { setZone, removeZoneInfo, addMessageDisplayHandler } from 'store/ducks/interface/tariffCard';
import { HeaderBlock, Name, OtherInfo, Status, StatusBlock, StyledTariffsGrid, TariffGeneralInfo, TransportClass } from './TariffCard.styled';
import TariffForm from './forms/tariff-form-old';

const igoraNumberFields = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'holiday'];
const parmaNumberFields = ['day_cost', 'night_cost'];
const menskingsNumberFields = ['day_cost', 'night_cost'];

class TariffCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsDisable: true, // true
      showUpdateMessage: false,
      showEditWarning: false,
      showSaveWarning: false,
      showStatusWarning: false,
      showValidationError: false,
      showPeriodError: false,
      periodErrorTitle: '',
    };
  }

  componentDidMount() {
    //console.log('🚀 mount:', this.props.info?.id);
    if (this.props.interface.showMessageAfterAdd) {
      this.removeMessageAfterAdd();
    }

    this.getTariffInfoById();
  }

  componentDidUpdate(prevProps) {
    const id = this.getTariffId();
    //console.log('🚀 update:', this.props.info?.id, prevProps.info?.id, id);
    if (this.props.info?.id !== id) {
      this.getTariffInfoById();
    }
    if (prevProps.error !== this.props.error) {
      if (this.props.error === 'duplicate') {
        if (this.props.error) {
          this.setState({ showValidationError: true });
        } else {
          this.setState({ showValidationError: false });
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.removeTariffInfo();
    this.props.removeZoneInfo();
  }

  getTariffInfoById = () => {
    const id = this.getTariffId();
    if (this.props.info && this.props.info.id !== id) {
      this.props.removeTariffInfo();
    }
    this.props.getTariffInfo(id);
  };

  removeMessageAfterAdd = () => {
    setTimeout(() => {
      this.props.addMessageDisplayHandler(false);
    }, 3000);
  };

  getTariffId = () => {
    const search = new URLSearchParams(this.props.location.search);
    return search.get('id');
  };

  getDisplayedType = (tariffType) => {
    try {
      let type = types.find((elem) => {
        return elem.id === tariffType;
      });
      return type.name;
    } catch (error) {
      return '';
    }
  };

  getStartTime = (time) => {
    if (time) {
      return moment(time).format('ll');
    }
  };

  /**
   * save tariff
   * @param {*} values
   * @returns
   */
  submit = (values) => {
    const secFields = ['secondentry_interval', 'secondentry_duration', 'secondentry_period', 'time_to_leave'];
    const priceFields = ['subscription_amount', 'secondentry_amount'];

    const { tarifficator } = this.props.info;
    const tarifficatorType = getTarrificatorType(tarifficator);

    this.setState({ showSaveWarning: false });

    // clone
    const decorateValues = JSON.parse(JSON.stringify(values));

    // min to sec
    secFields.forEach((fn) => {
      if (decorateValues[fn] !== undefined) {
        decorateValues[fn] *= 60;
      }
    });

    // rub to kop
    priceFields.forEach((fn) => {
      if (decorateValues[fn] !== undefined) {
        decorateValues[fn] *= 100;
      }
    });

    //decorateValues.time_to_leave *= 60;
    //decorateValues.secondentry_interval *= 60;
    //decorateValues.subscription_amount *= 100;
    const decorateZones = decorateValues.zones;

    // zones
    if (decorateZones) {
      switch (tarifficatorType) {
        // igora
        case 'igora':
          const iZones = { ...decorateZones };
          for (let zone in decorateZones) {
            iZones[zone].free_interval *= 60;
            igoraNumberFields.forEach((fn) => {
              iZones[zone][fn] *= 100;
            });
          }
          decorateValues.zones = iZones;
          break;
        // tumen
        case 'tumen':
          const tZones = { ...decorateZones };
          for (let zone in decorateZones) {
            tZones[zone].free_interval *= 60;
          }
          decorateValues.zones = tZones;
          break;
        // menskings
        case 'menskings':
          const mkZones = { ...decorateZones };
          for (let zone in decorateZones) {
            mkZones[zone].free_day_interval *= 60;
            mkZones[zone].free_night_interval *= 60;
            menskingsNumberFields.forEach((fn) => {
              mkZones[zone][fn] *= 100;
            });
          }
          decorateValues.zones = mkZones;
          break;
        // parma
        case 'parma':
          const pZones = { ...decorateZones };
          for (let zone in decorateZones) {
            pZones[zone].free_interval *= 60;
            parmaNumberFields.forEach((fn) => {
              pZones[zone][fn] *= 100;
            });
          }
          decorateValues.zones = pZones;
          break;

        // two periods tariffication
        case 'two_periods':
          const newZones = Object.assign({}, decorateZones);
          for (let zone in decorateZones) {
            const periods = decorateZones[zone].periods;
            periods.forEach((period, index) => {
              newZones[zone].periods[index].amount = parseInt(period.amount) * 100;
              period.period *= 60;
            });
          }

          decorateValues.zones = newZones;
          break;

        // default tariffication
        default:
          for (let zone in decorateZones) {
            // fix price
            if (decorateZones[zone].fixprice) {
              decorateZones[zone].fixprice *= 100;
            }

            // loop
            let loop;
            if (decorateZones[zone].loop !== undefined) {
              loop = parseInt(decorateZones[zone].loop);
              delete decorateZones[zone].loop;
            }

            const periods = decorateZones[zone].periods;
            let periodStartAt = 0;
            periods.forEach((period, index, array) => {
              period.amount = Math.round(period.amount * 100);
              period.period *= 60;
              if (period.count) {
                period.count = parseInt(period.count);
              }
              if (index === 0) {
                period.start_at = periodStartAt;
              } else {
                periodStartAt += array[index - 1].period * (array[index - 1].count ? array[index - 1].count : 1);
                period.start_at = periodStartAt;
              }
              // loop
              if (loop !== undefined) {
                period.loop = loop === index;
              }
              delete period.id;
            });
          }
          break;
      }
    }

    // data
    delete decorateValues.data;

    const id = this.getTariffId();
    // const withSecondEntry = decorateValues.secondentry_period !== undefined;
    // check secondentry_interval secondentry_amount
    // tarifficator: "PeriodicTarifficator"

    // if (
    //   withSecondEntry &&
    //   decorateValues.secondentry_amount > 0 &&
    //   decorateValues.secondentry_interval > 0 &&
    //   tarifficatorType === 'default' &&
    // ) {
    //   // check secondentry_interval amount
    //   let found = false;

    //   for (let zone in decorateZones) {
    //     decorateZones[zone].periods.forEach((period) => {
    //       if (period.start_at === decorateValues.secondentry_interval || period.start_at + period.period === decorateValues.secondentry_interval) {
    //         found = true;
    //       }
    //     });

    //     break;
    //   }

    //   if (!found) {
    //     this.setState({
    //       showPeriodError: true,
    //       periodErrorTitle:
    //         'Задана стоимость первого часа при повторном въезде и отсутствует период начинающийся с ' +
    //         decorateValues.secondentry_interval / 60 +
    //         '-й минуты. Задайте такой период или укажите стоимость 0 рублей для отключения тарификации повторного въезда',
    //     });
    //     return false;
    //   }
    // }

    // test
    const test = false;
    if (test) {
      console.log('🚀 ~ update tariff:', decorateValues);
      return Promise.resolve(true);
    }

    return this.props.updateTariff(id, decorateValues, this.afterSubmit).then((errors) => {
      let submissionErrors = {};
      if (Array.isArray(errors)) {
        errors.forEach((element) => {
          submissionErrors[element.field] = element.type;
          submissionErrors._error = element.type;
        });
      } else {
        submissionErrors[errors.field] = errors.type;
        submissionErrors._error = errors.type;
      }

      throw new SubmissionError({ ...submissionErrors });
    });
  };

  afterSubmit = (id) => {
    this.props.history.push(`${routes.tariff}?id=${id}`);

    this.setState({
      fieldsDisable: true,
      showEditWarning: false,
      showStatusWarning: false,
    });
    this.showMessage();
  };

  showMessage = () => {
    this.setState({ showUpdateMessage: true });
    setTimeout(() => {
      this.setState({ showUpdateMessage: false });
    }, 3000);
  };

  closeCard = () => {
    this.props.history.push(routes.tariffs);
  };

  getTransportClasses = (fullList, valuesList) => {
    if (Array.isArray(fullList) && Array.isArray(valuesList)) {
      let displayedSting = '';

      fullList.forEach((fullListValue) => {
        valuesList.forEach((value) => {
          if (fullListValue.id === value) {
            if (displayedSting) {
              displayedSting += `, ${fullListValue.name}`;
            } else {
              displayedSting += fullListValue.name;
            }
          }
        });
      });
      return displayedSting;
    }
  };

  getStatus = () => {
    const { status, time_to } = this.props.info;

    if (status === 'archive') {
      if (time_to) {
        return `Тариф в архиве с ${moment(time_to).format('lll')}`;
      } else {
        return 'Тариф в архиве';
      }
    } else if (status === 'active') {
      return 'Активный тариф';
    }
  };

  /**
   * archive tariff
   */
  toArchive = () => {
    const id = this.getTariffId();
    this.props.toArchive(id);

    this.setState({ showStatusWarning: false });
  };

  createNewTariff = () => {
    this.setState({ showEditWarning: true });
  };

  // render
  render() {
    const { info, selectedZone, setZone, tariffication, initialValues } = this.props;
    const { fieldsDisable, periodErrorTitle } = this.state;
    const dataFields = ['subscription_period', 'subscription_amount', 'secondentry_amount', 'secondentry_period', 'secondentry_duration'];

    if (!info) {
      return null;
    }

    const categories = getCategories();
    const vehicleClasses = getVehicleClasses();

    const isIndividual = info.type === 'individual';
    const infoOk = { ...info };
    const initialOk = { ...initialValues };

    if (infoOk.data !== undefined) {
      dataFields.forEach((fn) => {
        if (infoOk.data[fn] !== undefined) {
          infoOk[fn] = infoOk.data[fn];
        }
      });
    }

    // new tariff editor
    // change start time for current
    if (!fieldsDisable && !isIndividual) {
      initialOk.time_from = moment().toISOString();
      infoOk.time_from = initialOk.time_from;
    }

    const tarrificatorParams = {
      info: infoOk,
      selectedZone,
      setZone,
      tariffication,
      disabled: fieldsDisable,
      zones: infoOk.zones,
    };

    const showNewTariffButton = fieldsDisable && !(infoOk.type !== 'individual' && infoOk.status === 'archive') && user.haveRight('tariff.update');

    // console.log('🚀 ~ getInitial ~ out:',  selectedZone, tariffication);

    return (
      <PageWrapper>
        <Headline>
          <p>Карточка тарифа</p>
          <CloseCardButton close={this.closeCard} />
        </Headline>

        <>
          <StyledTariffsGrid hideTotal vehicleClasses={vehicleClasses} categories={categories} entries={[infoOk]} />

          <Card>
            <TariffGeneralInfo>
              <HeaderBlock align={'flex-start'}>
                <Name>{infoOk.name}</Name>
                <TransportClass>{this.getTransportClasses(vehicleClasses, infoOk.vehicle_class)}</TransportClass>
                <OtherInfo>
                  <span>{this.getDisplayedType(infoOk.type)}</span>
                  <span>{this.getStartTime(infoOk.time_from)}</span>
                </OtherInfo>
              </HeaderBlock>
              <HeaderBlock align={'flex-end'}>
                <StatusBlock>
                  {fieldsDisable && isIndividual && infoOk.status === 'active' && user.haveRight('tariff.update') && (
                    <Button onClick={() => this.setState({ showStatusWarning: true })} style={{ marginRight: '1rem' }}>
                      <img src="/images/to-archive-icon.png" alt="" />В архив
                    </Button>
                  )}
                  {fieldsDisable && <Status status={infoOk.status}>{this.getStatus()}</Status>}
                </StatusBlock>
                {showNewTariffButton && (
                  <Button theme={themes.blue} onClick={() => this.createNewTariff()}>
                    Создать новый на основе данного
                  </Button>
                )}
              </HeaderBlock>
            </TariffGeneralInfo>

            <DataWrapper>
              <TariffManageWrapper>
                <TariffForm
                  key={'tariff_form' /*+ (fieldsDisable ? 'disabled' : 'normal')*/}
                  onSubmit={this.submit}
                  onSubmitClick={() => this.setState({ showSaveWarning: true })}
                  initialValues={initialOk}
                  categories={categories}
                  vehicleClasses={vehicleClasses}
                  disabled={fieldsDisable}
                  info={infoOk}
                  tarrificatorParams={tarrificatorParams}
                  showUpdateMessage={this.state.showUpdateMessage}
                  showMessageAfterAdd={this.props.interface.showMessageAfterAdd}
                />
              </TariffManageWrapper>
            </DataWrapper>
          </Card>
        </>

        {this.state.showPeriodError && (
          <Warning
            type="error"
            width="480px"
            header="Тариф не сохранен"
            mainText={periodErrorTitle}
            cancelButton={{
              func: () => this.setState({ showPeriodError: false }),
              text: 'ОК',
            }}
          />
        )}

        {this.state.showValidationError && (
          <Warning
            type="error"
            width="480px"
            header="Тариф не сохранен"
            mainText="Проверьте настройку периодов по всем зонам, проставьте значения и сохраните ещё раз"
            cancelButton={{
              func: () => this.setState({ showValidationError: false }),
              text: 'ОК',
            }}
          />
        )}

        {this.state.showEditWarning && (
          <Warning
            width="480px"
            header="После сохранения нового тарифа, активный тариф автоматически прекратит действие"
            mainText="Создать новый тариф?"
            cancelButton={{
              func: () => this.setState({ showEditWarning: false }),
              text: 'Отменить',
            }}
            acceptButton={{
              func: () =>
                this.setState({
                  fieldsDisable: false,
                  showEditWarning: false,
                }),
              text: 'Создать',
              theme: themes.blue,
            }}
          />
        )}

        {this.state.showSaveWarning && (
          <Warning
            width="480px"
            header="Новый тариф автоматически прекратит действие активного"
            mainText="Сохранить новый тариф?"
            cancelButton={{
              func: () => this.setState({ showSaveWarning: false }),
              text: 'Отменить',
            }}
            acceptButton={{
              func: () => this.props.dispatch(submit('TariffForm')),
              text: 'Сохранить',
              theme: themes.blue,
            }}
          />
        )}

        {this.state.showStatusWarning && (
          <Warning
            width="480px"
            header="Данный тариф прекратит действие"
            mainText="Отправить тариф в архив?"
            cancelButton={{
              func: () => this.setState({ showStatusWarning: false }),
              text: 'Отменить',
            }}
            acceptButton={{
              func: this.toArchive,
              text: 'В архив',
              theme: themes.blue,
            }}
          />
        )}
      </PageWrapper>
    );
  }
}

/**
 * get initial data from store
 * @param {*} store
 * @returns
 */
const getInitial = (store) => {
  if (!store.tariff.info) {
    return {};
  }

  const dataFields = ['subscription_period', 'subscription_amount', 'secondentry_amount', 'secondentry_period', 'secondentry_duration'];
  const priceFields = ['subscription_amount', 'secondentry_amount'];

  const tarifficatorType = getTarrificatorType(store.tariff.info.tarifficator);
  // console.log('🚀 ~ getInitial ~ tarifficatorType:', tarifficatorType);
  const decorateInitValue = JSON.parse(JSON.stringify(store.tariff.info));
  // console.log('🚀 ~ getInitial after parse:', store.tariff.info.zones?.P1?.periods[0]?.amount);
  const isIndividual = decorateInitValue.type === 'individual';
  delete decorateInitValue.time_to;
  delete decorateInitValue.parent;
  delete decorateInitValue.created_at;
  delete decorateInitValue.id;
  delete decorateInitValue.status;
  if (!isIndividual) {
    delete decorateInitValue.vehicle_class;
    delete decorateInitValue.vehicle_category;
  }

  if (decorateInitValue.data !== undefined) {
    dataFields.forEach((fn) => {
      if (decorateInitValue.data[fn] !== undefined) {
        decorateInitValue[fn] = decorateInitValue.data[fn];
      }
    });
  }

  priceFields.forEach((fn) => {
    decorateInitValue[fn] /= 100;
  });

  /**
   * get zones info
   * @returns
   */
  const getDecorateZones = () => {
    const zones = decorateInitValue.zones;
    if (!zones) {
      return {};
    }

    const getLoopId = (periods) => {
      return periods.findIndex((el) => el.loop).toString();
    };

    const decorateZones = { ...zones };
    // console.log('🚀 ~ getDecorateZones:', tarifficatorType, decorateZones);
    switch (tarifficatorType) {
      case 'two_periods':
        break;
      case 'igora':
        for (let zone in decorateZones) {
          decorateZones[zone].free_interval /= 60;
          igoraNumberFields.forEach((fn) => {
            decorateZones[zone][fn] /= 100;
          });
        }
        break;
      case 'tumen':
        for (let zone in decorateZones) {
          decorateZones[zone].free_interval /= 60;
        }
        break;
      case 'menskings':
        for (let zone in decorateZones) {
          decorateZones[zone].free_day_interval /= 60;
          decorateZones[zone].free_night_interval /= 60;
          menskingsNumberFields.forEach((fn) => {
            decorateZones[zone][fn] /= 100;
          });
        }
        break;
      case 'parma':
        for (let zone in decorateZones) {
          decorateZones[zone].free_interval /= 60;
          parmaNumberFields.forEach((fn) => {
            decorateZones[zone][fn] /= 100;
          });
        }
        break;
      default:
        for (let zone in decorateZones) {
          decorateZones[zone].fixprice = 0;
          const loopId = getLoopId(decorateZones[zone].periods);
          if (loopId !== '-1') {
            decorateZones[zone].loop = loopId;
          }

          decorateZones[zone].periods.forEach((period, idx) => {
            if (period.count === undefined) {
              period.count = 1;
            }
            if (period.loop === undefined) {
              period.loop = false;
            }
          });
        }

        break;
    }
    return decorateZones;
  };

  const out = {
    ...decorateInitValue,
    zones: decorateInitValue ? getDecorateZones() : {},
  };
  // console.log('🚀 ~ getInitial ~ out:', out?.zones?.P1?.periods[0]?.amount);

  return out;
};

const mapStateToProps = (store) => {
  const initialValues = getInitial(store);
  const out = {
    initialValues,
    info: store.tariff.info,
    selectedZone: store.tariffCard.selectedZone,
    interface: store.tariffCard,
  };
  return out;
};

TariffCard = withRouter(TariffCard);

export default connect(mapStateToProps, {
  getTariffInfo,
  removeTariffInfo,
  updateTariff,
  setZone,
  toArchive,
  removeZoneInfo,
  addMessageDisplayHandler,
})(TariffCard);
