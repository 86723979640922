import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { user } from 'services';
import { getDataField, getAmount } from 'libs/common';
import { GridRow, Cell, OptionsCell } from 'libs/ui';
import { getDateTime } from 'services/types';
import { routes } from 'router';

class Benefit extends Component {
  showCard = () => {
    if (this.props.disableClick === true) {
      return;
    }
    this.props.history.push(`${routes.stay}?id=${this.props.stay_id}`);
  };

  render() {
    const { data } = this.props;
    const withClick = this.props.stay_id && this.props.stay_id !== 'system_30_07_2018_rfids' && user.haveRight('stays.view');

    return (
      <GridRow onClick={withClick ? this.showCard : undefined}>
        <Cell pr10>{getDateTime(this.props.created_at)}</Cell>
        <Cell>{this.props.name}</Cell>
        <Cell pr10>{getAmount(this.props.amount)}</Cell>
        <Cell pr10>{getAmount(this.props.amount_used)}</Cell>
        <Cell>{getDataField(data, 'fn')}</Cell>
      </GridRow>
    );
  }
}

export default withRouter(Benefit);
