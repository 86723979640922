import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getDuration, getFormattedDateTime } from 'libs/common';
import { RackInfo } from './rack-info';
import { BtnImages } from './btn-images';
import { Images } from './images';
import { MeanInfo } from './mean-info';

import {
  Content,
  CarPlate,
  CentralBlock,
  CentralBlockContent,
  CentralBlockSeparator,
  ContentWrapper,
  Length,
  MeanNumber,
  NoNumber,
  Time,
} from './ride-main.styled';

export const RideMainInfo = (props) => {
  const { from, to, duration, is_suspicious, last } = props;
  const [withImages, setWithImages] = useState(last);

  const showImages = () => {
    setWithImages(true);
  };

  const hideImages = () => {
    setWithImages(false);
  };

  const getPhotos = (obj) => {
    let out = [];
    if (obj && obj.data && obj.data.photos) {
      out = [obj.data.photos.photo_1, obj.data.photos.photo_2, obj.data.photos.photo_3];
    }
    return out;
  };

  const photosFrom = getPhotos(from);
  const photosTo = getPhotos(to);

  return (
    <>
      <Content>
        <RackInfo rackId={from.rack_id} vehicleClass={from.vehicle_class} />

        <BtnImages action={showImages} />

        <CentralBlock>
          <CentralBlockContent>
            <Time>{getFormattedDateTime(from.timestamp)}</Time>
            <Length>{getDuration(duration)}</Length>
            <Time>{getFormattedDateTime(to ? to.timestamp : null)}</Time>
          </CentralBlockContent>

          <CentralBlockSeparator />

          <CentralBlockContent>
            <ContentWrapper>
              <CarPlate $isSuspicious={is_suspicious}>{from.data.vehicle_plate || <NoNumber>Не распознан</NoNumber>}</CarPlate>
              <MeanNumber left>
                <MeanInfo direction={'from'} from={from} to={to} />
              </MeanNumber>
            </ContentWrapper>
            <ContentWrapper>
              <MeanNumber right>
                <MeanInfo direction={'to'} from={from} to={to} />
              </MeanNumber>
              <CarPlate $isSuspicious={is_suspicious}>
                {to ? to.data.vehicle_plate || <NoNumber>Не распознан</NoNumber> : <NoNumber>Не распознан</NoNumber>}
              </CarPlate>
            </ContentWrapper>
          </CentralBlockContent>
        </CentralBlock>

        <BtnImages action={showImages} />

        <RackInfo rackId={to.rack_id} vehicleClass={to.vehicle_class} />
      </Content>

      {withImages && <Images hideImages={hideImages} photosFrom={photosFrom} photosTo={photosTo} />}
    </>
  );
};

RideMainInfo.propTypes = {
  from: PropTypes.any.isRequired,
  to: PropTypes.any.isRequired,
  duration: PropTypes.any.isRequired,
  is_suspicious: PropTypes.any.isRequired,
  last: PropTypes.any.isRequired,
};
