const CLEAR_ENTRIES = '/pages/reports/benefits/CLEAR_ENTRIES';
const ADD_BENEFITS = '/pages/reports/benefits/ADD_BENEFITS';
const SET_BENEFITS_COUNT = '/pages/reports/benefits/SET_BENEFITS_COUNT';
const SET_OFFSET = '/page/reports/benefits/SET_OFFSET';
const SET_SORT_PARAMS = '/pages/reports/benefits/SET_SORT_PARAMS';
const RESET_MODEL = '/pages/reports/benefits/RESET_MODEL';

export default function reducer(state, action) {
  const defaultState = {
    benefits: {},
    offset: 0,
    count: 0,
    sortParams: [{ field_name: 'timestamp', direction: 'desc' }],
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case RESET_MODEL:
      return {
        ...defaultState,
      };
    case CLEAR_ENTRIES:
      return {
        ...state,
        benefits: {},
      };
    case ADD_BENEFITS:
      return {
        ...state,
        benefits: {
          ...state.benefits,
          ...action.data,
        },
      };
    case SET_BENEFITS_COUNT:
      return {
        ...state,
        count: action.data,
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    default:
      return state;
  }
}

export const reset = () => {
  return {
    type: RESET_MODEL,
  };
};
export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const setCount = (value) => {
  return {
    type: SET_BENEFITS_COUNT,
    data: value,
  };
};
export const addBenefits = (benefits) => {
  return {
    type: ADD_BENEFITS,
    data: benefits,
  };
};

export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};

export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};
