import React from 'react';
import { Link } from 'react-router-dom';
import { GridRow } from 'libs/ui';

import { Cell } from 'libs/ui';
import { getDateTime } from 'services/types';

const Row = (props) => {
  const { user } = props;
  const { name, role } = user ? user : { name: '', role: '' };
  //console.log('🚀 ~ Row ~ props', props);
  const { timestamp, action, setActionInfo } = props;
  let field;

  const getAction = () => {
    switch (props.data.method) {
      case 'clients.change_status':
        return (
          <Link to={'/clients/client?id=' + props.data.params.id}>
            <img src="/images/icon-go.png" alt="" />
          </Link>
        );
      case 'clients.update':
        return (
          <Link to={'/clients/client?id=' + props.data.params.id}>
            <img src="/images/icon-go.png" alt="" />
          </Link>
        );
      case 'rfid.add':
        return '-';
      case 'rfid.change_status':
        // props.dispatch(reset('rfidFilter'))
        // props.dispatch(change('rfidFilter', 'rfid', props.data.params.id))
        // return <Link to={'/payments/rfid'}><img src='/images/icon-go.png' alt=''/></Link>
        return '-';
      case 'stays.set_handicapped':
      case 'stays.set_exemption':
      case 'stays.change_suspicious':
        field = props.data.params['stay_id'] ? 'stay_id' : props.data.params['id'] ? 'id' : '';        

        return (
          <Link
            to={'/card/stay?id=' + props.data.params[field]}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(ev) => {
              ev.stopPropagation();
            }}
          >
            <img src="/images/icon-go.png" alt="" />
          </Link>
        );
      case 'tariff.change_status':
        return (
          <Link to={'/options/tariff?id=' + props.data.params.id}>
            <img src="/images/icon-go.png" alt="" />
          </Link>
        );
      case 'tariff.update':
        return (
          <Link to={'/options/tariff?id=' + props.data.params.id}>
            <img src="/images/icon-go.png" alt="" />
          </Link>
        );
      case 'clients.add':
        return '-';
      case 'clients.add_contact':
        return (
          <Link to={'/clients/client?id=' + props.data.params.client_id}>
            <img src="/images/icon-go.png" alt="" />
          </Link>
        );
      default:
        let out = '-';
        field = props.data.params['stay_id'] ? 'stay_id' : props.data.params['id'] ? 'id' : '';

        if (field && props.data.method.indexOf('stays.') !== -1) {
          out = (
            <Link
              to={'/card/stay?id=' + props.data.params[field]}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(ev) => {
                ev.stopPropagation();
              }}
            >
              <img src="/images/icon-go.png" alt="" />
            </Link>
          );
        }

        return out;
    }
  };

  return (
    <GridRow onClick={() => setActionInfo({ ...props })}>
      <Cell>{getDateTime(timestamp)}</Cell>
      <Cell>{name}</Cell>
      <Cell>{role}</Cell>
      <Cell>{action}</Cell>
      <Cell align="center">{getAction()}</Cell>
    </GridRow>
  );
};

export default Row;
