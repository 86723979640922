import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { user } from 'services';
import { TableExt, TableInfo, GridBox } from 'libs/ui';
import { NewTicketAction } from './actions/new-ticket-action';
import { getTableParams } from './grid.params';
import { routes } from 'router';

export const StayGrid = (props) => {
  const { disableClick, count, entries, className, theme, getXlsAction, clientId } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const rightView = user.haveRight('stays.view');
  const onRowClick = (row) => {
    if (disableClick === true) {
      return;
    }
    history.push(routes.stay + '?id=' + row.id);
  };

  // items
  const items = Object.keys(entries).map((key) => entries[key]);

  // top actions
  const topActions = [];
  topActions.push({ cmp: <NewTicketAction key="new-ticket-action" withoutInfo /> });
  if (count) {
    getXlsAction(topActions);
  }
  const tableParams = useMemo(() => getTableParams(), []);

  return (
    <GridBox>
      <TableInfo theme={theme} count={count} actions={topActions} />

      <TableExt
        theme={theme}
        title={t('sections.Парковки')}
        rows={{ count, items }}
        //actions={actions}
        className={className}
        refresh={props.refresh}
        clientId={clientId}
        {...tableParams}
        onRowClick={rightView ? onRowClick : undefined}
      />
    </GridBox>
  );
};
