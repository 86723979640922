import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(142, 142, 142);

  input {
    color: #000000;
    opacity: 1;
    padding-left: 4px;
    max-width: 250px;
    width: 100%;
    height: 100%;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
  }
  input[type='file'] {
    height: auto;
  }

  &.error {
    background-color: rgba(211, 61, 52, 0.2);
    label, input {
      color: #d33d34;
    }
    //border-top: 1px solid #d33d34 !important;
    //border-bottom: 1px solid #d33d34 !important;
  }
`;

export const Label = styled.label`
  color: #3e3e3e;
  font-weight: normal;
  font-size: 13px;
  opacity: 0.6;
  min-width: 200px;
  text-align: left;
`;

export const Error = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -44px;
  height: 40px;
  min-width: 300px;
  background-color: #d33d34;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  font-size: 15px;
  color: #fff;
  line-height: 1.73;
  width: max-content;
  padding: 0 15px;
  &::before {
    content: '';
    position: absolute;
    background-color: #d33d34;
    top: -3px;
    width: 18px;
    height: 18px;
    border-bottom-right-radius: 100%;
    left: 50%;
    transform: rotate(45deg) translate(-50%, 0);
  }
`;
