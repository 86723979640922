import React from 'react';
import { useTranslation } from 'react-i18next';

import { TotalCmp } from './total.styled';

export const Total = ({ cnt, white = false, title = 'found', after = '' }) => {
  const { t } = useTranslation();
  return (
    <TotalCmp $white={white}>
      {t('grid.' + title)}: <span>{cnt ? cnt : 0}</span>
      {after}
    </TotalCmp>
  );
};
