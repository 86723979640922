import styled from 'styled-components';

export const Place = styled.div`
  width: 200px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: -25px auto 30px;
  &.noabs {
    position: relative;
    left: auto;
    top: auto;
    width: auto;
    margin: 0 10px 0 0;
  }
`;

export const Button = styled.button`
  display: inline-block;
  cursor: pointer;
  height: 30px;
  padding: 0 15px;
  margin: 0 5px;
  border-radius: 40px;
  line-height: 0.8;
  border: none;
  outline: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5), inset 0 0px 30px 0 rgba(0, 0, 0, 0);
  transition: 0.3s all ease-out;
  &.active {
    background: #fff;
    &:hover {
      background: #fff;
      cursor: default;
      box-shadow: none;
    }
  }
  &:hover {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5), inset 0 0px 30px 0 rgba(0, 0, 0, 0.3);
  }
  &.flag {
    padding: 0;
    margin: 0 0 0 1px;
    line-height: 1;

    border-radius: 2px;
    width: auto;
    height: 18px;
    max-width: 26px;
    overflow: hidden;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      transition: 0.2s all ease-in;
      opacity: 1;
    }
    img {
      border-radius: 2px;
      height: 100%;
      max-width: none;
      //opacity: .7;
    }
    &:hover {
      &:after {
        opacity: 0.3;
      }
    }
    &.active {
      &:after {
        opacity: 0;
      }
    }
  }
`;
