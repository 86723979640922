import { Cell } from './cell';
import { getParking } from 'services/types';

export const ParkingCell = ({ parking_id, cmp }) => {
  if (!window.config.central) {
    return null;
  }

  const parking = getParking(parking_id);
  if (cmp === 'span') {
    return <span>{parking}</span>;
  }
  const Cmp = cmp ? cmp : Cell;
  // console.log('🚀 ~ ParkingCell ~ Cmp:', Cmp);

  return <Cmp>{parking}</Cmp>;
};
