import { useState } from 'react';
import { Label, Title } from './filter-config.styled';

export const FilterConfig = ({ item, config }) => {
  const [checked, setChecked] = useState(item.active);
  const title = item.title;

  const change = (event) => {
    item.active = event.target.checked;
    setChecked(item.active);
  };

  return (
    <Label>
      <input type="checkbox" checked={checked} onChange={change} />
      <Title>{title}</Title>
    </Label>
  );
};
