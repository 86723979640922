import styled from 'styled-components';

export const Content = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  li {
    &:first-child {
      z-index: 8;
      left: 0px;
    }
    &:nth-child(2) {
      z-index: 7;
      left: -24px;
    }
    &:nth-child(3) {
      z-index: 6;
      left: -48px;
    }
    &:nth-child(4) {
      z-index: 5;
      left: -72px;
    }
    &:nth-child(5) {
      z-index: 4;
      left: -96px;
    }
    &:nth-child(6) {
      z-index: 3;
      left: -120px;
    }
    &:nth-child(7) {
      z-index: 2;
      left: -144px;
    }
    &:nth-child(8) {
      z-index: 1;
      left: -168px;
    }
  }
`;

export const Zone = styled.li`
  position: relative;
  list-style: none;
  margin-left: ${(props) => (props.ellipsis ? '22px' : '0')};
  font-size: 22px;
`;

export const ZoneIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  line-height: 32px;
  border: 2px solid #fff;
  font-size: 13px;
  color: white;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;
