import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';

import { Container, Input, Select, ButtonsContainer } from 'libs/form';
import { UseBtn } from 'libs/ui';
import { ResetBtn } from 'libs/ui';


const StyledContainer = styled(Container)`
  form {
  }
`;

class Filter extends Component {
  constructor(props) {
    super(props);

    // initial
    this.props.setInitial(initialValues);
  }

  render() {
    const { handleSubmit, reset } = this.props;

    return (
      <StyledContainer /*height='200px'*/>
        <form onSubmit={handleSubmit}>
          <Input width="220px" name="client_name" type="text" label="Ф.И.О." placeholder="Ф.И.О." />

          <Input
            width="220px"
            name="contract_number"
            type="text"
            label="№ договора паркирования"
            placeholder="№ договора паркирования"
          />

          <Select width="150px" name="is_payed" label="Статус оплаты" type="select">
            <option value="">Все</option>
            <option value={'payed'}>Оплачено</option>
            <option value={'notpayed'}>Не оплачено</option>
          </Select>

          <Input width="220px" name="mean_number" type="text" label="№ СО" placeholder="№ СО" />

          <ButtonsContainer>
            <ResetBtn onClick={reset} />
            <UseBtn />
          </ButtonsContainer>
        </form>
      </StyledContainer>
    );
  }
}

const initialValues = {};

export default reduxForm({
  form: 'promise_payFilter',
  destroyOnUnmount: false,
  initialValues: initialValues,
})(Filter);
