export const UPDATE_COMMENT_EPM = '/pages/payments/epm/UPDATE_COMMENT';
export const UPDATE_COMMENT_RFID = '/pages/payments/rfid/UPDATE_COMMENT';
export const UPDATE_COMMENT_VP = '/pages/payments/vehiclePlates/UPDATE_COMMENT';
export const UPDATE_COMMENT_SUB = '/pages/payments/subscriptions/UPDATE_COMMENT';

export const SET_FORM_INFO_EPM = '/pages/payments/epm/SET_FORM_INFO';
export const SET_FORM_INFO_RFID = '/pages/payments/rfid/SET_FORM_INFO';
export const SET_FORM_INFO_VP = '/pages/payments/vehiclePlates/SET_FORM_INFO';
export const SET_FORM_INFO_SUB = '/pages/payments/subscriptions/SET_FORM_INFO';

export const REMOVE_FORM_INFO_EPM = '/pages/payments/epm/REMOVE_FORM_INFO';
export const REMOVE_FORM_INFO_RFID = '/pages/payments/rfid/REMOVE_FORM_INFO';
export const REMOVE_FORM_INFO_VP = '/pages/payments/vehiclePlates/REMOVE_FORM_INFO';
export const REMOVE_FORM_INFO_SUB = '/pages/payments/subscriptions/REMOVE_FORM_INFO';
