import React from 'react';
import styled from 'styled-components';

import { user } from 'services';
import { WhiteStyledField, StyledField } from '../fields';
import { required } from '../validation';

const Place = styled.div`
  margin: 0 0 10px;
`;
const StyledLabel = styled.label`
  margin-right: 0.5rem;
`;

export const ParkingFilter2 = (props) => {
  if (!props.force && !window.config.central) {
    return null;
  }

  const { dark, disabled } = props;

  const parkings = user.getMaps().parking.map((el, idx) => {
    return (
      <option key={idx} value={el.id}>
        {el.name}
      </option>
    );
  });

  if (props.simple) {
    return (
      <Place>
        <StyledLabel>Парковка</StyledLabel>
        <select onChange={props.onChange} width="145px" value={props.current}>
          {props.withEmpty && <option value="">Выберите парковку</option>}
          {parkings}
        </select>
      </Place>
    );
  }

  const Cmp = dark ? StyledField : WhiteStyledField;
  const other = {};
  if (props.required) {
    other.validate = required;
  }

  return (
    <Cmp disabled={disabled} type="select" label="Парковка" defaultValue={props.defaultValue} name="parking_id" {...other}>
      {props.withEmpty && <option value="">Выберите парковку</option>}
      {parkings}
    </Cmp>
  );
};

ParkingFilter2.defaultProps = {
  force: false,
  withEmpty: false,
  disabled: false,
  method: '',
};
