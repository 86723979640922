import styled, { css } from 'styled-components';

export const TableHeadCell = styled.th`
  ${(props) =>
    props.width &&
    css`
      max-width: ${(props) => props.width}px;
    `}
  text-align: ${(props) => (props.$numeric || props.align === 'right' ? 'end' : 'start')};
  padding-right: ${(props) => (props.$numeric ? '35px' : '0')};
  ${(props) =>
    props.align === 'right' &&
    css`
      padding-right: 0;
    `}

  position: relative;
  border-bottom: 1px solid #929292;
  height: 35px;
  font-size: 15px;
  opacity: 1;
  &:nth-child(1) {
    padding-left: 20px;
  }

  ${(props) =>
    props.withSort &&
    css`
      pointer-events: auto;
      &:hover {
        font-weight: bold;
        opacity: 1;
      }
      div {
        cursor: pointer;
        p {
          display: inline-block;
          margin-right: 10px;
        }
      }
    `}

  ${(props) =>
    props.theme === 'dark' &&
    css`
      color: #b7babf;
      opacity: 1;
      border-bottom: 1px solid #6c7277;
    `}

  & > div {
    display: flex;
    align-items: center;

    ${(props) =>
      props.align === 'right' &&
      css`
        justify-content: center;
      `}
  }
`;
