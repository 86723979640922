import React from 'react';
import { FormSection } from 'redux-form';

import IgoraTariffication from './IgoraTariffication';
import zonesParams from 'services/zones';
import { Tabs, Tab } from 'modules/components/card/zoneTabs/styledComponents';
import { Content } from './Igora.styled';

class TarrificatorIgora extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      zones: [],
    };
  }

  componentDidMount() {
    const out = zonesParams.getZones();
    if (!this.props.selectedZone) {
      this.props.setZone(out[0]);
    }
    this.setState({ zones: out });
  }

  getTabs = () => {
    const zones = this.state.zones;
    const zoneType = 'single';
    let isSelected = null;
    const tabs = zones.map((zone) => {
      if (this.props.selectedZone && this.props.selectedZone.id) {
        isSelected = zone.id === this.props.selectedZone.id;
      }
      return (
        <Tab isSelected={isSelected} key={zone.id} color={zone.color} type={zoneType} onClick={() => this.props.setZone(zone)}>
          <div>{zone.name}</div>
        </Tab>
      );
    });
    return tabs;
  };

  /**
   * return current zone visual
   *
   * @memberof TarrificatorIgora
   */
  getZones = () => {
    const { selectedZone } = this.props;
    const { zones } = this.state;
    if (!selectedZone) {
      return null;
    }
    // console.log('🚀 ~ selectedZone:', selectedZone, zones);

    const out = zones.map((zone) => {
      return (
        <FormSection key={zone.id} name={zone.id}>
          <IgoraTariffication selectedZone={selectedZone} display={selectedZone.id === zone.id} name={zone.id} disabled={this.props.disabled} />
        </FormSection>
      );
    });

    return out;
  };

  render() {
    return (
      <Content>
        <Tabs>{this.getTabs()}</Tabs>
        {this.getZones()}
      </Content>
    );
  }
}

export default TarrificatorIgora;
