import styled from 'styled-components';

export const Content = styled.div`
  font-size: 13px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    margin-left: 15px;
  }
`;

export const ZoneIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  border: 2px solid #fff;
  line-height: 32px;
  font-size: 13px;
  color: white;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;
