import { api } from 'api';

const CLEAR_ENTRIES = '/pages/options/tariffs/CLEAR_ENTRIES';
const ADD_TARIFFS = '/pages/options/tariffs/ADD_TARIFFS';
const SET_OFFSET = '/page/options/tariffs/SET_OFFSET';
const SET_TARIFFS_COUNT = '/pages/options/tariffs/SET_TARIFFS_COUNT';
const SET_SORT_PARAMS = '/pages/options/tariffs/SET_SORT_PARAMS';

export default function reducer(state, action) {
  const defaultState = {
    tariffs: {},
    offset: 0,
    count: 0,
    sortParams: [],
  };
  if (!state) {
    state = defaultState;
  }
  switch (action.type) {
    case CLEAR_ENTRIES:
      return {
        ...state,
        tariffs: {},
      };
    case ADD_TARIFFS:
      return {
        ...state,
        tariffs: {
          ...state.tariffs,
          ...action.data,
        },
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_TARIFFS_COUNT:
      return {
        ...state,
        count: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    default:
      return state;
  }
}
export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const addTariffs = (tariffs) => {
  return {
    type: ADD_TARIFFS,
    data: tariffs,
  };
};
export const setCount = (value) => {
  return {
    type: SET_TARIFFS_COUNT,
    data: value,
  };
};
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};

export const getTariffList = (filterParams, sortParams, callback) => (dispatch) => {
  return api.call('tariff.list', { filter_params: filterParams || {}, sort_params: sortParams || [], paging_params: {} }).then((res) => {
    if (callback) {
      callback(res);
    }
  });
};

export const getRfidTariffList = (filterParams, sortParams, callback) => (dispatch) => {
  return api.call('rfid.tariff_list', { filter_params: filterParams || {}, sort_params: [], paging_params: {} }).then((data) => {
    if (callback) {
      callback(data);
    }
  });
};

export const replaceTariffs = (params, callback) => (dispatch) => {
  return new Promise((resolve) => {
    return api.call('tariff.replace', params).then((res) => {
      if (callback) callback();
    });
  }).catch((error) => resolve(res.data));
};

export const getRequestParams = (filter) => (dispatch, getState) => {
  let decorateParams = {};
  for (let key in filter) {
    if (filter[key]) {
      switch (key) {
        case 'vehicle_category':
        case 'vehicle_class':
          decorateParams[key] = Number(filter[key]);
          break;
        default:
          decorateParams[key] = filter[key];
      }
    }
  }
  return decorateParams;
};
