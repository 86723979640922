import styled from 'styled-components';

export const Content = styled.div`
  margin: 10px auto;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border-style: solid;
  border-width: 8px;
  border-color: ${(props) => props.color};
`;
