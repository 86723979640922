import styled from 'styled-components';

export const InputBox = styled.div`
  ul {
    overflow: hidden;
    display: flex;
    align-items: center;
    li {
      margin-right: 5px;
      list-style: none;
    }
  }
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
  &.checkbox {
    input {
      margin-right: 15px;
      appearance: none;
      outline: none;
      cursor: pointer;
      width: 14px;
      height: 14px;
      border-radius: 4px;
      opacity: 1;
      &:checked {
        background-image: url('/images/checkbox-checked.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #fff;
        border: none;
      }
    }
    label {
      font-size: 15px;
      color: #fff;
      opacity: 1;
    }
  }
`;

export const Popup = styled.div`
  position: absolute;
  z-index: 11;
  padding: 20px;
  width: 100%;
  border-radius: 4px;
  top: 42px;
  left: 0;
  display: ${(props) => (props.$popupIsOpen ? 'block !important' : 'none !important')};
`;
