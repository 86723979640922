import { SettingsFilters } from './settings-filters';
import { Content } from './settings.styled';

export const Settings = ({ page, close, ...rest }) => {
  return (
    <Content {...rest}>
      <SettingsFilters page={page} close={close} />
    </Content>
  );
};
