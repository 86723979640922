import React from 'react';
import PropTypes from 'prop-types';

import { getAmount } from 'libs/common';
import { Content, Sum, SumWrapper } from './ride-additional.styled';
import { ZonesIcons } from './zones-icons';

export const RideAdditionalInfo = (props) => {
  const { amount, from } = props;

  return (
    <Content>
      <ZonesIcons activeZoneId={from.zone_to} />
      <SumWrapper>
        <Sum>
          Стоимость: <span>{getAmount(amount)}</span>
        </Sum>
      </SumWrapper>
    </Content>
  );
};

RideAdditionalInfo.propTypes = {
  amount: PropTypes.any.isRequired,
  from: PropTypes.any.isRequired,
};
