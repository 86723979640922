import styled from 'styled-components';

export const Buttons = styled.div`
  margin: 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-end')};
  & > * {
    margin-right: ${(props) => (props.space ? props.space : '10px')};
    &:last-child {
      margin-right: 0;
    }
  }
`;
