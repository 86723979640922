import React, { Component } from 'react';

import { Button, themes, ButtonContainer } from 'libs/ui';
import { FormBox, Header, CommentBox, CommentFieldName, CommentInput } from './styledComponents';

export default class AddCommentForm extends Component {
  constructor(props) {
    super(props);

    this.state = { comment: '' };
    this.type = props.type;
  }

  getHeader = () => {
    switch (this.type) {
      case 'rfids':
        return `RFID ${this.props.info.value}`;
      case 'epms':
        return `БСК ${this.props.info.value}`;
      case 'vehiclePlates':
        return `ГРЗ ${this.props.info.value}`;
      case 'subscriptions':
        return `Абонемент ${this.props.info.value}`;
      default:
        return '';
    }
  };

  setCommentsValue = (value) => {
    this.setState({ comment: value });
  };

  updateComment = () => {
    const info = {
      id: this.props.info.id,
      comment: this.state.comment,
    };
    this.props.addComment(info, this.type);
    this.cancelForm();
  };

  cancelForm = () => {
    this.props.removeFormInfo(this.type);
  };

  render() {
    return (
      <FormBox type={this.type} style={{ height: 'auto' }}>
        <Header>{this.getHeader()}</Header>
        <CommentBox>
          <CommentFieldName>Комментарий</CommentFieldName>
          <CommentInput
            autoFocus
            placeholder="Напишите свой комментарий"
            value={this.state.value}
            onChange={(e) => this.setCommentsValue(e.target.value)}
          ></CommentInput>
        </CommentBox>
        <ButtonContainer space="20px" justifyContent="flex-end">
          <Button onClick={this.cancelForm}>Отменить</Button>
          <Button theme={themes.blue} onClick={this.updateComment}>
            <img src="/images/save_icon_white.png" alt="" />
            Сохранить
          </Button>
        </ButtonContainer>
      </FormBox>
    );
  }
}
