export class Engine {
  constructor(path, setDuration, setProgress, progressBar) {
    this.duration = 0;
    this.currentTime = 0;
    this.file = null;
    this.progressTimeout = null;

    this.path = path;
    this.setDuration = setDuration;
    this.setProgress = setProgress;
    this.progressBar = progressBar;

    this.loadFile();
  }

  loadFile = () => {
    this.file = new Audio(this.path);

    this.file.addEventListener('loadedmetadata', () => {
      this.duration = this.file.duration;
      this.setDuration(this.file.currentTime);
      this.play();
    });
  };

  countProgress = () => {
    return (this.file.currentTime * 100) / this.file.duration || 0;
  };

  runProgress = (percent = 0) => {
    let percentage = percent || this.countProgress();
    let cb = percent
      ? () => {
          this.file.currentTime = (percentage * this.file.duration) / 100;
        }
      : null;

    this.setProgress(percentage, cb);
    this.setDuration(this.file.currentTime);
    this.progressTimeout = setTimeout(this.runProgress.bind(this), 1000);
  };

  stopProgress = () => {
    clearTimeout(this.progressTimeout);
    this.progressTimeout = null;
  };

  pickNewProgress(e) {
    if (this.file.duration) {
      let coords = e.target.getBoundingClientRect().left;
      let newPercent = ((e.clientX - coords) / this.progressBar.offsetWidth) * 100;
      this.stopProgress();
      this.runProgress(newPercent);
    }
  }

  play = () => {
    this.file.play();
    this.runProgress();
  };

  stop = () => {
    this.file.pause();
    this.stopProgress();
  };
}
