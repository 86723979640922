import styled from 'styled-components';

import { UpdateMessage } from '../update-message/update-message';

export const GridUpdateMessage = styled(UpdateMessage)`
  position: fixed;
  top: 74px;
  right: 295px;
  background-color: #fff;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.22);
  p {
    color: #000;
  }
`;
