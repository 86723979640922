import { api } from 'api';

const ADD_BENEFITS = '/pages/options/benefits/ADD_BENEFITS';
const SET_OFFSET = '/page/options/benefits/SET_OFFSET';
const SET_SORT_PARAMS = '/pages/options/benefits/SET_SORT_PARAMS';
const SET_ITEM_INFO = '/pages/options/benefits/SET_ITEM_INFO';
const CLEAR_ENTRIES = '/pages/options/benefits/CLEAR_ENTRIES';

export default function reducer(state, action) {
  const defaultState = {
    benefits: {},
    offset: 0,
    count: 0,
    itemInfo: null,
    sortParams: [{ field_name: 'name', direction: 'asc' }],
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case CLEAR_ENTRIES:
      return {
        ...state,
        benefits: {},
      };
    case ADD_BENEFITS:
      return {
        ...state,
        benefits: {
          ...state.benefits,
          ...action.data,
        },
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    case SET_ITEM_INFO:
      return {
        ...state,
        itemInfo: action.data,
      };
    default:
      return state;
  }
}

export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const addBenefits = (items) => {
  return {
    type: ADD_BENEFITS,
    data: items,
  };
};
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};
export const setItemInfo = (value) => {
  return {
    type: SET_ITEM_INFO,
    data: value,
  };
};

export const getRequestParams = () => (dispatch, getState) => {
  return {};
};

const prepareData = (data) => {
  const out = { ...data };
  out.min_amount = parseInt(out.min_amount) * 100;
  out.amount = parseInt(out.amount) * 100;
  return out;
};

export const addBenefit = (params, callback) => (dispatch) => {
  const out = prepareData(params);

  return api.call('benefit_types.check_add', out).then((res) => {
    if (callback) {
      callback();
    }
  });
};

export const updateBenefit = (id, params, callback) => (dispatch) => {
  const out = prepareData(params);
  out.id = id;

  return api.call('benefit_types.check_update', out).then((res) => {
    if (callback) {
      callback();
    }
  });
};
