const SET_SCROLL_POSITION = '/page/options/benefits/SET_SCROLL_POSITION';
const ADD_FORM_DISPLAY_HANDLER = '/page/options/benefits/ADD_FORM_DISPLAY_HANDLER';
const UPDATE_FORM_DISPLAY_HANDLER = '/page/options/benefits/UPDATE_FORM_DISPLAY_HANDLER';
const UPDATE_MESSAGE_DISPLAY_HANDLER = '/page/options/benefits/UPDATE_MESSAGE_DISPLAY_HANDLER';

export default function reducer(state, action) {
  const defaultState = {
    scrollPosition: 0,
    showAddForm: false,
    showUpdateForm: false,
    showUpdateMessage: false,
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case SET_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.data,
      };
    case ADD_FORM_DISPLAY_HANDLER:
      return {
        ...state,
        showAddForm: action.data,
      };
    case UPDATE_FORM_DISPLAY_HANDLER:
      return {
        ...state,
        showUpdateForm: action.data,
      };
    case UPDATE_MESSAGE_DISPLAY_HANDLER:
      return {
        ...state,
        showUpdateMessage: action.data,
      };
    default:
      return state;
  }
}

export const setScroll = (value) => {
  return {
    type: SET_SCROLL_POSITION,
    data: value,
  };
};
export const addFormDisplayHandler = (value) => {
  return {
    type: ADD_FORM_DISPLAY_HANDLER,
    data: value,
  };
};
export const updateFormDisplayHandler = (value) => {
  return {
    type: UPDATE_FORM_DISPLAY_HANDLER,
    data: value,
  };
};
export const updateMessageDisplayHandler = (value) => {
  return {
    type: UPDATE_MESSAGE_DISPLAY_HANDLER,
    data: value,
  };
};
