import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { user } from 'services';
import { routes } from 'router';
import { getMeanType } from 'services/types';
import { TableExt, CloseCardButton, Button, themes } from 'libs/ui';
import { Headline, PageWrapper } from 'modules/components/pageComponents';
import { Wrapper as CardWrapper } from 'modules/components/card/styledComponents';
import Warning from 'modules/components/warnings';
import { getStayInfo, removeStayInfo, changeSuspicious, changeHandicapped, closeParking } from 'store/ducks/models/stays';
import { setParameters, resetParameters } from 'store/ducks/interface/widgetPopup';
import { MeanTypeId, MeanId, AddtionalWrapper, ButtonContainer2, ClientName, FreeButton, HandicappedBlock, Hover } from './stay-card.styled';
import Promise from './Promise';
import NewPay from './NewPay';
import Invalid from './Invalid';
import { FreeBlock } from './FreeBlock';
import { TicketAction, NewTicketAction, AppPayAction, SetTariffAction } from './actions';
import { getTableParams } from 'grids/stay/grid.params';

import { StayCardRide } from './ride';
import { Payments } from './payments/payments';

class StayCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInvalid: false,
      showFree: false,
      showNewPay: false,
      confirm: false,
      showPromise: false,
    };

    this.hoverDelay = null;
    this.hoverDelayClose = null;
    this.hoverPlace = React.createRef();
  }

  componentDidMount() {
    const id = this.getStayId();
    if (this.props.info && this.props.info.id !== id) {
      this.props.removeStayInfo();
    }
    this.props.getStayInfo(id);
  }

  componentWillUnmount() {
    if (this.hoverDelay) {
      clearTimeout(this.hoverDelay);
    }
    if (this.hoverDelayClose) {
      clearTimeout(this.hoverDelayClose);
    }
  }

  toggleSuspicious = () => {
    let data = !this.props.info.is_suspicious;
    this.props.changeSuspicious(this.getStayId(), data);
  };

  toggleInvalid = () => {
    let data = !this.props.info.is_handicapped;
    this.props.changeHandicapped(this.getStayId(), data);
  };

  getStayId() {
    let params = {};
    if (window.location.search) {
      var parts = window.location.search.substring(1).split('&');

      for (var i = 0; i < parts.length; i++) {
        var nv = parts[i].split('=');
        if (!nv[0]) continue;
        params[nv[0]] = nv[1] || true;
      }
    }
    return params.id;
  }

  closeCard = () => {
    this.props.removeStayInfo();

    if (document.referrer) {
      this.props.history.goBack();
    } else {
      this.props.history.push(routes.stays);
    }
  };

  openNewPay = () => {
    this.setState({ showNewPay: true });
  };
  closeNewPay = () => {
    this.setState({ showNewPay: false });
  };
  openFree = () => {
    this.setState({ showFree: true });
  };
  openInvalid = () => {
    this.setState({ showInvalid: true });
  };
  closeInvalid = () => {
    this.setState({ showInvalid: false });
  };
  closeFree = () => {
    this.setState({ showFree: false });
  };

  /**
   * get segments
   * @returns
   */
  getRides = () => {
    const rides = this.props.info.periods;
    let ridesComponents = [];
    if (rides) {
      ridesComponents = rides.map((item, idx) => {
        let from = this.props.info.passages[item.id_1];
        let to = this.props.info.passages[item.id_2];
        const last = rides.length === idx + 1;
        return (
          <StayCardRide
            key={'period' + item.id_1 + item.id_2}
            from={from || { data: { photos: {} } }}
            to={to || { data: { photos: {} } }}
            last={last}
            amount={item.amount}
            duration={item.duration}
            is_suspicious={this.props.info.is_suspicious}
          />
        );
      });
    }
    return ridesComponents;
  };

  getStayZones = () => {
    const periods = this.props.info.periods;
    let zones = periods.map((item) => {
      return item.zone;
    });
    return zones;
  };

  mouseEnter = () => {
    if (this.hoverDelayClose) {
      clearTimeout(this.hoverDelayClose);
      this.hoverDelayClose = null;
    }
    this.hoverDelay = setTimeout(this.setWidgetParams, 500);
  };

  mouseLeave = (e) => {
    const target = e.relatedTarget;
    this.hoverDelayClose = setTimeout(() => {
      const widget = document.getElementById('widget-popup');
      if (target !== widget) {
        clearTimeout(this.hoverDelay);
        this.props.resetParameters();
      }
      this.hoverDelayClose = null;
    }, 500);
  };

  setWidgetParams = () => {
    if (this.hoverPlace) {
      const el = this.hoverPlace.current;
      const rect = el.getBoundingClientRect();
      const leftShift = 250; //((el.scrollWidth - el.clientWidth) / 2) + 20; //190;
      const parameters = {
        content: el.innerText,
        width: 550,
        fontSize: '14px',
        top: rect.bottom + 15,
        left: rect.left - leftShift,
        parent: el,
        clearParentClose: () => {
          if (this.hoverDelayClose) {
            clearTimeout(this.hoverDelayClose);
            this.hoverDelayClose = null;
          }
        },
      };
      this.props.setParameters(parameters);
    }
  };

  confirmCloseParking = () => {
    this.setState({ confirm: true });
  };

  promiseForm = () => {
    this.setState({ showPromise: true });
  };
  hidePromiseForm = () => {
    this.setState({ showPromise: false });
  };

  render() {
    const { info } = this.props;
    // console.log('🚀 ~ render ~ info:', info);
    const { confirm, showPromise, showNewPay } = this.state;
    const second = info && info.is_secondentry;
    const tableParams = getTableParams();

    return (
      <PageWrapper>
        <Headline>
          <p>{this.props.t('cards.Карточка парковки')}</p>
          <CloseCardButton close={this.closeCard} />
        </Headline>
        {info && (
          <>
            <TableExt rows={{ count: 1, items: [info] }} {...tableParams} />
            <CardWrapper>
              {/* type */}
              <MeanTypeId>
                {info.mean_type && <ClientName>{getMeanType(info.mean_type) + ' ' + (info.mean_number || ' ')}</ClientName>}
                {info.mean_type === 'receipt' && <MeanId>ID {info.mean_id}</MeanId>}
              </MeanTypeId>

              {/* segments */}
              {this.getRides()}

              {/* buttons */}
              <AddtionalWrapper>
                <ButtonContainer2 space="20px">
                  <TicketAction />

                  <NewTicketAction />

                  <AppPayAction />

                  {/*1 == 0 && info.is_active && user.haveRight('stays.print_receipt') && (
                    <Button theme={themes.blue} fontSize="16px" onClick={this.openNewPay}>
                      <i className="fa fa-plus"></i>
                      Добавить платёж
                    </Button>
                  ) */}
                  {info.mean_id &&
                    info.mean_type &&
                    (info.is_handicapped ? (
                      <HandicappedBlock className="free" ref={this.hoverPlace} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                        {/*
                        <img src='/images/blue-icon-disabled.png' alt='' />
                        <p>
                            Удостоверение<br />
                            №:{info.handicapped_permit_number}
                        </p>
                        */}
                        <img src="/images/tax-free.png" alt="" />
                        <Hover>
                          Льгота
                          <br />
                          {info.handicapped_permit_number}
                        </Hover>
                      </HandicappedBlock>
                    ) : (
                      info.is_active &&
                      user.haveRight('stays.set_exemption') && (
                        <FreeButton theme={themes.gray} fontSize="16px" onClick={this.openFree}>
                          {/*<img src='/images/icon-disabled.png' alt='' /> Признак инвалид*/}
                          <img src="/images/tax-free.png" alt="" />
                          Льгота
                        </FreeButton>
                      )
                    ))}

                  <SetTariffAction />

                  {info.is_active && user.haveRight('stays.manual_close') && (
                    <Button theme={themes.red} fontSize="16px" onClick={this.confirmCloseParking}>
                      Закрыть парковку
                    </Button>
                  )}
                  {second && (
                    <Button title="Повторный проезд" theme={themes.gray} disabled>
                      <img src="/images/second.png" height="20" alt="se" />
                    </Button>
                  )}
                  {info.is_active && user.haveRight('promise_pay.edit') && (
                    <Button title="Договор паркирования" theme={themes.gray} onClick={this.promiseForm}>
                      Договор паркирования
                    </Button>
                  )}
                </ButtonContainer2>

                <Payments info={info} />
              </AddtionalWrapper>

              {/* Promise pay  */}
              {showPromise && <Promise id={info.id} parkingId={info.parking_id} close={this.hidePromiseForm} />}

              {/* New pay  */}
              {showNewPay && (
                <NewPay id={info.id} parkingId={info.parking_id} meanId={info.mean_id} meanType={info.mean_type} close={this.closeNewPay} />
              )}

              {/* Invalid  */}
              {this.state.showInvalid && (
                <Invalid setHandicapped={(number) => this.props.changeHandicapped(this.getStayId(), number)} close={this.closeInvalid} />
              )}

              {/* Free  */}
              {this.state.showFree && (
                <FreeBlock setFree={(number) => this.props.changeHandicapped(this.getStayId(), number)} close={this.closeFree} />
              )}
            </CardWrapper>
          </>
        )}

        {/* close parking  */}
        {confirm && (
          <Warning
            width="480px"
            header={`Закрыть парковку?`}
            cancelButton={{
              func: () => {
                this.setState({ confirm: false });
              },
              text: 'Отменить',
            }}
            acceptButton={{
              func: () => {
                this.setState({ confirm: false });
                this.props.closeParking(info.id, () => {
                  this.props.getStayInfo(info.id);
                });
              },
              text: 'Подтвердить',
              theme: themes.blue,
            }}
          />
        )}
      </PageWrapper>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    info: store.stays.stay,
  };
};

const TransletedCard = withTranslation()(StayCard);

export default withRouter(
  connect(mapStateToProps, {
    getStayInfo,
    removeStayInfo,
    changeSuspicious,
    changeHandicapped,
    setParameters,
    resetParameters,
    closeParking,
  })(TransletedCard)
);
