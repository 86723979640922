import { api } from 'api';

const UPDATE_STATUS = '/pages/payments/rfid/UPDATE_STATUS';
const UPDATE_COMMENT = '/pages/payments/rfid/UPDATE_COMMENT';
const ADD_RFIDS = '/pages/payments/rfid/ADD_STAYS';
const SET_OFFSET = '/page/payments/rfid/SET_OFFSET';
const SET_RFIDS_COUNT = '/pages/payments/rfid/SET_RFIDS_COUNT';
const CLEAR_ENTRIES = '/pages/payments/rfid/CLEAR_ENTRIES';
const SET_SORT_PARAMS = '/pages/payments/rfid/SET_SORT_PARAMS';
const SET_FORM_INFO = '/pages/payments/rfid/SET_FORM_INFO';
const REMOVE_FORM_INFO = '/pages/payments/rfid/REMOVE_FORM_INFO';
const REMOVE_CARD_FORM_DATA = '/pages/payments/rfid/REMOVE_CARD_FORM_DATA';
const SET_CARD_FORM_DATA = '/pages/payments/rfid/SET_CARD_FORM_DATA';
const SET_FORM_DATA = '/pages/payments/rfid/SET_FORM_DATA';
const SET_ITEM_INFO = '/pages/payments/rfid/SET_ITEM_INFO';

export default function reducer(state, action) {
  const defaultState = {
    rfids: {},
    offset: 0,
    count: 0,
    sortParams: [],
    formInfo: {},
    cardForm: {},
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case UPDATE_STATUS:
      //console.log('🚀 ~ reducer ~ action', action);
      return {
        ...state,
        rfids: {
          ...state.rfids,
          [action.id]: {
            ...state.rfids[action.id],
            is_blocked: action.is_blocked,
            status: action.status,
            comment: action.comment,
          },
        },
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        rfids: {
          ...state.rfids,
          [action.id]: {
            ...state.rfids[action.id],
            comment: action.comment,
            comments: [1],
          },
        },
      };
    case CLEAR_ENTRIES:
      return {
        ...state,
        rfids: {},
      };
    case ADD_RFIDS:
      //console.log('🚀 ~ reducer ~ action.data', action.data);
      return {
        ...state,
        rfids: {
          ...state.rfids,
          ...action.data,
        },
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_RFIDS_COUNT:
      return {
        ...state,
        count: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    case SET_FORM_INFO:
      return {
        ...state,
        formInfo: action.data,
      };
    case REMOVE_FORM_INFO:
      return {
        ...state,
        formInfo: null,
      };
    case REMOVE_CARD_FORM_DATA:
      return {
        ...state,
        cardForm: null,
        errors: [],
      };
    case SET_FORM_DATA:
      return {
        ...state,
        cardForm: {
          ...state.cardForm,
          data: {
            ...state.cardForm.data,
            [action.fieldName]: action.value,
          },
        },
      };
    case SET_CARD_FORM_DATA:
      return {
        ...state,
        cardForm: action.data,
      };
    case SET_ITEM_INFO:
      return {
        ...state,
        item: action.data,
      };

    default:
      return state;
  }
}
export const updateStatus = (id, status, comment) => {
  const v = status === 'blocked' ? true : false;
  return {
    type: UPDATE_STATUS,
    id: id,
    //id: `id-${id}`,
    is_blocked: v,
    status: status,
    comment: comment,
  };
};
export const updateComment = (id, comment) => {
  return {
    type: UPDATE_COMMENT,
    id: id,
    //id: `id-${id}`,
    comment: comment,
  };
};
export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const setCount = (value) => {
  return {
    type: SET_RFIDS_COUNT,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const addRfids = (rfids) => {
  return {
    type: ADD_RFIDS,
    data: rfids,
  };
};
export const setFormInfo = (info) => {
  return {
    type: SET_FORM_INFO,
    data: info,
  };
};
export const setFormData = (fieldName, value) => {
  return {
    type: SET_FORM_DATA,
    fieldName: fieldName,
    value: value,
  };
};
export const removeFormInfo = () => {
  return {
    type: REMOVE_FORM_INFO,
  };
};
export const setCardFormData = (data) => {
  return {
    type: SET_CARD_FORM_DATA,
    data: data,
  };
};
export const removeCardFormData = () => {
  return {
    type: REMOVE_CARD_FORM_DATA,
  };
};

export const setItemInfo = (value) => {
  return {
    type: SET_ITEM_INFO,
    data: value,
  };
};

export const getRequestParams = (filter) => (dispatch, getState) => {
  //console.log('filter', filter)

  let decorateParams = {};
  for (let key in filter) {
    if (filter[key]) {
      switch (key) {
        case 'vehicle_class':
          decorateParams[key] = Number(filter[key]);
          break;
        default:
          decorateParams[key] = filter[key];
      }
    }
  }
  return decorateParams;
};

export const addRfid = (values, callback) => (dispatch, getState) => {
  const clientId = getState().client.clientInfo.id;

  return api.call('rfid.add', { client_id: clientId, ...values }).then((data) => {
    if (callback) {
      callback();
    }
  }); /*.catch(data => {
        console.log('error', data);
    });*/
};

const updateRfidCall = (params, callback) => {
  return api.call('rfid.update', params).then((data) => {
    if (callback) {
      callback(data);
    }
  }); /*.catch(data => {
        console.log('error', data);
    });*/
};

export const updateRfidByCard = (values, id, callback) => (dispatch) => {
  const out = { ...values };
  return updateRfidCall({ id: id, ...out }, callback);
};

export const updateRfid = (params, callback) => (dispatch) => {
  return updateRfidCall({ id: params.id, ...params.info }, callback);
};

const getRfidInfo = (rfids, rfidInfo) => {
  console.log('getRfidInfo', rfidInfo);

  let refreshRfid = {};
  for (let key in rfids) {
    if (rfids[key].id === rfidInfo.id) {
      refreshRfid = { ...rfids[key] };
    }
  }
  refreshRfid['comment'] = rfidInfo.comment;

  delete refreshRfid.client;
  delete refreshRfid.tariff;

  return refreshRfid;
};
export const addComment = (rfidInfo) => (dispatch, getState) => {
  const rfids = getState().rfids.rfids;
  const rfid = getRfidInfo(rfids, rfidInfo);

  return updateRfidCall({ id: rfidInfo.id, ...rfid }, (data) => {
    dispatch(updateComment(data.id, data.comment));
  });
};

export const changeRfidStatus = (params) => (dispatch) => {
  return api.call('rfid.change_status', params).then((res) => {
    dispatch(updateStatus(res.id, res.status, res.comment));
  });
};

export const addItem = (params, callback) => (dispatch) => {
  return api
    .call('payment_means.add', params)
    .then((res) => {
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      resolve(error);
    });
};

export const updateItem = (params, callback) => (dispatch) => {
  return api
    .call('payment_means.update', params)
    .then((res) => {
      if (callback) {
        callback();
      }
    })
    .catch((error) => resolve(error));
};
