import styled, { css } from 'styled-components';

import { FieldComponent } from '../field-component';
import { Container as CommonContainer } from 'libs/ui';

export const Input = styled(FieldComponent)`
  width: ${(props) => props.width};
  margin-right: ${(props) => (props.lastField ? '0' : '20px')};
  label {
    margin-bottom: 2px;
    display: block;
    color: #3e3e3e;
    font-size: 13px;
    opacity: 0.6;
    white-space: nowrap;
  }
  input,
  select,
  textarea,
  .input {
    font-size: 15px;
    color: #000;
    width: 100%;
    padding: 11px 10px 9px;
    border-radius: 4px;
    border: solid 1px #bebebe;
    height: 40px;
  }
`;

export const Select = styled(Input)`
  position: relative;
  &:before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 30px;
    right: 10px;
    width: 6px;
    height: 6px;
    border: 2px solid #6e7a86;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  select,
  input {
    padding: 11px 22px 9px 10px;
    position: relative;
    background-color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  ${(props) =>
    props.black &&
    css`
      select,
      input {
        color: #fff;
        background-color: #4f5761;
      }
      label {
        color: #b7babf;
      }
    `}
`;

export const DoubleField = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: ${(props) => (props.lastField ? '0' : '20px')};
  div {
    margin-right: 0;
  }
  & > div:first-child {
    /* input,
    select {
      border-radius: 4px 0 0 4px;
    } */
    margin-right: 34px;
    position: relative;
    &:after {
      content: '—';
      /* font-weight: bold; */
      position: absolute;
      left: 106%;
      top: 50%;
      color: #8e8e8e;
      font-size: 1.2em;
      line-height: 1;
    }
  }
  & > div:last-child {
    /* input,
    select {
      border-radius: 0 4px 4px 0;
      border-left: none;
    } */
  }
`;

export const DateTime = styled(Input)`
  input {
    background-color: #fff;
  }
  ${(props) =>
    props.black &&
    css`
      label {
        color: #b7babf;
      }
      input {
        background-color: rgb(79, 87, 97);
        color: rgb(255, 255, 255);
      }
    `}
`;

// temporary styled
export const Reset = styled.div``;

export const Multiselect = styled(Select)`
  & .popup {
    top: 39px;
    background-color: #fff;
    box-shadow: 0 1px 2px 2px rgba(85, 85, 85, 0.2);
    border: solid 1px #bebebe;
  }
  & .checkbox {
    label {
      color: #000;
    }
    input {
      border: solid 1px #bebebe;
      padding: 0;
    }
  }
  & .input {
    padding: 0 9px;
  }
`;

export const Toggle = styled(Input)`
  height: 40px;
  display: flex;
  align-items: center;
  label {
    margin: 0 10px 0 0;
    font-size: 15px;
    color: #000;
    opacity: 1;
  }
  .toggle {
    position: relative;
    height: 20px;
    width: 38px;
    span {
      cursor: pointer;
      top: 1px;
      left: 1px;
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      background-color: #fff;
      width: 18px;
      height: 18px;
      transition: 0.2s;
    }
    input {
      height: inherit;
      position: relative;
      cursor: pointer;
      appearance: none;
      border: 0;
      border-radius: 20px;
      outline: none;
      background-color: #d0d2d6;
      &:checked {
        background-color: #3ea5ff;
      }
      &:checked + span {
        left: 18px;
      }
    }
  }
`;

export const Container = styled(CommonContainer)`
  position: relative;
  padding: 20px;
  border-radius: 2px;
  background-color: #f3f3f4;
  box-shadow: 0 1px 0 0 #d7d7d7;
  height: ${(props) => props.height};
  form {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-content: space-between;
    align-items: flex-start;
    /*
    & > div:last-child {
      align-self: flex-end;
    }
    */
    & > * {
      margin-bottom: 1rem;
    }
    & > .center,
    & > div:last-child {
      align-self: center;
      margin-top: 0rem;
      margin-bottom: 0;
    }
  }
  ${(props) =>
    props.theme === 'dark' &&
    css`
      background-color: #4f5761;
      box-shadow: none;
      select,
      input {
        background-color: #4f5761;
        color: #fff;
      }
      label {
        color: #b7babf;
      }
      .calendar-intervals p:hover {
        color: #fff;
      }
    `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  & > button {
    margin-left: 10px;
  }
`;

export const Apply = styled.button``;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 1rem;
  flex-grow: 1;
  .last {
    margin-right: 0;
  }
  .input-big {
    max-width: 280px;
  }
  @media (min-width: 1870px) {
    .input-big {
      max-width: 180px;
    }
  }

  @media (max-width: 1870px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;
