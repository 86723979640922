import React, { Component } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button, themes, ButtonContainer } from 'libs/ui';
//import { Wrapper } from 'modules/components/windows/styledComponents'
import Warning from 'modules/components/warnings';
import { Popup2 } from 'libs/ui';

import { printNewTicket } from 'store/ducks/models/stays';
import { ErrorMsg } from '../../styled';

const NewPayContent = styled.div`
  form {
    margin-bottom: 20px;
  }
`;
const NewPayHeader = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;
const NewPayInfo = styled.label`
  font-size: 15px;
  display: block;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 10px;
  select {
    margin-left: 0.5rem;
  }
`;

const Line = styled.span`
  display: inline-flex;
  [type='radio'] {
    margin-right: 0.2rem;
  }
  [name='penaltyvalue'] {
    width: 70px;
    margin: 0 0.5rem;
    align-items: center;
  }
  .l {
    display: inline-block;
    vertical-align: middle;
    width: 150px;
    margin-right: 1rem;
  }
`;

class NewPay extends Component {
  state = {
    parkomat: '',
    penalty: '0',
    comment: 'Штраф',
    penaltyvalue: 1000,
    working: false,
    error: '',
    confirm: false,
  };

  showConfirm = () => {
    this.setState({ confirm: true });
  };

  setComment = (value) => {
    this.setState({ comment: value.target.value });
  };

  setPenalty = (value) => {
    let val = Number(value.target.value);
    if (val < 0) {
      val = 0;
    }
    this.setState({ penaltyvalue: val });
  };

  getErrorMessage = () => {
    return this.state.error;
  };

  addPay = () => {
    this.setState({ confirm: false });
  };

  render() {
    const { penaltyvalue, working, comment, confirm } = this.state;

    return (
      <>
        <Popup2 w={400}>
          <NewPayContent>
            <NewPayHeader>Добавление услуги к оплате</NewPayHeader>
            {this.state.error && (
              <ErrorMsg>
                <img src="/images/error-triangle-icon.png" alt="" />
                {this.getErrorMessage()}
              </ErrorMsg>
            )}
            <NewPayInfo>
              <Line>
                <span className="l">Сумма</span>
                <input
                  style={{ margin: 0, width: '80px' }}
                  min={1}
                  onChange={this.setPenalty}
                  type="number"
                  name="penaltyvalue"
                  value={penaltyvalue}
                />
                &nbsp;₽
              </Line>
            </NewPayInfo>
            <NewPayInfo>
              <Line>
                <span className="l">Наименование</span>
                <input min={0} onChange={this.setComment} type="text" name="penaltytype" value={comment} />
              </Line>
            </NewPayInfo>
            <ButtonContainer space="20px" justifyContent={'flex-start'} style={{ marginTop: '30px' }}>
              <Button disabled={working} fontSize="16px" onClick={this.props.close}>
                Отменить
              </Button>
              <Button disabled={working} theme={themes.blue} fontSize="16px" onClick={this.showConfirm}>
                Подтвердить
              </Button>
            </ButtonContainer>
          </NewPayContent>
        </Popup2>
        {confirm && (
          <Warning
            width="480px"
            header={`Стоимость парковочной сессии будет увеличена. Продолжить?`}
            cancelButton={{
              func: () => this.setState({ confirm: false }),
              text: 'Отменить',
            }}
            acceptButton={{
              func: () => {
                this.addPay();
              },
              text: 'Подтвердить',
              theme: themes.blue,
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps, { printNewTicket })(NewPay));
