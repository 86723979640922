import { config } from 'config';
import { api } from 'api';

/**
 * adv
 ****************************/

/**
 * get adv lists
 * @param {*} type
 * @returns
 */
export const getAdvList = (type) => {
  const flt = {
    list_id: type,
  };
  return api.call('advertising.list', { filter_params: flt, sort_params: null, paging_params: null });
};

/**
 * add item to list
 * @param {*} data
 * @returns
 */
export const addAdv = (data) => {
  return api.call('advertising.item.add', data);
};

/**
 * delete item
 * @param {*} id
 * @returns
 */
export const deleteAdv = (id) => {
  return api.call('advertising.item.delete', { item_id: id });
};

/**
 * sort items
 * @param {*} ids
 * @returns
 */
export const sortAdvList = (ids) => {
  return api.call('advertising.sort', { item_ids: ids });
};

/**
 * get absolute file link
 * @param {*} fn
 * @returns
 */
export const getFileLink = (fn) => {
  const domen = window.local ? config.network.api_url.replace('/api', '') : window.location.origin;
  const d = new Date().getTime();
  return domen + fn + '?' + d;
};
