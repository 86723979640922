import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { getAmount } from 'libs/common';
import { getMeanType } from 'services/types';
import { Cell, GridRow } from 'libs/ui';

class Benefit extends Component {
  render() {
    return (
      <GridRow>
        <Cell pr10>{this.props.client_name}</Cell>
        <Cell>{this.props.contract_number}</Cell>
        <Cell>{getMeanType(this.props.mean_type)}</Cell>
        <Cell>{this.props.mean_number}</Cell>
        <Cell>{getAmount(this.props.debt)}</Cell>
        <Cell pr10>{this.props.is_payed ? 'Оплачено' : 'Не оплачено'}</Cell>
      </GridRow>
    );
  }
}

export default withRouter(Benefit);
