import React from 'react'
import { connect } from 'react-redux'

import { GridContainer } from 'libs/ui'
import { Controller } from 'libs/control'
import Grid from './Grid'

import {
  getRequestParams,
  clearEntries,
  addRoles,
  setSortParams,
  setOffset,
  setRoleInfo,
  addRole,
  updateRole,
  delItem,
  updatePermission
} from 'store/ducks/models/roles'
import { 
  setScroll,
  addFormDisplayHandler, 
  updateFormDisplayHandler,
  updateMessageDisplayHandler,
  updatePermDisplayHandler
} from 'store/ducks/interface/rolesGrid'

const Roles = (props) => {
  return (
    <GridContainer>
      <Controller
        type='roles'
        grid={Grid}
        offset={props.offset}
        entries={props.roles}
        getRequestParams={props.getRequestParams}
        itemInfo={props.roleInfo}
        clearEntries={props.clearEntries}
        addEntries={props.addRoles}
        setItemInfo={props.setRoleInfo}
        addItem={props.addRole}
        updateItem={props.updateRole}
        setSortParams={props.setSortParams}
        setOffset={props.setOffset}
        setScroll={props.setScroll}
        sortParams={props.sortParams}
        addFormDisplayHandler={props.addFormDisplayHandler}
        updateFormDisplayHandler={props.updateFormDisplayHandler}
        updatePermDisplayHandler={props.updatePermDisplayHandler}
        updateMessageDisplayHandler={props.updateMessageDisplayHandler}
        delItem={props.delItem}
        updatePermission={props.updatePermission}
        interface={props.interface}
      />
    </GridContainer>
  )
}

const mapStateToProps = (store) => {
  return {
    roles: store.roles.roles,
    offset: store.roles.offset,
    roleInfo: store.roles.roleInfo,
    sortParams: store.roles.sortParams,
    interface: store.rolesGrid
  }
}

export default connect(mapStateToProps, {
  clearEntries,
  addRoles,
  getRequestParams,
  setSortParams,
  setOffset,
  setScroll,
  setRoleInfo,
  addRole,
  updateRole,
  addFormDisplayHandler,
  updateFormDisplayHandler,
  updateMessageDisplayHandler,
  updatePermDisplayHandler,
  delItem,
  updatePermission
})(Roles)