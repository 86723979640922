import { useState } from 'react';
import { reduxForm, SubmissionError } from 'redux-form';

import { user } from 'services';
import { addEpm, updateEpm, getClientsDict } from 'services/payments-service';
import { getCategoriesList, getVehicleClassesList, getOptionsList } from 'services/types';
import { ParkingSession, Confirm, Button, themes, Popup2 } from 'libs/ui';
import { DictInput, ParkingFilter2, required, WhiteStyledField, Title, ErrorMsg } from 'libs/form';
import { StyledButtonContainer } from './epm-form.styled';

const EpmForm = (props) => {
  const { error, destroy, refresh, setMessage, form, handleSubmit, close, type, clientId, id, meanId, meanType, initialValues, tariffs } = props;
  const [confirm, setConfirm] = useState(false);
  const [data, setData] = useState(null);

  const rightSessionActive = user.haveRight('csc.show_active_stay');
  const isAdd = type === 'addForm';

  const closeConfirm = () => {
    setConfirm(false);
  };

  /**
   * after submit
   */
  const afterSubmit = () => {
    refresh();
    setMessage('Изменения сохранены');
    setTimeout(() => {
      setMessage('');
    }, 3000);
    close();
    destroy(form);
  };

  /**
   * submit
   * @param {*} values
   * @returns
   */
  const submit = (values) => {
    // data
    const test = false;
    const out = { ...values };
    out.is_blocked = out.is_blocked === 'true' || out.is_blocked === true;
    out.vehicle_category = out.vehicle_category ? parseInt(out.vehicle_category) : null;
    out.vehicle_class = out.vehicle_class ? parseInt(out.vehicle_class) : null;
    if (!out.client_id && clientId) {
      out.client_id = clientId;
    }
    if (!clientId && !out.client_id) {
      out.client_id = null;
      out.client_name = null;
    }

    if (!isAdd) {
      out.id = id;
      // confirm
      if (out.mean_id !== props.initialValues.mean_id) {
        setData(out);
        setConfirm(true);
        return false;
      }
    }
    if (test) {
      console.log('🚀 ~ submit ~ out:', out);
      return Promise.resolve();
    }

    // method
    const method = isAdd ? addEpm : updateEpm;
    return method(out)
      .then(() => {
        afterSubmit();
      })
      .catch((errors) => {
        if (errors && errors.data) {
          throw new SubmissionError({
            _error: errors.data.type,
          });
        }
        if (errors && errors.field) {
          throw new SubmissionError({
            [errors.field]: errors.message,
          });
        }
      });
  };

  const submitAfterConfirm = () => {
    return updateEpm(data)
      .then(() => {
        afterSubmit();
      })
      .catch((errors) => {
        if (errors && errors.data) {
          throw new SubmissionError({
            _error: errors.data.type,
          });
        }
      });
  };

  const cancel = () => {
    close();
    destroy(form);
  };

  const getClients = (name) => {
    return getClientsDict({
      filter_params: { name },
      sort_params: [{ field_name: 'name', direction: 'asc' }],
      paging_params: { limit: 5, offset: 0 },
    });
  };

  const title = isAdd ? 'Добавить СО' : 'Изменить СО';

  return (
    <Popup2 w={482} top={3}>
      <Title>{title}</Title>
      <form onSubmit={handleSubmit(submit)}>
        <ErrorMsg error={error} />
        <ParkingFilter2 />

        {!clientId && (
          <WhiteStyledField
            selectedName={initialValues.client_name}
            label="Клиент"
            placeholder="Клиент"
            type="autocomplete2"
            name="client_id"
            getList={getClients}
          />
        )}

        <DictInput dict="payment_mean.status" />

        <WhiteStyledField
          type="text"
          label="ГРЗ"
          placeholder="А000AA 000 rus"
          name="vehicle_plate"
          //validate={required}
        />

        <WhiteStyledField type="text" label="БСК" placeholder="3786 4678 6467 5656" name="mean_id" validate={required} />
        <WhiteStyledField type="text" label="Держатель" placeholder="Введите текст" name="owner" validate={required} />

        <WhiteStyledField validate={required} type="select" label="Класс ТС" name="vehicle_class">
          <option value={null}>Не задано</option>
          {getVehicleClassesList()}
        </WhiteStyledField>

        <WhiteStyledField type="select" label="Категория" name="vehicle_category">
          <option value={null}>Не задано</option>
          {getCategoriesList()}
        </WhiteStyledField>

        <WhiteStyledField
          className="shortfield"
          type="dateTime"
          label="Дата действия с"
          placeholder="Дата действия с"
          name="start_at"
          //validate={required}
        />

        <WhiteStyledField
          className="shortfield cl2359"
          type="dateTime"
          label="Дата действия по"
          placeholder="Дата действия по"
          name="end_at"
          //validate={required}
        />

        <WhiteStyledField className="shortfield" type="select" label="Тариф" name="tariff_id">
          <option value="">Не задано</option>
          {getOptionsList(tariffs)}
        </WhiteStyledField>

        {!isAdd && rightSessionActive && <ParkingSession meanType={meanType} meanId={meanId} />}

        <StyledButtonContainer space="20px">
          <Button type="button" onClick={cancel}>
            Отменить
          </Button>
          <Button theme={themes.blue}>
            <i className="fa fa-save" />
            Сохранить
          </Button>
        </StyledButtonContainer>

        {confirm && (
          <Confirm
            width={'550px'}
            title={'Номер карты для данной записи будет изменён. Предыдущий номер карты будет удалён. Продолжить?'}
            type="danger"
            cancelButton={{ text: 'Отмена', func: closeConfirm }}
            acceptButton={{ text: 'Изменить номер карты', func: submitAfterConfirm, theme: themes.red }}
          />
        )}
      </form>
    </Popup2>
  );
};

//vehicle_category: props.categories ? props.categories[0].id : null,

export default reduxForm({
  form: 'clientEpmForm',
  destroyOnUnmount: false,
})(EpmForm);
