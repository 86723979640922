import { useEffect } from 'react';
import { reduxForm } from 'redux-form';

import { getOptionsList } from 'services/types';
import { ResetBtn, UseBtn } from 'libs/ui';
import { Container, Input, ParkingFilter, DictFilter, Select, ButtonsContainer } from 'libs/form';

const initial = {};

const Filter = (props) => {
  const { setInitial, reset, handleSubmit, theme, categories, vehicleClasses } = props;

  // did mount
  useEffect(() => {
    setInitial(initial);
    return () => {
      reset();
      setInitial(initial);
    };
  }, []);

  return (
    <Container theme={theme}>
      <form onSubmit={handleSubmit}>
        <DictFilter w={140} dict="rfid.status" />

        <Input width="170px" name="rfid" type="text" label="RFID" placeholder="3786 4678 6467 5656" />

        <Input width="300px" name="client_name" type="text" label="Клиент" placeholder="Иванов И.И." />

        <Input width="300px" name="owner_name" type="text" label="Держатель" placeholder="Иванов И.И." />

        <Select width="300px" name="vehicle_category" type="select" label="Категория">
          <option value="">Все</option>
          {getOptionsList(categories)}
        </Select>

        <Select width="140px" name="vehicle_class" type="select" label="Класс ТС">
          <option value="">Все</option>
          {getOptionsList(vehicleClasses)}
        </Select>

        <Input width="300px" name="tariff" type="text" label="Тариф" placeholder="Базовый" />

        <Input width="170px" name="vehicle_plate" type="text" label="ГРЗ" placeholder="А000AA 000 rus" />

        <Input label="Комментарий" width="370px" name="comment" type="text" placeholder="Текст комментария" lastField />

        <ParkingFilter />

        <ButtonsContainer>
          <ResetBtn onClick={reset} theme={theme} />
          <UseBtn />
        </ButtonsContainer>
      </form>
    </Container>
  );
};

export default reduxForm({
  form: 'rfidFilter',
  destroyOnUnmount: false,
  initialValues: initial,
})(Filter);
