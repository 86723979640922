import styled, { css } from 'styled-components';

import { Button } from 'libs/ui';

export const AddButton = styled(Button)`
  margin: 20px 12px;
`;

export const InputList = styled.ul`
  li {
    list-style-type: none;
    display: block;
    margin: 0 0 5px;
  }
`;

export const InputsPlace = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  div {
    &:nth-child(1) {
      border-right: 0;
      border-radius: 4px 0 0 4px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      border-radius: 0;
      border-right: 0;
    }
    &:nth-child(4) {
      border-radius: 0 4px 4px 0;
    }
    ${(props) =>
      !props.$extended &&
      css`
        &:nth-child(2) {
          border-radius: 0 4px 4px 0;
          border-right: 1px solid #9195a6;
        }
      `}
  }
  img {
    cursor: pointer;
    margin-left: 12px;
  }
`;

export const Sums = styled.div`
  //width: 200px;
  min-width: 80px;
  white-space: nowrap;
  //margin: 6px 0;

  opacity: 0.5;
  line-height: 1.2;
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
`;

export const InfoPlace = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const FieldPlace = styled.div`
  width: ${(props) => props.w}px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px;
  border-color: #9195a6;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 10px;
  ${(props) =>
    props.$center &&
    css`
      justify-content: center;
    `}
  input {
    width: ${(props) => (props.wide ? '90%' : '100%')};
    height: 100%;
    border: none;
    background-color: inherit;
    outline: none;
    color: ${(props) => (props.error ? '#d33d34' : '#fff')};
    font-size: 15px;
    text-align: end;
    //margin-right: 10px;
  }
  input[type='radio'] {
    appearance: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 1px solid #999;
    transition: 0.2s all linear;
    position: relative;
    top: 2px;
    cursor: pointer;
    &:checked {
      background-color: #ccc;
    }
  }
  span {
    display: block;
    margin-left: 7px;
    opacity: 0.7;
    color: ${(props) => (props.error ? '#d33d34' : '#fff')};
    font-size: 15px;
  }
`;
