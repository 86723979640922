import styled from 'styled-components';

export const Content = styled.div`
  margin: 20px 0 0;  
  font-size: 14px;
  line-height: normal;
  p {
    margin: 0 0 20px;
    b {
      font-weight: normal;
      color: #888;
    }
  }
`;

export const Items = styled.div``;

export const Item = styled.div`
  margin: 0 0 3px;
  border-radius: 3px;
  padding: 7px 5px;
  background: #f8f8f8;
  i {
    font-style: normal;
    color: #999;
    display: inline-flex;
    margin-right: 10px;
  }
`;
