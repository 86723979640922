import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  /* styles for no_photo image */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: ${(props) => (props.$static ? '200px' : '100%')};
  height: ${(props) => (props.$static ? '200px' : '100%')};
  opacity: ${(props) => props.opacity};
`;

const Arrow = styled.div`
  display: ${(props) => (props.$hide ? 'none' : 'ihnerit')};
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 150px;
  color: white;
`;

export const LeftArrow = styled(Arrow)`
  left: 20px;
  font-size: 64px;
`;

export const RightArrow = styled(Arrow)`
  right: 20px;
  font-size: 64px;
`;

export const ImageWindow = styled.div`
  z-index: 11;
  position: fixed;
  width: 1248px;
  height: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.95);
  border-radius: 20px;
  padding: 10px;
  & > img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }
`;
