import { api } from 'api';

const UPDATE_STATUS = '/pages/payments/epm/UPDATE_STATUS';
const UPDATE_COMMENT = '/pages/payments/epm/UPDATE_COMMENT';
const CLEAR_ENTRIES = '/pages/payments/epm/CLEAR_ENTRIES';
const ADD_EPMS = '/pages/payments/epm/ADD_EPM';
const SET_EPMS_COUNT = '/pages/payments/epm/SET_EPMS_COUNT';
const SET_OFFSET = '/page/payments/epm/SET_OFFSET';
const SET_IMPORT_ERRORS = '/pages/payments/epm/SET_IMPORT_ERRORS';
const SET_SORT_PARAMS = '/pages/payments/epm/SET_SORT_PARAMS';
const SET_UPLOAD_DATA = '/pages/payments/epm/SET_UPLOAD_DATA';
const SET_CATEGORIES = '/pages/payments/epm/SET_CATEGORIES';
const SET_VEHICLE_СLASSES = '/pages/payments/epm/SET_VEHICLE_СLASSES';
const SET_FORM_INFO = '/pages/payments/epm/SET_FORM_INFO';
const REMOVE_FORM_INFO = '/pages/payments/epm/REMOVE_FORM_INFO';

const SET_ITEM_INFO = '/pages/payments/epm/SET_ITEM_INFO';

export default function reducer(state, action) {
  const defaultState = {
    epms: {},
    item: {},
    offset: 0,
    count: 0,
    sortParams: [{ field_name: 'pan', direction: 'asc' }],
    formInfo: {},
    importErrors: null,
    uploadData: null,
    categories: [],
    vehicleClasses: [],
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case UPDATE_STATUS:
      return {
        ...state,
        epms: {
          ...state.epms,
          [action.id]: {
            ...state.epms[action.id],
            status: action.status,
            comment: action.comment,
          },
        },
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        epms: {
          ...state.epms,
          [action.id]: {
            ...state.epms[action.id],
            comment: action.comment,
            comments: [1],
          },
        },
      };
    case CLEAR_ENTRIES:
      return {
        ...state,
        epms: {},
      };
    case SET_EPMS_COUNT:
      return {
        ...state,
        count: action.data,
      };
    case SET_OFFSET:
      return {
        ...state,
        offset: action.data,
      };
    case SET_SORT_PARAMS:
      return {
        ...state,
        sortParams: action.data,
      };
    case ADD_EPMS:
      return {
        ...state,
        epms: {
          ...state.epms,
          ...action.data,
        },
      };
    case SET_IMPORT_ERRORS:
      return {
        ...state,
        importErrors: action.data,
      };
    case SET_UPLOAD_DATA:
      return {
        ...state,
        uploadData: action.data,
      };
    case SET_FORM_INFO:
      return {
        ...state,
        formInfo: action.data,
      };
    case REMOVE_FORM_INFO:
      return {
        ...state,
        formInfo: null,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.data,
      };
    case SET_VEHICLE_СLASSES:
      return {
        ...state,
        vehicleClasses: action.data,
      };
    case SET_ITEM_INFO:
      return {
        ...state,
        item: action.data,
      };

    default:
      return state;
  }
}

export const updateStatus = (id, status, comment) => {
  return {
    type: UPDATE_STATUS,
    id: `id-${id}`,
    status: status,
    comment: comment,
  };
};
export const updateComment = (id, comment) => {
  return {
    type: UPDATE_COMMENT,
    id: id, //`id-${id}`,
    comment: comment,
  };
};
export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};
export const addEpms = (epms) => {
  return {
    type: ADD_EPMS,
    data: epms,
  };
};
export const setCount = (value) => {
  return {
    type: SET_EPMS_COUNT,
    data: value,
  };
};
export const setOffset = (value) => {
  return {
    type: SET_OFFSET,
    data: value,
  };
};
export const setSortParams = (value) => {
  return {
    type: SET_SORT_PARAMS,
    data: value,
  };
};
export const setFormInfo = (info) => {
  return {
    type: SET_FORM_INFO,
    data: info,
  };
};
export const removeFormInfo = () => {
  return {
    type: REMOVE_FORM_INFO,
  };
};
export const setImportErrors = (importErrors) => {
  return {
    type: SET_IMPORT_ERRORS,
    data: importErrors,
  };
};
export const clearErrors = () => {
  return {
    type: SET_IMPORT_ERRORS,
    data: null,
  };
};
export const setUploadData = (data) => {
  return {
    type: SET_UPLOAD_DATA,
    data: data,
  };
};
export const setCategories = (data) => {
  return {
    type: SET_CATEGORIES,
    data: data,
  };
};
export const setVehicleСlasses = (data) => {
  return {
    type: SET_VEHICLE_СLASSES,
    data: data,
  };
};

export const setItemInfo = (value) => {
  return {
    type: SET_ITEM_INFO,
    data: value,
  };
};

export const getRequestParams = (filter) => (dispatch, getState) => {
  const intItems = ['vehicle_category', 'vehicle_class', 'vehicle_category'];
  let decorateParams = {};
  for (let key in filter) {
    if (filter[key]) {
      let key1 = key;
      let val = filter[key];
      if (key === 'is_blocked') {
        val = val === 'true';
      }
      if (intItems.indexOf(key) !== -1) {
        val = Number(val);
      }
      decorateParams[key1] = val;
    }
  }
  return decorateParams;
};

export const addComment = (epmInfo) => (dispatch) => {
  api.call('csc.update', { id: epmInfo.id, item: { comment: epmInfo.comment } }).then((data) => {
    dispatch(updateComment(data.id, data.comment));
  });
};

export const addCommentCommon = (info) => (dispatch) => {
  api.call('comment.add', { linked_id: info.id, comment: info.comment }).then((data) => {
    console.log('🚀 ~ api ~ data', data);
    dispatch(updateComment(data.linked_id, data.comment));
  });
};

/**
    REQUIRED PARAM: id [int]
    REQUIRED PARAM: status [str]
    REQUIRED PARAM: comment [str]
 */
export const changeEpmStatus = (params) => (dispatch) => {
  api.call('csc.change_status', params).then((data) => {
    dispatch(updateStatus(data.id, data.status, data.comment));
  });
};

export const sendFileContent = (params, callback) => (dispatch) => {
  api
    .call('csc.import_list', params)
    .then((data) => {
      if (callback) {
        callback();
      }
    })
    .catch((data) => {
      dispatch(setImportErrors(data.data));
    });
};

export const addItem = (params, callback) => (dispatch) => {
  return api.call('csc.add', params).then((data) => {
    if (callback) {
      callback();
    }
  });
};

export const updateItem = (params, callback) => (dispatch) => {
  return api.call('csc.update', params).then((data) => {
    if (callback) {
      callback();
    }
  });
};
