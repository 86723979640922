import React from 'react';
import { SubmissionError } from 'redux-form';

import { user } from 'services';
import { LangSelector } from 'libs/ui';
import { LoginForm } from './form';
import { StyledAuth } from './index.styled';

const Auth = () => {
  /**
   * login
   * @param {*} values
   * @returns
   */
  const submit = (values) => {
    return user.login(values).catch((error) => {
      throw new SubmissionError({
        _error: error,
      });
    });
  };

  return (
    <StyledAuth>
      <img src="/images/auth-logo.png" alt="" />
      <LangSelector />
      <LoginForm onSubmit={submit} />
    </StyledAuth>
  );
};

export default Auth;
