import React from 'react';
import styled from 'styled-components';

import { Checkbox, Popup } from '../styled';

const StyledCheckbox = styled(Checkbox)`
  &.checkbox {
    input {
      border: 1px solid #fff;
    }
  }
`;
const InputBox = styled.div`
  position: relative;
  height: 100%;
  &:after {
    content: '';
    position: absolute;
    top: 12px;
    right: 5px;
    width: 6px;
    height: 6px;
    border: 2px solid #bebebe;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  input {
    text-overflow: ellipsis;
    padding-right: 20px;
    max-width: 393px;
  }
`;

export const MultiselectView = ({ showPopup, values, setValue, data, popupIsOpen, $popupIsOpen, selectAll, placeholder, input: { name, value } }) => {
  const open = popupIsOpen || $popupIsOpen;

  const getDisplayedValue = () => {
    if (Array.isArray(value)) {
      let displayedValue = '';

      value.forEach((id) => {
        data.forEach((elem) => {
          if (id == elem.id) {
            if (displayedValue) {
              displayedValue += `, ${elem.name}`;
            } else {
              displayedValue += elem.name;
            }
          }
        });
      });
      return displayedValue;
    } else return '';
  };

  const getInputs = () => {
    if (Array.isArray(data)) {
      return data.map((elem) => {
        return (
          <StyledCheckbox key={elem.id} className="checkbox">
            <input
              id={'id' + elem.name}
              onChange={(e) => setValue(e, elem.id)}
              type="checkbox"
              name={elem.name}
              checked={Array.isArray(value) ? value.includes(elem.id) || value.includes(parseInt(elem.id)) : false}
            />
            <label htmlFor={'id' + elem.name}>{elem.name}</label>
          </StyledCheckbox>
        );
      });
    }
  };

  return (
    <>
      <InputBox onClick={showPopup}>
        <input placeholder={placeholder} value={getDisplayedValue()} disabled />
      </InputBox>
      <Popup className="popup" $popupIsOpen={open}>
        <StyledCheckbox className="checkbox">
          <input id={name + 'all'} onChange={selectAll} checked={values.length === data.length} type="checkbox" name="all" />
          <label htmlFor={name + 'all'}>Все</label>
        </StyledCheckbox>
        {getInputs()}
      </Popup>
    </>
  );
};
