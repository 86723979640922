import React, { useState, useEffect } from 'react';
import { StyleSheetManager } from 'styled-components';

import { api } from 'api';
import { user, data } from 'services';
import { Router } from 'router';
import { Loader } from 'libs/ui';
import { notify } from 'libs/common';
import { KeyInterceptor } from 'libs/common/key-interceptor';

export const App = () => {
  const [checked, setChecked] = useState(false);
  // const [error, setError] = useState('');
  const [logined, setLogined] = useState(false);
  const [ready, setReady] = useState(false);

  /**
   * check user token
   */
  useEffect(() => {
    user
      .getInfo()
      .then(() => {
        setChecked(true);
        setLogined(user.isLogined);
        // console.log('🚀 ~ .then ~ user.isLogined:', user.isLogined);
      })
      .catch((error) => {
        notify('Ошибка сети', error);
        // setError(error);
        setLogined(false);
        setChecked(true);
      });
  }, []);

  /**
   * load data
   */
  useEffect(() => {
    const getData = async () => {
      try {
        let items = await api.call('dictionaries.list');
        data.setDictionaries(items);

        items = await api.call('zones.list');
        data.setZones(items);

        setReady(true);
      } catch (e) {
        notify('Ошибка получения данных', e.toString());
      }
    };

    if (logined) {
      getData();
    }
  }, [logined]);

  if (!checked || (checked && logined && !ready)) {
    return (
      <div className="App">
        <Loader />
      </div>
    );
  }

  const filterProps = (prop) => {
    return ![
      'status',
      'brdColor',
      'justifyContent',
      'isActive',
      'selectedTab',
      'withSort',
      'renderCell',
      'innerRef',
      'bold',
      'left',
      'right',
      'final',
      'number',
      'isSelected',
      'wide',
      'ml0',
    ].includes(prop);
  };

  return (
    <StyleSheetManager shouldForwardProp={filterProps}>
      <div className="App">
        <KeyInterceptor />
        <Router />
      </div>
    </StyleSheetManager>
  );
};
