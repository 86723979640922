import styled from 'styled-components';

import { UpdateMessage } from 'libs/ui';

export const UpdateMessageBox = styled(UpdateMessage)`
  top: 20px;
  left: 45%;
`;

export const Confirm = styled.div`
  padding: 0;
`;
export const Title = styled.div`
  padding: 0;
  margin: 0 0 1rem;
`;
export const Buttons = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  button {
    margin-right: 1rem;
  }
`;
