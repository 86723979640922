export const required = (value) => {
  //console.log('🚀 ~ required ~ value:', value, value || value === 0);
  return value || value === 0 ? undefined : 'Обязательное поле';
};

export const itn = (value) => {
  if (value) {
    return value.length === 12 || value.length === 10 ? undefined : `В строке должно быть 10 или 12 символов`;
  }
};

export const iec = (value) => {
  if (value) {
    return value.length === 9 ? undefined : `В строке должно быть 9 символов`;
  }
};

export const psrn = (value) => {
  if (value) {
    return value.length === 13 ? undefined : `В строке должно быть 13 символов`;
  }
};

export const psrnsp = (value) => {
  if (value) {
    return value.length === 15 ? undefined : `В строке должно быть 15 символов`;
  }
};

export const okpo = (value) => {
  if (value) {
    return value.length === 8 || value.length === 10 ? undefined : `В строке должно быть 8 или 10 символов`;
  }
};

export const rfid = (value) => {
  if (value) {
    return value.length === 6 ? undefined : `В строке должно быть 6 символов`;
  }
};

export const tariffIsCorrect = (tariffs) => (value) => {
  if (value) {
    if (Array.isArray(tariffs) && tariffs.length) {
      const isCorrect = tariffs.some((tariff) => {
        return tariff.name === value;
      });
      return isCorrect ? undefined : `Не найдено тарифа с данным именем`;
    } else {
      return `Не найдено тарифа с данным именем`;
    }
  }
};

export const min1 = (value) => {
  //console.log('🚀 ~ required ~ value:', value, value || value === 0);
  if (!value) {
    return 'Заполните поле';
  }
  return parseInt(value) === 0 ? 'Период не может быть равен 0 минут' : undefined;
};
