/**
 * get padded with zero value
 * @param {*} val
 */
export const getPadValue = (val) => {
  return val < 10 ? '0' + val : val.toString();
};

export const getDegreeIndex = (degree) => {
  return (degree / 30) % 12;
};

export const getHourDegree = (val) => {
  const degree = (val * 15) % 360;
  return {
    degreeIndex: getDegreeIndex(degree),
    degree,
  };
};

export const getMinuteDegree = (val) => {
  const degree = (val * 6) % 360;
  return {
    degreeIndex: getDegreeIndex(degree),
    degree,
  };
};

export const getDegree = (val, view) => {
  switch (view) {
    default:
    case 'h':
      return getHourDegree(val);
    case 'm':
      return getMinuteDegree(val);
    case 's':
      return getMinuteDegree(val);
  }
};

/**
 * get degree from coords
 * @param {*} offsetX
 * @param {*} offsetY
 * @returns
 */
export const getDegreeFromCoord = (offsetX, offsetY) => {
  const center = { x: 130, y: 130 };
  const basePoint = { x: 130, y: 0 };

  const x = offsetX - center.x;
  const y = offsetY - center.y;
  const cx = basePoint.x - center.x;
  const cy = basePoint.y - center.y;
  const atan = Math.atan2(cx, cy) - Math.atan2(x, y);
  return (atan * 57.29577951308232) % 360;
};

/**
 * get k const
 * @returns
 */
export const getK = (view) => {
  const data = {
    h: 15,
    m: 6,
    s: 6,
  };

  return data[view];
};

/**
 * is mouse pressed
 * @param {*} event
 * @returns
 */
export const isMousePressed = (event) => {
  if (typeof event.buttons === 'undefined') {
    return event.nativeEvent.which !== 1;
  }
  return event.buttons !== 1;
};
