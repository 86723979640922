import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { config } from 'config';
import { user } from 'services';
import { routes } from 'router';
import { LangSelector, Tooltip } from 'libs/ui';
import { LayoutContainer, HeaderContainer, HeaderWrapper, BrandName, Login, LinksContainer, MenuLink, Right } from './layout.styled';

export const Layout = ({ children, sections }) => {
  const { t } = useTranslation();
  const openMnemonicDiagram = (e) => {
    e.preventDefault();
    const token = user.getToken();
    const popup = window.open(
      config.network.mnemonic_diagram_url + '?token=' + token,
      '_blank',
      'toolbar,scrollbars,resizable,top=110,left=0,width=' + window.screen.width + ',height=' + window.screen.height
    );
    popup.moveTo(101, 101);
  };

  const getMenuLinks = () => {
    return sections.map((section, idx) => {
      return (
        <MenuLink key={idx} to={section.path} onClick={section.id === 'scheme' ? openMnemonicDiagram : null}>
          {t('sections.' + section.name)}
        </MenuLink>
      );
    });
  };

  return (
    <LayoutContainer>
      <HeaderContainer>
        <HeaderWrapper>
          <BrandName>
            <NavLink to={routes.stays}>
              {/*CLEVER PARK*/}
              <img src="/images/logo.png" alt="CLEVER PARK" />
            </NavLink>
          </BrandName>
          <LinksContainer>{getMenuLinks()}</LinksContainer>
          <Right>
            <LangSelector flags abs={false} />
            <Login>
              {user.info ? user.info?.name : ''}
              <i className="fa fa-sign-out" onClick={() => user.logoutUser()} />
            </Login>
          </Right>
        </HeaderWrapper>
      </HeaderContainer>
      <>
        {/* need controller for widget */}
        <Tooltip />
        {children}
      </>
    </LayoutContainer>
  );
};
