const SET_ZONE = '/page/tariff/card/SET_ZONE'
const SHOW_MESSAGE_AFTER_ADD = '/page/tariff/card/SHOW_MESSAGE_AFTER_ADD'


export default function reducer(state, action) {

  const defaultState = {
    selectedZone: null,
    showMessageAfterAdd: false 
  }

  if (!state) { state = defaultState }

  switch (action.type) {
    case SET_ZONE:
      return {
        ...state,
        selectedZone: action.data
      }
    case SHOW_MESSAGE_AFTER_ADD:
      return {
        ...state,
        showMessageAfterAdd: action.data
      }
    default: return state
  }
}
export const setZone = (value) => {
  return {
    type: SET_ZONE,
    data: value
  }
}
export const removeZoneInfo = () => {
  return {
    type: SET_ZONE,
    data: null
  }
}
export const addMessageDisplayHandler = (value) => {
  return {
    type: SHOW_MESSAGE_AFTER_ADD,
    data: value
  }
}