import { useState, useEffect, useRef } from 'react';

import { prepareFilter } from 'libs/common';
import { getVehicleClasses, getCategories } from 'services/types';
import { Controller } from 'libs/control';
import { getTariffsDict } from 'services/payments-service';
import { Loader2 } from 'libs/ui';
import { EpmGrid } from './grid';
import Filter from './filter';

export const Epm = (props) => {
  const [items, setItems] = useState({});
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [sortParams, setSortParams] = useState([{ field_name: 'pan', direction: 'asc' }]);
  const [tariffs, setTariffs] = useState(null);
  const mounted = useRef(false);

  // tariffs
  useEffect(() => {
    mounted.current = true;
    getTariffsDict().then((data) => {
      if (mounted.current) {
        setTariffs(data);
      }
    });
    return () => {
      mounted.current = false;
    };
  }, []);

  if (!tariffs) {
    return <Loader2 theme={props.theme} />;
  }

  // classes
  const vehicleClasses = getVehicleClasses();
  const categories = getCategories();

  /**
   * prepare params
   * @param {*} data
   * @returns
   */
  const prepareParams = (data) => {
    //console.log('🚀 ~ prepareParams ~ data:', data);
    const intFields = ['vehicle_category', 'vehicle_class'];
    const boolFields = ['is_blocked'];
    const addParams = {};
    if (props.clientId) {
      addParams.client_id = props.clientId;
    }
    return prepareFilter(data, addParams, { boolFields, intFields });
  };

  // grid actions
  const gridActions = {
    addEntries: (data) => setItems({ ...items, ...data }),
    updateEntry: (id, data) => {
      const newItems = { ...items };
      newItems[id] = { ...newItems[id], ...data };
      setItems(newItems);
    },
    clearEntries: () => setItems({}),
    setSortParams: (data) => setSortParams(data),
    setOffset: (val) => setOffset(val),
    setCount: (val) => setCount(val),
  };

  // grid params
  const gridParams = {
    entries: items,
    offset,
    count,
    sortParams,
  };

  return (
    <Controller
      {...props}
      {...gridParams}
      {...gridActions}
      type="csc"
      filterName="cscFilter"
      grid={EpmGrid}
      gridFilter={Filter}
      getRequestParams={prepareParams}
      // additional data
      tariffs={tariffs}
      vehicleClasses={vehicleClasses}
      categories={categories}
    />
  );
};
