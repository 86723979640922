import styled from 'styled-components';

export const FormBox = styled.div`
  position: absolute;
  z-index: 999;
  top: -220px;
  right: 0;
  width: 440px;
  height: 220px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.5);
`;

export const Header = styled.h1`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #262b31;
`;

export const CommentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 20px 0;
  padding: 13px 0;
  width: 400px;
  height: 80px;
  padding: 10px 0;
  align-items: flex-start;
  border-bottom: solid 1px #8e8e8e;
  border-top: solid 1px #8e8e8e;
  input::placeholder {
    color: #cccccc;
  }
`;

export const CommentFieldName = styled.p`
  color: #8f8f8f;
  font-size: 13px;
`;

export const CommentInput = styled.textarea`
  font-size: 15px;
  width: 270px;
  height: 60px;
  border: none;
  outline: none;
  resize: none;
  ::placeholder {
    color: #cccccc;
  }
`;
