import styled from 'styled-components';

export const DispatcherPass = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  align-items: center;
  font-size: 13px;
  font-style: italic;
  color: #d33d34;
  font-weight: 500;
  img {
    margin: 0 10px;
  }
`;
