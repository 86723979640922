import { FieldComponent } from 'libs/form';
import { ZoneInputBox } from '../../styledComponents';
import { FormWrapper, Inputs, Interface, ParamsWrapper, Wrapper, ZoneLabel } from './MenskingsTariffication.styled';

export const MenskingsTariffication = (props) => {
  const { selectedZone, display, disabled } = props;

  /**
   * get params for zones
   * @returns
   */
  const getParams = () => {
    // "free_day_interval": 900,
    // "free_night_interval": 600,
    // "day_cost": 15000,
    // "night_cost": 50000,
    // "start_day_time": "09:00:00",
    // "end_day_time": "21:00:00"

    const fields = [
      { name: 'free_day_interval', title: 'Бесплатный период день, мин', type: 'number' },
      { name: 'free_night_interval', title: 'Бесплатный период ночь, мин', type: 'number' },
      { name: 'day_cost', title: 'Стоимость день, руб/час', type: 'number' },
      { name: 'night_cost', title: 'Стоимость ночь, руб/час', type: 'number' },
      { name: 'start_day_time', title: 'Начало дневного времени', type: 'time' },
      { name: 'end_day_time', title: 'Начало ночного времени', type: 'time' },
    ];

    const fieldsEl = fields.map((el, idx) => {
      const other = { type: 'text', name: el.name, maxValueLength: 8 };
      if (el.type === 'number') {
        other.naturalNumber = true;
        other.withZero = true;
      }
      if (el.type === 'time') {
        other.type = el.type;
      }

      return (
        <Inputs key={idx}>
          <ZoneLabel>{el.title}</ZoneLabel>
          <ZoneInputBox wide>
            <FieldComponent disabled={disabled} {...other} />
            {/* <span>₽</span> */}
          </ZoneInputBox>
        </Inputs>
      );
    });

    return (
      <FormWrapper>
        <ParamsWrapper>{fieldsEl}</ParamsWrapper>
      </FormWrapper>
    );
  };

  return (
    <Interface color={selectedZone.color} display={display ? 'block' : 'none'}>
      <Wrapper>{getParams()}</Wrapper>
    </Interface>
  );
};

export default MenskingsTariffication;
