import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common_ru from './translations/ru/common.json';
import common_kk from './translations/kk/common.json';
import common_ar from './translations/ar/common.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  ru: {
    common: common_ru,
  },
  kk: {
    common: common_kk,
  },
  ar: {
    common: common_ar,
  },
};

const defaultLang = 'ru';
let lang = localStorage.getItem('lang');
if (!lang) {
  lang = defaultLang;
}

i18n.use(initReactI18next).init({
  ns: ['common'],
  lng: lang,
  defaultNS: 'common',
  resources,
  fallbackLng: defaultLang,
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
