import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { api } from 'api';
import { Button, themes } from 'libs/ui';
import { Content, DropZone, FileInfo, Buttons, Error, Success } from './uploader.styled';
import { Result } from './result';

export const Uploader = ({ isClients }) => {
  // console.log('🚀 ~ Uploader ~ isClients:', isClients);
  const [file, setFile] = useState('');
  const [error, setError] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [checking, setChecking] = useState(true);
  const [success, setSuccess] = useState(false);
  const novalid = !valid || success;

  const method = isClients ? 'clients.import_from_csv' : 'payment_means.import_from_csv';

  // event
  const onDrop = useCallback((acceptedFiles) => {
    setFile('');
    setError('');
    setValid(false);
    setResult(null);
    setChecking(true);
    setSuccess(false);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => {
        setError('Операция отменена');
      };
      reader.onerror = () => {
        setError('Ошибка чтения файла');
      };
      reader.onload = () => {
        setFile(reader.result);
        setError('');
      };
      reader.readAsText(file);
    });
  }, []);

  // dz
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    multiple: false,
    onDrop,
    onFileDialogOpen: () => {
      setFile('');
      setError('');
      setValid(false);
      setResult(null);
      setChecking(true);
      setSuccess(false);
    },
  });

  // file info
  const files = acceptedFiles.map((file) => (
    <div key={file.path}>
      {file.path} <span>- {file.size} байт</span>
    </div>
  ));

  /**
   * check csv
   */
  const check = () => {
    setLoading(true);
    api
      .call(method, { data: file, write: false })
      .then((data) => {
        setError('');
        setResult(data);
        const valid = data.total > 0 && !data.details?.filter((el) => el.result === 'failed').length;
        setValid(valid);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * save
   */
  const save = () => {
    if (novalid) {
      return;
    }
    setLoading(true);
    setChecking(false);
    api
      .call(method, { data: file, write: true })
      .then((data) => {
        setError('');
        setResult(data);
        setSuccess(true);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cl = loading ? 'loading' : '';

  return (
    <Content className={cl}>
      <DropZone {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Перетащите сюда файл или нажмите, чтобы выбрать файл</p>
      </DropZone>
      <Buttons>
        <Button theme={themes.gray} onClick={check} disabled={!file || !!result}>
          Проверить
        </Button>
        <Button theme={themes.green} onClick={save} disabled={!file || novalid}>
          Добавить
        </Button>
      </Buttons>
      <FileInfo>{files}</FileInfo>
      {error && <Error>{error}</Error>}
      {success && <Success>{'Данные из файла сохранены'}</Success>}
      <Result result={result} check={checking} />
    </Content>
  );
};
