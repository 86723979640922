import { getCategoryById, getVehicleClassById, getDate } from 'services/types';
import { ParkingCell } from 'libs/ui';
import { data } from 'services';

export const getTableParams = () => {
  const withBalance = window.config.use_subscription_balance;

  const getBalance = (__val, __params, row) => {
    const value = row.data?.balance;
    if (value === undefined) {
      return '';
    }

    return Math.round(value / 100);
  };

  const getFields = (props) => {
    const fields = [
      { name: 'pan', title: 'Абонемент', width: 180, sortField: 'pan' },
      { name: 'vehicle_plate', title: 'ГРЗ', width: 112, sortField: 'vehicle_plate' },
      { name: 'is_blocked', title: 'Статус', width: 155, sortField: 'status', render: (val) => data.getDictValue('payment_mean.status', val) },
      { name: 'start_at', title: 'Дата действия с', width: 210, sortField: 'start_at', render: (val) => getDate(val) },
      { name: 'end_at', title: 'Дата действия по', width: 210, sortField: 'end_at', render: (val) => getDate(val) },
      {
        name: 'balance',
        title: 'Баланс, ₽',
        width: 150,
        sortField: 'balance',
        enabled: withBalance,
        render: (val, params, row) => getBalance(val, params, row),
      },
      { name: 'name', title: 'Держатель', sortField: 'name' },
    ];
    if (!props.clientId) {
      fields.push({ name: 'client_name', title: 'Клиент', width: 145 });
    }

    fields.push({ name: 'vehicle_class', title: 'Класс ТС', width: 160, sortField: 'vehicle_class', render: (val) => getVehicleClassById(val) });
    fields.push({
      name: 'vehicle_category',
      title: 'Категория',
      width: 112,
      sortField: 'vehicle_category',
      render: (val) => getCategoryById(val),
    });
    fields.push({ name: 'tariff', title: 'Тариф', width: 180 });
    fields.push({ name: 'comment', title: 'Комментарий', width: 150 });

    if (window.config.central) {
      fields.unshift({ name: 'parking_id', title: 'Парковка', render: (val) => <ParkingCell parking_id={val} cmp={'span'} />, width: 140 });
    }

    return fields;
  };

  // get row class
  const getRowClass = (row) => {
    let out = '';
    out += ' ' + row.status;
    return out;
  };

  return { getFields, getRowClass, withComment: true };
};
