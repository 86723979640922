import React from 'react';
import { useTranslation } from 'react-i18next';

import { themes } from './theme';
import { Button } from './button';

export const UseBtn = () => {
  const { t } = useTranslation();
  return <Button theme={themes.blue}>{t('grid.useflt')}</Button>;
};
