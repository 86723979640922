import PropTypes from 'prop-types';

import { Wrapper, Indicator, Dot } from './loader.styled';

export const Loader = (props) => {
  const { size = 'small', ...rest } = props;
  return (
    <Wrapper {...rest}>
      <Indicator>
        <Dot $delay="200ms" size={size} />
        <Dot $delay="400ms" size={size} />
        <Dot $delay="600ms" size={size} />
      </Indicator>
    </Wrapper>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  $absolute: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
};
