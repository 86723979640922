import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getDegree, getDegreeFromCoord, getK, isMousePressed } from './helper';
import { Content, Dial, ClockLabel, Hand } from './clock.styled';

export const Clock = (props) => {
  const { view, current, setCurrent } = props;

  const [degree, setDegree] = useState(0);
  const [degreeIndex, setDegreeIndex] = useState(0);
  const clockRef = useRef(null);
  const [containerPos, setContainerPos] = useState(null);

  // init
  useEffect(() => {
    const def = getDegree(current, view);
    setDegree(def.degree);
    setDegreeIndex(def.degreeIndex);
  }, [current]);

  useEffect(() => {
    if (clockRef.current) {
      const maskPosition = clockRef.current.getBoundingClientRect();
      setContainerPos({
        y: maskPosition.top,
        x: maskPosition.left,
      });
    }
  }, [clockRef]);

  /**
   * get clock labels
   * @returns
   */
  const getClockLabels = () => {
    const data = {
      h: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22],
      m: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
      s: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
    };
    return data[view].map((i, j) => {
      return (
        <ClockLabel key={i} $active={degreeIndex === j}>
          {i}
        </ClockLabel>
      );
    });
  };

  /**
   * change degree after moving the arrow
   * @param {*} clientX
   * @param {*} clientY
   */
  const changeClock = (clientX, clientY) => {
    if (!containerPos) {
      return;
    }
    const x = clientX - containerPos.x;
    const y = clientY - containerPos.y;
    const degree = getDegreeFromCoord(x, y);
    const maxValues = {
      h: 23,
      m: 59,
      s: 59,
    };

    const k = getK(view);
    let base = Math.round(degree / k);
    if (base > maxValues[view]) {
      base = maxValues[view];
    }
    //console.log('🚀 ~ changeClock ~ base:', base);
    // const degreeOk = base * k;
    //const degreeIndex = getDegreeIndex(degreeOk);
    //setDegree(degreeOk);
    //setDegreeIndex(degreeIndex);
    setCurrent(base);
  };

  const handleMove = (event) => {
    event.preventDefault();
    if (isMousePressed(event)) {
      return;
    }
    changeClock(event.nativeEvent.clientX, event.nativeEvent.clientY);
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
    changeClock(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
  };

  const handleMoveUp = (event) => {
    //if (event.target === clockRef.current) return;
    changeClock(event.nativeEvent.clientX, event.nativeEvent.clientY);
  };

  const handleTouchUp = (event) => {
    //if (event.target === clockRef.current) return;
    changeClock(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
  };

  return (
    <Content>
      <div ref={clockRef}>
        <Dial onTouchMove={handleTouchMove} onTouchEnd={handleTouchUp} onMouseMove={handleMove} onMouseDown={handleMoveUp}>
          <Hand
            style={{
              transform: `rotate(${degree - 90}deg)`,
              WebkitTransform: `rotate(${degree - 90}deg)`,
            }}
          >
            &nbsp;
          </Hand>
          {getClockLabels()}
        </Dial>
      </div>
    </Content>
  );
};

Clock.propTypes = {
  view: PropTypes.string.isRequired,
  current: PropTypes.number.isRequired,
  setCurrent: PropTypes.func.isRequired,
};
