import React from 'react'
import { connect } from 'react-redux'

import { GridContainer } from 'libs/ui'
import { Controller } from 'libs/control'
import Grid from './Grid'

import {
  getRequestParams,
  clearEntries,
  addItems,
  setSortParams,
  setOffset,
  setItemInfo,
  addItem,
  updateItem,
  deleteItem
} from 'store/ducks/models/collection'
import { 
  setScroll,
  addFormDisplayHandler, 
  updateFormDisplayHandler,
  updateMessageDisplayHandler
} from 'store/ducks/interface/collectionGrid'

const Collection = (props) => {
  return (
    <GridContainer>
      <Controller
        type='collection'
        grid={Grid}
        offset={props.offset}
        entries={props.items}
        getRequestParams={props.getRequestParams}
        itemInfo={props.itemInfo}
        clearEntries={props.clearEntries}
        addEntries={props.addItems}
        setItemInfo={props.setItemInfo}
        addItem={props.addItem}
        updateItem={props.updateItem}
        deleteItem={props.deleteItem}
        setSortParams={props.setSortParams}
        setOffset={props.setOffset}
        setScroll={props.setScroll}
        sortParams={props.sortParams}
        addFormDisplayHandler={props.addFormDisplayHandler}
        updateFormDisplayHandler={props.updateFormDisplayHandler}
        updateMessageDisplayHandler={props.updateMessageDisplayHandler}
        interface={props.interface}
      />
    </GridContainer>
  )
}

const mapStateToProps = (store) => {
  return {
    items: store.collection.items,
    offset: store.collection.offset,
    itemInfo: store.collection.itemInfo,
    sortParams: store.collection.sortParams,
    interface: store.collectionGrid
  }
}

export default connect(mapStateToProps, {
  clearEntries,
  getRequestParams,
  setSortParams,
  setOffset,
  setScroll,
  setItemInfo,
  addItems,
  addItem,
  updateItem,
  deleteItem,
  addFormDisplayHandler,
  updateFormDisplayHandler,
  updateMessageDisplayHandler
})(Collection)