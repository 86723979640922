import React from 'react';

export const Select = (props) => {
  const { className, label, input, children, disabled } = props;

  return (
    <div className={className}>
      <label>{label}</label>
      <select {...input} disabled={disabled}>
        {children}
      </select>
    </div>
  );
};
