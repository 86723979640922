import React from 'react';
import PropTypes from 'prop-types';

import { getMeanType } from 'services/types';
import { DispatcherPass } from './mean-info.styled';

export const MeanInfo = (props) => {
  const { direction, from, to } = props;

  let meanType = null;
  let meanNumber = null;
  if (direction === 'from') {
    if (from.data && from.data.dispatcher_pass) {
      return (
        <DispatcherPass reverse>
          Шлагбаум открыт диспетчером
          <img src="/images/dispatcher-pass.png" alt="" />
        </DispatcherPass>
      );
    }
  }
  if (direction === 'to') {
    if (to.data && to.data.dispatcher_pass) {
      return (
        <DispatcherPass>
          Шлагбаум открыт диспетчером
          <img src="/images/dispatcher-pass.png" alt="" />
        </DispatcherPass>
      );
    } else {
      meanType = getMeanType(to.mean_type);
      meanNumber = to.mean_number;
    }
  }
  return meanType && meanNumber ? `${meanType} ${meanNumber}` : null;
};

MeanInfo.propTypes = {
  direction: PropTypes.any.isRequired,
  from: PropTypes.any.isRequired,
  to: PropTypes.any.isRequired,
};
